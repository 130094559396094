import React, { CSSProperties } from 'react';


function setLabelStyle(element: HTMLElement, width: number, height: number): void {
    if (!element) {
        return;
    }

    const isLandscape = width >= height;
    const baseSideSize = isLandscape ? width : height;
    const secondarySideSize = isLandscape ? height : width;

    const {
        width: textWidth,
        height: textHeight,
    } = element.getBoundingClientRect();

    const factorByWidth = baseSideSize / textWidth;
    const factorByHeight = secondarySideSize / textHeight;
    const factor = Math.min(factorByWidth, factorByHeight) * 0.6;
    const fontSize = textHeight * (factor > 1.5 ? Math.ceil(factor) : factor);

    element.style.fontSize = `min(${fontSize}px, 3vh)`;

    if (isLandscape) {
        element.classList.add('vertical');
    }
}

export type Area = {
    id: string,
    label: string,
    width: number,
    height: number,
    x?: number,
    y?: number,
    rotate_degree?: number,
    color?: string,
}

type Props = {
    area: Area,
    style?: CSSProperties,
}

export default function AreaComponent(props: Props) {
    const {
        area,
        style
    } = props;
    const {
        label,
        width,
        height,
        x = 0,
        y = 0,
        rotate_degree = 0,
        color,
    } = area;

    return (
        <div
            ref={element => color && element?.style.setProperty('--area-custom-color', color)}
            style={{
                ...style,
                width,
                height,
                left: x,
                top: y,
                transformOrigin: 'top left',
                transform: `rotate(${rotate_degree}deg)`,
            }}
        >
            <span
                ref={element => setLabelStyle(element, width, height)}
            >{label} {width} &times; {height}</span>
        </div>
    )
}
