export const NIKE_EASE = [0.4, 0, 0, 1];

export const CHAPTERS = 6;
export const CHAPTER_TIMING = [6000, 10000, 10000, 10000, 11000, 2000];
export const CHAPTER_FRAMES = [0, 360, 960, 1540, 2140, 0]

export const NTC_FALLBACK = 'https://res.cloudinary.com/jbboerner/image/upload/v1676403353/nikehyperlive-staging/1676403353691.png'

export const MEDIA_TRANSITIONS = {
    TopToBottom: 'topToBottom',
    LeftToRight: 'leftToRight',
    RightToLeft: 'rightToLeft',
    RightToMiddle: 'rightToMiddle',
    LeftCorner: 'leftCorner',
    TopBack: 'topBack',
    None: 'none',
    Center: 'center'
}

export const TRANSITIONS = {
    [MEDIA_TRANSITIONS.TopToBottom]: {
        initial: { clipPath: `inset(0% 1% 100% 0%)` },
        animate: { clipPath: 'inset(0% 0% 0% 0%)' },
        exit: { clipPath: 'inset(100% 0% 0% 0%)' }
    },
    [MEDIA_TRANSITIONS.LeftToRight]: {
        initial: { clipPath: `inset(0% 100% 0% 0%)` },
        animate: { clipPath: 'inset(0% 0% 0% 0%)' },
        exit: { clipPath: 'inset(0% 0% 0% 100%)', }
    },
    [MEDIA_TRANSITIONS.RightToLeft]: {
        initial: { clipPath: `inset(0% 0% 0% 100%)` },
        animate: { clipPath: 'inset(0% 0% 0% 0%)' },
        exit: { clipPath: 'inset(0% 100% 0% 0%)', }
    },
    [MEDIA_TRANSITIONS.RightToMiddle]: {
        initial: { clipPath: `inset(0% 0% 0% 100%)` },
        animate: { clipPath: 'inset(0% 0% 0% 0%)' },
        exit: { clipPath: 'inset(50% 0% 50% 0%)', }
    },
    [MEDIA_TRANSITIONS.LeftCorner]: {
        initial: { clipPath: `inset(0% 100% 100% 0%)` },
        animate: { clipPath: 'inset(0% 0% 0% 0%)' },
        exit: { clipPath: 'inset(0% 100% 100% 0%)', }
    },
    [MEDIA_TRANSITIONS.TopBack]: {
        initial: { clipPath: `inset(0% 1% 100% 0%)` },
        animate: { clipPath: 'inset(0% 0% 0% 0%)' },
        exit: { clipPath: 'inset(0% 0% 100% 0%)' }
    },
    [MEDIA_TRANSITIONS.None]: {
        initial: { clipPath: `inset(0% 0% 0% 0%)` },
        animate: { clipPath: 'inset(0% 0% 0% 0%)' },
        exit: { clipPath: 'inset(0% 0% 0% 0%)' }
    },
    [MEDIA_TRANSITIONS.Center]: {
        initial: { clipPath: `inset(0% 100% 100% 0%)` },
        animate: { clipPath: 'inset(0% 0% 0% 0%)' },
        exit: { clipPath: 'inset(50% 50% 50% 50%)'}
    },
}

export const DEFAULT_TRANSITION = { ease: NIKE_EASE, duration: 2 }

export const BLOCK_SIZES = {
    Logo: {
        width: '12.5vh',
        height: '12.5vh',
    },
    Header: {
        width: '37.5vh',
        height: '12.5vh',
    },
    Body: {
        width: '37.5vh',
        height: '18.75vh',
    },
    AppCard: {
        width: '37.5vh',
        height: '34.375vh',
    },
    AppCardTall: {
        width: '37.5vh',
        height: '46.875vh',
    },

}

export const TEXT_SIZES = {
    Super: 'super',
    Heading: 'heading',
    AppHeading: 'appHeading',
    Body: 'body',
    CTA: 'cta',

}

export const TEXT_STYLES = {
    [TEXT_SIZES.Super]: { fontSize: '16.3vh', lineHeight: '12.2vh', letterSpacing: '-.3vh' },
    [TEXT_SIZES.Heading]: { fontSize: '5.98958333vh', lineHeight: '5.09114583vh' },
    [TEXT_SIZES.AppHeading]: { fontSize: '3.64583333vh', lineHeight: '3.09895833vh' },
    [TEXT_SIZES.Body]: { fontSize: '1.5625vh', lineHeight: '1.5625vh' },
    [TEXT_SIZES.CTA]: { fontSize: '1.666vh', lineHeight: '1.666vh' }
}

