import {SequenceConfig, Translatable, WaveDirection} from "../types";
import { get, set } from "./index";
import { wait } from "../helpers";

export interface SequenceState {
	sequence?: SequenceConfig;
	setSequence: (sequence: SequenceConfig) => void;
	_getSequence: () => SequenceConfig;
	timeouts: Array<number>;
	addTimeout: (timeout: number) => void;

	waveDirection: WaveDirection;
	codificationTrigger: number;
	codify: () => void;

	headlineIndex: number;
	playingHeadline: boolean;
	activeMediaIndex: number;
	playingMediaSequence: boolean;
	activeLanguage?: string;

	playSequence: (config?: SequenceConfig) => Promise<void>;
	playContentRotation: () => Promise<void>;
	_playHeadline: () => Promise<void>;
	nextLanguage: () => void;
	scheduleTakeover: () => Promise<void>;
	schedulePlayMedia: () => Promise<void>;
	stopSequence: () => void;
	stopHeadline: () => void;

	getActiveHeading: (sectionIndex: number) => Translatable;
}

// const DEFAULT_SWITCH_OUT_DURATION = 30_000;
const DEFAULT_SWITCH_OUT_DURATION = 5_000;
const DEFAULT_PLAY_MEDIA_INTERVAL = 2 * 60 * 1_000;
// const DEFAULT_PLAY_MEDIA_INTERVAL = 15_000;
const DEFAULT_MEDIA_DURATION = 5_000;
const DEFAULT_MEDIA_GAP = 1_000;

export const sequenceState = (set: set, get: get): SequenceState => ({
	timeouts: [],
	addTimeout: (timeout: number) => {
		set(({ timeouts }) => ({ timeouts: [...timeouts, timeout] }));
	},
	waveDirection: "left-to-right",

	codificationTrigger: 0,
	codify: () => {
		set(({ codificationTrigger }) => ({ codificationTrigger: codificationTrigger + 1 }));
	},

	headlineIndex: 0,
	playingHeadline: false,
	activeMediaIndex: 0,
	playingMediaSequence: false,
	activeLanguage: 'en',

	// play the sequence recursively and make sure to stop if the sequence is stopped
	playSequence: async (sequence?: SequenceConfig) => {
		sequence && set({ sequence });
		get().codify();
		get().playContentRotation().then();

		set({ playingMediaSequence: true });
		get().schedulePlayMedia().then();
	},
	playContentRotation: async () => {
		set({ playingHeadline: true });
		return get()._playHeadline();
	},
	_playHeadline: async () => {
		const switchDuration = get()._getSequence()?.headlineSwitchDuration ?? DEFAULT_SWITCH_OUT_DURATION;

		await wait(switchDuration, {
			getTimeout: get().addTimeout,
		});

		if (!get().playingHeadline) {
			return;
		}
		const current = get().headlineIndex;

		const next = current === 0 ? 1 : 0;

		if (current === 1) {
			get().nextLanguage();
		}

		set({
			headlineIndex: next,
		});

		return get().playContentRotation();
	},

	setSequence: (sequence: SequenceConfig) => {
		set({ sequence });
	},
	_getSequence: () => get().sequence ?? get().theme?.sequence ?? {},

	nextLanguage: () => {
		const languageIndex = get().languages?.indexOf(get().activeLanguage ?? 'en') ?? 0;
		const nextLanguageIndex = (languageIndex + 1) % (get().languages?.length ?? 1);
		const nextLanguage = get().languages?.[nextLanguageIndex] ?? 'en';

		set({
			activeLanguage: nextLanguage,
		});
	},

	scheduleTakeover: async () => {
		return await get().enableTakeover({
      ...get().takeover,
      enabled: true,
    });
	},

	schedulePlayMedia: async () => {
		const sequence = get()._getSequence();
		const interval = sequence?.playMediaInterval ?? DEFAULT_PLAY_MEDIA_INTERVAL;
		const duration = sequence?.mediaDuration ?? DEFAULT_MEDIA_DURATION;
		const skipTakeover = sequence?.skipTakeover ?? false;
		const gap = sequence?.mediaGap ?? DEFAULT_MEDIA_GAP;

		const entries = get().waveDirection === "right-to-left" ? get().entries : get().entries;

		await wait(interval, {
			getTimeout: get().addTimeout,
		});

		get().stopHeadline();

		// force index 1
		set({
			headlineIndex: 1,
		});

		for (let i = 0; i < entries.length; i++) {
			const reverseIndex = get().waveDirection === "right-to-left" ? entries.length - i - 1 : i;
			get().disableMedia();

			const entry = entries[reverseIndex];
			if (!entry.media?.url) {
				await wait(DEFAULT_SWITCH_OUT_DURATION, {
					getTimeout: get().addTimeout,
				});
				continue;
			}

			if (!get().playingMediaSequence) {
				return;
			}

			get().enableMedia(reverseIndex);

			await wait(duration, {
				getTimeout: get().addTimeout,
			});
			get().disableMedia();
			
			const isLast = i === entries.length - 1;
			const lastGap = isLast ? DEFAULT_SWITCH_OUT_DURATION : gap;

			await wait(lastGap, {
				getTimeout: get().addTimeout,
			});
		}

		get().playContentRotation();

		await wait(DEFAULT_SWITCH_OUT_DURATION * 2, {
			getTimeout: get().addTimeout,
		});

		if (get().takeover && !skipTakeover) {
			await get().scheduleTakeover();
		}

		return get().schedulePlayMedia();
	},

	stopHeadline: () => {
		set({ playingHeadline: false });
	},

	stopSequence: () => {
		get().disableMedia();
		set({
			headlineIndex: 1,
			playingHeadline: false,
			playingMediaSequence: false,
		});

		get().timeouts.forEach(i => clearTimeout(i));
		set({ timeouts: [] });
	},

	getActiveHeading: (sectionIndex: number) => {
		const section = get().entries?.[sectionIndex];

		const index = get().isMediaPlaying(sectionIndex) ? 1 : get().headlineIndex;
		return section.heading?.[index] ?? section.heading?.[0] ?? {};
	},
});