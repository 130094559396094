export default function Arrow({}) {
    return (
        <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_22_10142)">
                <path d="M59 29.5822C59 45.7976 45.8524 59 29.5822 59C13.0928 59 0 45.7976 0 29.5822C0 12.9285 13.0928 0 29.5822 0C45.8524 0 59 12.9285 59 29.5822ZM2.08171 29.5822C2.08171 44.8115 14.0789 56.9183 29.5274 56.9183C44.5376 56.9183 56.8635 44.8115 56.8635 29.5822C56.8635 14.4076 44.5924 2.13649 29.5274 2.13649C14.1337 2.08171 2.08171 14.4076 2.08171 29.5822Z" fill="currentColor"/>
                <g clipPath="url(#clip1_22_10142)">
                    <path d="M12.0488 28.7686H36.5621C39.0619 28.7686 40.7782 29.3963 43.1661 29.3963V29.2486C38.4276 26.7749 36.3382 24.0428 34.7711 21.4215L36.6367 19.7231C38.9873 23.8951 41.7483 26.9226 46.5241 29.2486V30.43C41.7483 32.756 38.9499 35.8204 36.6367 39.9924L34.7711 38.2572C36.3382 35.7097 38.3903 32.9406 43.1288 30.5039V30.3562C40.7409 30.3562 39.0246 30.9838 36.5247 30.9838H12.0488V28.7686Z" fill="currentColor"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_22_10142">
                    <rect width="59" height="59" fill="white"/>
                </clipPath>
                <clipPath id="clip1_22_10142">
                    <rect width="34.5125" height="20.2693" fill="white" transform="translate(12.0498 19.7214)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export function ArrowRaw({}) {
    return (
        <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.57227 28.5492H38.0285C41.0324 28.5492 43.0948 29.3034 45.9642 29.3034V29.1259C40.2702 26.1534 37.7595 22.8704 35.8764 19.7206L38.1182 17.6797C40.9428 22.6929 44.2605 26.3309 49.9993 29.1259V30.5456C44.2605 33.3406 40.8978 37.0229 38.1182 42.0362L35.8764 39.9511C37.7595 36.8899 40.2254 33.5624 45.9194 30.6344V30.4569C43.05 30.4569 40.9876 31.211 37.9836 31.211H8.57227V28.5492Z"
                fill="currentColor"/>
        </svg>
    )
}