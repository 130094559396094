.chapter_container {
    --hoi-font-vertical-correction: 0;

    display: grid;
    grid-template-areas:
        "main"
        "footer";
    height: 100%;

    &.preprocessing {
        opacity: 0;
    }

    .main_container {
        position: relative;
        z-index: 1;

        > * {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
