import {useMemo} from "react";
import {qrUrlTrack, stringIsFilled} from "../shared";
import Typography from "./Typography";
import {SnkrsCalProps} from "../screens/SnkrsCal";

export function SnkrsDropQr({url, cta = 'VIEW IN SNKRS', concept = 'wof'}: { url: string, cta?: string, concept?: SnkrsCalProps['concept'] }) {
    const fullUrl = useMemo(() => qrUrlTrack(url), [url]);

    return (
        <div style={{position: 'absolute', bottom: 45, minWidth: 400}}>
            {
                stringIsFilled(cta) && (
                    <Typography variant={'jordan'} color={'black'} size={20}>
                        {cta}
                    </Typography>
                )
            }

            <div style={{display: 'flex', gap: 20, marginTop: 13, alignItems: 'center'}}>
                <img
                    style={{
                        width: 130,
                        height: 130,
                        border: concept === 'wof' ? '1px solid rgba(0,0,0,0.25)' : '1px solid black',
                        borderRadius: concept === 'wof' ? 5.7 : 0,
                    }}
                    src={fullUrl}
                    alt=""
                />
            </div>
        </div>
    )
}