import * as Sentry from '@sentry/browser';
import { ChannelVitals } from 'vitals';
import _, { isEmpty } from 'lodash';
import { ANIMATION_TYPES } from '../constants';
import initSentry from '../utils/sentry';
import { getChannel, getStore } from '../touchpoints/rise/api';
import pulseDataHydrator from '../touchpoints/rise/data/pulseDataHydrator';
import { dispatchRestart, extractData } from '../utilities';
import StoreTakeover from '../services/takeover-service/store-takeover';
import { shouldPlayTakeover } from '../touchpoints/rise/animations/fastlane-kids-takeover/shared';
import { toBool } from '../utils/string-converter';
import * as AppEventsService from '../services/app-events.service';
import ConductorEventsEnum from '../enums/ConductorEvents.enum';
import renderAnimationOverlay from '../touchpoints/core/animations/overlay/entry';
import {
  ANALYTICS_BASE_URL,
  DISABLE_SEND_HEARTBEATS_QUERY_PARAM_KEY,
  IS_ANALYTICS_ENABLED,
  HEARTBEATS_DELAY,
} from '../config';
import loadFonts from '../utils/loadFonts';

initSentry();


const fontsToLoad = {
  [ANIMATION_TYPES.LOOP]: undefined,
  [ANIMATION_TYPES.SPORT_HUB]: [
    'COMP_FUTURA',
  ],
  [ANIMATION_TYPES.POD]: [
    'COMP_FUTURA',
  ],
  [ANIMATION_TYPES.FAST_LANE]: undefined,
  [ANIMATION_TYPES.FASTLANE_TICKER]: undefined,
  [ANIMATION_TYPES.FAST_LANE_KIDS_TAKEOVER]: undefined,
  [ANIMATION_TYPES.HUDDLE_FACETS]: [
    'COMP_FUTURA',
  ],
  [ANIMATION_TYPES.RISE_TICKERS]: [
    'COMP_FUTURA',
  ],
  [ANIMATION_TYPES.CITY_REPLAY]: [
    'COMP_FUTURA',
  ],
  [ANIMATION_TYPES.MTZ]: undefined,
  [ANIMATION_TYPES.RAW_VIDEO]: [],
  [ANIMATION_TYPES.COVERSTORY]: undefined,
  [ANIMATION_TYPES.MANNEQUIN]: undefined,
  [ANIMATION_TYPES.LL_TICKER]: undefined,
  [ANIMATION_TYPES.CATALOG_APPAREL]: undefined,
  [ANIMATION_TYPES.LIFE_365]: undefined,
  [ANIMATION_TYPES.ATRIUM]: undefined,
  [ANIMATION_TYPES.WIRE_FRAMES]: undefined,
  [ANIMATION_TYPES.PROGRAM_MESSAGING]: undefined,
  [ANIMATION_TYPES.CATALOG_APPAREL_WIDE]: undefined,
  [ANIMATION_TYPES.CHAPTER_SLIDESHOW]: [],
  [ANIMATION_TYPES.LIFE_SNKRS_CAL]: undefined,
  [ANIMATION_TYPES.COMMUNITY_BOARD]: undefined,
  [ANIMATION_TYPES.LIVE_FITNESS_TICKERS]: undefined,
  [ANIMATION_TYPES.RISE_STORY_TELLING]: undefined,
  [ANIMATION_TYPES.WOF_CORE]: undefined,
  [ANIMATION_TYPES.WOF_CENTER_COURT]: undefined,
  [ANIMATION_TYPES.NSIL_SCHEDULE]: undefined,
  [ANIMATION_TYPES.CORE_TICKER]: [
    'jordan-condensed',
    'jordan-condensed-cn',
    'COMP_FUTURA',
    'noto-sans-sc',
    'helvetica-neue-hoi',
    'helvetica-neue-hoi_ehq',
  ],
  [ANIMATION_TYPES.RISE_NBY]: undefined,
  [ANIMATION_TYPES.MEDIA_WALL]: undefined,
  [ANIMATION_TYPES.NTC]: undefined,
  [ANIMATION_TYPES.ECOSYSTEM_BOARD]: undefined,
  [ANIMATION_TYPES.WAYFINDING]: [
    'helvetica-neue-hoi-ehq',
  ],
  [ANIMATION_TYPES.MEMBERSHIP]: [
    'palatino',
  ],
  [ANIMATION_TYPES.NBY]: undefined,
  [ANIMATION_TYPES.FAST_LANE_2]: undefined,
};

/**
 * Describes which animation types have precedence over the others.
 * It will not touch the order of the unprioritized animations.
 */
const PRIORITIZE_ANIMATIONS = [
  ANIMATION_TYPES.RAW_VIDEO,
  // Additional prioritized animations...
];

class Runner {
  constructor() {
    this.isSyncing = false;
    this.currentAnimationIndex = 0;
    this.broadcasts = [];
    this.originalBroadcasts = [];
    this.channel = null;
    this.data = null;
    this.params = new URLSearchParams(window.location.search);
    this.setChannel();
    this.extractData();
    this.storeTakeover = null;
    this.channelData = null;
    this.pixelmapSlug = this.getParam('pixelmap-slug');
    this.disableSendHeartbeats = toBool(this.getParam(DISABLE_SEND_HEARTBEATS_QUERY_PARAM_KEY));
  }

  getParam(param) {
    return this.params.get(param);
  }

  setChannel() {
    this.channel = this.getParam('channel');
    this.label = this.getParam('label');
  }

  isPlayground() {
    return this.getParam('playground');
  }

  setDemo(animation) {
    const demo_loop = _.get(animation, 'json_data.demo_loop', false);

    const url_mode = this.getParam('mode');
    // IS DEMO LOOP if set in Hyperlive or ?mode=store
    // ?mode=develop overrides Hyperlive setting for development
    const isDemo = (demo_loop || url_mode === 'store') && url_mode != 'develop';
    this.demo = isDemo ? isDemo : null;
  }

  extractData() {
    this.data = extractData();
  }

  fromUrlData() {
    window.animations = [this.data];
    console.log('data', this.data);
    // this.loadChannel()
  }

  async loadStoreDetails() {
    const animation = window.animations?.[0];
    if (!animation || !animation.store_id) {
      console.warn('Could not find animation or storeId and thus unable to fetch store');
      return;
    }

    const store = await getStore(animation.store_id);
    window.animation_store = store;
    window.languages = _.get(store, 'json_data.languages');
  }

  // Adding `zchannel=slug` to the editor will pull in the channel data.
  async loadZChannelData() {
    const channel = this.getParam('zchannel');

    if (channel) {
      try {
        this.channelData = await getChannel(channel);
        window.channel = _.cloneDeep(this.channelData);
        window.isPreviewMode = true;
        console.log('data', this.channelData);
      } catch (err) {
        console.warn(`could not load channel data for ${channel}`);
      }

    }
  }

  // loads data, if the same, no touchy...
  async load() {
    try {
      const channel = await this.fetchChannel(true);
      const live = this.filterLive(channel);
      const sortFunc = (a, b) => a.id > b.id;

      const current = JSON.stringify(live.sort(sortFunc));
      const prev = JSON.stringify(this.originalBroadcasts.sort(sortFunc));

      const contentChanged = current !== prev;

      if (contentChanged && this.broadcasts.length !== 0) {
        AppEventsService.sendEvent(ConductorEventsEnum.Loaded);
        return false;
      }

      this.broadcasts = live;
      this.originalBroadcasts = _.cloneDeep(live);
      window.animations = this.prioritizeAnimations(live.map(broadcast => broadcast.animation));
      window.channel = _.cloneDeep(channel);

      AppEventsService.sendEvent(ConductorEventsEnum.Loaded);
    } catch (e) {
      AppEventsService.sendEvent(ConductorEventsEnum.Error, e);
      console.log('load() - error', e);
    }
    return true;
  }

  prioritizeAnimations = (animations) => {
    // filter down the prioritized animations
    const prioritized = PRIORITIZE_ANIMATIONS.map((animationType) => {
      return animations.filter((animation) => animation.animation_type_id === animationType);
    }).flat();

    // get all other animations, don't touch the order.
    const unprioritized = animations.filter((animation) => PRIORITIZE_ANIMATIONS.indexOf(animation.animation_type_id) === -1);

    return [
      ...prioritized,
      ...unprioritized,
    ];
  };

  async start() {
    if (this.isPlayground()) {
      let r = require('../touchpoints/rise/animations/_playground/entry');
      r();
      return;
    }

    if (!window.animations?.length || !window.animations?.[this.currentAnimationIndex]) {
      console.error('Could not find any animations, make sure you have the correct channel / pixelmap or data.');

      renderAnimationOverlay();
      return;
    }

    const current = window.animations[this.currentAnimationIndex];
    this.setDemo(current);

    const currentType = Object.keys(ANIMATION_TYPES).find(
      key => ANIMATION_TYPES[key] == current.animation_type_id,
    );

    Sentry.addBreadcrumb({
      category: currentType,
      message: `${this.channel ? 'Listening to' : 'Embedded in'} "${this
        .channel ?? 'Hyperlive'}" - ${currentType}`,
      level: Sentry.Severity.Info,
    });

    Sentry.setTag(
      'origin',
      this.isListening() ? 'touchpoint' : 'hyperlive',
    );
    Sentry.setTag('animation', currentType);

    const renderer = this.getRenderer(current);
    const loader = this.getDataLoader(current);

    // @NOTE: if preview too slow just let it use the fallback data.
    // by only loading when listening to channel.
    // if (loader && this.isListening()) {
    if (loader) {
      await loader();
    }

    await this.prepStoreTakeover();
    this.prepSyncing();

    this.overloadConfig();

    AppEventsService.sendEvent(ConductorEventsEnum.Start);

    renderer();
  }

  overloadConfig() {
    const override = this.channelData?.channels?.[0]?.json_config?.override_animation_data;

    if (!override) {
      return;
    }

    window.animations = window.animations?.map((animation) => {
      return _.merge(animation, { json_data: override });
    });
  }

  prepSyncing() {
    const data = this.channelData;

    const syncMinutes = data?.channels?.[0]?.json_config?.sync_every_minutes;

    if (syncMinutes && this.isSyncing === false) {
      this.isSyncing = true;
      shouldPlayTakeover(syncMinutes, () => {
        dispatchRestart();
      }, 2);
    }
  }

  async prepStoreTakeover() {
    if (this.storeTakeover) {
      await this.storeTakeover.reload();
      return;
    }

    const channels = await this.fetchChannel();
    if (!channels?.channels?.length) {
      return;
    }

    this.storeTakeover = new StoreTakeover(channels?.channels?.[0], window.animation_store);
  }

  async fetchChannel(forceLoad = false) {
    const shouldFetchChannel = !this.channelData || forceLoad;

    if (shouldFetchChannel && !isEmpty(this.channel)) {
      this.channelData = await getChannel(this.channel);
    }

    if (this.storeTakeover) {
      this.storeTakeover.updateChannel(this.channelData?.channels?.[0] || null);
    }

    return this.channelData;
  }

  /**
   * DataLoaders can hydrate the data based on
   * the animation type and its data.
   *
   * Useful for example for real time data.
   *
   * @param animation
   * @returns Promise{*|null}
   */
  getDataLoader(animation) {
    const loaders = {
      [ANIMATION_TYPES.FAST_LANE]: pulseDataHydrator,
    };

    return loaders[animation.animation_type_id] ?? null;
  }

  inPixelMap() {
    const originPixelMap = this.getParam('origin') === 'pixel-map';
    const ignorePixelMap = this.getParam('ignore-pixel-map') === 'true';

    return ignorePixelMap ? false : originPixelMap;
  }

  getRenderer(animation) {
    const renderers = {
      [ANIMATION_TYPES.LOOP]: () => require('../touchpoints/rise/animations/demo/entry'),

      [ANIMATION_TYPES.SPORT_HUB]: () => require('../touchpoints/rise/animations/hub/entry'),

      [ANIMATION_TYPES.POD]: () => require('../touchpoints/rise/animations/pod/entry'),

      [ANIMATION_TYPES.FAST_LANE]: () => {
        // embedded only
        if (window.animations.length === 1 && this.data || this.inPixelMap()) {
          require('../touchpoints/rise/animations/fastlane/cms');
        } else {
          require('../touchpoints/rise/animations/fastlane/entry');
        }
      },
      [ANIMATION_TYPES.FASTLANE_TICKER]: () =>
        require('../touchpoints/rise/animations/fastlaneticker/entry'),
      [ANIMATION_TYPES.FAST_LANE_KIDS_TAKEOVER]: () => {
        if (window.animations?.some(({ animation_type_id }) => animation_type_id === ANIMATION_TYPES.FAST_LANE)) {
          return require('../touchpoints/rise/animations/fastlane/entry');
        }

        if (window.animations?.some(({ animation_type_id }) => animation_type_id === ANIMATION_TYPES.FASTLANE_TICKER)) {
          return require('../touchpoints/rise/animations/fastlaneticker/entry');
        }

        if (this.getParam('target') === 'fastlane') {
          return require('../touchpoints/rise/animations/fastlane-kids-takeover/FastLane/Preview');
        }

        if (this.getParam('target') === 'tickers') {
          return require('../touchpoints/rise/animations/fastlane-kids-takeover/FastLaneTickers/Preview');
        }
      },
      [ANIMATION_TYPES.HUDDLE_FACETS]: () =>
        require('../touchpoints/rise/animations/huddle/entry'),

      [ANIMATION_TYPES.RISE_TICKERS]: () =>
        require('../touchpoints/rise/animations/scrollticker/entry'),

      [ANIMATION_TYPES.CITY_REPLAY]: () =>
        require('../touchpoints/rise/animations/cityreplay/entry'),

      [ANIMATION_TYPES.MTZ]: () =>
        require('../touchpoints/rise/animations/mtz/entry'),

      [ANIMATION_TYPES.RAW_VIDEO]: () =>
        require('../touchpoints/rise/animations/video/entry'),

      [ANIMATION_TYPES.COVERSTORY]: () =>
        require('../touchpoints/live/animations/coverstory/entry'),

      [ANIMATION_TYPES.MANNEQUIN]: () =>
        require('../touchpoints/live/animations/mannequin/entry'),

      [ANIMATION_TYPES.LL_TICKER]: () =>
        require('../touchpoints/live/animations/ll_ticker/entry'),

      [ANIMATION_TYPES.CATALOG_APPAREL]: () =>
        require('../touchpoints/live/animations/catalog-apparel/entry'),

      [ANIMATION_TYPES.LIFE_365]: () =>
        require('../touchpoints/live/animations/life365/entry'),

      [ANIMATION_TYPES.ATRIUM]: () =>
        require('../touchpoints/live/animations/atrium/entry'),
      [ANIMATION_TYPES.WIRE_FRAMES]: () =>
        require('../touchpoints/live/animations/atrium/entry'),
      [ANIMATION_TYPES.PROGRAM_MESSAGING]: () =>
        require('../touchpoints/live/animations/program-messaging/entry'),
      [ANIMATION_TYPES.CATALOG_APPAREL_WIDE]: () =>
        require('../touchpoints/live/animations/catalog-apparel/entry'),
      [ANIMATION_TYPES.CHAPTER_SLIDESHOW]: () =>
        require('../touchpoints/rise/animations/chapter-slideshow/entry'),
      [ANIMATION_TYPES.LIFE_SNKRS_CAL]: () =>
        require('../touchpoints/live/animations/snkrs-cal/entry'),
      [ANIMATION_TYPES.COMMUNITY_BOARD]: () =>
        require('../touchpoints/live/animations/community-board/entry'),
      [ANIMATION_TYPES.LIVE_FITNESS_TICKERS]: () =>
        require('../touchpoints/live/animations/community-board/ticker-entry'),
      [ANIMATION_TYPES.RISE_STORY_TELLING]: () =>
        require('../touchpoints/rise/animations/risestorytelling/entry'),
      [ANIMATION_TYPES.WOF_CORE]: () =>
        require('../touchpoints/jordan/animations/core/entry'),
      [ANIMATION_TYPES.WOF_CENTER_COURT]: () =>
        require('../touchpoints/jordan/animations/center-court/entry'),
      [ANIMATION_TYPES.NSIL_SCHEDULE]: () =>
        require('../touchpoints/nsil/animations/schedule/entry'),
      [ANIMATION_TYPES.CORE_TICKER]: () =>
        require('../touchpoints/core/animations/ticker/entry'),
      [ANIMATION_TYPES.RISE_NBY]: () =>
        require('../touchpoints/rise/animations/nby-pricing/entry'),
      [ANIMATION_TYPES.MEDIA_WALL]: () =>
        require('../touchpoints/core/animations/media-wall/entry'),
      [ANIMATION_TYPES.NTC]: () =>
        require('../touchpoints/rise/animations/ntc/entry'),
      [ANIMATION_TYPES.ECOSYSTEM_BOARD]: () =>
        require('../touchpoints/x/animations/ecosystem-board/entry'),
      [ANIMATION_TYPES.WAYFINDING]: () =>
        require('../touchpoints/house-of-innovation/animations/core/entry'),
      [ANIMATION_TYPES.MEMBERSHIP]: () =>
        require('../touchpoints/core/animations/membership/entry'),
      [ANIMATION_TYPES.NBY]: () =>
        require('../touchpoints/core/animations/nby/entry'),
      [ANIMATION_TYPES.FAST_LANE_2]: () =>
        require('../touchpoints/core/animations/fast-lane-2/entry'),
      [ANIMATION_TYPES.CHAPTER_PLAYER]: () =>
        require('../touchpoints/chapter-player/entry'),
    };

    const type = this.demo
      ? ANIMATION_TYPES.LOOP
      : animation.animation_type_id;
    const renderer = renderers[type];

    if (!renderer) {
      console.warn(`Unknown animation type id "${type}". The basic overlay will be displayed for it.`);
      return renderAnimationOverlay;
    }

    return async () => {
      await loadFonts(fontsToLoad[type]);
      renderer();
    };
  }

  filterLive(schedule) {
    let broadcasts = [];

    schedule.channels?.forEach(item => {
      broadcasts = [...broadcasts, ...item.schedule];
    });

    return broadcasts.filter(broadcast => {
      // @todo: scope by time.
      return true;
    });
  }

  isListening() {
    return !!this.channel;
  }
}

export default async function runRise() {
  AppEventsService.sendEvent(ConductorEventsEnum.Init);

  try {
    const runner = new Runner();

    if (runner.isListening()) {
      const result = await runner.load();

      if (result === false) {
        window.location.reload();
      } else {
        await runner.loadStoreDetails();
        runner.start();
      }

      let vitals = null;
      if (!runner.disableSendHeartbeats && runner.label && window.animation_store) {
        vitals = new ChannelVitals({
          baseUrl: ANALYTICS_BASE_URL,
          slug: runner.channel,
          label: runner.label,
          storeId: _.get(window, 'animation_store.id'),
          pixelMapSlug: runner.pixelmapSlug ?? null,
          delay: HEARTBEATS_DELAY,
        });
      }

      setInterval(async () => {
        try {
          const result = await runner.load();
          if (result === false) {
            window.location.href =
              window.location.pathname +
              window.location.search +
              window.location.hash;
          } else {
            runner.start();
          }

          if (vitals && IS_ANALYTICS_ENABLED) {
            await vitals.sendHeartbeat();
          }
        } catch (e) {
          console.log('runRise() - setInterval - error', e);
        }
      }, 20 * 1000); // 20 seconds
    } else {
      try {
        runner.fromUrlData();
        await runner.loadStoreDetails();
        await runner.loadZChannelData();
        runner.start();
      } catch (e) {
        console.log('runRise() - error', e);
      }
    }
  } catch (e) {
    console.log('runRise() - error', e);
  }
};

// runRise();
