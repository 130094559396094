import { useFastLane } from "../store";
import { useEffect, useState } from "react";
import { keyframe } from "../helpers";
import { motion } from "framer-motion";
import { DEFAULT_EASE } from "../../../../jordan/shared";

interface CircleProps {
	index: number;
}

export default function SectionGridCircle({ index }: CircleProps) {
	const verticalStrokeWidth = useFastLane(f => f.getGridLineThickness("vertical"));
	const circleStrokeWidth = useFastLane(f => f.getGridLineThickness("circle"));
	const [runner, setRunner] = useState(0);
	const theme = useFastLane(f => f.getTheme());
	const isMediaPlaying = useFastLane(f => f.isMediaPlaying(index));

	const color = theme.gridColor;

	const circleVariants = [
		{
			pathLength: 0,
			rotate: 0,
			stroke: color,
		},
		{
			pathLength: .75,
			rotate: 0,
			stroke: color,
		},
		{
			pathLength: 0,
			rotate: 270,
			stroke: color,
		},
	];

	const [showLine, setShowLine] = useState(false);
	const [active, setActive] = useState(0);

	const nextFrame = () => {
		setActive((a) => a + 1);
	};

	const reset = (scene: number) => {
		setActive(0);
		if (scene === 2) {
			return;
		}
		setScene(i => i + 1);
	};

	const [scene, setScene] = useState(0);

	useEffect(() => {
		const { trigger, clear } = keyframe(1.5);

		trigger
		(4, nextFrame) // double y action split
		// (6, nextFrame) // product
		// (1, () => reset(scene)); // product


		return () => {
			clear();
		};
	}, [scene]);

	useEffect(() => {
		const { trigger: lineTrigger, clear: lineClear } = keyframe(0);

		lineTrigger
		(4, () => setShowLine(true))
		// (35, () => setShowLine(false))
		// (1, () => setRunner(i => i + 1));

		return lineClear;
	}, [runner]);

	return (
		<motion.svg
			width={`100%`}
			height={`100%`}
			viewBox={`0 0 600 600`}
			// initial="hidden"
			// animate="visible"
			style={{
				position: "relative",
				rotate: 180,
				scaleX: -1,
				transformOrigin: "center",
				overflow: "visible",
			}}
		>
			<motion.line
				x1="300"
				x2="300"
				y1="0"
				y2="600"
				animate={{
					pathLength: showLine ? 1 : 0,
					strokeWidth: verticalStrokeWidth,
					stroke: color,
				}}
				transition={{
					duration: 1,
					delay: (index + 1) * .1,
					ease: DEFAULT_EASE,
				}}
			/>

			<motion.line
				x1="0"
				x2="0"
				y1="0"
				y2="600"
				animate={{
					pathLength: showLine ? 1 : 0,
					strokeWidth: verticalStrokeWidth,
					stroke: color,
				}}
				transition={{
					delay: index * .1,
					duration: 1,
					ease: DEFAULT_EASE,
				}}
			/>

			<motion.line
				x1="600"
				x2="600"
				y1="0"
				y2="600"
				animate={{
					pathLength: showLine ? 1 : 0,
					strokeWidth: verticalStrokeWidth,
					stroke: color,
				}}
				transition={{
					delay: (index + 1) * .1,
					duration: 1,
					ease: DEFAULT_EASE,
				}}
			/>

			<motion.circle
				cx="300"
				cy="300"
				r={300}
				fill={"transparent"}
				style={{
					strokeWidth: circleStrokeWidth,
				}}
				animate={circleVariants?.[active]}
				strokeDasharray={290}
				transition={{
					duration: 1,
					ease: DEFAULT_EASE,
				}}
			/>
		</motion.svg>
	);
}