import { createContext, type CSSProperties, type ReactNode, useMemo, useRef } from 'react';

import Theme from '../../enums/Theme.enum';


export type ChapterPlayerContextType = {
  width: number,
  height: number,
  languages: string[],
  channel: object,
  theme?: Theme,
};

const ChapterPlayerContext = createContext<ChapterPlayerContextType>({
  width: 0,
  height: 0,
  languages: [],
  channel: {},
});

export function ChapterPlayerProvider({ children, value, style }: {
  children: ReactNode,
  value: Omit<ChapterPlayerContextType, 'width' | 'height'>,
  style?: CSSProperties,
}) {
  const container = useRef<HTMLDivElement>(null);
  const { width, height } = useMemo(() => {
    if (container.current) {
      return container.current.getBoundingClientRect();
    }

    return { width: 1080, height: 1920 };
  }, [container.current]);

  return (
    <div
      ref={container}
      style={{
        position: 'absolute',
        top: 0,
        ...style,
      }}
    >
      <ChapterPlayerContext.Provider
        value={{
          ...value,
          width,
          height,
        }}
      >
        {children}
      </ChapterPlayerContext.Provider>
    </div>
  );
}

export default ChapterPlayerContext;
