import { create } from "zustand";
import { generalState, GeneralState } from "./general";
import { takeoverState, TakeoverState } from "./takeover";
import { DisplayType, FastLaneSection } from "../types";
import { initializeState, InitializeState } from "./initialize";
import { MediaState, mediaState } from "./media";
import { SequenceState, sequenceState } from "./sequence";
import { ThemeState, themeState } from "./theme";
import { epcTagState, EpcTagState } from "./epc-tag";

export type set = (partial: (FastLaneState | Partial<FastLaneState> | ((state: FastLaneState) => (FastLaneState | Partial<FastLaneState>))), replace?: (boolean | undefined)) => void;
export type get = () => FastLaneState;

export interface FastLaneState extends InitializeState,
	GeneralState,
	TakeoverState,
	MediaState,
	SequenceState,
	ThemeState,
	EpcTagState {
	debugMode: boolean;
	enableDebugMode: () => void;

	display: DisplayType;
	entries: Array<FastLaneSection>;

	setEntries: (entries: Array<FastLaneSection>) => void;

	takeoverSectionWidths?: Array<{
		index: number;
		width: number;
	}>
}

export const useFastLane = create<FastLaneState>()((set, get) => ({
	debugMode: false,
	enableDebugMode: () => set({ debugMode: true }),
	entries: [],
	display: "fast-lane",

	...initializeState(set, get),
	...generalState(set, get),
	...themeState(set, get),
	...takeoverState(set, get),
	...mediaState(set, get),
	...sequenceState(set, get),
	...epcTagState(set, get),

	setEntries: (entries: Array<FastLaneSection>) => set({ entries }),
}));