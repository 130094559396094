import {usePx} from "./ScreenWrapper";
import {useMemo} from "react";

export type StatusType = 'quiet' | 'closed' | 'reserve_now' | 'do_not_enter' | 'booked' | 'available';

export default function Status({status = 'available'}: { status?: StatusType | string }) {
    const px = usePx();

    const {color, label} = useMemo(() => {
        const statusMap: Record<StatusType, { color: string, label: string }> = {
            quiet: {
                color: '#fff000',
                label: 'Meeting in progress'
            },
            closed: {
                color: '#fff000',
                label: 'Closed'
            },
            reserve_now: {
                color: '#fff000',
                label: 'Meeting in progress'
            },
            do_not_enter: {
                color: '#FF0000',
                label: 'Do Not Enter'
            },
            booked: {
                color: '#FF0000',
                label: 'Booked'
            },
            available: {
                color: '#73EA78',
                label: 'Available'
            }
        };

        return statusMap[status as StatusType] || statusMap.available;
    }, [status]);

    return (
        <>
            <div>
                <div style={{display: 'flex', gap: px(12), alignItems: 'center', flexGrow: 0}}>
                    <StatusBall color={color}/>
                    <div style={{fontSize: px(24)}}>
                        {label}
                    </div>
                </div>
            </div>
        </>
    )
}

function StatusBall({color}: { color: string }) {
    const px = usePx();

    return (
        <div
            style={{
                transform: `translateY(${px(1)}px)`,
                width: px(16),
                height: px(16),
                borderRadius: '50%',
                backgroundColor: color,
            }}
        />
    )
}