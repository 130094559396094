import { Text } from '@react-three/drei';
import { fonts } from '../../../core/animations/ticker/ticker/shared';
import { Canvas, useLoader } from '@react-three/fiber';
import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { EffectComposer } from '@react-three/postprocessing';
import { GlitchEffect } from './GlitchEffect';
import Typography, { TypographyProps } from '../Typography';
import { loadImageBlobUrl } from '../../storage';
import CacheRenderedCanvasToImage from '../CacheRenderedCanvasToImage';
import textureImage from './glitch.jpg';
import * as THREE from 'three';
import {containsChinese} from "../../../../utilities";

interface GlitchTypographyProps extends TypographyProps {
    width?: number;
    height?: number;
    idKey?: string;
}

const getUniqueName = (
    text: string,
    fontSize: number | 'xl',
    color: string,
    align: string,
    variant: string,
    idKey: string,
) => `##-${idKey}-${text}-${fontSize}-${color}-${align}-${variant}-##`;

const GlitchTypography = ({
    color,
    size,
    style,
    variant,
    align = 'left',
    children,
    uppercase,
    idKey,
    scaleX = 0.5,
    ...rest
}: GlitchTypographyProps) => {
    const containsChines = useMemo(() => containsChinese(children), [children]);

    const lineHeight = containsChines ? 0.95 : 0.8;
    variant = (containsChines && variant === 'jordan-condensed') ? 'jordan-condensed-cn' : variant;
    size = (variant === "jordan-condensed-cn" && typeof size === "number") ? size * 0.8 : size;

    const wrapper = useRef<HTMLDivElement | null>(null);
    const [positioning, setPositioning] = useState<DOMRect | null>(null);
    const [urlState, setUrlState] = useState({
        url: null,
        urlCheckComplete: false,
    });
    const texture = useLoader(THREE.TextureLoader, textureImage);
    const xOffset = useMemo(() => {
        if (positioning) {
            if (align === 'left') {
                return -positioning.width / 2;
            }

            if (align === 'right') {
                return positioning.width / 2;
            }

            return 0;
        }
    }, [positioning, align]);

    useEffect(() => {
        const inner = wrapper?.current;
        if (!inner) {
            return;
        }

        const rect = wrapper.current.getBoundingClientRect();
        setPositioning(rect);

        async function getCachedImage() {
            const blobUrl = await loadImageBlobUrl(
                getUniqueName(children, Number(size), color, align, variant, idKey),
            );
            if (blobUrl) {
                setUrlState({ url: blobUrl, urlCheckComplete: true });
            } else {
                setUrlState({ url: null, urlCheckComplete: true });
            }
        }

        getCachedImage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const width = positioning?.width ?? rest.width ?? '100%';
    const height = positioning?.height ?? rest.height ?? '100%';

    return (
        <div ref={wrapper} style={{ position: 'relative', width, height }}>
            {!positioning ? (
                <div style={{ opacity: 0 }}>
                    <Typography
                        variant={variant}
                        color={color}
                        uppercase={uppercase}
                        style={style}
                        size={size}
                        align={align}
                        scaleX={scaleX}
                    >
                        {children}
                    </Typography>
                </div>
            ) : (
                <>
                    {urlState.url ? (
                        <img src={urlState.url.toString()} alt="" />
                    ) : (
                        urlState.urlCheckComplete && (
                            <Suspense fallback={null}>
                                <Canvas
                                    gl={{ antialias: true, preserveDrawingBuffer: true }}
                                    flat={true}
                                    dpr={[1, 2]}
                                    orthographic
                                    frameloop="demand"
                                    camera={{
                                        zoom: 0.98,
                                        position: [0, 0, 100],
                                        near: 0.1,
                                        far: 10000,
                                    }}
                                    style={{
                                        background: 'transparent',
                                        position: 'absolute',
                                        ...positioning,
                                        ...style,
                                    }}
                                >
                                    <CacheRenderedCanvasToImage
                                        name={getUniqueName(
                                            children,
                                            Number(size),
                                            color,
                                            align,
                                            variant,
                                            idKey,
                                        )}
                                    >
                                        <Text
                                            fontSize={size as number}
                                            font={fonts[variant]}
                                            position={[xOffset, 0, 0]}
                                            scale-x={scaleX}
                                            overflowWrap={'normal'}
                                            whiteSpace={'overflowWrap'}
                                            maxWidth={positioning.width * (1 / scaleX)}
                                            lineHeight={lineHeight}
                                            textAlign={align}
                                            anchorX={align}
                                            anchorY={'middle'}
                                            color={color || 'white'}
                                            key="glitch"
                                            children={uppercase ? children?.toUpperCase() : children}
                                        />
                                        <EffectComposer>
                                            <GlitchEffect texture={texture} />
                                        </EffectComposer>
                                    </CacheRenderedCanvasToImage>
                                </Canvas>
                            </Suspense>
                        )
                    )}
                </>
            )}
        </div>
    );
};

export default React.memo(GlitchTypography);
