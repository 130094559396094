.pixelMapTestModeSlot {
  display: grid;
  grid-auto-rows: 100px;
  overflow: hidden;
  box-sizing: border-box;
  background: linear-gradient(to right, red, blue);

  .gridItem {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: white;
  }
}
