import React, { useEffect, useMemo, useState } from 'react';
import { AnimatePresence, usePresence } from 'framer-motion';
import classNames from 'classnames';

import * as cssClassNames from '../../styles/screens/product/IntroV3.module.scss';

import {
    getMediaDuration,
    hasTextToDisplay,
    MediaType,
    MultiLangText,
    secToMs,
    setAnimationTimeout,
} from '../../shared';
import {
    BaseChapterProps,
} from '../../components/ChapterRenderer';
import CodificationWithTranslations, {
    calculateDuration as calculateCodificationDuration,
    calculateExitDuration as calculateExitCodificationDuration,
    TranslationCodificationProps,
} from '../../components/Codification/CodificationWithTranslations';
import GridWithCells from '../../components/GridWithCells';
import Grid from '../../components/Grid';
import MediaRenderer from '../../components/MediaRenderer';


export type IntroV3Props = BaseChapterProps & {
    text?: MultiLangText,
    primaryMedia?: MediaType,
    gridVariant?: string,
}

const bigCellLengthRef = 80 / 1920;

const codificationProps: TranslationCodificationProps = {
    characterSwitchAmount: 4,
    characterNextTrigger: 2,
    timingConfig: {
        control: 'character',
        duration: 0.03,
    },
};
const languageTransitionDelay = 0.25;
const pause = 4;

export default function IntroV3(props: IntroV3Props) {
    const {
        text,
        languages,
        width,
        height,
        primaryMedia,
        gridVariant,
        onDurationsCalculated,
    } = props;

    const hasAnyTextToDisplay = useMemo(() => text && hasTextToDisplay(text, languages), [text, languages]);
    const [rewrappedText, setRewrappedText] = useState<null | MultiLangText>(hasAnyTextToDisplay ? null : {});
    const [langIndex, setLangIndex] = useState(0);
    const [nextLangIndex, setNextLangIndex] = useState(0);
    const [isPresent, safeToRemove] = usePresence();

    const grids = useMemo(() => {
        if (gridVariant === 'off') {
            return null;
        }

        const bigCellLength = bigCellLengthRef * height;
        const smallCellLength = bigCellLength / 2;
        const smallGridWidth = width / 2.25;
        const bigGridWidth = smallGridWidth * 1.5;
        const smallGridCols = Math.ceil(smallGridWidth / smallCellLength);
        const bigGridCols = Math.ceil(bigGridWidth / bigCellLength);

        return [
            (
                <div
                    className={classNames(cssClassNames.grid_container, cssClassNames.solid)}
                    style={{
                        right: `${(smallGridCols - 2) * smallCellLength - 1}px`
                    }}
                >
                    <Grid
                        rows={10}
                        cols={bigGridCols}
                        height={10 * bigCellLength}
                        width={bigGridWidth}
                        toDrawBoundaries
                        initialAnimation='visible'
                        lineStyle={{
                            strokeWidth: `${100/1920}vh`,
                        }}
                    />
                </div>
            ),
            (
                <GridWithCells
                    rows={20}
                    cols={smallGridCols}
                    cellLength={smallCellLength}
                    toDrawBoundaries
                    className={classNames(cssClassNames.grid_container, cssClassNames.dotted)}
                />
            )
        ]
    }, [height, width]);

    useEffect(() => {
        if (nextLangIndex === langIndex) {
            return;
        }

        const duration = primaryMedia && getMediaDuration(primaryMedia) || pause;
        return setAnimationTimeout(() => {
            setLangIndex(nextLangIndex);
        }, secToMs(duration));
    }, [nextLangIndex]);

    useEffect(() => {
        if (hasAnyTextToDisplay || isPresent) {
            return;
        }

        safeToRemove();
    }, [hasAnyTextToDisplay, isPresent]);

    const textComponent = useMemo(() => hasAnyTextToDisplay ? (
        <CodificationWithTranslations
            text={text}
            languages={languages}
            langIndex={langIndex}
            codificationProps={codificationProps}
            runFinalTextAnimation
            languageTransitionDelay={languageTransitionDelay}
            onTyped={() => {
                setNextLangIndex(i => {
                    return (i >= languages.length - 1) ? i : (i + 1);
                });
            }}
            onTextRewrapped={lines => setRewrappedText(lines)}
        />
    ) : null, [text, languages, langIndex]);

    useEffect(() => {
        if (!onDurationsCalculated || !rewrappedText) {
            return;
        }

        const newData = {
            ...props,
            text: rewrappedText
        };
        const duration = calculateDuration(newData);
        const exitDuration = calculateExitDuration(newData);
        onDurationsCalculated({
            duration,
            exitDuration,
        });
    }, [rewrappedText]);

    return (
        <div className={cssClassNames.intro_v3}>
            {primaryMedia ? (
                <div className={cssClassNames.media_container}>
                    <MediaRenderer
                        height={height}
                        media={primaryMedia}
                        toApplyInternalEffect
                    />
                </div>
            ) : null}
            {grids}
            {hasAnyTextToDisplay ? (
                <div className={cssClassNames.text_container}>
                    <div>
                        <AnimatePresence onExitComplete={isPresent ? undefined : safeToRemove}>
                            {isPresent ? textComponent : null}
                        </AnimatePresence>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export function calculateDuration(data: IntroV3Props) {
    const {
        text,
        languages,
        primaryMedia,
    } = data;
    const entryDuration = 0;

    const mediaDuration = primaryMedia && getMediaDuration(primaryMedia) || pause;

    const hasText = text && hasTextToDisplay(text, languages);
    const textDuration = hasText ? calculateCodificationDuration({
        text,
        languages,
        codificationProps,
        languageTransitionDelay,
    }): 0;

    const mainDuration = textDuration + mediaDuration * (hasText ? languages.length : 1);

    const total = entryDuration + mainDuration;

    const duration = secToMs(total) + calculateExitDuration(data);

    return duration;
}

export function calculateExitDuration(data: IntroV3Props) {
    const {
        text,
        languages,
    } = data;

    const hasText = text && hasTextToDisplay(text, languages);
    if (!hasText) {
        return 0;
    }

    const textDuration = calculateExitCodificationDuration({
        text,
        languages,
        codificationProps,
        languageTransitionDelay,
    });

    return secToMs(textDuration);
}
