import { motion } from "framer-motion";
import { useFastLane } from "../store";
import { ABSOLUTE_FILL } from "../helpers";
import {useDisplayType} from "../store/fast-lane-context";

interface BackgroundProps {
	laneIndex: number;
	totalLanes: number;
}

export default function Background({ laneIndex, totalLanes }: BackgroundProps) {
	const theme = useFastLane(f => f.getTheme());
	const display = useDisplayType();

	if (display === 'ticker') {
		return null;
	}

	return (
		<motion.div
			style={{ ...ABSOLUTE_FILL, zIndex: 0 }}
			animate={{
				background: theme.backgroundColor,
			}}
		>
			{theme?.fullGrid?.({ laneIndex, totalLanes })}
		</motion.div>
	);
}