import * as THREE from 'three'
import fontUrl from 'url:./NIKE_FUTURA_CN-XB.ttf';

export const isLetter = letter => letter.toLowerCase() !== letter.toUpperCase();
export const isDigit = letter => !isNaN(letter);
export const isOtherAscii = letter => !isDigit(letter) && !isLetter(letter);
export const isVideo = asset => asset?.resource_type === 'video';
export const isImage = asset => asset?.resource_type === 'image';
export const isColor = asset => asset?.resource_type === 'color';
export const ascii = {
    numbers: '01234567890',
    characters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    symbols: '@#$%^&*-,./!?<>`~=+()|"\'\\}]{[_'
}
export const fontProps: {
    anchorX?: number | "center" | "left" | "right" | undefined;
    anchorY?: number | "bottom" | "top" | "middle" | "top-baseline" | "bottom-baseline" | undefined;
    fontSize?: number | undefined;
    lineHeight?: number | undefined;
    letterSpacing?: number | undefined;
    font?: string | undefined;
} = {
    anchorX: "center",
    fontSize: 24,
    anchorY: "middle",
    lineHeight: 1,
    letterSpacing: 0,
    font: fontUrl
};
export const tileGeometry = new THREE.PlaneGeometry(24, 10);
export const characterFlipSpeed = 8;
export const rotateSpeed = 32;

export const canvasRowHeight = 32;
export const canvasWidth = 512;
export const canvasHeight = 320;
export const columnWidth = 23;
export const fontColumnWidth = 22;
export const rowHeight = 43;
export const padding = (columns) => Array(columns).fill(' ');

const delay = 50;

const clonedTextures = {};

const colours = {
    'white': new THREE.MeshBasicMaterial({ color: 'white', side: THREE.DoubleSide }),
    'black': new THREE.MeshBasicMaterial({ color: 'black', side: THREE.DoubleSide })
};

export const getColorMaterial = (color) => {
    if (colours[color]) return colours[color];
    colours[color] = new THREE.MeshBasicMaterial({ color: color, side: THREE.DoubleSide });
    return colours[color];
}

export const getTextColor = (color) => {
    return color ? getColorMaterial(color) : getColorMaterial('black');
}

export const getMediaTexture = (media, cloneTexture, numRows, columns, config) => {
    if (clonedTextures[media.asset.etag]) return clonedTextures[media.asset.etag];
    if (isImage(media.asset)) {
        clonedTextures[media.asset.etag] = getImageTextures(media, cloneTexture, numRows, columns, config);
    } else {
        clonedTextures[media.asset.etag] = getVideoTextures(media, cloneTexture, numRows, columns, config);
    }
    return clonedTextures[media.asset.etag]
}

export const generateFlipcardProps = (idx, medias, texts, textures, columns, rows, pageNumber, config, assetOffset = 0) => {
    let clonedTextures = [];
    const numRows = rows;
    const asset = medias?.[0]?.asset;
    const isColorAsset = isColor(asset);
    const text = texts[idx]?.text;
    const hasText = !!text;
    const hasOneChapter = config.numChapters === 1;
    const hasOnePage = config.numPages === 1;
    let backgroundColor = texts[idx]?.color;
    const isTransparentText = backgroundColor === 'transparent';
    const word = hasText ? text : null;
    let pageBackgroundColor = !isTransparentText && backgroundColor ? getColorMaterial(backgroundColor) : getColorMaterial('black');
    if (isColorAsset) pageBackgroundColor = getColorMaterial(asset?.color);

    let flipCardsProps = {
        key: idx,
        x: (-(columns) * columnWidth / 2) + assetOffset,
        y: (((rowHeight * rows) / 2) - rowHeight / 2) + -(rowHeight * idx),
        columns: columns,
        width: columnWidth,
        height: rowHeight,
        staging: 'front',
        forceChapterFlip: hasOneChapter && hasOnePage,
        characterFlipSpeed: config.character_flip_speed,
        characterSplit: config.character_split,
        row: idx,
        isColorMedia: isColorAsset,
        state: {
            word,
            newChapter: pageNumber === 0 || hasOneChapter,
            colors: {
                backgroundColor: pageBackgroundColor,
            },
            delay: idx * delay,
            rowFlip: config.row_flip,
            texture: undefined,
        }
    };

    if ((!isColorAsset && asset && isTransparentText)) {
        const texture = textures[medias[0].asset.asset_id];
        if (texture) {
            clonedTextures = getMediaTexture(
                medias[0],
                textures[medias[0].asset.asset_id],
                numRows,
                columns,
                config
            );
            flipCardsProps.state.texture = clonedTextures[9 - idx];
        }
    }
    return flipCardsProps;
}

const getVideoTextures = (media, cloneTexture, numRows, columns, config) => {
    const spacing = 1 + config.row_spacing;
    const width = columns * columnWidth;
    const height = numRows * rowHeight;
    const aspect = width / height;
    const mediaWidth = Math.ceil(width);
    const mediaHeight = Math.ceil(spacing * height) - (rowHeight * config.row_spacing)
    const imageAspect = mediaWidth / mediaHeight;
    return Array.from({ length: numRows }).map((_, idx) => {
        const texture = cloneTexture.clone();
        texture.needsUpdate = true;
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        if (aspect < imageAspect) {
            texture.offset.x = (1 - (width / ((height * media?.asset?.width) / media?.asset?.height))) / 2;
            texture.offset.y = idx / numRows;
            texture.repeat.set(aspect / imageAspect, (1 / numRows) / spacing);
        } else {
            const yOffset = 0;//(1 - (height / ((width * media?.asset?.height) / media?.asset?.width))) / 2
            texture.offset.x = 0;
            texture.offset.y = yOffset + (idx / numRows) + (config.row_spacing * idx) / (numRows * numRows);
            texture.repeat.set(1, 1 / numRows - config.row_spacing / numRows);
        }
        texture.name = media?.asset?.etag;
        texture.encoding = THREE.sRGBEncoding;
        return texture;
    });
};

const getImageTextures = (media, cloneTexture, numRows, columns, config) => {
    const spacing = 1 + config.row_spacing;
    const width = columns * columnWidth;
    const height = numRows * rowHeight;
    const aspect = width / height;
    const mediaWidth = Math.ceil(width);
    const mediaHeight = Math.ceil(spacing * height) - (rowHeight * config.row_spacing)
    const imageAspect = mediaWidth / mediaHeight;
    return Array.from({ length: numRows }).map((_, idx) => {
        const texture = cloneTexture.clone();
        texture.needsUpdate = true;
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        if (aspect < imageAspect) {
            texture.offset.x = (1 - (width / ((height / mediaHeight) * mediaWidth))) / 2;
            texture.offset.y = idx / numRows;
            texture.repeat.set(aspect / imageAspect, (1 / numRows) / spacing);
        } else {
            const yOffset = 0;
            texture.offset.x = 0;
            texture.offset.y = yOffset + (idx / numRows) + (config.row_spacing * idx) / (numRows * numRows);
            texture.repeat.set(1, 1 / numRows - config.row_spacing / numRows);
        }
        texture.name = media?.asset?.etag;
        texture.encoding = THREE.sRGBEncoding;
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        texture.anisotropy = config.maxAnsitropy;
        return texture;
    });
};

export const getAssetsByType = (json, type) => {
    let assets = {}
    json.chapters.forEach((chapter) => {
        chapter.screens.forEach((screen) => {
            screen.pages.forEach((page) => {
                page.media?.forEach((media) => {
                    if (media?.asset?.resource_type === type)
                        assets[media?.asset?.asset_id] = media?.asset?.url;
                })
            })
        })
    })
    return assets;
}