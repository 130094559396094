import {FastLaneSection} from "../types";
import {useFastLane} from "../store";
import {ABSOLUTE_FILL} from "../helpers";
import {AnimatePresence, motion} from "framer-motion";
import {useMemo} from "react";
import {usePixelMap} from "../store/fast-lane-context";

interface SectionContentProps {
    section: FastLaneSection;
    index: number;
}

export default function SectionContentShoe({section, index}: SectionContentProps) {
    const isEditor = useFastLane(f => f.isEditor);
    const pixelMap = usePixelMap();

    const imageUrl = useMemo(() => {
        return section?.product?.image?.url?.replace('/upload/', '/upload/w_400,q_70/');
    }, []);

    const {left, width, marginTop}  = useMemo(() => {
        const isLarge = pixelMap.contentHeight > 120

        return {
            left: isLarge ? 300 : 240,
            width: isLarge ? 300 : 140,
            marginTop: isLarge ? -70 : -35,
        }
    }, [pixelMap]);

    return (
        <div
            style={{
                ...ABSOLUTE_FILL,
                left,
                pointerEvents: 'none',
                zIndex: 99,
            }}
        >
            <AnimatePresence
                mode={'wait'}
            >
                {
                    isEditor && (
                        <motion.img
                            style={{
                                width,
                                marginTop,
                            }}
                            key={imageUrl}
                            src={imageUrl}
                        />
                    )
                }

            </AnimatePresence>
        </div>
    )
}