@use '../variables' as vars;

.Carousel {
    background: white;
    height: 100%;

    > * {
        position: absolute;
        top: 0;
    }
}

.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: vars.to-vh(102, 1920);
    width: 100%;
}

.CarouselItem {
    display: grid;
    overflow: hidden;

    > * {
        height: 100%;
    }
}

.text {
    @include vars.hoi-font;
    padding: vars.to-vh(80, 1920) vars.to-vw(75, 1080) 0;
    margin-bottom: vars.to-vh(115, 1920);
}

.title {
    font-size: vars.to-vh(80, 1920);
}

.description {
    font-size: vars.to-vh(40, 1920);
    margin-top: vars.to-vh(15, 1920);
}

.grid_container {
    opacity: 0.4;

    line {
        stroke: white;
    }
}
