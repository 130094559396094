import React, { useEffect, useMemo, useRef, useState } from 'react';

import * as cssClassNames from '../styles/components/ChapterRenderer.module.scss';

import {
    CenterCourtGradient,
    ChapterType,
    ThemeType,
} from '../shared';
import {
    HoiConfig
} from './ChapterRunner';
import * as AppEventsService from '../../../services/app-events.service';
import TemplateEventsEnum from '../../../enums/TemplateEvents.enum';

export type DurationData = {
    duration: number,
    exitDuration: number,
};

type ChapterRendererProps = {
    chapter: ChapterType,
    render: React.ElementType,
    index: number,
    config: HoiConfig,
    loop?: boolean,
    gradient?: CenterCourtGradient,
    onDurationsCalculated?: (durations: DurationData) => void,
}

export type ChapterRenderConfig = {
    render: React.ElementType,
    toPreprocess?: boolean;
    canLoop?: boolean;
};

export type BaseChapterProps = {
    index: number,
    config: HoiConfig,
    width: number,
    height: number,
    languages?: string[],
    theme?: ThemeType,
    loop?: boolean,
    onDurationsCalculated?: (durations: DurationData) => void,
}

export type ChapterRenderOptions = Record<string, ChapterRenderConfig>;

export default function ChapterRenderer(props: ChapterRendererProps) {
    const {
        chapter,
        index,
        render: Render,
        config,
        ...other
    } = props;
    const {
        data,
        ...otherStuff
    } = chapter;

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const mainRef = useRef<HTMLDivElement>();

    useEffect(() => {
        AppEventsService.sendEvent(TemplateEventsEnum.ChaptersRender, chapter);
    });

    useEffect(() => {
        if (!mainRef.current) {
            return;
        }

        const {
            width,
            height,
        } = mainRef.current.getBoundingClientRect();

        setWidth(width);
        setHeight(height);
    }, [mainRef.current]);

    const component = useMemo(() => {
        return (width && height) ? (
            <Render
                index={index}
                config={config}
                {...other}
                {...data}
                width={width}
                height={height}
            />
        ) : null;
    }, [width, height]);

    if (!Render) {
        return (
            <div>no chapter for this type</div>
        )
    }

    return (
        <div
            ref={mainRef}
            className={cssClassNames.chapter}
        >
            {component}
        </div>
    );
}
