import LeftGrid from '../components/left-grid';
import Typography from '../components/typography';
import LandscapeView from '../components/landscape-view';
import React, { useMemo } from 'react';
import { isPortrait, themeToStyles, useRpx } from '../shared';
import { useNbyStore } from '../store';
import PricingRight from '../components/pricing-right';

export default function Pricing({ theme }: { theme?: string }) {
  const themedConfig = useMemo(() => themeToStyles(theme), [theme]);
  const animationData = useNbyStore(state => state.animationData);
  const rpx = useRpx();
  const portrait = isPortrait();

  const themeContainer = {
    background: themedConfig?.background,
    color: themedConfig?.text,
  }

  if (portrait) {
    return (
      <div
        style={{
          ...themeContainer,
          display: 'flex',
          height: '1920px',
          flexDirection: 'column',
        }}
      >
        <LeftGrid
          theme={theme}
          hideBottom
          extended={true}
          content={(
            <div
              style={{
                fontSize: portrait ? 151 : rpx(125),
                lineHeight: portrait ? '140px' : rpx(115),
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                marginLeft: portrait ? 48 : rpx(50),
                marginRight: portrait ? 48 : rpx(50),
              }}
            >
              {
                theme === 'one' ? (
                  <Typography
                    text={animationData.pricingTitle}
                    disableCodification
                    style={{
                      fontFamily: 'helvetica-neue',
                      fontSize: 119,
                      fontWeight: '700',
                      textTransform: 'uppercase',
                    }}
                  />
                ) : (
                  <Typography
                    text={animationData.pricingTitle}
                    primary={true}
                  />
                )
              }

            </div>
          )}
        />

        <div style={{ flex: 1 }}>
          <PricingRight theme={theme} />
        </div>

      </div>
    );
  }

  return (
      <LandscapeView
        style={themeContainer}
        left={(
          <LeftGrid
            theme={theme}
            extended={true}
            content={(
              <div
                style={{
                  fontSize: rpx(125),
                  lineHeight: rpx(115),
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  marginLeft: rpx(50),
                }}
              >
                {
                  theme === 'one' ? (
                    <Typography
                      text={animationData.pricingTitle}
                      disableCodification
                      style={{
                        fontFamily: 'helvetica-neue',
                        fontWeight: '700',
                        textTransform: 'uppercase',
                      }}
                    />
                  ) : (
                    <Typography
                      text={animationData.pricingTitle}
                      primary={true}
                    />
                  )
                }

              </div>
            )}
            description={(
              <div
                style={{
                  fontSize: rpx(40),
                  fontWeight: 700,
                  margin: rpx(50),
                  marginRight: rpx(20),
                  height: '45%',
                  display: 'flex',
                  alignItems: 'center',
                  // respect line breaks
                  whiteSpace: 'pre-wrap',
                }}
              >
                {animationData.pricingCallout}
              </div>
            )}
          />
        )}
        right={(
          <PricingRight />
        )}
      />
  );
}