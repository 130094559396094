// simple styles mainly just to have unique class names

.text,
.line,
.word,
.space {
    color: transparent;
}

.nowrap .line,
.word {
    white-space: nowrap;
}
