import React from 'react';

import Chapter from '../Chapter';
import { AnimationDataType } from '../../core/animations/membership/types';
import Membership from '../../core/animations/membership/membership';
import { ENTRY_DURATION } from '../../core/animations/membership/store';


export default class MembershipChapter extends Chapter<AnimationDataType> {
    component = () => (
        <Membership
            data={this.data}
        />
    )

    canLoop = true

    calculateDuration() {
        return ENTRY_DURATION/1000 * (this.data.entries.length + 1) + 1;
    }
}
