import React, {
    CSSProperties,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    AnimatePresence,
    motion,
    useIsPresent,
} from 'framer-motion';

import * as cssClassNames from '../styles/screens/TitleCard.module.scss';

import {
    DEFAULT_EASE,
    getLangText,
    MultiLangText,
    ThemeType,
    secToMs,
    setAnimationTimeout,
    hasTextToDisplay,
} from '../shared';
import { DEFAULT_DURATION_S } from '../constants/transition';
import { BaseChapterProps } from './ChapterRenderer';
import Grid from './Grid';
import CodificationWithTranslations from './Codification/CodificationWithTranslations';
import TitleText, {
    calculateDuration as calculateTextDuration
} from './TitleText';


export type TitleWithSubtitleProps = BaseChapterProps & {
    title?: MultiLangText;
    subtitle?: MultiLangText;
    theme?: ThemeType;
}

const cellSizeRef = 216 / 1080;
const gridLineThickness = 1;

const gridDuration = DEFAULT_DURATION_S;
const subtitleCodificationDuration = 0.015;
const characterSwitchAmount = 4;
const characterNextTrigger = 2;
const languageTransitionDelay = 0.25;
const delayClearGridForTitle = 2 * DEFAULT_DURATION_S;
const delaySubtitleShow = delayClearGridForTitle + 2 * DEFAULT_DURATION_S;
const delaySubtitleType = DEFAULT_DURATION_S / 2;
const pause = 6;

export default function TitleWithSubtitle(props: TitleWithSubtitleProps) {
    const {
        width,
        height,
        title,
        subtitle,
        languages,
        theme,
    } = props;

    const cellSize = cellSizeRef * width;
    const cols = Math.ceil(width / cellSize);
    const rows = Math.ceil(height / cellSize);

    const hasTitle = useMemo(() => title && hasTextToDisplay(title, languages), [title, languages]);
    const hasSubTitle = useMemo(() => subtitle && hasTextToDisplay(subtitle, languages), [subtitle, languages]);
    const subtitleContainerRef = useRef<HTMLDivElement>();
    const [toShowTitle, setToShowTitle] = useState(false);
    const [showSubtitle, setShowSubtitle] = useState(false);
    const [subtitleMaxSize, setSubtitleMaxSize] = useState(null);
    const [langIndex, setLangIndex] = useState(0);
    const [nextLangIndex, setNextLangIndex] = useState(0);
    const subtitlePadding = subtitleContainerRef.current ? parseFloat(window.getComputedStyle(subtitleContainerRef.current).paddingTop) : 0;
    const subTitleBoundingRect = useMemo(() => ({
        x: 0,
        y: 5,
        rows: subtitleMaxSize ? Math.min(Math.ceil((subtitleMaxSize.height + subtitlePadding * 2) / cellSize), rows) : 1,
        cols,
    }), [subtitleMaxSize]);
    const isPresent = useIsPresent();

    const onTyped = () => {
        setNextLangIndex(i => {
            return (i >= languages.length - 1) ? i : (i + 1);
        });
    }

    const subTitleComponent = useMemo(() => hasSubTitle ? (
        <CodificationWithTranslations
            text={subtitle}
            languages={languages}
            langIndex={langIndex}
            codificationProps={{
                characterSwitchAmount,
                characterNextTrigger,
                timingConfig: {
                    control: 'character',
                    duration: subtitleCodificationDuration,
                },
            }}
            delay={hasTitle ? delaySubtitleType : 0}
            runFinalTextAnimation={false}
            languageTransitionDelay={languageTransitionDelay}
            onLanguageContainerMeassuerd={sizes => {
                let maxWidth = 0;
                let maxHeight = 0;

                sizes.forEach(({width, height}) => {
                    maxWidth = Math.max(maxWidth, width);
                    maxHeight = Math.max(maxHeight, height);
                });

                setSubtitleMaxSize({
                    width: maxWidth,
                    height: maxHeight,
                });
            }}
            onTyped={hasTitle ? undefined : onTyped}
        />
    ) : null, [hasTitle, subtitle, languages, langIndex]);

    const halfGridLineThickness = gridLineThickness / 2;
    const generalStyle: CSSProperties = {
        marginTop: -halfGridLineThickness,
        marginLeft: -halfGridLineThickness,
        borderWidth: gridLineThickness,
    };
    const subTitleStyle = subtitleMaxSize ? {
        ...generalStyle,
        top: subTitleBoundingRect.y * cellSize,
        height: subTitleBoundingRect.rows * cellSize + gridLineThickness,
        width: subTitleBoundingRect.cols * cellSize
    } : undefined;

    useEffect(() => {
        const cancelShowTitle = setAnimationTimeout(() => setToShowTitle(true), secToMs(gridDuration));
        const cancelShowSubTitle = setAnimationTimeout(() => {
            setShowSubtitle(languages.findIndex(lang => getLangText(subtitle, lang).length > 0) >= 0);
        }, secToMs(hasTitle ? delaySubtitleShow : gridDuration));

        return () => {
            cancelShowTitle();
            cancelShowSubTitle();
        }
    }, []);

    useEffect(() => {
        if (nextLangIndex === langIndex) {
            return;
        }

        return setAnimationTimeout(() => {
            setLangIndex(nextLangIndex);
        }, secToMs(pause));
    }, [nextLangIndex]);

    return (
        <>
            <Grid
                width={cellSize * cols}
                height={cellSize * rows}
                cols={cols}
                rows={rows}
                initialAnimation={'hidden'}
                animation={'in'}
                maxDuration={gridDuration}
            />
            {hasTitle ? (
                <AnimatePresence>
                    {toShowTitle && isPresent ? (
                        <TitleText
                            text={title}
                            languages={languages}
                            theme={theme}
                            langIndex={langIndex}
                            cellLength={cellSize}
                            cols={cols}
                            rows={rows}
                            delay={gridDuration}
                            gridLineThickness={gridLineThickness}
                            exit={{
                                clipPath: 'inset(0% 0% 100% 0%)',
                            }}
                            onTyped={onTyped}
                        />
                    ) : null}
                </AnimatePresence>
            ) : null}

            {hasSubTitle && showSubtitle ? (
                <motion.div
                    className={cssClassNames.sub_title}
                    style={subTitleStyle}
                    initial={{
                        clipPath: 'inset(0% 0% 0% 0%)'
                    }}
                    exit={{
                        clipPath: 'inset(0% 100% 0% 0%)'
                    }}
                    transition={{
                        duration: DEFAULT_DURATION_S,
                        ease: DEFAULT_EASE,
                    }}
                >
                    {subtitleMaxSize ? (
                        <motion.div
                            className={cssClassNames.sub_title_background}
                            initial={{
                                clipPath: 'inset(0% 100% 0% 0%)'
                            }}
                            animate={{
                                clipPath: 'inset(0% 0% 0% 0%)'
                            }}
                            transition={{
                                duration: DEFAULT_DURATION_S,
                                ease: DEFAULT_EASE,
                            }}
                        >
                        </motion.div>
                    ) : null}
                    <div
                        ref={subtitleContainerRef}
                        className={cssClassNames.sub_title_container}
                    >
                        <div style={{
                            width: subtitleMaxSize?.width,
                            height: subtitleMaxSize?.height
                        }}>
                            <AnimatePresence>
                                {subTitleComponent}
                            </AnimatePresence>
                        </div>
                    </div>
                </motion.div>
            ) : null}
        </>
    );
}

export function calculateDuration(data: TitleWithSubtitleProps) {
    const {
        title,
        subtitle,
        languages,
    } = data;

    const hasTitle = title && hasTextToDisplay(title, languages);
    const hasSubTitle = subtitle && hasTextToDisplay(subtitle, languages);

    const entryDuration = (hasTitle ? 2 : 1) * gridDuration;

    const titleDuraiton = hasTitle ? calculateTextDuration({
        text: title,
        languages,
    }) : 0;
    const subTitleDuraiton = hasSubTitle ? calculateTextDuration({
        text: subtitle,
        languages,
    }) : 0;
    const textAnimation = (titleDuraiton || subTitleDuraiton) + pause * (hasTitle || hasSubTitle ? languages.length : 1);

    const total = entryDuration + textAnimation;

    const exitDuration = calculateExitDuration(data);

    return secToMs(total) + exitDuration;
}

export function calculateExitDuration(data: TitleWithSubtitleProps) {
    const duration = 2 * DEFAULT_DURATION_S;

    return secToMs(duration);
}
