import React from 'react';

import * as cssClassNames from '../../styles/components/PhantomText.module.scss';


export type PhantomTextProps = {
    text: string,
};

export default function PhantomText(props: PhantomTextProps) {
    const {text} = props;
    const lines = text.split('\n');
    const words = lines.map(line => line.split(' '));
    return (
        <div
            className={cssClassNames.text}
            style={{
                color: 'transparent',
            }}
        >
            {words.map((line, index) => (
                <div
                    key={index}
                    className={cssClassNames.line}
                >
                    {line.length > 0 ? (
                        line.map((word, wIndex) => {
                            const key = `${index}-${wIndex}`;
                            return (
                                <span key={key}>
                                    <span className={cssClassNames.word}>{word}</span>
                                    {wIndex < line.length - 1 ? (
                                        <span className={cssClassNames.space}> </span>
                                    ) : null}
                                </span>
                            );
                        })
                    ) : (
                        <span className={cssClassNames.space}>&nbsp;</span>
                    )}
                </div>
            ))}
        </div>
    );
};
