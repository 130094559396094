import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/react';

import Chapter, { AnimationData } from '../Chapter';


export default function prepareAnimationChapters(chapters: Chapter[]): AnimationData[] {
    return chapters.reduce<AnimationData[]>((result, chapter) => {
        try {
            const data = chapter.getAnimationData();
            result.push(data);
        } catch (error) {
            console.warn(error);
            Sentry.withScope(scope => {
                scope.setLevel(Severity.Warning);
                Sentry.captureException(error);
            });
        }

        return result;
    }, []);
}
