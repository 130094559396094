import ReactDOM from 'react-dom/client';
import CoreTicker from './CoreTicker';
import { fetchJSON } from '/src/touchpoints/rise/hyperlive/utils';
import useTickerTakeover from '../../../../services/takeover-service/takeover-ticker.hooks';
import cssClassNames from '../../../rise/animations/scrollticker/ScrollTicker.module.scss';
import React from 'react';
const {json_data} = fetchJSON();

const root = ReactDOM.createRoot(document.getElementById('root'));

function App() {
  const {
    component: takeoverComponent,
    primaryColor,
  } = useTickerTakeover({
    canvas_width: json_data.canvas_width,
    canvas_height: json_data.canvas_height,
    width: json_data.width,
    height: json_data.height,
  });

  return (
    <div>
      <CoreTicker data={json_data} />

      {takeoverComponent ? (
        <div className={cssClassNames.takeover}>
          {takeoverComponent}
        </div>
      ) : null}
    </div>
  )
}

root.render(<App />);