import {MultiLangText} from "../../../house-of-innovation/shared";
import {MediaType} from "../../../jordan/shared";


export enum HeadVariant {
    Text = 'text',
    Media = 'media',
}

export interface AnimationDataType {
    headVariant?: HeadVariant;
    title: MultiLangText;
    headMedia?: MediaType;
    description: MultiLangText;
    entries: Array<EntryType>;
}

export interface EntryType {
    title: MultiLangText;
    description: MultiLangText;
    media?: Array<MediaType>;
    duration?: number;
}