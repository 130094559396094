import {ThemeConfig} from "../types";
import HoiTheme from "./hoi-theme";
import WellTheme from "./well-theme-grey";
import RiseLegacyTheme from "./rise-legacy";
import RiseTheme from "./rise-theme";

export type Theme = 'hoi' | 'rise' | 'rise-legacy' | 'well';

export const themes: Record<Theme, ThemeConfig> = {
    hoi: HoiTheme,
    well: WellTheme,
    rise: RiseTheme,
    'rise-legacy': RiseLegacyTheme,
}

export function getThemeFromString(theme: Theme, defaultTheme: ThemeConfig = RiseTheme): ThemeConfig {
    return themes?.[theme] ?? defaultTheme;
}