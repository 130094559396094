import React, { CSSProperties, useEffect, useRef } from 'react';

import cssClassNames from './PixelMapDisplaySlot.module.scss';

import AreaComponent, { Area } from './AreaComponent';


type Props = {
    position?: 'absolute' | 'relative',
    areas: Area[],
};

export default function PixelMapDisplaySlot(props: Props) {
    const {
        position = 'absolute',
        areas = [],
    } = props;

    return (
        <div
            className={cssClassNames.areas}
        >
            {areas.map(area => (
                <AreaComponent
                    key={area.id}
                    area={area}
                    style={{
                        position
                    }}
                />
            ))}
        </div>
    );
}
