import { type Ref, useEffect, useLayoutEffect } from 'react';

import useAreFontsLoaded from './useAreFontsLoaded';


export default function useFitText(element: HTMLElement) {
    const areFontsLoaded = useAreFontsLoaded();

    useLayoutEffect(() => {
        const target = element?.parentElement;
        if (!areFontsLoaded || !element || !target) {
            return;
        }

        fitText(element, target);
    }, [element, areFontsLoaded]);
}

function fitText(element: HTMLElement, targetElement: HTMLElement) {
    const {
        width,
        height,
    } = element.getBoundingClientRect();
    const {
        width: targetWidth,
        height: targetHeight,
    } = targetElement.getBoundingClientRect();

    if (width <= targetWidth && height <= targetHeight) {
        return;
    }

    let scale = 1;
    if (width > targetWidth) {
        scale = targetWidth / width;
        const scaledHeight = height * scale;
        if (scaledHeight > targetHeight) {
            scale *= targetHeight / scaledHeight;
        }
    } else {
        scale = targetHeight / height;
    }

    element.style.fontSize = `${scale}em`;
    element.style.lineHeight = `1em`;

    // When the scale down the font size was done by height
    // then the word position can be changed, so this part
    // scales it up to fit the container width.
    if (height > targetHeight) {
        const {
            width: newWidth,
            height: newHeight,
        } = element.getBoundingClientRect();

        // This selects the smallest scale up factor, that can be applied
        const scaleUpFactor = Math.min(targetWidth / newWidth, targetHeight / newHeight);

        scale = Math.floor(scale * scaleUpFactor * 100) / 100;
        element.style.fontSize = `${scale}em`;
    }
}
