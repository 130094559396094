import React from 'react';

import { MultilangText } from '../types';
import RiseStorytelling from '../../rise/animations/risestorytelling/RiseStorytelling';
import Chapter from '../Chapter';


enum ChapterVariant {
    fullScreen = 'full_screen',
    grid = 'grid',
};

type ApparelPodProps = {
    assets: [],
    chapter_type?: ChapterVariant,
    orientation?: 'portrait' | 'landscape',
    headline?: MultilangText,
    body?: MultilangText,
    supertitle?: MultilangText,
    subtitle?: MultilangText,
};

export default class ApparelPodChapter extends Chapter<ApparelPodProps> {
    component = () => (
        <RiseStorytelling
            json_data={{chapters: [{
                orientation: 'portrait',
                ...this.data
            }]}}
            ignoreUrlParams
        />
    )

    canLoop = true

    calculateDuration() {
        return this.calcLangDuration() * this.calcAmountActiveLanguages();
    }

    calcLangDuration() {
        if (this.data.chapter_type === undefined || this.data.chapter_type === ChapterVariant.fullScreen) {
            return 11;
        }

        return this.data.orientation === 'portrait' ? 11.5 : 10;
    }

    calcAmountActiveLanguages() {
        if (this.data.chapter_type === ChapterVariant.fullScreen ) {
            return 1;
        }

        const {
            headline = {},
        } = this.data;

        return Object.keys(headline).filter(lang => this.languages.includes(lang)).length || 1;
    }
}
