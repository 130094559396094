import React from 'react';
import { motion } from 'framer-motion';
import { ANIMATION_DURATION_S, NIKE_EASE } from '../../constants/nikerise';
import { DynamicComponent } from './DynamicComponent';

const TickerRow = ({
	spacePx,
	repeatedZones,
	zoneLength,
	languageIndex,
	language,
	elementSize,
	effectIndex,
	height,
	left,
	inset,
	activeLanguage,
  effectOccurrenceMultiplier = 1,
	effectTypes,
	effectStaggerTime,
	solarizationInset,
}) => {
	const lineHeight = React.useMemo(
		() => height - solarizationInset * height * 2,
		[height, solarizationInset],
	);

	return (
		<motion.div
			className="scroll-ticker-container"
			initial={{
				height,
				top: languageIndex * height,
				left,
				padding: `0 ${inset}px`,
			}}
			animate={{
				top: (languageIndex - activeLanguage) * height,
			}}
			transition={{
				ease: NIKE_EASE,
				duration: ANIMATION_DURATION_S,
			}}
		>
			{[...Array(2)].map((_, repeatIndex) => (
				<div
					key={repeatIndex}
					className="scroll-ticker-row"
					style={{
						gridColumnGap: `${spacePx}px`,
					}}
				>
					{repeatedZones[languageIndex]?.map((zone, zoneIndex) => (
						<DynamicComponent
							key={zoneIndex}
							{...zone}
							language={language}
							elementSize={elementSize}
							height={height}
							lineHeight={lineHeight}
							showEffect={zoneIndex % Math.round(zoneLength / effectOccurrenceMultiplier) === Math.round(effectIndex / effectOccurrenceMultiplier)}
							effectTypes={effectTypes}
							effectStaggerTime={effectStaggerTime}
							elementIndex={Math.floor(zoneIndex / zoneLength)}
							solarizationInset={solarizationInset}
						/>
					))}
				</div>
			))}
		</motion.div>
	);
};

export default TickerRow;
