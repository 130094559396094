enum PixelMapEventsEnum {
	Init = 'pixelmap.init',
	Loaded = 'pixelmap.loaded',
	Start = 'pixelmap.start',
	Restart = 'pixelmap.restart',
	DataLoaded = 'pixelmap.data.loaded',
	DataError = 'pixelmap.data.error',
	Error = 'pixelmap.error',
    RequestPreviewSnapshot = 'pixelmap.snapshot.request',
    PreviewSnapshot = 'pixelmap.snapshot.done',
    PreviewSnapshotError = 'pixelmap.snapshot.error'
}

export default PixelMapEventsEnum;
