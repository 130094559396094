import { fromPairs } from "lodash";


const ONE_SECOND = 1000;


export default class FPSMeter {
  constructor(config = {}) {
    const {
      trailSize = 60,
      autoStart = false,
    } = config;

    this.fps = new Array(trailSize);
    this.toStop = true;

    if (autoStart) {
      this.startMeter();
    }
  }

  startMeter() {
    this.toStop = false;

    let prevTS = 0;
    let frameCounter = 0;

    const loop = timestamp => {
      if (timestamp - prevTS >= ONE_SECOND) {
        this._collectFrame(frameCounter);
        frameCounter = 0;
        prevTS = timestamp;
      }

      if (this.toStop) {
        return;
      }

      frameCounter++;

      requestAnimationFrame(loop);
    }

    requestAnimationFrame(timestamp => {
      prevTS = timestamp;

      requestAnimationFrame(loop);
    });
  }

  stopMeter() {
    this.toStop = true;
  }

  toJSON() {
    return fromPairs(this.fps.filter(data => data));
  }

  _collectFrame(value) {
    this.fps.shift();
    this.fps.push([Date.now(), value]);
  }
}
