import ReactDOM from 'react-dom/client';
import * as AppEventsService from '../../../../services/app-events.service';
import TemplateEventsEnum from '../../../../enums/TemplateEvents.enum';
import App from './app';
import { mergeChapters, fetchChannelInfo } from '/src/touchpoints/rise/hyperlive/utils';
import {channelConfig} from "../../shared";

AppEventsService.sendEvent(TemplateEventsEnum.Init);

const {json_data} = mergeChapters();

const params = new URLSearchParams(window.location.search)
const chapter = params.get('chapter');
const openChapter = ["", null, undefined].includes(chapter) ? null : chapter;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App config={channelConfig({forceAssetHeight: true})} openChapter={openChapter} data={json_data} />);