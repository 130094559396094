import {createContext, ReactNode, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {resetMaxTexturesInShader} from "@tensorflow/tfjs-backend-webgl/dist/webgl_util";
import {useContainerWidth} from "./ScreenWrapper.hooks";

interface ScreenContext {
    width: number;
}

const ScreenContext = createContext<ScreenContext>({
    width: 1080
});

export function usePx() {
    const screenContext = useContext(ScreenContext);

    return (px: number) => {
        return (px / 1080) * screenContext.width
    }
}

export default function ScreenWrapper({children}: { children: ReactNode }) {
    const container = useRef<HTMLDivElement>();
    const width = useContainerWidth(container);

    return (
        <ScreenContext.Provider value={{width}}>
            <div
                style={{
                    width: '100vw',
                    height: '100vh',
                    backgroundColor: 'black',
                    userSelect: 'none',
                    color: 'white',
                }}>
                <div
                    ref={container}
                    style={{
                        height: '100vh',
                        aspectRatio: '9 / 16',
                        position: 'relative',
                        backgroundColor: '#888',
                        overflow: 'hidden',
                    }}
                >
                    {children}
                </div>
            </div>
        </ScreenContext.Provider>
    )
}