import React, {useState, useEffect, CSSProperties, useMemo, useCallback, useRef} from 'react';
import Transition from "./Transition";
import {MediaType, ThemeType} from "../shared";
import {motion, AnimatePresence} from "framer-motion";
import ReactHlsPlayer from "react-hls-player";
import {PreventAnimationBubble} from "./WrapAnimatePresence";

interface MediaProps {
    style?: CSSProperties;
    mediaStyle?: CSSProperties;
    mediaContainerStyle?: CSSProperties;
    media: Array<MediaType>;
    width: number | string;
    height: number | string;
    theme?: ThemeType;
    cover?: CoverType;
    dim?: boolean;
    duration?: number;
    delay?: number;
    preventInitial?: boolean;
    preventInitialSlide?: boolean;
    preventExit?: boolean;
    transitionDuration?: number;
    effect?: MediaEffect;
    forceEffectOnVideo?: boolean;
    transparent?: boolean;
    layoutId?: string;
    useInternalTiming?: boolean;
    preventTransitions?: boolean;
}

export type MediaEffect =
    'zoom-top'
    | 'zoom-center'
    | 'pan-left'
    | 'pan-right'
    | 'zoom-pan-left'
    | 'zoom-out-center'
    | 'rotate-out';

type CoverType = 'fill' | 'fit';

export default function Media({
                                  layoutId,
                                  media,
                                  transparent,
                                  style = {},
                                  mediaContainerStyle = {},
                                  width,
                                  height,
                                  preventInitial = false,
                                  preventInitialSlide = false,
                                  preventExit = false,
                                  theme,
                                  dim = false,
                                  cover = 'fill',
                                  duration = 2_500,
                                  useInternalTiming = false,
                                  transitionDuration,
                                  mediaStyle = {},
                                  delay = 0,
                                  effect,
                                  preventTransitions = false,
                                  forceEffectOnVideo = false
                              }: MediaProps) {
    const [showMedia, setShowMedia] = useState(false);
    const filteredMedia = useMemo(() => media?.filter(i => i) ?? [], [media]);
    const [rendered, setRendered] = useState(false);
    const [activeMediaIndex, setActiveMediaIndex] = useState(0);

    const asset = filteredMedia[activeMediaIndex % filteredMedia.length];

    const animate = useMemo(() => {
        if (asset?.resource_type === 'video' && forceEffectOnVideo === false) {
            return {};
        }

        return {
            'zoom-center': {scale: [1, 1.5]},
            'zoom-top': {scale: [1, 1.5], transformOrigin: 'top center'},
            'zoom-out-center': {scale: [1.5, 1]},
            'pan-left': {scale: [1.5, 1.5], x: ['10%', '-10%'], transformOrigin: 'top center'},
            'pan-right': {scale: [1.5, 1.5], x: ['-10%', '10%'], transformOrigin: 'top center'},
            'zoom-pan-left': {scale: [1.5, 1], x: ['-10%', '0%'], transformOrigin: 'top center'},
            'zoom-pan-right': {scale: [1.5, 1], x: ['0%', '10%'], transformOrigin: 'top center'},
            'rotate-out': {scale: [2, 1], rotate: ['25deg', '-10deg']}
        }?.[effect] ?? {};
    }, [effect, activeMediaIndex]);

    useEffect(() => {
        setTimeout(() => {
            setShowMedia(true);
        }, delay * 1000);
    }, []);

    const playSlideshow = (index = null) => {
        let a = index !== null ? filteredMedia[index % filteredMedia.length] : asset;
        const durationSec = duration / 1000;
        let d = durationSec;

        if (useInternalTiming) {
            d = a?.force_duration === 'full' ? (a?.duration ?? durationSec) : Number(a.force_duration);
        }

        setTimeout(() => {
            setActiveMediaIndex(i => {
                playSlideshow(i + 1);
                return i + 1;
            });
        }, d * 1_000);
    }

    useEffect(() => {
        if (!showMedia) {
            return;
        }

        let intervalId;
        if (filteredMedia.length > 1) {
            // intervalId = setInterval(() => {
            //     setActiveMediaIndex(i => i + 1);
            // }, duration);
            playSlideshow();
        }

        setRendered(true);

        return () => {
            clearInterval(intervalId);
        }
    }, [showMedia]);

    return (
        <motion.div layoutId={layoutId} style={{width, height, position: 'relative', ...style}}>
            {
                dim && (
                    <div
                        style={{
                            top: 0,
                            left: 0,
                            zIndex: 1,
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            backgroundColor: theme === 'dark' ? 'rgba(0,0,0,0.2)' : 'rgba(100,100,100,0.2)'
                        }}
                    />
                )
            }
            {
                showMedia && (
                    <Transition
                        variant={'wipe-y'}
                        delay={delay}
                        preventInitial={preventInitial}
                        preventExit={preventExit}
                        duration={transitionDuration}
                    >
                        <div
                            style={{
                                background: transparent ? 'transparent' : 'white',
                                filter: dim ? `contrast(.9) brightness(1.3)` : 'contrast(1.1)',
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                                ...mediaContainerStyle,
                            }}
                        >
                            {/* @ts-ignore */}
                            <AnimatePresence>
                                <Transition
                                    duration={transitionDuration}
                                    cover
                                    preventTransitions={preventTransitions}
                                    variant={(activeMediaIndex === 0 && preventInitialSlide) ? 'wipe-x' : 'wipe-slide-x'}
                                    initial={rendered}
                                    preventInitial={preventInitial && activeMediaIndex === 0}
                                    preventExit={preventExit}
                                    key={activeMediaIndex}
                                >
                                    <motion.div
                                        style={{width: '100%', height: '100%'}}
                                        animate={animate}

                                        transition={{
                                            duration: 15,
                                            ease: "linear",
                                            repeatType: 'mirror',
                                            repeat: Infinity,
                                        }}
                                    >
                                        <MediaRenderer
                                            mediaStyle={mediaStyle}
                                            media={asset}
                                            cover={cover}
                                            height={height}
                                        />
                                    </motion.div>
                                </Transition>
                            </AnimatePresence>
                        </div>
                    </Transition>
                )
            }
        </motion.div>
    )
}

interface MediaRendererProps {
    media: MediaType;
    cover?: CoverType;
    mediaStyle?: CSSProperties;
    height?: number | string;
}

function MediaRenderer({media, cover, height, mediaStyle = {}}: MediaRendererProps) {
    const playerRef = useRef();
    const source = useMemo(() => {
        if (media?.blob) {
            return URL.createObjectURL(media.blob);
        }
        return media?.url;
    }, [media]);
    const style = (c) => ({
        width: '100%',
        height: '100%',
        objectFit: c === 'fit' ? 'scale-down' : 'cover',
        ...mediaStyle
    });

    if (media?.resource_type === 'image') {
        return (
            <img
                src={source}
                style={style(media.fit ?? cover) as CSSProperties} alt={''}/>
        )
    }

    if (media?.resource_type === 'video') {
        return (
            <video style={style('fill') as CSSProperties} src={source} muted loop autoPlay/>
        )
    }

    if (media?.resource_type === 'm3u8-video') {
        return (
            <ReactHlsPlayer
                playerRef={playerRef}
                src={media.url}
                autoPlay={true}
                controls={false}
                loop={true}
                width="100%"
                height={height ?? 'auto'}
                muted={true}
                style={style(cover) as any}
            />
        )
    }
}