import React from 'react';

import Chapter from '../Chapter';

import { AssetType } from '../../../utils/storage';
import NTC from '../../rise/animations/ntc/NTC';


type IntroChapter = {
    title: string,
    media?: AssetType,
};

type FullScreenChapter = {
    data: {
        title: string,
        body: string,
        qr_section: {
            qrcode: string,
        },
        media?: AssetType,
    },
    media?: AssetType,
};

type SplitChapter = {
    data: {
        title: string,
        selected_entries: AssetType[],
        qr_section: {
            media: AssetType,
        },
    },
    media?: AssetType,
};


type NtcChapterProps = {
    chapters: [
        IntroChapter,
        FullScreenChapter,
        SplitChapter,
        SplitChapter,
        FullScreenChapter,
    ]
};


export default class NtcChapter extends Chapter<NtcChapterProps> {
    component = () => (
        <NTC
            json_data={this.data}
        />
    )

    canLoop = true

    calculateDuration() {
        return 48.5;
    }
}
