import { AnimatePresence } from "framer-motion";
import { useFastLane } from "../store";
import TakeoverMedia from "./takeover-media";
import TakeoverTransition from "./takeover-transition";
import {useDisplayType} from "../store/fast-lane-context";
import { useEffect, useState } from 'react';

export default function Takeover() {
	const [key, setKey] = useState(0);
	const takeover = useFastLane(f => f.takeover);
	const display = useDisplayType();

	useEffect(() => {
		if (!takeover.enabled) {
			setTimeout(() => {
				setKey(key + 1);
			}, 2_000);
			return;
		}
	}, [takeover?.enabled]);

	if (display !== 'fast-lane') {
		return null;
	}


	return (
		<AnimatePresence>
			{
				takeover?.enabled && (
					<TakeoverTransition
							key={`takeover-${key}`}
					>
						<TakeoverMedia />
					</TakeoverTransition>
				)
			}
		</AnimatePresence>
	);
}