import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, {forwardRef, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { Codification } from '../../../components';
import { DEFAULT_TRANSITION, HUB_CODIFICATION_PROPS } from '../../../constants/transition';
import { TRANSITION_WAIT } from '../../../constants/hub';
import { figmaToPx } from '../../../utils/math';
import { useHubContext } from '../hooks/useHubContext';
import { useReadyCounter } from '../hooks/useReadyCounter';
import {containsChinese, containsLanguage} from "../../../../../utilities";

const titleBlockVariants = {
	expand: ({ heightRatio }) => ({
		height: `${heightRatio * 100}%`,
	}),
	shrink: ({ shrinkHeight }) => ({
		height: `${shrinkHeight}px`,
	}),
};

export const TitleBlock = forwardRef(({
	active = true,
	heightRatio,
	title,
	subtitle,
	titleImage,
	style,
	reverse,
	rootDimensions,
	onMinTitleHeight,
	blocksAmount,
}, forwardedRef) => {
	const { initialized, onInitBlock, onNext, onReverseBlock} = useHubContext();
	const titleRef = useRef();
	const tempEndTimer = useRef(null);
	subtitle = subtitle.trim();

	const getLanguage = useCallback(() => {
		// containsLanguage
		return '';
	}, [title, subtitle]);

	const hasChineseOnFirstLine = useMemo(() => {
		const [firstLine = ''] = title?.split('\n') ?? [];
		return containsChinese(firstLine);
	}, [title]);

	const { updateCounter } = useReadyCounter(
		// subtitle ? 2 : 1,
		1,
		initialized
			? reverse ? onReverseBlock : onNext
			: onInitBlock
	);

	const minHeight = figmaToPx({
		figmaPx: 350,
		actualDimension: rootDimensions.height,
		baseHeight: blocksAmount === 1 ? 800 : undefined,
	});
	let padding = figmaToPx({
		figmaPx: 43,
		actualDimension: rootDimensions.height,
	});
	let shouldScale = false;

	const getTitleHeight = () => {
		return titleRef.current?.getBoundingClientRect().height || 0
	}

	const [titleHeight, setTitleHeight] = useState(getTitleHeight());

	// console.log('lines', title.split(/\r\n|\r|\n/).length)

	if (title.split(/\r\n|\r|\n/).length >= 3) {
		padding = figmaToPx({
			figmaPx: 404,
			actualDimension: rootDimensions.height,
		});
		shouldScale = true;
		// shrinkHeight = 1.2;
		// shrinkHeight = shrinkHeight * 0.8;
	}

	let shrinkHeight = Math.max(titleHeight + padding, minHeight);

	useEffect(() => {
		tempEndTimer.current = setTimeout(() => {
			updateCounter();
		}, 100);
	}, [reverse]);

	useEffect(() => {
		if (shrinkHeight) {
			onMinTitleHeight(shrinkHeight);
		}
	}, [shrinkHeight, titleRef.current]);

	return (
		<motion.div
			ref={forwardedRef}
			className="c-title-block"
			layout
			custom={{ shrinkHeight, heightRatio }}
			initial="expand"
			animate={active ? 'expand' : 'shrink'}
			variants={titleBlockVariants}
			transition={DEFAULT_TRANSITION}
			style={style}
		>
			<motion.div
				style={{
					transformOrigin: 'top left'
				}}
				animate={{
					scale: (shouldScale && !active) ? 0.7 : 1,
				}}
				transition={DEFAULT_TRANSITION}
			>
				{
					titleImage?.url ? (
						<img
							src={titleImage?.url}
							style={{
								width: '100%',
								height: 'auto',
								marginTop: '20px',
								marginBottom: '-20px',
							}}
						/>
					) : (
            <Codification
              {...HUB_CODIFICATION_PROPS}
              ref={titleRef}
              className={`c-title-block__title ${hasChineseOnFirstLine && 'chinese-first-line'}`}
              text={title}
              typography={{ primary: true, size: "7xl", tag: "span" }}
              reverse={reverse}
            />
          )
				}

			</motion.div>
			{subtitle && (
				<Codification
					{...HUB_CODIFICATION_PROPS}
					className={`c-title-block__subtitle is-${getLanguage(subtitle)}`}
					setLanguageClassName
					text={subtitle}
					typography={{ size: "xl", tag: "span" }}
					reverse={reverse}
				/>
			)}
		</motion.div>
	);
});

TitleBlock.propTypes = {
	active: PropTypes.bool,
	heightRatio: PropTypes.number,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	style: PropTypes.object,
	width: PropTypes.number,
	height: PropTypes.number,
	onAnimationComplete:PropTypes.func,
	blocksAmount: PropTypes.number,
};
