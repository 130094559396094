@use 'sass:math';
@use '../variables' as vars;

$color: #dadada;


.GridWithCell {
    & > * {
        position: absolute;
        top: 0;
    }

    :global(.grid) {
        line {
            stroke-dasharray: vars.to-vh(2, 1920) vars.to-vh(2, 1920);
            stroke: $color;
        }
    }
}

