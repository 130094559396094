import { LineType, ThemeConfig } from "../types";
import { get, set } from "./index";
import wellTheme from "../themes/archive/well-theme";
import color from "color";

export interface ThemeState {
	theme: ThemeConfig | null;

	setTheme: (theme: ThemeConfig) => void;
	getTheme: () => ThemeConfig;
	isBackgroundDark: () => boolean;
	getGridLineThickness: (lineType: LineType) => number;
}

const DEFAULT_GRID_LINE_THICKNESS = 2;

export const themeState = (set: set, get: get): ThemeState => ({
	theme: null,

	setTheme: (theme: ThemeConfig) => set({ theme }),
	getTheme: () => get()?.theme ?? wellTheme,
	isBackgroundDark: () => {
		const background = get()?.theme?.backgroundColor ?? wellTheme.backgroundColor;

		return color(background).isDark()
	},
	getGridLineThickness: (lineType: LineType) => {
		const gridLineThickness = get()?.theme?.gridLineThickness ?? DEFAULT_GRID_LINE_THICKNESS;

		if (typeof gridLineThickness === "number") {
			return gridLineThickness;
		}

		return gridLineThickness[lineType];
	}
})