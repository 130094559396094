import ReactDOM from 'react-dom/client';
import FastLane from './fast-lane';
import FastLaneEditor from "./editor/fast-lane-editor";
import {DisplayType} from "./types";
import {initialize} from "./entry-initializer";
import '../../../../init';

const {instance, displayType} = initialize();

// Either render the editor or the actual fast lane runner.
const component = displayType === 'editor' ?
    <FastLaneEditor instance={instance}/> :
    <FastLane display={displayType as DisplayType} instance={instance}/>;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(component);