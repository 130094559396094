import React, {
    CSSProperties,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    motion,
    AnimationProps,
    AnimatePresence,
    useIsPresent,
} from 'framer-motion';
import classNames from 'classnames';

import * as cssClassNames from '../styles/components/Floor.module.scss';

import {
    getLangText,
    hasTextToDisplay,
    MultiLangText,
    secToMs,
    setAnimationTimeout,
} from '../shared';
import DirectionArrow from './DirectionArrow';
import CodificationWithTranslations from './Codification/CodificationWithTranslations';


export type FloorDirection = 'up' | 'down' | 'current';

export type FloorProps = {
    children: any;
    active?: boolean,
    level: string,
    title: MultiLangText,
    languages: string[],
    delay: number,
    description: MultiLangText,
    languageTransitionDelay?: number,
    languageDisplayDuration: number,
    runFinalTextExitAnimation?: boolean,
    floorGridTemplateColumns: string[],
    gridLineThickness: number,
    direction?: FloorDirection,
    className?: string,
    style?: CSSProperties,
    backgroundAnimationProps?: AnimationProps,
}

export default function FloorComponent(props: FloorProps) {
    const {
        active = false,
        level,
        title = {},
        description = {},
        languages,
        languageTransitionDelay = 0,
        direction = 'down',
        runFinalTextExitAnimation = true,
    } = props;
    const [langIndex, setLangIndex] = useState(0);
    const isPresent = useIsPresent();
    const [toShowDescriptionComponent, setToShowDescriptionComponent] = useState(false);

    const text: MultiLangText = {};
    languages.forEach(lang => {
        const titleLang = getLangText(title, lang);
        const descrLang = getLangText(description, lang);

        text[lang] = titleLang ? `${titleLang}\n${descrLang}` : descrLang;
    });
    const hasText = useMemo(() => hasTextToDisplay(text, languages), [text, languages]);

    const descriptionComponent = hasText ? (
        <CodificationWithTranslations
            text={text}
            languages={languages}
            langIndex={langIndex}
            languageTransitionDelay={languageTransitionDelay}
            runFinalTextAnimation={runFinalTextExitAnimation}
            codificationProps={{
                characterNextTrigger: 2,
                characterSwitchAmount: 3,
            }}
        />
    ) : null;

    useEffect(() => {
        if (!toShowDescriptionComponent) {
            return;
        }

        const cancelTimeout = setAnimationTimeout(() => {
            if (langIndex >= languages.length - 1) {
                return;
            }

            setLangIndex(langIndex + 1);
        }, secToMs(props.languageDisplayDuration));

        return () => {
            cancelTimeout();
        };
    }, [toShowDescriptionComponent, langIndex]);

    useEffect(() => {
        const cancelTimeout = setAnimationTimeout(() => {
            setToShowDescriptionComponent(true);
        }, secToMs(props.delay));

        return () => {
            cancelTimeout();
        };
    }, []);

    return (
        <motion.div
            className={classNames(cssClassNames['floor'], props.className, {
                [cssClassNames['active']]: active,
            })}
            style={{
                gridTemplateColumns: props.floorGridTemplateColumns.join(' '),
                ...props.style
            }}
        >
            <div
                className={cssClassNames['floor-description']}
                style={{
                    gridColumnEnd: `span ${props.floorGridTemplateColumns.length - 2}`,
                }}
            >
                <motion.div
                    className={cssClassNames['description-background']}
                    {...props.backgroundAnimationProps}
                ></motion.div>
                <div
                    className={cssClassNames['description-text']}
                    style={{
                        margin: props.gridLineThickness / 2,
                        borderWidth: props.gridLineThickness,
                        transform: `translateX(${props.gridLineThickness}px)`,
                    }}
                >
                    <AnimatePresence>
                        {isPresent && toShowDescriptionComponent ? descriptionComponent : null}
                    </AnimatePresence>
                </div>
            </div>
            <div className={cssClassNames['floor-direction']}>
                {direction !== 'current' ? (
                    <DirectionArrow
                        sideLength={50}
                        strokeWidth={7}
                        animate='show'
                        duration={1.2}
                        delay={props.delay}
                        exitDelay={0.3}
                        rotation={getDirectionArrowRotation(direction)}
                    />
                ) : null}
            </div>
            <div className={cssClassNames['number']} data-level={level}>{props.children}</div>
        </motion.div>
    );
}

function getDirectionArrowRotation(direction: FloorDirection) {
    switch (direction) {
        case 'up':
            return 180;

        case 'down':
        default:
            return 0;
    }
}
