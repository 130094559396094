import Preview from "./preview";
import {FastLaneInstance} from "../types";

export default function FastLaneEditor({instance}: {instance: FastLaneInstance}) {
	return (
		<div
			style={{
				background: '#efefef',
				position: 'absolute',
				width: '100%',
				height: '100%',
			}}
		>
			<Preview instance={instance} />
		</div>
	)
}