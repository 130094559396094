import {rpx, useResponsivePx} from "../helpers";
import SectionGridCircle from "./section-grid-circle";
import { useFastLane } from "../store";
import { motion } from "framer-motion";
import {DEFAULT_EASE} from "../../../../jordan/shared";
import {useMemo} from "react";
import {usePixelMap} from "../store/fast-lane-context";

interface SectionGridProps {
	index: number;
}

export default function SectionGrid({ index }: SectionGridProps) {
	const responsivePx = useResponsivePx();
	const theme = useFastLane(f => f.getTheme());
	const pixelMap = usePixelMap();
	const isMediaPlaying = useFastLane(f => f.isMediaPlaying(index));

	const leftBorder = theme?.leftBorder ?? {
		thickness: 0,
	};

	const opacity = (theme.hideGridsWhenPlayingMedia && isMediaPlaying) ? 0 : 1;

	const circleLeft = useMemo(() => {
		return pixelMap.contentHeight > 120 ? 212 : 265
	}, [pixelMap]);

	return (
		<motion.div
			style={{
				width: "100%",
				height: "100%",
				position: "absolute",
				left: theme?.gridOffset?.vertical ?? 0,
				top: 0,
				zIndex: 3,
			}}
			animate={{
				opacity,
			}}
			transition={{
				duration: .5,
				delay: .5,
				ease: DEFAULT_EASE,
			}}
		>
			<div
				style={{
					width: leftBorder.thickness,
					height: "100%",
					backgroundColor: theme?.leftBorder?.color ?? theme.gridColor,
					position: "absolute",
					top: 0,
					left: 0,
				}}
			/>
			<div
				style={{
					padding: responsivePx(10),
					height: "100%",
					aspectRatio: "1 / 1",
					marginLeft: circleLeft,
					overflow: "visible",
				}}
			>
				<SectionGridCircle index={index} />
			</div>
		</motion.div>
	);
}