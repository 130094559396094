import { has, isObject, isNumber } from 'lodash';
import Api from "./api";
import FPSMeter from './data/FPSMeter';
import Vital from "./data/Vital";
import * as deviceUUID from './utils/device-uuid';
import SessionData from './data/SessionData';
import { TOUCHPOINT_TYPES } from './constants';

const DEFAULT_INTERVAL = 20_000;

export default class Vitals {
	constructor(params) {
		this.verifyParams(params);

		this.api = new Api(params.baseUrl);
		this.fpsMeter = new FPSMeter({
			autoStart: true,
		});
		this.type = params.type;
		this.pixelMapSlug = params.pixelMapSlug; // rudimental, clean up
		this.storeId = params.storeId;
		this.slug = params.slug;
		this.label = params.label;
		this.deviceUUID = deviceUUID.retrieve();
		this.sessionKey = this.generateSessionKey();
		this.delay = params.delay;
		this.userAgent = window.navigator.userAgent;

		console.log(`Vitals initialization start`, this);

		this.touchpointId = null;
		this.sessionId = null;

		if (isNumber(params.delay) && !this.isAppSpaceApp()) {
			this.delayInitialize(params.delay);
		} else {
			this.initialize();
		}
	}

	createAutoVitalsService(params, interval = DEFAULT_INTERVAL) {
		const intervalId = setInterval(() => this.sendHeartbeat(params), interval);

		return () => {
			clearInterval(intervalId);
		};
	}

	getPixelMapSlug() {
		if (this.type === TOUCHPOINT_TYPES.PIXEL_MAP) {
			return this.slug;
		} else if (this.type === TOUCHPOINT_TYPES.CHANNEL && this.pixelMapSlug) { // pixelmap channel
			return this.pixelMapSlug;
		} else {
			return null;
		}
	}

	isAppSpaceApp() {
		const userAgent = this.userAgent.toLowerCase();
		return userAgent.includes('appspaceapp/') || userAgent.includes('sportpulseapp/');
	}

	async sendHeartbeat(params = {}, config = {}) {
		if (!this.touchpointId || !this.sessionId) {
			console.warn('Skip sending a heartbeat: vitals service is not ready');
			return;
		}

		const vital = new Vital(params.event, this.fpsMeter);

		let data = {};
		const {
			is_delayed = false,
		} = params;

		const {
			ignoreErrors = true
		} = config;

		try {
			data = vital.snapshot()
		} catch (err) {
			console.warn(`Could not create vitals snapshot`, err);
			return;
		}

		try {
			return await this.api.heartbeats.send({
				is_delayed,
				json_data: data,
				channel: this.type === TOUCHPOINT_TYPES.CHANNEL ? this.slug : null,
				pixelmap_slug: this.getPixelMapSlug(),
				device_uuid: this.deviceUUID,
				session_id: this.sessionKey,
				label: this.label ? this.label : this.sessionKey, // rudimental, clean up
				sessionId: this.sessionId,
				touchpointId: this.touchpointId,
			});
		} catch (err) {
			console.warn(`Could not send a heartbeat`, err);

			if (!ignoreErrors) {
				throw err;
			}
		}
	}

	/**
	 * @private
	 */
	async delayInitialize(delay) {
		setTimeout(() => this.initialize(), delay * 1000);
	}

	/**
	 * @private
	 */
	async initialize() {
		await this.initTouchpoint();
		await this.initSession();
	}

	/**
	 * @private
	 */
	async initTouchpoint() {
		const touchpoint = await this.createTouchpoint();

		this.touchpointId = touchpoint.id;
	}

	/**
	 * @private
	 */
	async initSession() {
		if (this.sessionId) {
			console.log('Session is already started for this vitals instance.');
			return;
		}

		if (!this.touchpointId) {
			throw new Error('Unable to create vitals session: touchpoint not initialized');
		}

		const session = await this.api.sessions.create({
			touchpointId: this.touchpointId,
			sessionKey: this.sessionKey,
			jsonData: new SessionData().snapshot(),
			userAgent: this.userAgent,
		});

		this.sessionId = session.id;
	}

	/**
	 * @private
	 */
	async createTouchpoint() {
		try {
			return await this.api.touchpoints.create({
				type: this.type,
				slug: this.slug,
				label: this.label,
				deviceUUID: this.deviceUUID,
				storeId: this.storeId,
			});
		} catch (error) {
			console.error('Unable to create vitals touchpoint', error);
			throw error;
		}
	}

	/**
	 * @private
	 */
	verifyParams(params) {
		if (!isObject(params)) {
			throw new Error('Missing required parameters');
		}

		const requiredParams = ['baseUrl', 'type', 'slug', 'label', 'storeId'];
		const missedParams = requiredParams.filter(paramKey => !has(params, paramKey) || !params[paramKey])

		if (missedParams.length > 0) {
			throw new Error(`Missing required parameters: "${missedParams.join('", "')}"`);
		}
	}

	/**
	 * @private
	 */
	generateSessionKey() {
		const currentTimestamp = Date.now() / 1000;
		const randomStringSuffix = (Math.random() + 1).toString(36).substring(7);

		return `${currentTimestamp}-${randomStringSuffix}`;
	};
}
