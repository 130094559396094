import React, {useEffect, useRef, useContext, useMemo} from "react";
import { useTranslation } from "react-i18next";

import Typography from "./Typography";

import { GLYPHS } from "./codification";

import Context from "./Codification.context";
import defaultProps from "./Codification.defaultProps";
import propTypes from "./Codification.propTypes";

export const random = (min, max) =>
	Math.floor(Math.random() * (max - min + 1)) + min;

const CodificationCharacter = ({ text, index, setLanguageClassName = false }) => {
	const { typography, active, dir, repeat, timeout, trigger, onTrigger, reverse, isStatic, initialIsStatic } =
		useContext(Context);
	const { t } = useTranslation();
	const glyphRef = useRef();
	const resultRef = useRef();
	const isActive = active === index;
	let step = reverse ? repeat : 0;
	const updateTimeout = useRef(null);

	const languageClassName = useMemo(() => {
		return '';
	}, [text]);


	const getRandomGlyph = () => t(`glyphs.${GLYPHS[random(0, GLYPHS.length - 1)]}`);

	const setGlyph = (value) => {
		if (glyphRef.current) {
			glyphRef.current.innerText = value;
		}
	};

	const clearUpdateTimeout = () => {
		clearTimeout(updateTimeout.current);
		updateTimeout.current = null;
	};

	const onRepeat = () => {
		clearUpdateTimeout();

		step += dir;
		setGlyph(getRandomGlyph());

		if (step === trigger) {
			onTrigger();
		}
	};

	const onComplete = () => {
		setGlyph(reverse ? "" : text);
		clearUpdateTimeout();
	};

	const setUpdateTimeout = () => {
		updateTimeout.current = setTimeout(() => {
			if (step >= 0 && step <= repeat) {
				onRepeat();
				setUpdateTimeout();
			} else {
				onComplete();
			}
		}, timeout);
	};

	useEffect(() => {
		if (!isStatic && isActive) {
			if ((reverse && step === repeat) || (!reverse && step === 0)) {
				setUpdateTimeout();
			}
		}

		return () => {
			if (!isActive) {
				clearUpdateTimeout();
			}
		};
	}, [isActive]);

	useEffect(() => {
		if (initialIsStatic && !isStatic && !reverse) {
			setGlyph("");
		}
	}, [isStatic]);

	useEffect(() => {
		if (reverse) {
			setGlyph(text);
		}

		if (initialIsStatic) {
			setGlyph(text);
		}

		return () => {
			clearUpdateTimeout();
		};
	}, []);

	return (
		<Typography className={`c-codification__character ${languageClassName}`} {...typography} tag="span">
			<span ref={resultRef} className="c-codification__result">
				{text}
			</span>

			<span ref={glyphRef} className="c-codification__glyph" />
		</Typography>
	);
};

CodificationCharacter.defaultProps = defaultProps.character;
CodificationCharacter.propTypes = propTypes.character;

export default CodificationCharacter;
