import * as cssClassNames from '../styles/screens/Podium.module.scss'

import {
    MediaType,
    MultiLangText,
    secToMs,
    ThemeType,
} from '../shared';
import LayeredMedia, {
    calculateDuration as calculateMediaDuration,
    calculateExitDuration as calculateExitMediaDuration,
    LayeredMediaProps,
} from './LayeredMedia';
import CodifiedText, {
    calculateDuration as calculateCodificationDuration,
    calculateExitDuration as calculateCodificationExitDuration,
    fitLanguagesInDuration,
} from '../components/Codification/CodifiedText';
import MediaRenderer from '../components/MediaRenderer';

const oneLanguagePauseDuration = 5;

type OverlayProps = {
    variant: 'media',
    data: MediaType,
} | {
    variant: 'text',
    data: MultiLangText,
};

type Props = Omit<LayeredMediaProps, 'overlays'> & {
    overlay?: OverlayProps,
    theme: ThemeType,
}

export default function Podium(props: Props) {
    const {
        languages,
        overlay,
        loop,
        theme,
    } = props;

    const overlays = [];
    const hasOverlay = overlay?.variant && overlay?.data;
    if (hasOverlay) {
        const {
            variant,
            data,
        } = overlay;

        let buildComponent;
        switch (variant) {
            case 'text':
                let fittedLanguages = languages;
                if (!loop) {
                    const mediaDuration = calculateMediaDuration(props);
                    fittedLanguages = fitLanguagesInDuration({
                        text: data,
                        languages,
                        pauseDuration: oneLanguagePauseDuration
                    }, mediaDuration);
                }

                buildComponent = () => (
                    <div className={cssClassNames.text}>
                        <CodifiedText
                            text={data}
                            theme={theme}
                            languages={fittedLanguages}
                            pauseDuration={oneLanguagePauseDuration}
                            className={cssClassNames.text_container}
                            loop={loop}
                        />
                    </div>
                );
                break;

            case 'media':
                buildComponent = () => (
                    <div className={cssClassNames.media}>
                        <MediaRenderer
                            media={data}
                            mediaStyle={{
                                width: 'unset',
                                height: 'unset',
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}
                        />
                    </div>
                );
                break;
        }

        const properties = {
            languages,
            ...data
        };
        overlays.push(
            <div className={cssClassNames.two_sides}>
                {buildComponent?.(properties)}
                {buildComponent?.(properties)}
            </div>
        );
    }

    return (
        <LayeredMedia
            {...props}
            overlays={overlays}
        />
    );
}


export function calculateDuration(props: Props) {
    const {
        overlay,
        languages,
    } = props;

    const mediaDuration = calculateMediaDuration(props);
    const hasOverlay = overlay?.variant && overlay?.data;

    return mediaDuration > 0 || !hasOverlay ? mediaDuration : secToMs(calcOverlayDuration(overlay, languages));
}

export function calculateExitDuration(props: Props) {
    const {
        overlay,
        languages,
    } = props;

    const mediaDuration = calculateExitMediaDuration(props);
    const hasOverlay = overlay?.variant && overlay?.data;

    return mediaDuration > 0 || !hasOverlay ? mediaDuration : secToMs(calcOverlayExitDuration(overlay, languages))
}

function calcOverlayDuration(overlayProps: OverlayProps, languages?: string[]) {
    const {
        variant,
        data,
    } = overlayProps || {};

    switch (variant) {
        case 'media':
            return oneLanguagePauseDuration;

        case 'text':
            return calculateCodificationDuration({
                text: data,
                languages,
                pauseDuration: oneLanguagePauseDuration,
            });

        default:
            return 0;
    }
}

function calcOverlayExitDuration(overlayProps: OverlayProps, languages?: string[]) {
    const {
        variant,
        data,
    } = overlayProps || {};

    switch (variant) {
        case 'media':
            return 0;

        case 'text':
            return calculateCodificationExitDuration({
                text: data,
                languages,
                pauseDuration: oneLanguagePauseDuration,
            });

        default:
            return 0;
    }
}
