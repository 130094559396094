import {ANIMATION_TYPES} from '../../../../constants';
import * as Hyperlive from "../../hyperlive/utils";

export const getKidsTakeover = () => {
  const animation_json = Hyperlive.fetchAnimations();

  return animation_json.find(({animation_type_id}) => animation_type_id === ANIMATION_TYPES.FAST_LANE_KIDS_TAKEOVER);
}

export const DURATIONS = {
  INTRO: 3_000,
  SWEEP_IN: 4_000,
  ACTIVITY_INTRO: 2_000,
  ACTIVITY_TRIGGER_NEXT: 2_000,
  ACTIVITY_NUMBER_ON_SCREEN_DELAY: 1_000,
  SECOND: 1_000,
}

/**
 * Calculates the duration of the entire sequence.
 */
export const takeoverTimeout = (data, callback, beforeSwipeOutCallback) => {
  const initial = DURATIONS.INTRO + DURATIONS.SWEEP_IN + DURATIONS.ACTIVITY_TRIGGER_NEXT;

  const chapters = data?.json_data?.chapters?.reduce((acc, curr) => {
    const activity = curr.count * curr.duration
    const totalActivity = activity + DURATIONS.ACTIVITY_INTRO + DURATIONS.ACTIVITY_NUMBER_ON_SCREEN_DELAY + DURATIONS.ACTIVITY_TRIGGER_NEXT;
    return acc + totalActivity;
  }, 0);

  const outro = DURATIONS.SWEEP_IN;
  const total = initial + chapters + outro;

  setTimeout(callback, total);
  setTimeout(beforeSwipeOutCallback, initial + chapters)

  return total;
}

export const shouldPlayTakeover = (frequency, callback, precision = 3) => {
  console.log('[TAKEOVER]')
  const getMinute = () => Number(new Date() / 1_000 / 60).toFixed(precision)
  let wait = false;

  const check = () => {
    const isDue = getMinute() % frequency === 0;

    if (isDue && wait === false) {
      wait = true;
      console.log('shouldPlayTakeover');

      callback();

      setTimeout(() => {
        wait = false;
      }, 1_000);
    }
    requestAnimationFrame(check)
  }

  check();
}