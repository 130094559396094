import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import classNames from 'classnames';
import {
    AnimatePresence,
    motion,
    usePresence,
} from 'framer-motion';

import * as cssClassNames from '../../styles/screens/product/IntroV2.module.scss';

import {
    DEFAULT_EASE,
    getMediaDuration,
    hasTextToDisplay,
    MediaType,
    MultiLangText,
    secToMs,
    setAnimationTimeout,
} from '../../shared';
import { DEFAULT_DURATION_S } from '../../constants/transition';
import {
    BaseChapterProps,
} from '../../components/ChapterRenderer';
import CodificationWithTranslations, {
    calculateDuration as calculateCodificationDuration,
    calculateExitDuration as calculateExitCodificationDuration,
    TranslationCodificationProps,
} from '../../components/Codification/CodificationWithTranslations';
import Grid from '../../components/Grid';
import MediaRenderer from '../../components/MediaRenderer';


const bigCellLengthRef = 80 / 1920;
const primaryMediaHeightRef = 1040 / 1920;
const gridWidthRef = 400 / 1080;

const codificationProps: TranslationCodificationProps = {
    characterSwitchAmount: 4,
    characterNextTrigger: 2,
    timingConfig: {
        control: 'character',
        duration: 0.03,
    },
};
const languageTransitionDelay = 0.25;
const primaryMediaEntryDuration = DEFAULT_DURATION_S;
const secondaryMediaEntryDuration = primaryMediaEntryDuration / 2;
const secondaryMediaDelay = 0.3;
const pause = 4;

const driftSpeed = 13 / 1920; // px/s
const driftDuration = 16; // s
const driftDistanceRef = driftSpeed * driftDuration;


export type IntroV2Props = BaseChapterProps & {
    headVariant?: 'text' | 'media',
    text?: MultiLangText,
    headMedia?: MediaType,
    primaryMedia?: MediaType,
    secondaryMedia?: MediaType,
    gridVariant?: string,
};

export default function IntroV2(props: IntroV2Props) {
    const {
        headVariant = 'text',
        text,
        headMedia,
        languages,
        width,
        height,
        primaryMedia,
        secondaryMedia,
        gridVariant,
        onDurationsCalculated,
    } = props;

    const bigCellLength = bigCellLengthRef * height;
    const rows = Math.ceil(height / bigCellLength);
    const cols = Math.ceil(width * gridWidthRef / bigCellLength);
    const primaryMediaHeight = Math.ceil(height * primaryMediaHeightRef / bigCellLength) * bigCellLength;
    const secondaryMediaSize = bigCellLength * cols;
    const driftDistance = driftDistanceRef * height;

    const hasText = useMemo(() => headVariant === 'text' && text && hasTextToDisplay(text, languages), [text, languages]);
    const [rewrappedText, setRewrappedText] = useState<null | MultiLangText>(hasText ? null : {});
    const [langIndex, setLangIndex] = useState(0);
    const [nextLangIndex, setNextLangIndex] = useState(0);
    const [toShowMedia, setToShowMedia] = useState(!hasText);
    const [toShowSecondaryMedia, setToShowSecondaryMedia] = useState(false);
    const [isPresent, safeToRemove] = usePresence();

    const grid = useMemo(() => {
        if (gridVariant === 'off') {
            return null;
        }

        return (
            <div className={classNames(cssClassNames.grid_container)}>
                <Grid
                    className={cssClassNames.grid}
                    rows={rows}
                    cols={cols}
                    height={rows * bigCellLength}
                    width={cols * bigCellLength}
                    toDrawBoundaries
                    initialAnimation='visible'
                    lineStyle={{
                        strokeWidth: `${100/1920}vh`,
                    }}
                />
            </div>
        );
    }, [bigCellLength, rows, cols]);

    useEffect(() => {
        if (nextLangIndex === langIndex || !hasText) {
            return;
        }

        return setAnimationTimeout(() => {
            setLangIndex(nextLangIndex);
        }, secToMs(pause));
    }, [nextLangIndex]);

    useEffect(() => {
        if (!toShowMedia) {
            return;
        }

        return setAnimationTimeout(() => {
            setToShowSecondaryMedia(true);
        }, secToMs(secondaryMediaDelay));
    }, [toShowMedia]);

    useEffect(() => {
        if (!onDurationsCalculated || !rewrappedText) {
            return;
        }

        const newData = {
            ...props,
            text: rewrappedText
        };
        const duration = calculateDuration(newData);
        const exitDuration = calculateExitDuration(newData);
        onDurationsCalculated({
            duration,
            exitDuration,
        });
    }, [rewrappedText]);

    const topComponent = useMemo(() => {
        switch (headVariant) {
            case 'text':
                return hasText ? (
                    <CodificationWithTranslations
                        text={text}
                        languages={languages}
                        langIndex={langIndex}
                        codificationProps={codificationProps}
                        runFinalTextAnimation
                        languageTransitionDelay={languageTransitionDelay}
                        onTyped={() => {
                            primaryMedia ? setToShowMedia(true) : setToShowSecondaryMedia(true);
                            setNextLangIndex(i => {
                                return (i >= languages.length - 1) ? i : (i + 1);
                            });
                        }}
                        onTextRewrapped={lines => setRewrappedText(lines)}
                    />
                ) : null;

            case 'media':
                return (
                    <MediaRenderer
                        media={headMedia}
                        cover='fit'
                        mediaStyle={{
                            width: 'unset',
                        }}
                    />
                );

            default:
                return null;
        }
    }, [headVariant, text, headMedia, languages, langIndex]);

    useEffect(() => {
        if (hasText || isPresent) {
            return;
        }

        safeToRemove();
    }, [hasText, isPresent]);

    return (
        <div className={cssClassNames.intro_v2}>
            {primaryMedia && toShowMedia ? (
                <div
                    className={cssClassNames.primary_media_container}
                    style={{
                        width: `${width - bigCellLength * (cols - 1) + 1}px`,
                        height: `${primaryMediaHeight}px`,
                        bottom: `${bigCellLength * 3}px`,
                    }}
                >
                    <motion.div
                        style={{
                            height: `calc(100% + ${driftDistance}px)`,
                        }}
                        initial={{
                            transform: 'translateY(100%)'
                        }}
                        animate={{
                            transform: 'translateY(0%)'
                        }}
                        exit={{
                            transform: 'translateY(-100%)'
                        }}
                        transition={{
                            duration: primaryMediaEntryDuration,
                            ease: DEFAULT_EASE
                        }}
                    >
                        <motion.div
                            style={{height: '100%'}}
                            animate={{
                                y: ['0px', `-${driftDistance}px`],
                                transition: {
                                    duration: driftDuration,
                                    ease: 'linear',
                                    repeatType: 'mirror',
                                    repeat: Infinity,
                                }
                            }}
                            exit={{
                                y: `-${driftDistance}px'`
                            }}
                            transition={{
                                duration: primaryMediaEntryDuration,
                                ease: DEFAULT_EASE
                            }}
                        >
                            <MediaRenderer
                                media={primaryMedia}
                            />
                        </motion.div>
                    </motion.div>
                </div>
            ) : null}
            {grid}
            {secondaryMedia && toShowSecondaryMedia ? (
                <motion.div
                    className={cssClassNames.secondary_media_container}
                    style={{
                        height: `${secondaryMediaSize}px`,
                        width: `${secondaryMediaSize}px`,
                        bottom: `${bigCellLength * 5}px`,
                    }}
                    initial={{
                        clipPath: 'inset(0% 0% 100% 0%)'
                    }}
                    animate={{
                        clipPath: 'inset(0% 0% 0% 0%)'
                    }}
                    exit={{
                        clipPath: 'inset(100% 0% 0% 0%)'
                    }}
                    transition={{
                        duration: secondaryMediaEntryDuration,
                        ease: DEFAULT_EASE
                    }}
                >
                    <MediaRenderer
                        media={secondaryMedia}
                    />
                </motion.div>
            ) : null}
            {topComponent ? (
                <div className={classNames(cssClassNames.top_container, {
                    [cssClassNames.text_container]: headVariant === 'text' && hasText,
                    [cssClassNames.top_media_container]: headVariant === 'media' && headMedia,
                })}>
                    <div>
                        <AnimatePresence onExitComplete={isPresent ? undefined : safeToRemove}>
                            {isPresent ? topComponent : null}
                        </AnimatePresence>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export function calculateDuration(data: IntroV2Props) {
    const {
        text,
        languages,
        primaryMedia,
        secondaryMedia,
    } = data;
    const entryDuration = 0;

    const primaryMediaDuration = primaryMedia ? getMediaDuration(primaryMedia) : 0;
    const secondaryMediaDuration = secondaryMedia ? getMediaDuration(secondaryMedia) : 0;
    const mediaDuration = Math.max(primaryMediaDuration, secondaryMediaDuration) || pause;

    const hasText = text && hasTextToDisplay(text, languages);
    const textDuration = hasText ? calculateCodificationDuration({
        text,
        languages,
        codificationProps,
        languageTransitionDelay,
    }): 0;
    const totalTextDuration = textDuration +  pause * (hasText ? languages.length : 1);

    const mainDuration = primaryMedia || secondaryMedia ? mediaDuration : totalTextDuration;

    const total = entryDuration + mainDuration;

    const duration = secToMs(total) + calculateExitDuration(data);

    return duration;
}

export function calculateExitDuration(data: IntroV2Props) {
    const {
        text,
        languages,
        primaryMedia,
        secondaryMedia,
    } = data;

    const hasText = text && hasTextToDisplay(text, languages);
    const textExitDuration = hasText ? calculateExitCodificationDuration({
        text,
        languages,
        codificationProps,
        languageTransitionDelay,
    }) : 0;

    const duration = primaryMedia || secondaryMedia ? primaryMediaEntryDuration : textExitDuration;

    return secToMs(duration);
}
