import { findBestMedia, useTakeover } from '../../../../../services/takeover-service/utils';
import type { UniversalTakeover } from '../../../../../services/takeover-service/universal-takeover';
import type { MediaType } from '../types';
import { useFastLane } from '../store/index';
import { DOUBLE_LANE_WIDTH, LANE_HEIGHT } from '../shared';
import { useFarthestPoint } from '../helpers';
import { loadAsset } from '../../../../jordan/storage';
import { useDisplayType } from '../store/fast-lane-context';
import { useEffect } from 'react';

// @TODO: could use some cleaning up!
export function useUniversalTakeover() {
  const display = useDisplayType();
  const enableTakeover = useFastLane(state => state.enableTakeover);
  const farRight = useFarthestPoint();

  function handleTakeover(universalTakeover: UniversalTakeover, media: MediaType) {
    if (!universalTakeover || display === 'ticker') {
      return;
    }

    const seconds = universalTakeover?.duration ?? 30;
    const width = universalTakeover?.fastlane_takeover_width;

    enableTakeover({
      takeOverDuration: seconds * 1000,
      media: media as MediaType,
      transitionDuration: .5,
      enabled: true,
      entryTransition: 'fade',
      exitTransition: 'fade',
      tiling: width === 'full' ? 'stretch' : 'repeat',
      tilingWidth: width === 'full' ? undefined : width,
      variant: 'full',
    }).then(() => {
    });
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     const takeover = {
  //       "id": "universal_takeover",
  //       "assets": [
  //         {
  //           "url": "https://res.cloudinary.com/jbboerner/video/upload/v1717025630/nikehyperlive/1717025628572.mp4",
  //           "etag": "dabbbe9ebd56ba21e8d6acf3814354fd",
  //           "width": 1920,
  //           "format": "mp4",
  //           "height": 1080,
  //           "duration": 15,
  //           "destination": null,
  //           "resource_type": "video"
  //         },
  //         {
  //           "url": "https://res.cloudinary.com/jbboerner/video/upload/v1717074419/nikehyperlive/1717074416952.mp4",
  //           "etag": "f76bc4a69eb4da0060ff7acedaefee6c",
  //           "width": 1800,
  //           "format": "mp4",
  //           "height": 1632,
  //           "duration": 15,
  //           "destination": "gvc",
  //           "resource_type": "video"
  //         },
  //         {
  //           "url": "https://res.cloudinary.com/jbboerner/video/upload/v1717129104/nikehyperlive/1717129103262.mp4",
  //           "etag": "4f021ebc4d218a294776a0f725ae1522",
  //           "width": 1080,
  //           "format": "mp4",
  //           "height": 240,
  //           "duration": 15,
  //           "destination": null,
  //           "resource_type": "video"
  //         },
  //         {
  //           "url": "https://res.cloudinary.com/jbboerner/video/upload/v1717111049/nikehyperlive/1717111047530.mp4",
  //           "etag": "5745aca8155275f8b0c7a848615dd9bc",
  //           "width": 1080,
  //           "format": "mp4",
  //           "height": 1920,
  //           "duration": 15,
  //           "destination": null,
  //           "resource_type": "video"
  //         },
  //         null
  //       ],
  //       "ticker": {
  //         "content": [
  //           {
  //             "uid": "1717111034908-1j0w3",
  //             "type": "image",
  //             "content": {
  //               "url": "https://res.cloudinary.com/jbboerner/image/upload/v1717111052/nikehyperlive/1717111052161.png",
  //               "etag": "dacf30b58b5f02809dc750b6aadf3ae7",
  //               "width": 1080,
  //               "format": "png",
  //               "height": 64,
  //               "resource_type": "image"
  //             }
  //           }
  //         ]
  //       },
  //       "disabled": false,
  //       "duration": 15,
  //       "template": "universal_takeover",
  //       "primary_color": "#cdff22",
  //       "fastlane_takeover_width": 1080
  //     };
  //
  //     const media = {
  //       "url": "https://res.cloudinary.com/jbboerner/video/upload/w_1080,h_240,q_65/v1717129104/nikehyperlive/1717129103262.mp4",
  //       "etag": "4f021ebc4d218a294776a0f725ae1522",
  //       "width": 1080,
  //       "format": "mp4",
  //       "height": 240,
  //       "duration": 15,
  //       "destination": null,
  //       "resource_type": "video",
  //     };
  //
  //     handleTakeover(takeover, media);
  //   }, 1500);
  // }, []);

  useTakeover<UniversalTakeover, MediaType>({
    initialize: async (universalTakeover) => {
      if (display === 'ticker') {
        return;
      }
      const width = universalTakeover?.fastlane_takeover_width;
      const media = findBestMedia(universalTakeover.assets, {
        tag: 'fastlane',
        width: width === 'full' ? farRight : width ?? DOUBLE_LANE_WIDTH,
        height: LANE_HEIGHT,
      });

      return media ? await loadAsset(media, undefined) : undefined;
    },
    start: (universalTakeover, media) => {
      if (display === 'ticker') {
        return;
      }

      handleTakeover(universalTakeover, media);
    },
    stop: () => {
      // setShowTakeover(false);
    },
  });
}
