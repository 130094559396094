import {JordanConfig} from "./ChapterRunner";
import {useCallback, useEffect, useMemo, useState} from "react";
import {SnkrsCalMode} from "./SnkrsCalHelpers";
import {motion} from "framer-motion";
import {DEFAULT_EASE, getLayoutId} from "../shared";
import Media from "./Media";
import {DropInterface, SnkrsCalProps} from "../screens/SnkrsCal";
import SnkrsCalDetailStoryContent from "./SnkrsCalDetailStoryContent";
import SnkrsCalDetailDropContent from "./SnkrsCalDetailDropContent";

interface SnkrsCalDetailProps {
    drop: DropInterface,
    show?: boolean,
    qr_url?: string,
    mode: SnkrsCalMode,
    index: number,
    hide_dates?: boolean,
    cta_label?: string,
    locale?: string,
    config: JordanConfig,
    available_copy?: string,
    play_mode: SnkrsCalProps['play_mode'],
    concept: SnkrsCalProps['concept'],
}

export function SnkrsCalDetail(props: SnkrsCalDetailProps) {
    const {
        drop,
        show,
        mode,
        index,
        hide_dates = false,
        cta_label,
        locale,
        qr_url,
        config,
        available_copy,
        play_mode,
        concept
    } = props;

    const {snkrs} = config.duration;
    const [showCoverCard, setShowCoverCard] = useState(true);

    const DEFAULT_STORY_COVER_DURATION = 4;

    const storyCoverDuration = useMemo(() => {
        if (mode === 'drops') {
            return;
        }

        if (snkrs.story_page_count === 0) {
            return DEFAULT_STORY_COVER_DURATION + (snkrs.story / 1_000);
        }

        return DEFAULT_STORY_COVER_DURATION;
    }, [mode, drop, snkrs]);

    const height = useMemo(() => {
        if (mode === 'drops') {
            return 1080;
        }
        return showCoverCard ? 1_492 : 1920
    }, [mode, showCoverCard]);
    const [showBackground, setShowBackground] = useState(false);

    const makeMedia = useCallback((drop) => {
        const totalTime = mode === 'drops' ? snkrs.drop : snkrs.story - (DEFAULT_STORY_COVER_DURATION * 1_000);
        const totalCount = mode === 'drops' ? (snkrs.drop_media_count + 1) : snkrs.story_page_count;

        const images = drop.images.map((i) => ({
            ...i,
            force_duration: (totalTime / totalCount) / 1_000,
        }))

        if (mode === 'drops') {
            const [first] = images;
            const i = images.splice(0, totalCount - 1);
            return [...i, {...first, force_duration: 10}];
        }

        const cover = {...drop.cover, force_duration: storyCoverDuration};

        if (snkrs.story_page_count === 0) {
            return [cover];
        }

        return [cover, ...images];
    }, [mode, storyCoverDuration]);

    useEffect(() => {
        let coverId: number = null;
        let bgId: number = null;

        if (show === true) {
            coverId = Number(setTimeout(() => {
                setShowCoverCard(false);
            }, storyCoverDuration * 1_000 + 200));

            if (mode === 'drops') {
                setShowBackground(true);

                setTimeout(() => {
                    setShowBackground(false);
                }, (snkrs.drop + 500))
            }

        } else {
            setTimeout(() => {
                setShowCoverCard(true);
            }, snkrs.story_page_count === 0 ? storyCoverDuration : 2_000);
        }


        return () => clearTimeout(coverId);
    }, [show, storyCoverDuration]);

    const bgColor = concept === 'wof' ? `rgba(246, 246, 246, 1)` : `rgba(255, 255, 255, 1)`;

    return (
        <motion.div
            initial={false}
            animate={{
                background: showBackground || play_mode === 'carousel' ? bgColor : `rgba(246, 246, 246, 0)`,
            }}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
            }}
            transition={{
                ease: DEFAULT_EASE,
                duration: showBackground ? 1 : 2,
            }}
        >
            {
                drop && (
                    <motion.div
                        style={{
                            width: 1080,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        animate={{height}}
                        initial={false}
                        transition={{
                            duration: 1,
                            delay: 0,
                            ease: DEFAULT_EASE,
                        }}
                    >
                        <Media
                            width={'1080px'}
                            height={mode === 'drops' ? '1080px' : '100%'}
                            preventInitial
                            preventInitialSlide
                            layoutId={getLayoutId(drop, index)}
                            transparent
                            useInternalTiming
                            media={makeMedia(drop)}
                        />
                    </motion.div>
                )
            }

            {
                mode === 'stories' && (
                    <SnkrsCalDetailStoryContent
                        drop={drop}
                        show={show}
                        showCoverCard={showCoverCard}
                        cta_label={cta_label}
                        qr_url={qr_url}
                    />
                )
            }

            {
                mode === 'drops' && (
                    <SnkrsCalDetailDropContent
                        show={show}
                        drop={drop}
                        qr_url={qr_url}
                        cta_label={cta_label}
                        hide_dates={hide_dates}
                        locale={locale}
                        available_copy={available_copy}
                        concept={concept}
                    />
                )
            }
        </motion.div>
    );
}