@use '../variables' as vars;

$spacing: vars.to-vh(20, 1920);

.ticker {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-size: vars.to-vh(56, 1920);
    line-height: vars.to-vh(73, 1920);
    background: vars.$red-color;
    color: white;

    .content_container {
        @include vars.hoi-font;

        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        height: 100%;

        .sample {
            white-space: pre;
            height: 100%;
            display: flex;
        }

        .item {
            padding-top: calc($spacing * var(--hoi-font-vertical-correction));
            overflow: hidden;
            height: 100%;
            display: flex;
            align-items: center;
            margin: 0 $spacing;
        }

        .icon {
            width: vars.to-vh(120, 1920);

            svg {
                width: 100%;
                height: 100%;
            }
        }

        .icon,
        .media {
            padding-bottom: $spacing;
        }

        .media .image {
            max-height: 100%;
        }
    }
}
