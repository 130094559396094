import {DisplayType, FastLaneInstance, PixelMap} from "../types";
import {createContext, ReactNode, useContext} from "react";

export interface FastLaneContextState {
    displayType: DisplayType;
    pixelMap: PixelMap;
}

export const FastLaneContext = createContext<FastLaneContextState>({
    displayType: "fast-lane",
    pixelMap: {
        contentHeight: 0,
        contentWidth: 0,
        wrapperHeight: 0,
        wrapperWidth: 0,
        fastLanePixelPitch: 1,
        tickerPixelPitch: 1,
    }
});

interface ContextProps {
    children: ReactNode;
    displayType: DisplayType;
    instance: FastLaneInstance;
}

export function FastLaneContextProvider({children, displayType, instance}: ContextProps) {
    const pixelMap = instance.pixelMap[displayType] as PixelMap;

    return (
        <FastLaneContext.Provider value={{displayType, pixelMap}}>
            {children}
        </FastLaneContext.Provider>
    )
}

export function useDisplayType() {
    const context = useContext(FastLaneContext);

    return context.displayType;
}

export function usePixelMap() {
    const context = useContext(FastLaneContext);

    return context.pixelMap;
}