@use 'sass:math';
@use '../variables' as vars;

$title-font-size: vars.to-vh(166, 1920);
$title-line-height: vars.to-vh(137, 1920);
// this offset compensates the vertical offset due to different font size and line height
$title-vertical-offset: math.max($title-font-size - $title-line-height, 0);

$subtitle-font-size: vars.to-vh(64, 1920);
$subtitle-line-height: vars.to-vh(83, 1920);
$subtitle-vertical-offset: math.max($subtitle-font-size - $subtitle-line-height, 0);


.title_chapter {
    & > * {
        position: absolute;
        top: 0
    }
}

.animation_container {
    width: 100%;
    box-sizing: content-box;
    border-color: black;

    .title_grid {
        position: absolute;
        top: 0;
    }

    .title_container {
        @include vars.hoi-font;

        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: vars.to-vh(47, 1920);
        font-size: $title-font-size;
        line-height: $title-line-height;

        > * {
            max-width: 100%;
            padding-top: calc($title-vertical-offset * var(--hoi-font-vertical-correction));
        }

        .title_text {
            width: 100%;
        }
    }
}

.sub_title {
    @include vars.hoi-font;

    font-size: $subtitle-font-size;
    line-height: $subtitle-line-height;
    color: white;
    width: 100%;

    .sub_title_background {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: #FF2800;
    }

    .sub_title_container {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 56px;

        > * {
            max-width: 100%;
            padding-top: calc($subtitle-vertical-offset * var(--hoi-font-vertical-correction));
        }
    }
}
