import BaseApi from './BaseApi';

export default class TouchpointsApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl, '/public/touchpoints');
  }

  /**
   * @param {object} params
   * @param {string} params.type
   * @param {string} params.slug
   * @param {string} params.label
   * @param {string} params.deviceUUID
   * @return {Promise<object>}
   */
  create(params) {
    return this.request({
      method: 'POST',
      body: JSON.stringify(params),
    });
  }
}
