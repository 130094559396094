import {useState} from 'react';
import Button from './Button';
import Modal from './Modal';
import {usePx} from "./ScreenWrapper";
import QrCodeImage from "./QrCodeImage.png";

export default function BookingFooter() {
    const [show, setShow] = useState(false);
    const px = usePx()

    function onClose() {
        setShow(false)
    }

    return (
        <>
            <Modal show={show} autoClose={10 * 1_000} onClose={onClose}>
                <QrCodeSection onClose={onClose}/>
            </Modal>
            <div
                style={{
                    position: 'absolute',
                    zIndex: 10,
                    width: '100%',
                    bottom: 0,
                    left: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: px(40),
                    fontSize: px(28),
                    alignItems: 'center',
                }}
            >
                <div>Interested in Booking?</div>

                <div>
                    <Button onClick={() => setShow(true)}>
                        Reserve Now
                    </Button>
                </div>
            </div>
        </>
    )
}

function QrCodeSection({onClose}) {
    const px = usePx();

    return (
        <div
            style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div
                    style={{
                        width: '60%',
                        aspectRatio: '1 / 1',
                        borderRadius: '10%',
                        padding: px(24),
                        backgroundColor: 'white',
                    }}
                >
                    <img src={QrCodeImage} alt="qr code" style={{width: '100%', height: '100%', }}/>
                </div>
                <div
                    style={{
                        textAlign: 'center',
                        fontSize: px(28),
                    }}
                >
                    <div style={{marginTop: px(48)}}>
                        Scan to book
                    </div>
                </div>
            </div>


            <div
                style={{
                    position: 'absolute',
                    width: '100%',
                    bottom: 0,
                    padding: px(40),
                    justifyContent: 'center',
                    display: 'flex'
                }}
            >
                <Button onClick={onClose}>
                    Back
                </Button>
            </div>
        </div>
    )
}