import React from 'react';

import Chapter from '../Chapter';


export default class UnknownBuilder extends Chapter {
    component = () => (
        <div>no renderer for this type</div>
    );

    calculateDuration() {
        return 5;
    }
};
