import BaseApi from './BaseApi';

export default class SessionsApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl, '/public/sessions');
  }

  /**
   * @param {object} params
   * @param {number} params.touchpointId
   * @param {string} params.sessionKey
   * @param {object} params.jsonData
   * @param {string} params.userAgent
   * @return {Promise<{
   *     "id": 6,
   *     "touchpointId": 38,
   *     "sessionKey": "sadaaa",
   *     "jsonData": {},
   *     "userAgent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36",
   *     "ip": "::1",
   *     "createdAt": "2024-01-24T23:01:58.094Z",
   *     "updatedAt": "2024-01-24T23:01:58.094Z"
   * }>}
   */
  create(params) {
    return this.request({
      method: 'POST',
      body: JSON.stringify(params),
    });
  }
}
