import {useFastLane} from "../store";
import {usePixelMap} from "../store/fast-lane-context";
import {useFarthestPoint} from "../helpers";
import {useEffect, useMemo, useState} from "react";
import {TakeOverTransition} from "../types";
import {TargetAndTransition} from "framer-motion";
import {DEFAULT_EASE} from "../../../../jordan/shared";

/**
 * Returns the index of the spotlight entry if the spotlight is enabled and the takeover is not a full takeover.
 */
export function useActiveSpotlightIndex() {
    const [showSpotlight, setShowSpotlight] = useState(false);
    const takeover = useFastLane(f => f.takeover);

    useEffect(() => {
        if (takeover?.variant === 'full') {
            return;
        }

        setTimeout(() => {
            setShowSpotlight(true);
        }, takeover.takeOverDuration - 1_000);
    }, []);

    return showSpotlight ? takeover?.spotlightEntryIndex : undefined;
}

/**
 * Returns the transition options for the takeover animation.
 *
 * @param spotlightIndex
 */
export function useTakeoverTransition({spotlightIndex}: { spotlightIndex?: number } = {}) {
    const takeover = useFastLane(f => f.takeover);
    const exitTransition = useExitTransition(spotlightIndex);
    const options = useTransitionOptions(spotlightIndex);

    const exitDuration = useMemo(() => {
        if (takeover?.transitionDuration) {
            return takeover.transitionDuration;
        }

        return spotlightIndex === undefined ? 3 : 2;
    }, [takeover?.transitionDuration, spotlightIndex]);

    return {
        initial: options.fromOptions[takeover?.entryTransition ?? 'from-left'],
        animate: {
            ...options.animate,
            opacity: 1,
            transition: {
                ease: DEFAULT_EASE,
                duration: takeover?.transitionDuration ?? 1,
            }
        },
        exit: {
            ...options.toOptions[exitTransition],
            transition: {
                ease: DEFAULT_EASE,
                duration: exitDuration,
                delay: spotlightIndex === undefined ? 0 : 1,
            }
        }
    }
}

function useTransitionOptions(spotlightIndex?: number) {
    const farRight = useFarthestPoint();
    const containShutPosition = useCurtainShutPosition();
    const spotlightInset = useSpotlightInset(spotlightIndex);

    const animate = {
        clipPath: `inset(0px ${spotlightInset.right}px 0px ${spotlightInset.left}px)`,
    }

    const fromOptions: Record<TakeOverTransition, any> = {
        "from-left": {
            clipPath: `inset(0px 0px 0px ${farRight}px)`,
            opacity: 1,
        },
        "from-right": {
            clipPath: `inset(0px ${farRight}px 0px 0px)`,
            opacity: 1,
        },
        "cinema-curtain": {
            clipPath: `inset(0px ${containShutPosition.right}px 0px ${containShutPosition.left}px)`,
            opacity: 1,
        },
        "stagger-sections-random": {
            clipPath: `inset(0px ${farRight / 2}px 0px ${farRight / 2}px)`,
            opacity: 1,
        },
        fade: {
            clipPath: `inset(0px 0px 0px 0px)`,
            opacity: 0,
        },
    };

    const toOptions: Record<TakeOverTransition, TargetAndTransition> = {
        "from-left": {
            clipPath: `inset(0px ${farRight}px 0px 0px)`,
            opacity: 1,
        },
        "from-right": {
            clipPath: `inset(0px 0px 0px ${farRight}px)`,
            opacity: 1,
        },
        "cinema-curtain": {
            clipPath: `inset(0px ${containShutPosition.right}px 0px ${containShutPosition.left}px)`,
            opacity: 1,
        },
        "stagger-sections-random": {
            clipPath: `inset(0px ${farRight / 2}px 0px ${farRight / 2}px)`,
            opacity: 1,
        },
        fade: {
            clipPath: `inset(0px ${spotlightInset.right}px 0px ${spotlightInset.left}px)`,
            opacity: 0,
        }
    };

    return {fromOptions, toOptions, animate};
}

function useSpotlightInset(spotlightIndex?: number) {
    const takeover = useFastLane(f => f.takeover);
    const farRight = useFarthestPoint();
    const entryIndex = useFastLane(f => f.getTakeoverEntryIndex());
    const entries = useFastLane(f => f.entries);

    return useMemo(() => {
        if (spotlightIndex === undefined) {
            return {
                left: 0,
                right: 0,
            }
        }
        const position = entries?.[entryIndex]?.position;

        const left = position?.left ?? 0;
        const width = position?.width ?? 0;

        return {
            left: position?.left,
            right: farRight - (left + width),
        }
    }, [takeover, spotlightIndex, entryIndex, entries, farRight]);
}

function useCurtainShutPosition() {
    const takeover = useFastLane(f => f.takeover);
    const entries = useFastLane(f => f.entries);
    const entryIndex = useFastLane(f => f.getTakeoverEntryIndex());
    const pixelMap = usePixelMap();
    const farRight = useFarthestPoint();

    return useMemo(() => {
        const position = entries?.[entryIndex]?.position;

        if (position?.left === undefined) {
            const center = pixelMap.contentWidth / 2;

            return {left: center, right: center};
        }

        const left = position.left + (position?.width / 2);
        const right = farRight - (position.width / 2);

        return {left, right};
    }, [takeover, entryIndex, farRight, entries]);
}

function useExitTransition(spotlightIndex?: number) {
    const takeover = useFastLane(f => f.takeover);

    return useMemo(() => {
        const transition = takeover?.exitTransition ?? 'from-right';

        if (takeover?.variant === 'full' || (spotlightIndex === undefined && takeover?.variant === 'background')) {
            return transition;
        }

        const usesCurtains: Array<TakeOverTransition> = ['from-left', 'from-right'];

        if (usesCurtains.includes(transition)) {
            return 'cinema-curtain';
        }

        return transition;
    }, [takeover?.exitTransition, takeover.variant, spotlightIndex]);
}