import * as localForage from "localforage";
import { MediaType } from "./types";
import md5 from 'md5';
import { optimizeMedia } from '../../../../services/takeover-service/utils';

export function createStorage() {
	localForage.config({
		driver: localForage.INDEXEDDB, // Force WebSQL; same as using setDriver()
		name: 'FastLane',
		version: 1.0,
		size: 5 * 1024 * 1024, // Size of database, in bytes. WebSQL-only for now.
		storeName: 'FastLane', // Should be alphanumeric, with underscores.
		description: 'FastLane Asset Storage'
	});

	const instance = localForage.createInstance({
		name: "FastLaneAssets",
	});

	return {instance};
}

export async function storeImageBlob(canvas: HTMLCanvasElement, name: string) {
	return canvas.toBlob(async (blob)=>{
		await localForage.setItem(md5(name), blob);
	})
}

export async function loadImageBlobUrl(name: string) {
	const blob = await localForage.getItem<Blob>(md5(name));
	if(!blob){
		return '';
	}
	return URL.createObjectURL(blob);
}

export async function loadAsset({media, forceHeight, dontCache}: {media: MediaType, forceWebp?: boolean, forceHeight?: number, dontCache?: boolean}) {
	const optimized = optimizeMedia(media, {height: forceHeight});
	const key = md5(optimized.url);

	try {
		const item = await localForage.getItem<Blob>(key);
		console.log('from cache');
		if (!item) {
			throw new Error();
		}
		optimized.blob = item;
	} catch (err) {
		// prevent caching.
		if (dontCache) {
			return media;
		}
		const blob = await fetch(optimized.url)
			.then((response) => response.blob());
		console.log('from network');
		media.blob = await localForage.setItem(key, blob);
	}

	return media;
}