import React, { useEffect, useMemo, useState } from 'react';
import { AnimatePresence, usePresence } from 'framer-motion';

import * as cssClassNames from '../../styles/screens/product/IntroV1.module.scss';

import {
    hasTextToDisplay,
    MultiLangText,
    secToMs,
    setAnimationTimeout,
} from '../../shared';
import {
    BaseChapterProps,
} from '../../components/ChapterRenderer';
import CodificationWithTranslations, {
    calculateDuration as calculateCodificationDuration,
    calculateExitDuration as calculateExitCodificationDuration,
    TranslationCodificationProps,
} from '../../components/Codification/CodificationWithTranslations';
import GridWithCells from '../../components/GridWithCells';


const cellLengthRef = 68 / 1920;
const gridYOffset = -2;

const codificationProps: TranslationCodificationProps = {
    characterSwitchAmount: 4,
    characterNextTrigger: 2,
    timingConfig: {
        control: 'character',
        duration: 0.03,
    },
};
const languageTransitionDelay = 0.25;
const pause = 4;


export type IntroV1Props = BaseChapterProps & {
    text?: MultiLangText,
    gridVariant?: string,
}

export default function IntroV1(props: IntroV1Props) {
    const {
        text,
        languages,
        width,
        height,
        gridVariant,
        onDurationsCalculated,
    } = props;

    const hasText = useMemo(() => text && hasTextToDisplay(text, languages), [text, languages]);
    const [langIndex, setLangIndex] = useState(0);
    const [nextLangIndex, setNextLangIndex] = useState(0);
    const [rewrappedText, setRewrappedText] = useState<null | MultiLangText>(hasText ? null : {});
    const [isPresent, safeToRemove] = usePresence();

    const grid = useMemo(() => {
        if (gridVariant === 'off') {
            return null;
        }

        const cellLength = cellLengthRef * height;
        const gridRows = Math.ceil((height - gridYOffset) / cellLength);
        const gridCols = Math.ceil(width / cellLength) + 1;
        const gridWidth = gridCols * cellLength;
        const gridHeight = gridRows * cellLength;
        const gridXOffset = -(gridWidth - width) / 2;

        return (
            <div
                style={{
                    top: `${gridYOffset}px`,
                    left: `${gridXOffset}px`,
                }}
            >
                <GridWithCells
                    rows={gridRows}
                    cols={gridCols}
                    height={gridHeight}
                    width={gridWidth}
                    cellLength={cellLength}
                />
            </div>
        );
    }, [height, width]);

    useEffect(() => {
        if (nextLangIndex === langIndex) {
            return;
        }

        return setAnimationTimeout(() => {
            setLangIndex(nextLangIndex);
        }, secToMs(pause));
    }, [nextLangIndex]);

    useEffect(() => {
        if (hasText || isPresent) {
            return;
        }

        safeToRemove();
    }, [hasText, isPresent]);

    useEffect(() => {
        if (!onDurationsCalculated || !rewrappedText) {
            return;
        }

        const newData = {
            ...props,
            text: rewrappedText
        };
        const duration = calculateDuration(newData);
        const exitDuration = calculateExitDuration(newData);
        onDurationsCalculated({
            duration,
            exitDuration,
        });
    }, [rewrappedText]);

    const textComponent = useMemo(() => hasText ? (
        <CodificationWithTranslations
            text={text}
            languages={languages}
            langIndex={langIndex}
            codificationProps={codificationProps}
            runFinalTextAnimation
            languageTransitionDelay={languageTransitionDelay}
            onTyped={() => {
                setNextLangIndex(i => {
                    return (i >= languages.length - 1) ? i : (i + 1);
                });
            }}
            onTextRewrapped={lines => setRewrappedText(lines)}
        />
    ) : null, [text, langIndex]);

    return (
        <div className={cssClassNames.intro_v1}>
            {grid}
            {hasText ? (
                <div className={cssClassNames.text_container}>
                    <div>
                        <AnimatePresence onExitComplete={isPresent ? undefined : safeToRemove}>
                            {isPresent ? textComponent : null}
                        </AnimatePresence>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export function calculateDuration(data: IntroV1Props) {
    const {
        text,
        languages,
    } = data;
    const entryDuration = 0;

    const hasText = text && hasTextToDisplay(text, languages);
    const textDuration = hasText ? calculateCodificationDuration({
        text,
        languages,
        codificationProps,
        languageTransitionDelay,
    }) : 0;

    const mainDuration = textDuration + pause * (hasText ? languages.length : 1);

    const total = entryDuration + mainDuration;

    const duration = secToMs(total) + calculateExitDuration(data);

    return duration;
}

export function calculateExitDuration(data: IntroV1Props) {
    const {
        text,
        languages,
    } = data;

    const hasText = text && hasTextToDisplay(text, languages);
    if (!hasText) {
        return 0;
    }

    const textDuration = calculateExitCodificationDuration({
        text,
        languages,
        codificationProps,
        languageTransitionDelay,
    });

    return secToMs(textDuration);
}
