@use '../variables' as vars;

.chapter {
    font-size: vars.to-vh(56, 1920);

    & > * {
        position: absolute;
        top: 0;
        width: 100%;
    }

    .floors {
        @include vars.hoi-font;

        display: grid;

        .active-floor-container {
            position: absolute;
            width: 100%;
            background: black;
            color: white;

            .grid {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}
