import Transition from "../components/Transition";
import Background from "../components/Background";
import {isInsideEditor, MediaType, stringIsFilled} from "../shared";
import GlitchTypography from "../components/GlitchText/GlitchTypography";
import React, {memo, useId, useMemo} from "react";
import Typography from "../components/Typography";
import Media from "../components/Media";
import StoreJumpman from "../components/StoreJumpman";
import {containsChinese} from "../../../utilities";

const jordanIcon = new URL('../assets/images/logo.png', import.meta.url);

const jordanLogo: MediaType = {
    url: jordanIcon.toString(),
    resource_type: 'image',
}

export interface TitleCardProps {
    title: any;
    subhead?: any;
    icon: MediaType;
    theme: 'dark' | 'light';
    campaign_background_media?: Array<MediaType>;
    headline_media?: Array<MediaType>;
    hide_logo?: boolean;
    has_headline_media?: boolean;
    has_background?: boolean;
    index?: number;
}

export default function TitleCard({
                                      title,
                                      subhead,
                                      theme,
                                      campaign_background_media,
                                      headline_media,
                                      hide_logo = false,
                                      icon = jordanLogo,
                                      has_headline_media = false,
                                      has_background = false,
                                      index,
                                  }: TitleCardProps) {
    const id = index;
    const subheadText = useMemo(() => stringIsFilled(subhead) ? subhead : null, [subhead]);
    const isAbnormal = useMemo(() => has_headline_media || has_background, [has_headline_media, has_background]);
    const showRepeated = useMemo(() => !isAbnormal, [isAbnormal]);
    const color = useMemo(() => {
        if (isAbnormal) {
            return 'white';
        }
        return theme === 'dark' ? 'white' : 'black'
    }, [isAbnormal, theme]);

    return (
        <>
            <Transition variant={'wipe-x'} style={{position: 'absolute'}}>
                {
                    has_background ? (
                        <Media
                            preventInitial
                            preventInitialSlide
                            media={campaign_background_media}
                            width={'100%'}
                            height={'100%'}
                            dim
                            theme={'dark'}
                            preventExit
                        />
                    ) : (
                        <Background
                            variant={color === 'white' ? 'black-grit' : 'light-dust'}
                        />
                    )
                }
            </Transition>

            <Transition variant={'wipe-x'} style={{position: 'absolute'}}>
                <Transition variant={'slide-y'}>
                    <div style={{
                        height: showRepeated ? '130%' : '60%',
                        marginTop: showRepeated ? '0%' : '50%',
                        // transform: 'translateY(-15%)',
                        transform: 'translateY(-11%)',
                        display: 'flex',
                        justifyContent: 'space-around',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        {
                            showRepeated && (
                                <>
                                    <HeadlineMemo title={title} uuid={id} color={color}/>
                                    <Jumpman
                                        hide_logo={hide_logo}
                                        color={color}
                                        showRepeated={showRepeated}
                                    />
                                </>
                            )
                        }


                        {
                            has_headline_media ? (
                                <Media
                                    media={headline_media}
                                    width={'60%'}
                                    height={'40%'}
                                    preventInitial
                                    preventInitialSlide
                                    preventExit
                                    transparent
                                />
                            ) : (
                                <HeadlineMemo
                                    title={title}
                                    uuid={id}
                                    color={color}
                                />
                            )
                        }

                        {
                            subheadText && (
                                <div style={{maxWidth: '75%', margin: '-70px auto 80px'}}>
                                    <Typography
                                        variant={'pp-ultralight'}
                                        color={color}
                                        align={'center'}
                                        size={38}
                                        style={{
                                            lineHeight: '120%'
                                        }}
                                    >
                                        {subhead}
                                    </Typography>
                                </div>

                            )
                        }

                        <Jumpman
                            hide_logo={hide_logo}
                            color={color}
                            showRepeated={showRepeated}
                        />

                        {
                            showRepeated && (
                                <HeadlineMemo
                                    title={title}
                                    uuid={id}
                                    color={color}
                                />
                            )
                        }
                    </div>
                </Transition>
            </Transition>
        </>
    )
}

const HeadlineMemo = memo(Headline);

function Headline({title, uuid, color}: { title: string, uuid: number, color?: string }) {
    const hasChinese = useMemo(() => {
        return containsChinese(title);
    }, [title]);

    const showGlitch = useMemo(() => {
        return true;
        // return isInsideEditor();
    }, []);

    return (
        <div style={{
            maxWidth: '80%',
            margin: '0 auto',
            height: '700px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end'
        }}>
            {
                showGlitch ? (
                    <GlitchTypography
                        variant={'jordan-condensed'}
                        idKey={`title_${uuid}`}
                        size={280}
                        align={'center'}
                        scaleX={hasChinese ? .67 : undefined}
                        color={color}
                    >
                        {title}
                    </GlitchTypography>
                ) : (
                    <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                        <Typography
                            variant={'jordan-condensed'}
                            color={color}
                            size={280}
                            align={'center'}
                            scaleX={hasChinese ? .67 : undefined}
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            {title}
                        </Typography>
                    </div>
                )
            }
        </div>
    )
}

function Jumpman({hide_logo, color, showRepeated}: { hide_logo?: boolean, color: string, showRepeated: boolean }) {
    return (
        <div style={{
            width: '300px',
            marginTop: !showRepeated ? '-100px' : '',
            height: hide_logo ? '50px' : undefined,
            opacity: hide_logo ? '0' : '1',
        }}>
            <StoreJumpman theme={color === 'black' ? 'light' : 'dark'} style={{width: '300px'}}/>
        </div>
    )
}