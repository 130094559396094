import {longestWordInString, makeDynamicFontSize, MediaType, ThemeType} from "../shared";
import Transition from "../components/Transition";
import Background from "../components/Background";
import Typography from "../components/Typography";
import Arrow from "../assets/icons/Arrow";
import React, {useEffect, useMemo, useState} from "react";
import InlineArrow from "../assets/icons/InlineArrow";
import {motion} from "framer-motion";
import {NIKE_EASE} from '../../rise/constants/nikerise';
import VerticalTicker, {TickerType} from "../components/VerticalTicker";
import {getColor} from "../styles/color";
import Media from "../components/Media";
import WrapAnimatePresence from "../components/WrapAnimatePresence";
import QrSection, {QrCodeInterface} from "../components/QrSection";
import {getChannelInfo} from '../../rise/hyperlive/utils';
import GlitchTypography from "../components/GlitchText/GlitchTypography";
import {containsChinese} from "../../../utilities";

export interface ServicesProps {
    theme: ThemeType;
    ticker: TickerType;
    sections: Array<ServicesSectionProps>;
    qr_code?: QrCodeInterface;
    custom_ticker?: MediaType;
    duration: number;
}

export interface ServicesSectionProps {
    title: string;
    subtitle?: string;
    entries: Array<ServicesEntryProps>;
}

export interface ServicesEntryProps {
    title: string;
    body: string;
    gutter: ServicesGutterProps;
    media?: Array<MediaType>;
}

interface ServicesGutterProps {
    type: 'text' | 'icon';
    content?: string;
    icon: 'red_arrows' | 'arrows';
}

/**
 * @NOTE: this handles the dynamic font size for the services screen set in the channel config.
 */
const factor = getChannelInfo('json_config.font-sizes.services', 1);
const fz = makeDynamicFontSize(Number(factor));

export default function Services({sections, qr_code, ticker, theme, custom_ticker}: ServicesProps) {
    const color = theme === 'dark' ? 'white' : 'black';
    const hasImages = useMemo(() => {
        return sections?.some((section) => {
            return section?.entries?.some((entry) => {
                return entry?.media?.length >= 1;
            })
        })
    }, [sections]);
    // theme = 'light';

    return (
        <Transition variant={'wipe-x'} cover>
            <Background
                variant={theme === 'dark' ? 'black-print-wash' : 'light-dust'}
            >
                <div style={{maxWidth: '100%', position: 'relative', display: 'flex', flex: 1}}>
                    <div style={{flex: 1, width: `calc(100% - ${ticker ? '100px' : '0px'})`}}>
                        {
                            sections.map((section, index) =>
                                <ServicesSection
                                    hasMedia={hasImages}
                                    index={index}
                                    key={`section_${index}`}
                                    theme={theme}
                                    section={section}
                                    previousTotalEntries={sections?.[index - 1]?.entries?.length ?? 0}
                                    hasMultipleSections={sections.length > 1}
                                    ticker={ticker}
                                />
                            )
                        }
                    </div>
                    {
                        ticker && (
                            <VerticalTicker
                                type={ticker}
                                theme={theme}
                                asset={custom_ticker}
                            />
                        )
                    }

                    <div style={{position: 'absolute', bottom: '40px', left: '40px', color: getColor(color)}}>
                        <QrSection qrCode={qr_code} theme={theme}/>
                    </div>

                </div>

            </Background>
        </Transition>
    )
}

interface ServiceSectionProps {
    section: ServicesSectionProps;
    hasMedia: boolean;
    previousTotalEntries: number;
    theme: ThemeType;
    index: number;
    hasMultipleSections: boolean;
    ticker: TickerType;
}

function ServicesSection({
                             section,
                             hasMedia,
                             theme,
                             index,
                             ticker,
                             previousTotalEntries,
                             hasMultipleSections
                         }: ServiceSectionProps) {
    const color = theme === 'dark' ? 'white' : 'black';
    const [activeEntry, setActiveEntry] = useState(0);
    const titleSize = useMemo(() => {
        if (hasMultipleSections || hasMedia || (ticker && longestWordInString(section.title) > 11)) {
            return 200;
        }
        return 250;
    }, [hasMultipleSections, hasMedia]);

    useEffect(() => {
        let intervalId = null;

        if (hasMedia) {
            intervalId = setInterval(() => {
                setActiveEntry(i => i + 1);
            }, 3_000);
        }

        return () => {
            intervalId && clearInterval(intervalId);
        }

    }, [hasMedia]);

    const shouldShow = (i) => {
        return i === (activeEntry % section.entries.length);
    }

    const shouldGlitch = useMemo(() => {
        return false;
        // return containsChinese(section.title) === false;
    }, [section.title]);

    return (
        <div
            style={{
                width: hasMedia ? '85%' : '100%',
                marginLeft: hasMedia ? '15%' : undefined,
                padding: '30px',
                boxSizing: 'border-box',
                marginTop: index !== 0 ? 180 : 0
            }}
        >
            <div style={{boxSizing: 'border-box'}}>
                {
                    shouldGlitch ? (
                        <GlitchTypography
                            variant={'jordan-condensed'}
                            size={titleSize}
                            color={color}
                            idKey={ticker}
                        >
                            {section.title}
                        </GlitchTypography>
                    ) : (
                        <Typography
                            variant={'jordan-condensed'}
                            size={titleSize}
                            color={color}
                            uppercase
                        >
                            {section.title}
                        </Typography>
                    )
                }


                {/*<Typography*/}
                {/*    variant={'jordan-condensed'}*/}
                {/*    size={titleSize}*/}
                {/*    color={color}*/}
                {/*>*/}
                {/*    {section.title}*/}
                {/*</Typography>*/}
                <div style={{marginTop: 30, minHeight: 150, marginBottom: 30, maxWidth: 800}}>
                    <Typography variant={'pp-ultralight'} size={fz(38)} color={color}>
                        {section?.subtitle}
                    </Typography>

                </div>

                <Divider theme={theme}/>

                {
                    section.entries?.map((entry, j) => (
                        <React.Fragment key={`sections_${index}_${j}`}>
                            <motion.div
                                initial={{
                                    opacity: 0,
                                    y: 30,
                                }}
                                animate={{
                                    opacity: 1,
                                    y: 0,
                                }}

                                transition={{
                                    duration: 1,
                                    delay: 1.5 + ((j + previousTotalEntries) * .3),
                                    ease: NIKE_EASE,
                                }}

                                style={{
                                    display: 'flex',
                                    margin: '16px',
                                    position: 'relative',
                                }}
                            >
                                <WrapAnimatePresence>
                                    {
                                        entry?.media && entry?.media?.length > 0 && shouldShow(j) && (
                                            <div
                                                style={{
                                                    zIndex: 0,
                                                    position: 'absolute',
                                                    top: '-50%',
                                                    left: '-195px',
                                                    height: '200%',
                                                    width: '350px'
                                                }}
                                            >
                                                <Media
                                                    mediaContainerStyle={{
                                                        filter: 'contrast(.9) brightness(0.8)',
                                                    }}
                                                    mediaStyle={{
                                                        objectPosition: 'top center',
                                                    }}
                                                    transitionDuration={1}
                                                    delay={1}
                                                    media={entry.media}
                                                    width={'100%'}
                                                    height={'100%'}
                                                    theme={theme}
                                                />
                                            </div>
                                        )
                                    }
                                </WrapAnimatePresence>
                                <Gutter gutter={entry?.gutter} theme={theme}/>
                                <div style={{paddingBottom: 50}}>
                                    <Typography
                                        variant={'jordan'}
                                        size={fz(45)}
                                        color={color}
                                        style={{
                                            marginBottom: 12
                                        }}
                                    >
                                        {entry.title}
                                    </Typography>
                                    <EntryBody index={j} entry={entry} theme={theme}/>
                                </div>
                            </motion.div>

                            {
                                section.entries.length > (j + 1) && (
                                    <Divider theme={theme}/>
                                )
                            }

                        </React.Fragment>
                    ))
                }
            </div>
        </div>
    )
}

function Gutter({gutter, theme}: { gutter?: ServicesGutterProps, theme: ThemeType }) {
    const getColor = (icon) => {
        return icon === 'red_arrows' ? '#FF0000' : (theme === 'dark' ? 'white' : 'black');
    }

    const content = {
        text: (
            <div>
                <Typography variant={'mono'} size={fz(20)} color={theme === 'dark' ? 'white' : 'black'}>
                    {gutter?.content}
                </Typography>
            </div>
        ),
        icon: (
            <div>
                {
                    (gutter?.icon == 'arrows' || gutter?.icon === 'red_arrows') && (
                        <div style={{
                            display: 'flex',
                            gap: '10px',
                            color: getColor(gutter.icon),
                            transform: 'scale(75%)',
                            transformOrigin: 'top left'
                        }}>
                            <Arrow/>
                            <Arrow/>
                        </div>
                    )
                }
            </div>
        )
    }[gutter?.type ?? 'icon'];

    return (
        <div
            style={{
                width: 200,
                height: 100,
                marginRight: 20,
                position: 'relative',
                zIndex: 1,
            }}
        >
            {content}
        </div>
    )
}

function Divider({theme}: { theme: ThemeType }) {
    return (
        <div
            style={{
                height: 2,
                zIndex: 1,
                position: 'relative',
                width: '100%',
                background: theme === 'light' ? '#000' : '#fff',
            }}
        />
    )
}

function EntryBody({index, entry, theme}: { entry: ServicesEntryProps, theme: ThemeType, index: number }) {
    const ARROW = '->';

    const color = theme === 'dark' ? 'white' : 'black';
    const list = useMemo(() => {
        let entries = [];
        entry?.body?.split('\n').forEach((line) => {
            entries.push({
                type: line.trim().indexOf(ARROW) === 0 ? 'list' : 'text',
                content: line.replace(ARROW, ''),
            });
        })
        return entries;
    }, [entry]);

    return (
        <div>
            {list.map(({content, type}, i) => (
                <React.Fragment key={`${index}_entry_${i}`}>
                    <div style={{
                        display: 'flex',
                        minHeight: 20,
                        marginTop: type === 'list' ? 4 : 0,
                        alignItems: 'center'
                    }}>
                        {type === 'list' && <span style={{color: color, marginRight: 10}}><InlineArrow/></span>}
                        <div style={{maxWidth: 600}}>
                            <Typography style={{lineHeight: '120%'}} variant={'pp-ultralight'} size={fz(25)} color={color}>{content}</Typography>
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </div>
    )
}