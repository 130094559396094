import {CenterCourtGradient, ChapterTemplateType, ChapterType} from "../shared";
import {JordanConfig} from "./ChapterRunner";
import GradientPlayer from "./GradientPlayer";
import React from "react";

interface ChapterRendererProps {
    chapter: ChapterType;
    renderOptions: ChapterRenderOptions;
    index: number;
    config: JordanConfig;
    gradient?: CenterCourtGradient;
}


export type ChapterRenderOptions = Partial<Record<string, any>>;

export default function ChapterRenderer({chapter, index, renderOptions, config, ...other}: ChapterRendererProps) {
    const {template, data, duration, ...otherStuff} = chapter;

    const Render: React.ElementType = renderOptions[chapter.template] ?? null;

    if (!Render) {
        return (
            <div>no chapter for this type</div>
        )
    }

    if (config.meta?.gradient && data?.gradient?.type !== 'disabled') {
        return (
            <GradientPlayer
                key={'gradient_player'}
                gradient={data?.gradient}
            />
        )
    }

    // @ts-ignore
    return (
        <Render
            index={index}
            // theme={index % 2 === 0 ? 'dark' : 'light'}
            theme={'dark'}
            duration={duration}
            config={config}
            {...other}
            {...data}
        />
    )
}