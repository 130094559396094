import { useFastLane } from "../store";
import { ReactNode } from "react";
import LaneDebugger from "./lane-debugger";
import { PixelMap as PixelMapType } from "../types";
import {usePixelMap} from "../store/fast-lane-context";

interface PixelMapProps {
	children: ({laneIndex, totalLanes}: {laneIndex: number, totalLanes: number}) => ReactNode;
}

function useLanes(pixelMap: PixelMapType) {
	if (!pixelMap?.contentWidth || !pixelMap?.wrapperWidth) {
		return [];
	}

	const laneCount = Math.ceil(pixelMap.contentWidth / pixelMap.wrapperWidth);

	return [...Array(laneCount).keys()].map(i => {
		const wrapperVisibleWidth = Math.min(pixelMap.contentWidth - (pixelMap.wrapperWidth * i), pixelMap.wrapperWidth);

		return {
			width: wrapperVisibleWidth,
		};
	});
}

export default function PixelMap({ children }: PixelMapProps) {
	const pixelMap = usePixelMap();

	const lanes = useLanes(pixelMap);

	return (
		<div
			style={{
				width: pixelMap.wrapperWidth,
				height: pixelMap.wrapperHeight,
				position: "relative",
				overflow: "hidden",
			}}
		>
			{
				lanes.map((lane, index) => (
					<div
						key={index}
						style={{
							position: "relative",
							width: "100%",
							height: pixelMap.contentHeight,
							overflow: "hidden",
						}}
					>
						<LaneDebugger width={lane.width} height={pixelMap.contentHeight} index={index} />

						<div
							style={{
								position: "relative",
								width: pixelMap.contentWidth,
								height: pixelMap.contentHeight,
								left: -index * pixelMap.wrapperWidth,
							}}
						>
							{children({laneIndex: index, totalLanes: lanes.length})}
						</div>
					</div>
				))
			}
		</div>
	);
}