import { useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import classNames from 'classnames';

import * as cssClassNames from './NbyAttract.module.scss';

import useIsPortrait from '../../../../../../utils/useIsPortrait';
import {
    setAnimationTimeout,
} from '../../shared';
import { MediaType } from '../../../../../house-of-innovation/shared';
import MediaRenderer from '../../../../../house-of-innovation/components/MediaRenderer';


const DEFAULT_DURATION = 13;

type NbyMediaType = MediaType & {
    isPatch?: boolean;
};

type Item = {
    backgroundMedia?: MediaType,
    media?: NbyMediaType[],
    animationVariant?: string,
    duration?: number,
};

type NbyAttractProps = {
    list?: Item[],
};

export default function NbyAttract(props: NbyAttractProps) {
    const {
        list: rawList = [],
    } = props;

    const isPortrait = useIsPortrait();
    const list = useMemo(() => rawList.filter(item => item), [rawList]);
    const cache = useRef([]);
    const [itemIndex, setItemIndex] = useState(0);
    const itemComponent = useMemo(() => {
        let cachedItem = cache.current[itemIndex];
        if (cachedItem) {
            return cachedItem;
        }

        const item = list[itemIndex];
        cachedItem = item ? (
            <Layout
                key={itemIndex}
                animationVariant={`${itemIndex}`}
                {...item}
                isPortrait={isPortrait}
            />
        ) : null;

        cache.current.push[cachedItem];

        return cachedItem;
    }, [itemIndex]);

    useEffect(() => {
        const item = list[itemIndex];
        const duration = Math.max(item?.duration || 0, DEFAULT_DURATION);
        return setAnimationTimeout(() => setItemIndex(i => ++i), duration * 1000);
    }, [itemIndex]);

    return (
        <div
            className={cssClassNames.nby_attract}
            data-orientation={isPortrait ? 'portrait' : 'landscape'}
        >
            <motion.div
                className={cssClassNames.background_medias}
                style={{
                    width: `${list.length * 99.99}vw`,
                }}
                initial={{
                    x: '0vw',
                }}
                animate={{
                    x: `${-itemIndex * 99.99}vw`
                }}
                transition={{
                    ease: 'linear',
                    duration: 1,
                }}
            >
                {list.map((item, index) => (
                    item.backgroundMedia ? (
                        <MediaRenderer
                            key={index}
                            media={item.backgroundMedia}
                            loopVideo={false}
                            videoPlayerMode={itemIndex === index ? 'play' : 'pause'}
                        />
                    ): null)
                )}
            </motion.div>
            <AnimatePresence>
                {itemComponent}
            </AnimatePresence>
        </div>
    );
}

type LayoutProps = Item & {
    isPortrait?: boolean,
}

function Layout(props: LayoutProps) {
    const {
        media: allMedia,
        animationVariant,
        isPortrait,
    } = props;

    const media = allMedia.slice(0, 5);
    const variants = animationVariants[animationVariant] || animationVariants.variant_1;

    return (
        <motion.div
            className={classNames(cssClassNames.overlay, cssClassNames[`animation-${animationVariant}`]) }
            initial={{
                x: '0vw',
            }}
            exit={{
                x: '-100vw',
                transition: {
                    ease: [0.17, 0.11, 0.43, 1],
                    duration: 1,
                },
            }}
        >
            {media.map((item, index) => (
                <motion.div
                    key={index}
                    initial='initial'
                    animate={['step1', 'step2']}
                    variants={variants[index]}
                    custom={{
                        isPortrait,
                    }}
                >
                    {item ? (
                        <MediaRenderer
                            className={classNames({
                                [cssClassNames.patch]: item.isPatch
                            })}
                            media={item}
                            cover={item.isPatch ? 'fit' : undefined}
                        />
                    ) : null}
                </motion.div>
            ))}
        </motion.div>
    );
}

const animationVariants = {
    variant_1: {
        0: {
            initial: {
                x: '120vw',
                y: '0%',
            },
            step1: {
                x: '0vw',
                transition: {
                    ease: [0.05, 0, 0.6, 1],
                    duration: 1.67,
                    delay: 2.3,
                }
            },
            step2: {
                y: '-140%',
                transition: {
                    ease: [0.4, 0, 0.75, 0.2],
                    duration: 0.4,
                    delay: 4.14,
                }
            },
        },
        1: {
            initial: {
                x: '60vw',
                y: '0%',
            },
            step1: {
                x: '0vw',
                transition: {
                    ease: [0.1, 0.1, 0.6, 0.95],
                    duration: 1.67,
                    delay: 2.3,
                },
            },
            step2: ({isPortrait}) => ({
                y: isPortrait ? '-245%' : '-87%',
                transition: {
                    ease: [0.75, -0.2, 0.12, 1],
                    duration: 5/3,
                    delay: 4.14,
                },
            }),
        },
        2: {
            initial: {
                y: '0vh',
            },
            step1: ({isPortrait}) => ({
                y: isPortrait ? '-130vh' : '-150vh',
                transition: {
                    ease: [0.4, 0.15, 0, 0.9],
                    duration: 1.4,
                    delay: 4.43,
                },
            }),
        },
        3: {
            initial: {
                x: '-100%',
                y: '80%',
            },
            step1: {
                x: '0%',
                y: '0%',
                transition: {
                    ease: [0.13, 0, 0, 0.97],
                    duration: 0.8,
                    delay: 4.9,
                },
            },
        },
        4: {
            initial: ({isPortrait}) => ({
                y: isPortrait ? '55vh' : '72vh',
            }),
            step1: {
                y: '0vh',
                transition: {
                    ease: [0.25, 0.85, 0.6, 0.98],
                    duration: 0.9,
                    delay: 4.77,
                },
            },
        },
    },
    variant_2: {
        0: {
            initial: {
                x: '100vw',
                y: '0vh',
            },
            step1: {
                x: '0vw',
                transition: {
                    ease: [0.05, 0, 0.6, 1],
                    duration: 1,
                    delay: 0.5,
                },
            },
            step2: {
                y: '-100vh',
                transition: {
                    ease: [0.9, 0, 0.73, 0.6],
                    duration: 1,
                    delay: 1.5,
                },
            },
        },
        1: {
            initial: {
                x: '100vw',
                y: '0vh',
            },
            step1: {
                x: '0vw',
                transition: {
                    ease: [0.05, 0, 0.6, 1],
                    duration: 1.1,
                    delay: 0.45,
                }
            },
            step2: {
                y: '-100vh',
                transition: {
                    ease: [0.9, 0, 0.73, 0.6],
                    duration: 1,
                    delay: 1.5,
                },
            },
        },
        2: {
            initial: {
                y: '0vh',
            },
            step1: ({isPortrait}) => ({
                y: isPortrait ? '-130vh' : '-150vh',
                transition: {
                    ease: [0.4, 0.15, 0.2, 0.9],
                    duration: 1.2,
                    delay: 2.2,
                },
            }),
        },
        3: {
            initial: {
                y: '100vh',
            },
            step1: {
                y: '0vh',
                transition: {
                    ease: [0.2, 0.25, 0.06, 0.98],
                    duration: 1,
                    delay: 2.3,
                },
            },
        },
        4: {
            initial: {
                y: '100vh',
            },
            step1: {
                y: '0vh',
                transition: {
                    ease: [0.15, 0.15, 0.05, 0.95],
                    duration: 1,
                    delay: 2.75,
                },
            },
        },
    },
    variant_3: {
        0: {
            initial: ({isPortrait}) => ({
                x: isPortrait ? '110%' : '150%',
                y: '0vh',
            }),
            step1: {
                x: '0%',
                transition: {
                    ease: [0.11, 0.1, 0.11, 0.94],
                    duration: 0.7,
                    delay: 0.92,
                },
            },
            step2: {
                y: '-100vh',
                transition: {
                    ease: [0.8, 0, 0.91, 0.3],
                    duration: 0.4,
                    delay: 2.23,
                },
            },
        },
        1: {
            initial: {
                x: '-110%',
                y: '0vh',
            },
            step1: {
                x: '0%',
                transition: {
                    ease: [0.12, 0, 0.1, 1],
                    duration: 2/3,
                    delay: 1.25,
                },
            },
            step2: {
                y: '-100vh',
                transition: {
                    ease: [1, 0, 0.33, 0.83],
                    duration: 0.65,
                    delay: 2.4,
                },
            },
        },
        2: {
            initial: {
                y: '0vh',
            },
            step1: ({isPortrait}) => ({
                y: isPortrait ? '-130vh' : '-150vh',
                transition: {
                    ease: [0.2, 0.15, 0.2, 0.9],
                    duration: 1.2,
                    delay: 2.7,
                },
            }),
        },
        3: {
            initial: {
                y: '100vh',
            },
            step1: {
                y: '0vh',
                transition: {
                    ease: [0.23, 0.35, 0.07, 0.96],
                    duration: 1.3,
                    delay: 2.8,
                },
            },
        },
        4: {
            initial: {
                y: '100vh',
            },
            step1: {
                y: '0vh',
                transition: {
                    ease: [0.18, 0.35, 0.1, 1],
                    duration: 1,
                    delay: 3.1,
                },
            },
        },
    },
};


export function calculateDuration(props: NbyAttractProps) {
    const duration = props.list.reduce((result, item) => (
        result + (item ? Math.max(item.duration || 0, DEFAULT_DURATION) : 0)
    ), 0);

    return (duration + calculateExitDuration()) * 1000;
}

export function calculateExitDuration() {
    return 0;
}
