import { useFastLane } from "../store";
import { CSSProperties, useCallback, useRef } from 'react';
import {ABSOLUTE_FILL, useFarthestPoint} from "../helpers";
import { useInView } from "framer-motion";
import MediaPlayer from "./media-player";
import { MediaType } from "../types";
import Scrim from "./scrim";
import { useTakeoverTiling } from './takeover-media.hooks';

export default function TakeoverMedia() {
	const takeover = useFastLane(f => f.takeover);
	const widths = useFastLane(f => f.takeoverSectionWidths);

	const getWidth = useCallback((index: number) => {
		return widths?.find(w => w.index === index)?.width ?? takeover.tilingWidth;
	}, [widths]);

	const farRight = useFarthestPoint();
	const tiling = useTakeoverTiling();

	if (takeover.tiling === "repeat") {
		// work back from the takeover entry index to find the first entry that has media
		return (
			<div
				style={{
					...ABSOLUTE_FILL,
					width: farRight,
					display: "flex",
				}}
			>
				{[...new Array(tiling.repeatCount)].map((entry, index) => (
					<MediaPlayerContainer
						key={index}
						media={takeover.media}
						style={{ width: getWidth(index) ?? tiling?.width, flexShrink: 0, }}
					/>
				))}

				{
					takeover?.scrimOpacity !== undefined && (
						<div
							style={{
								...ABSOLUTE_FILL,
								backgroundColor: takeover?.transitionBackgroundColor ?? "#000",
								opacity: takeover?.scrimOpacity,
							}}
						/>
					)
				}
			</div>
		);
	}

	return (
		<MediaPlayerContainer media={takeover.media} style={{ width: farRight }} />
	);
}

function MediaPlayerContainer({ style, media }: { style: CSSProperties, media: MediaType | null }) {
	const containerRef = useRef<HTMLDivElement>(null);
	const inView = useInView(containerRef, {
		amount: 'some',
	});

	return (
		<div
			ref={containerRef}
			style={{
				height: "100%",
				...style,
			}}
		>
			{
				inView && media && (
					<MediaPlayer media={media} />
				)
			}
		</div>
	);
}