import { createContext } from "react";

export default createContext({
	characterSwitchAmount: 12,
	characterNextTrigger: 6,
	characterTimeout: 30,
	typography: {},
	reverse: false,
	start: true,
	delay: 0,
	active: 0,
	text: "",
	isStatic: false,
    toNotBreakWord: false,
});
