import { useRpx } from '../shared';
import { type CSSProperties, ReactNode } from 'react';

interface MainViewProps {
  left: ReactNode;
  right: ReactNode;
  style?: CSSProperties;
}

export default function LandscapeView({ left, right, style }: MainViewProps) {
  const rpx = useRpx();

  return (
    <div
      style={{
        ...style,
        width: rpx(1920),
        height: rpx(1080),
        display: 'flex',
        position: 'relative',
      }}
    >
      <div
        style={{
          width: '50%',
          borderRight: `1px solid #222`,
        }}
      >
        {left}
      </div>

      <div
        style={{
          width: '50%',
          borderRight: `1px solid #222`,
        }}
      >
        {right}
      </div>
    </div>
  );
}