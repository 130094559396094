import React, { useEffect, useMemo, useState } from 'react';
import PodText from './components/PodText/PodText';
import PodLogo from './components/PodLogo/PodLogo';
import PodDiD from './components/PodDiD/PodDiD';
import PodIntroLogo from './components/PodIntroLogo/PodIntroLogo';
import * as Hyperlive from '../../hyperlive/utils.js';
import _ from 'lodash';
import './styles/Pods.scss';

const getUsedLanguages = (data) => {
  const contentsKeys = ['title', 'static_pulse_story'];

  return Hyperlive.getStoreLanguages().filter(language =>
    contentsKeys.some(key => {
      const keyData = data[key];

      return Object.keys(keyData).includes(language) && keyData[language].trim() !== ''
    }),
  );
};


const Pod = ({ grids, json_data, forceLang }) => {
  let {
    title,
    static_pulse_story,
    assets = {},
  } = json_data;
  const providedLogo = useMemo(() => {
    const { logo: providedLogos = [] } = assets;
    return providedLogos[0];
  }, [assets]) ;

  const languages = getUsedLanguages(json_data);

  const [lang, setLang] = useState(languages[0]);
  const [logo, setLogo] = useState(providedLogo);
  const [frame, setFrame] = useState({});

  useEffect(() => {
    if (!forceLang) {
      return;
    }
    setLang(forceLang);
  }, [forceLang]);

  useEffect(() => {
    grids.setCallback(0, (props) => {
      const { name, frameIndex, loop, language: languageIndex } = props;

      if (!forceLang) {
        setLang(languages[languageIndex])
      }
      setFrame({ index: frameIndex, name })
    });

    return () => {
      grids.removeCallback(0);
    };
  }, [])

  useEffect(() => {
    let logo_assets = Hyperlive.fetchAssets('logo');

    // console.log('logo_asset', logo_assets)

    if (logo_assets) {
      setLogo(logo_assets[0])
    }

  }, []);

  // console.log(frame.index,frame.name)

  return (
    <div className={'Pod'}>
      {logo && (frame.index <= 4) &&  <PodIntroLogo grids={grids} frame={frame} logo_asset={logo} />}
      {/* <PodLogo text={'Nike Test'} grids={grids} frame={frame} logo_asset={logo_assets[0]}/> */}
      <PodText text={title[lang]} grids={grids} frame={frame} />
      {static_pulse_story && <PodDiD text={_.get(static_pulse_story, lang)} grids={grids} frame={frame} />}
    </div>
  );
};

export default Pod;
