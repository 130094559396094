export const ANIMATION_TYPES = {
    LOOP: 0,
    PEDESTAL: 1,
    SLIDESHOW: 2,
    SPEEDSHOP: 3,
    VIDEO: 4,
    VISIBLE_HEAT: 5,
    TICKER: 6,
    EXTERNAL: 7,
    CALENDAR: 8,

    FAST_LANE: 9,
    POD: 10,
    SPORT_HUB: 11,
    FASTLANE_TICKER: 12,
    CITY_REPLAY: 13,
    MTZ: 14,
    HUDDLE_FACETS: 15,
    RISE_TICKERS: 20,
    RAW_VIDEO: 21,
    COVERSTORY: 22,
    LL_TICKER: 23,
    MANNEQUIN: 24,
    CATALOG_APPAREL: 25,
    LIFE_365: 26,
    ATRIUM: 27,
    WIRE_FRAMES: 28,
    PROGRAM_MESSAGING: 29,
    CATALOG_APPAREL_WIDE: 30,
    CHAPTER_SLIDESHOW: 31,
    FAST_LANE_KIDS_TAKEOVER: 32,
    RISE_STORY_TELLING: 33,
    COMMUNITY_BOARD: 34,
    LIFE_SNKRS_CAL: 35,
    JORDAN_CORE: 39,
    LIVE_FITNESS_TICKERS: 40,
    WOF_CORE: 41,
    WOF_CENTER_COURT: 42,
    NSIL_SCHEDULE: 43,
    CORE_TICKER: 44,
    RISE_NBY: 45,
    MEDIA_WALL: 46,
    NTC: 47,
    ECOSYSTEM_BOARD: 48,
    WAYFINDING: 49,
    MEMBERSHIP: 50,
    NBY: 51,
    FAST_LANE_2: 52,
    CHAPTER_PLAYER: 53,
};
