import React from 'react';

import { MultilangText } from '../../types';
import { AssetType } from '../../../../utils/storage';
import Chapter from '../../Chapter';
import { DirectoryHub } from '../../../rise/animations/hub/views/DirectoryHub';
import { TRANSITION_WAIT } from '../../../rise/constants/hub';


type Segment = {
    tag: string,
    description: MultilangText,
    title: MultilangText,
    data: Date,
    cover: AssetType,
    icon: AssetType,
}

type SportHubProps = {
    header: MultilangText,
    sub_header: MultilangText,
    header_asset: AssetType,
    languages: string[],
    segments: Segment[],
};

export default class SportHubDirectory extends Chapter<SportHubProps> {
    component = () => (
        <DirectoryHub
            data={{
                json_data: this.data,
            }}
        />
    )

    canLoop = true;

    calculateDuration() {
        return this.calculateLanguageDuration() * this.data.languages.length;
    }

    calculateLanguageDuration() {
        return 1/1.5 + (TRANSITION_WAIT/1000 + 0.5) * (this.data.segments.length + 1) + 1.5 + 0.5;
    }
}
