import { useNbyStore } from '../store';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { MediaType } from '../../../../jordan/shared';
import { DEFAULT_BORDER, fairlyUnique, setAnimationTimeout, SOFTENED_BORDER, useRpx } from '../shared';
import { DEFAULT_TRANSITION } from '../../../../rise/constants/transition';
import Media from '../../../../jordan/components/Media';
import { VERTICAL_X_PORTRAIT_LINE } from '../screens/steps';

export default function StepsBottom({ theme }: { theme?: string }) {
  const [exit, setExit] = useState(false);
  const duration = useNbyStore(state => state.stepDuration);
  const activeIndex = useNbyStore(state => state.currentIndex);
  const steps = useNbyStore(state => state.animationData.steps);
  const step = useNbyStore(state => state.getCurrentStep());

  const columnCount = useMemo(() => {
    return activeIndex === steps.length - 1 ? 1 : 3;
  }, [steps, activeIndex]);

  const columns = useMemo(() => {
    const media = step?.media || [];

    if (columnCount === 1) {
      return [media];
    }

    return [media.slice(0, 3), media.slice(3, 6)];
  }, [columnCount, activeIndex]);

  useEffect(() => {
    setExit(false);

    return setAnimationTimeout(() => {
      setExit(true);
    }, duration - 1000);
  }, [activeIndex]);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <AnimatePresence mode={'wait'}>
        {columns.map((mediaList, index) => exit ? <Fragment key={index} /> : (
          <ImageScroller
            theme={theme}
            columnCount={columnCount}
            direction={index % 2 === 0 ? 'down' : 'up'}
            rowCount={columnCount === 1 ? 1 : 2}
            media={mediaList}
            index={index}
            key={`image-scroller-${index}-column-${activeIndex}}`}
          />
        ))}
      </AnimatePresence>
    </div>
  );
}

interface ImageScrollerProps {
  media: Array<MediaType>;
  direction: 'up' | 'down';
  columnCount: number;
  index: number;
  rowCount: number;
  theme?: string;
}

function ImageScroller({ media, direction, columnCount, rowCount, index, theme }: ImageScrollerProps) {
  const id = useMemo(() => {
    return fairlyUnique();
  }, []);
  const rpx = useRpx();
  const ref = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState(0);

  useEffect(() => {
    if (!ref.current) return;

    setSize(ref.current.getBoundingClientRect().width);
  }, [ref.current, columnCount, index]);

  const y = useMemo(() => {
    return 0;
    // if (columnCount === 1) {
    //     // return -299;
    //     return 180;
    // }
    //
    // return 180 + -480
  }, [size, columnCount]);

  const dir = direction === 'up' ? -1 : 1;

  return (
    <motion.div
      key={id}
      ref={ref}
      initial={{
        x: rpx(1080 * dir),
        opacity: 0,
      }}
      animate={{
        x: 0,
        opacity: 1,
      }}
      style={{
        height: rowCount === 1 ? '100%' : '50%',
        width: rowCount === 1 ? 3 * 864 : 3 * VERTICAL_X_PORTRAIT_LINE,
        borderRight: index === 0 ? SOFTENED_BORDER : 'none',
        boxSizing: 'content-box',
        position: 'relative',
      }}
      exit={{
        x: rpx(-2000 * dir),
        opacity: 0,
      }}
      transition={{
        ...DEFAULT_TRANSITION,
        duration: 1.5,
      }}
    >
      <div
        style={{
          borderBottom: DEFAULT_BORDER,
          width: '300vw',
          position: 'absolute',
          bottom: 0,
          left: '-100vw',
        }}
      />

      <motion.div
        animate={{
          opacity: 1,
          x: [rpx(y + 200 * dir), rpx(y - 200 * dir)],
          transition: {
            ease: 'linear',
            duration: 10,
          },
        }}
        style={{
          display: 'grid',
          flex: 1,
          gridTemplateColumns: `repeat(${media.length}, 1fr)`,
          height: '100%',
          width: '100%',
        }}
      >
        {media.map((item, index) => (
          <Media
            key={`mediascroller-${index}`}
            media={[item]}
            width={'100%'}
            height={'100%'}
            cover={'fit'}
            preventExit
            preventInitial
            preventTransitions
            preventInitialSlide
            transparent={theme === 'one'}
            style={{
              borderRight: SOFTENED_BORDER,
              borderLeft: index === 0 ? SOFTENED_BORDER : 'none',
              flexShrink: 0,
            }}
          />
        ))}
      </motion.div>
    </motion.div>
  );
}