import { rpx } from "../helpers";
import { useFastLane } from "../store";
import { motion } from "framer-motion";
import { DEFAULT_EASE } from "../../../../jordan/shared";

interface SectionGridProps {
	index: number;
}

const points = [...new Array(4)].map((_, i) => {
	return [...new Array(6)].map((_, j) => j);
});

export default function SectionGridHoi({ index }: SectionGridProps) {
	const theme = useFastLane(f => f.getTheme());
	const isMediaPlaying = useFastLane(f => f.isMediaPlaying(index));

	const strokeWidth = useFastLane(f => f.getGridLineThickness("horizontal"));

	const opacity = (theme.hideGridsWhenPlayingMedia && isMediaPlaying) ? 0 : 1;
	const sectionDelay = index * .1;

	return (
		<motion.div
			style={{
				width: "100%",
				height: "100%",
				position: "absolute",
				left: theme?.gridOffset?.vertical ?? 0,
				top: 0,
				zIndex: 3,
			}}
			animate={{
				opacity,
			}}
			transition={{
				duration: .5,
				delay: .5,
				ease: DEFAULT_EASE,
			}}
		>
			<div
				style={{
					padding: rpx(10, "positioning"),
					height: "100%",
					marginLeft: rpx(212, "positioning"),
					overflow: "visible",
				}}
			>
				<svg
					style={{
						width: "100%",
						height: "100%",
					}}
					viewBox={`0 0 900 600`}
				>
					{[...new Array(7)].map((_, i) => (
						<motion.line
							key={i}
							x1={i * 150}
							x2={i * 150}
							y1={0}
							y2={600}
							stroke={theme.gridColor}
							strokeWidth={strokeWidth}
							initial={{
								pathLength: 0,
							}}
							exit={{
								pathLength: 0,
							}}
							animate={{
								pathLength: 1,
							}}
							transition={{
								duration: 1,
								delay: i * .1 + 2 + sectionDelay,
								ease: DEFAULT_EASE,
							}}
						/>
					))}

					{[...new Array(2)].map((_, i) => (
						<motion.line
							key={i}
							y1={i === 0 ? 150 : 450}
							y2={i === 0 ? 150 : 450}
							x1={0}
							x2={900}
							stroke={theme.gridColor}
							strokeWidth={strokeWidth}
							initial={{
								pathLength: 0,
							}}
							exit={{
								pathLength: 0,
							}}
							animate={{
								pathLength: 1,
							}}
							transition={{
								duration: 1,
								delay: i * .1 + 3 + sectionDelay,
								ease: DEFAULT_EASE,
							}}
						/>
					))}

					{
						points.map((row, i) => {
						return row.map((col, j) => {
							const x = (j * 150) + 75;
							const y = (i * 150) + 75;
							
							return (
								<motion.rect
									key={`${i}-${j}`}
									x={x - 3}
									y={y - 3}
									width={6}
									height={6}
									fill={theme.gridColor}
									initial={{
										opacity: 0,
									}}
									exit={{
										opacity: 0,
									}}
									animate={{
										opacity: 1,
									}}
									transition={{
										duration: 1,
										// delay: (i * 6 + j) * .03 + 3,
										// delay: (i * 6 + j) * .02 + 4,
										delay: j * .15 + (i * 0.025) + 4 + sectionDelay,
										ease: DEFAULT_EASE,
									}}
								/>
							)
						})
					})}
				</svg>
			</div>
		</motion.div>
	);
}