import {DEFAULT_EASE, stringFallback, truncate} from "../shared";
import Typography from "./Typography";
import {SnkrsDropQr} from "./SnkrsDropQr";
import {dateFormat, SNKRS_URL} from "./SnkrsCalHelpers";
import {motion} from "framer-motion";
import {DropInterface, SnkrsCalProps} from "../screens/SnkrsCal";
import {useCallback, useMemo} from "react";
import {containsJapanese} from "../../../utilities";

interface SnkrsCalDetailDropContent {
    show: boolean;
    drop: DropInterface;
    cta_label?: string;
    qr_url?: string
    hide_dates?: boolean;
    locale?: string;
    available_copy?: string;
    concept: SnkrsCalProps['concept'];
}

export default function SnkrsCalDetailDropContent(props: SnkrsCalDetailDropContent) {
    const {show, qr_url, concept, drop, cta_label, locale, available_copy, hide_dates} = props;

    const droppingAt = useMemo(() => dateFormat(drop, {locale, available_copy}), [drop, hide_dates]);

    const hasJapanese = useCallback((content: string) => {
        return containsJapanese(content);
    }, [drop]);

    const url = stringFallback(drop?.qr_link, stringFallback(qr_url, SNKRS_URL));

    const titleHasJapanese = hasJapanese(drop?.subtitle);

    const wofFont = titleHasJapanese ? 'jordan' : 'jordan-condensed';
    const wofSize = titleHasJapanese ? 125 : 170;
    const wofLineHeight = titleHasJapanese ? '90%' : '80%';
    const wofColorDescriptionFont = hasJapanese(drop?.colorDescription) ? 'pp-ultralight' : 'pp-ultralight-italic';

    return (
        <motion.div
            initial={false}
            animate={{
                y: show ? '0%' : '100%',
            }}
            transition={{
                ease: DEFAULT_EASE,
                duration: 1,
            }}
            style={{
                zIndex: 2,
                position: 'absolute',
                bottom: 0,
                width: '100%',
                height: 840,
                padding: '33px 40px',
                background: 'white',
            }}
        >
            <Typography
                variant={concept === 'wof' ? wofFont : 'futura'}
                color={'black'}
                size={concept === 'wof' ? wofSize : 150}
                style={{
                    marginTop: 102 - 33 - 50,
                    lineHeight: concept === 'wof' ? wofLineHeight : '85%',
                }}
            >
                {truncate(drop?.subtitle?.toUpperCase(), titleHasJapanese ? 24 : 31)}
            </Typography>

            <Typography
                size={42}
                style={{
                    marginTop: concept === 'wof' ? 10 : 60,
                    fontWeight: concept !== 'wof' && 500,
                }}
                variant={concept === 'wof' ? wofColorDescriptionFont : 'neue'}
                color={'black'}
            >
                {truncate(drop?.colorDescription, hasJapanese(drop?.colorDescription) ? 20 : 35)}
            </Typography>
            {
                hide_dates ? null : (
                    <Typography
                        size={42}
                        style={{
                            marginTop: 17,
                            fontWeight: concept !== 'wof' && 500,
                        }}
                        variant={concept === 'wof' ? 'pp-ultralight' : 'neue'}
                        color={'black'}
                    >
                        {droppingAt}
                    </Typography>
                )
            }
            {
                url && !drop?.hide_qr && (
                    <SnkrsDropQr
                        concept={concept}
                        cta={cta_label}
                        url={url}
                    />
                )
            }
        </motion.div>
    )
}