import React, { useEffect, useMemo, useState } from 'react';
import Codification from '../../../../components/Codification';
import './TextBlock.scss';
import { NIKE_EASE } from '../../../../constants/nikerise';
import { motion, useAnimation } from 'framer-motion';
import { TEXT_STYLES, TEXT_SIZES } from '../../constants';


const DEFAULTS = { duration: 1, delay: 1 };

const TextBlock = ({
                     text,
                     codify = true,
                     overflow = 'visible',
                     textMode = 'black',
                     top = 'auto',
                     left = 'auto',
                     bottom = 'auto',
                     right = 'auto',
                     rotate = false,
                     width = 0,
                     height = 0,
                     delay = DEFAULTS.delay,
                     duration = DEFAULTS.duration,
                     type = TEXT_SIZES.Heading,
                   }) => {

  const [reverse, setReverse] = useState(false);

  const typeStyle = TEXT_STYLES[type];

  const rotation = rotate ? { transform: `rotate(-90deg)`, transformOrigin: 'top left' } : {};

  const animation = useAnimation();

  useEffect(() => {

    setTimeout(() => {
      setReverse(true);
    }, delay * 1000 + duration * 1000);

  }, []);

  useEffect(() => {
    sequence();
  }, []);

  async function sequence() {
    await animation.start({ opacity: 1, transition: { ease: NIKE_EASE, duration: 1, delay: 2 } });
    await animation.start({ opacity: 0, transition: { ease: NIKE_EASE, delay: 5 } });
  }

  const containsEmbellishments = useMemo(() => {
    // check for any accents on the text like é, ñ, etc
    return text.match(/[^\x00-\x7F]/g) !== null;
  }, [text]);


  const style = useMemo(() => {
    if (!containsEmbellishments) {
      return typeStyle;
    }

    const fontSize = `${parseFloat(typeStyle.fontSize) * 0.85}vh`;

    return {
      ...typeStyle,
      fontSize,
    }
  }, [containsEmbellishments, typeStyle]);

  return text ? (
    <div className="TextBlock" style={{ top, left, bottom, right, width, height, overflow: 'visible' }}>

      <div style={rotation}>
        {
          codify ?
            <Codification
              className={`TextBlock-codify mode-${textMode}`}
              text={text}
              typography={{
                primary: true,
                typeStyle: style,
                tag: 'span',
              }}
              characterSwitchAmount={3}
              characterNextTrigger={2}
              characterTimeout={30}
              start={true}
              delay={delay * 1000}
              reverse={reverse}
            />
            :

            <motion.div
              className={'TextBlock-fade'} style={typeStyle}
              initial={{ opacity: 0 }}
              animate={animation}
              exit={{ opacity: 0 }}
            >
              {text && <div>{text.split('\n').map((line) => {
                return <span>{line}</span>;
              })}</div>}
            </motion.div>


        }
      </div>
    </div>
  ) : (
    <div />
  );
};

export default TextBlock;
