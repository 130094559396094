import Enum from "../utils/types";

const PIXEL_MAP_WRAP_TYPE = {
    SPACER: 'spacer',
    IFRAME: 'iframe',
    DISPLAY: 'display',
} as const;

export type PixelMapWrapType = Enum<typeof PIXEL_MAP_WRAP_TYPE>;

export default PIXEL_MAP_WRAP_TYPE;
