import {usePx} from "./ScreenWrapper";
import Status from "./Status";

const LOGO_PNG = 'https://res.cloudinary.com/jbboerner/image/upload/v1665545811/nsil/nsil_logo.png';
export default function Header({status}) {
    const px = usePx();
    return (
        <div
            style={{
                position: 'absolute',
                left: 0,
                width: '100%',
                height: '100%',
                top: 0,
                padding: px(40),
                zIndex: 10,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            }}
        >
            <div>
                <img
                    src={LOGO_PNG}
                    style={{
                        width: px(287),
                        height: 'auto',
                    }}
                />
            </div>


            <Status status={status}/>
        </div>
    )
}