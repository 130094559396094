import React, { useMemo } from 'react';

import * as styles from './PixelMapTestModeSlot.module.scss';
import { INITIAL_MIN_SIDE_VALUE } from "../helpers";

interface PixelMapDisplaySlotProps {
    idx: number,
    width: number,
    height: number,
    minWrapSide: number,
}

export default function PixelMapTestModeSlot({
    idx,
    width,
    height,
    minWrapSide,
    ...otherProps
}: PixelMapDisplaySlotProps) {
    const rows = useMemo(() => Math.ceil(height / minWrapSide), [height, minWrapSide]);
    const cols = useMemo(() => Math.ceil(width / minWrapSide), [width, minWrapSide]);

    const getColumnLabel = (index: number) => {
        let label = '';
        let dividend = index;

        while (dividend > 0) {
            const remainder = (dividend - 1) % 26;
            label = String.fromCharCode(65 + remainder) + label;
            dividend = Math.floor((dividend - 1) / 26);
        }

        return label;
    };

    const gridItems = useMemo(() => {
        return Array.from({ length: rows * cols }, (_, index) => {
            const row = Math.floor(index / cols) + 1;
            const col = (index % cols) + 1;

            const label = `${getColumnLabel(col)}${row}`;
            return (
                <div
                    key={index}
                    className={styles.gridItem}
                    style={{
                        fontSize: minWrapSide === INITIAL_MIN_SIDE_VALUE ? 16 : minWrapSide * 0.3,
                        width: `${minWrapSide}px`,
                        height: `${minWrapSide}px`,
                        lineHeight: `${minWrapSide}px`,
                    }}
                >
                    {label}
                </div>
            );
        });
    }, [rows, cols, minWrapSide]);

    return (
        <div
            {...otherProps}
            className={styles.pixelMapTestModeSlot}
            style={{
                height: `${height}px`,
                width: `${width}px`,
                gridTemplateColumns: `repeat(${cols}, ${minWrapSide}px)`,
                gridTemplateRows: `repeat(${rows}, ${minWrapSide}px)`,
            }}
        >
            {gridItems}
        </div>
    );
}
