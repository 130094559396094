import {get, set} from "./index";

export interface GeneralState {
    isEditor: boolean;
    mode: 'offline' | 'online';
    setMode: (mode: 'offline' | 'online') => void;
    shouldListen: () => boolean;
    languages: Array<string>;
}

export const generalState = (set: set, get: get): GeneralState => ({
    isEditor: false,
    mode: 'offline',
    setMode: (mode: 'offline' | 'online') => {
        set({ mode });
    },
    shouldListen: () => {
        if (get().isEditor) {
            return true;
        }
        return get().mode === 'online'
    },
    languages: [],
})