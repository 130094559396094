import { debounce } from "lodash";
import { useEffect } from "react";


export default function useOnWindowResize(callbackFn: () => void, debounceWait = 100) {
    useEffect(() => {
        const onResize = debounce(callbackFn, debounceWait);

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, [callbackFn]);
}
