import VerticalTicker, {TickerType} from "./VerticalTicker";
import React, {useMemo} from "react";
import {getChannelInfo} from "../../rise/hyperlive/utils";
import {motion} from "framer-motion";

export default function CenterCourtVerticalTicker({verticalTicker, customTicker}: {
    verticalTicker: TickerType,
    customTicker: any
}) {
    const height = 1920;

    const scale = useMemo(() => {
        const canvasHeight = getChannelInfo('json_config.canvas_height', 1920);

        return canvasHeight / height;

    }, [height]);

    const forceDuration = useMemo(() => verticalTicker === 'custom' ? 30 : undefined, [verticalTicker]);

    const {leftTicker, rightTicker} = useMemo(() => {
        return {
            leftTicker: verticalTicker,
            rightTicker: verticalTicker === 'wings_china' ? 'wings' : verticalTicker
        }
    }, [verticalTicker]);

    return (
        <>
            <motion.div
                style={{
                    position: 'absolute',
                    right: (scale - 1) * -18,
                    height,
                    width: 100,
                    zIndex: 8,
                    top: 0,
                    display: 'flex',
                    transformOrigin: 'right top',
                    scale
                }}
            >
                <VerticalTicker
                    theme={'dark'}
                    duration={forceDuration}
                    type={rightTicker}
                    asset={customTicker}
                    custom={{
                        // forceContentHeight: '1000px',
                    }}
                />
            </motion.div>

            <motion.div style={{
                position: 'absolute',
                left: (scale - 1) * -18,
                height,
                width: 100,
                zIndex: 8,
                top: 0,
                display: 'flex',
                scale,
                transformOrigin: 'left top',
            }}>
                <VerticalTicker
                    reverse
                    duration={forceDuration}
                    theme={'dark'}
                    type={leftTicker}
                    asset={customTicker}
                    custom={{
                        // forceContentHeight: '1000px',
                        lineLocation: 'right'
                    }}
                />
            </motion.div>
        </>
    );
}