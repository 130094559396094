import { get, set } from "./index";

export interface EpcTagState {
	epcTagEnabled: boolean;

	enableEpcTag: () => void;
	disableEpcTag: () => void;

	playAvailableEpcTag: () => void;
	stopAvailableEpcTag: () => void;
}

export const epcTagState = (set: set, get: get): EpcTagState => ({
	epcTagEnabled: false,

	enableEpcTag: () => set({ epcTagEnabled: true }),
	disableEpcTag: () => set({ epcTagEnabled: false }),

	playAvailableEpcTag: () => {
		const entries = get().entries;

		entries.forEach((entry, index) => {
			if (!entry.tagEnabled) {
				return;
			}

			get().enableMedia(index);
		})
	},
	stopAvailableEpcTag: () => {
		get().disableMedia();
	}
});