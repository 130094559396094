import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

import { version } from '../../package.json';
import {
  SENTRY_DSN,
  SENTRY_SAMPLE_RATE,
  SENTRY_SAMPLE_RATE_TRACES,
  SENTRY_SAMPLE_RATE_PROFILES,
  SENTRY_SAMPLE_RATE_REPLAYS_SESSION,
  SENTRY_SAMPLE_RATE_REPLAY_ON_ERROR
} from '../config';


export default function initSentry() {
    if (!SENTRY_DSN) {
        return;
    }

    Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [
            new Integrations.BrowserTracing(),
            new CaptureConsole({
                levels: ['error'],
            }),
        ],
        release: `rise-displays@${version}`,
        environment: window.location.host,
        sampleRate: SENTRY_SAMPLE_RATE,
        tracesSampleRate: SENTRY_SAMPLE_RATE_TRACES,
        profilesSampleRate: SENTRY_SAMPLE_RATE_PROFILES,
        replaysSessionSampleRate: SENTRY_SAMPLE_RATE_REPLAYS_SESSION,
        replaysOnErrorSampleRate: SENTRY_SAMPLE_RATE_REPLAY_ON_ERROR,
    });
}
