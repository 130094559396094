import { motion } from "framer-motion";
import { ABSOLUTE_FILL } from "../helpers";

interface ScrimProps {
	isDark: boolean;
	useFilter?: boolean;
}

export default function Scrim({ isDark, useFilter }: ScrimProps) {
	return (
		<motion.div
			style={{
				...ABSOLUTE_FILL,
			}}
			animate={{
				// backdropFilter: useFilter ? `contrast(.7)` : undefined,
				// background: isDark ? "rgba(0,0,0,0.05)" : "rgba(255,255,255,0.05)",
				background: "rgba(0,0,0,0.1)"
			}}
		/>
	);
}