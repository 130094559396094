import { rpx } from "../helpers";
import { useFastLane } from "../store";

interface LaneDebuggerProps {
	width: number;
	height: number;
	index: number;
}

const testColors = [
	"#FF0000",
	"#00FF00",
	"#0000FF",
	"#FFFF00",
	"#00FFFF",
	"#FF00FF",
	"#F00FF0",
	"#0FF0F0",
	"#F0F00F",
];

export default function LaneDebugger({width, height, index}: LaneDebuggerProps) {
	const debugMode = useFastLane(f => f.debugMode);

	if (!debugMode) {
		return null;
	}

	return (
		<div
			style={{
				top: 0,
				left: 0,
				position: "absolute",
				width: width,
				height: height,
				borderColor: testColors[index % testColors.length],
				borderWidth: rpx(4, "positioning"),
				display: "flex",
				// justifyContent: "center",
				// alignItems: "center",
				fontSize: rpx(24, "font"),
				color: "#FFFFFF",
				fontWeight: "bold",
				opacity: 0.6,
			}}
		>
			<div style={{ marginTop: rpx(10, "positioning"), marginLeft: rpx(10, "positioning") }}>
				Lane {index}
			</div>

		</div>
	)
}