import { useMemo } from 'react';
import Ticker from 'react-ticker';
import _ from 'lodash';
import './NSILSchedule.scss';
import NSILVideo from './components/NSILVideo';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);

const video =
  'https://res.cloudinary.com/jbboerner/video/upload/v1672181443/nsil/BGW2.mp4';
const logo =
  'https://res.cloudinary.com/jbboerner/image/upload/v1665545811/nsil/nsil_logo.png';

const NSILSchedule = ({
  title,
  supertitle,
  status,
  currentMonth,
  currentDate,
  showUpcoming,
  smallFont,
}) => {
  const ticker_text = useMemo(() => {
    switch (status) {
      case 'quiet':
        return (
          <>
            <span>MOCK SHOP AVAILABLE</span>
            <span>RESERVE NOW</span>
          </>
        );
      case 'reserve_now':
        return (
          <>
            <span>MOCK SHOP AVAILABLE</span>
            <span>RESERVE NOW</span>
          </>
        );
      case 'closed':
        return (
          <>
            <span>MOCK SHOP AVAILABLE</span>
            <span>RESERVE NOW</span>
          </>
        );
      case 'do_not_enter':
        return (
          <>
            <span>MEETING IN PROGRESS</span>
            <span>DO NOT ENTER</span>
          </>
        );
      case 'booked':
        return (
          <>
            <span>MEETING IN PROGRESS</span>
            <span>DO NOT ENTER</span>
          </>
        );
      case 'open':
        return (
          <>
            <span>OPEN</span>
          </>
        );
      case 'available':
        return (
          <>
            <span>OPEN</span>
          </>
        );
      default:
        return (
          <>
            <span>OPEN</span>
          </>
        );
    }
  }, [status]);

  const heading = useMemo(() => {
    if (title) {
      return title.split('\n').map((line, index) => {
        return (
          <span className={smallFont ? 'small-font' : ''} key={index}>
            {line}
          </span>
        );
      });
    } else {
      return null;
    }
  }, [title]);

  const eyebrow = useMemo(() => {
    if (supertitle) {
      return supertitle.split('\n').map((line, index) => {
        return <span key={index}>{line}</span>;
      });
    } else {
      return null;
    }
  }, [supertitle]);

  const Content = () => {
    return (
      <div className={'NSILSchedule-content'}>
        {eyebrow &&
          showUpcoming === 'current-event' &&
          status !== 'closed' &&
          status !== 'quiet' &&
          status !== 'reserve_now' && <h2>{eyebrow}</h2>}
        {heading &&
          status !== 'closed' &&
          status !== 'quiet' &&
          status !== 'reserve_now' && <h1>{heading}</h1>}
        {(status === 'closed' ||
          status === 'quiet' ||
          status === 'reserve_now') && (
          <>
            <h1 className={`${status}`}>
              Mock Shop Available,
              <br />
              to Reserve contact
              <span className="email">NikeStoresInnovationLab1F@nike.com</span>
            </h1>
          </>
        )}
        <div className={`NSILSchedule-content-footer ${status}`}>
          Contact NikeStoresInnovationLab1F@nike.com for booking.
        </div>
      </div>
    );
  };

  return (
    <div className={`NSILSchedule ${status} ${showUpcoming}`}>
      <div className={`NSILSchedule-ticker ${status}`}>
        <Ticker speed={3}>{({ index }) => <>{ticker_text}</>}</Ticker>
      </div>

      <div className={'NSILSchedule-video'}>
        <NSILVideo data={{ asset: { url: video } }} />
      </div>

      <div className={'NSILSchedule-header'}>
        <img src={logo} />
      </div>

      <div className={'NSILSchedule-header-right'}>
        <h2>
          {currentMonth}
          <br></br>
          {currentDate}
        </h2>
      </div>

      <Content />

      {status === 'closed' || status === 'quiet' || status === 'reserve_now' ? (
        <div className={`NSILSchedule-bottom-ticker ${status}`}>
          <Ticker speed={3}>{({ index }) => <>{ticker_text}</>}</Ticker>
        </div>
      ) : null}
    </div>
  );
};

export default NSILSchedule;
