import React, { useMemo } from 'react';
import { motion, MotionStyle } from 'framer-motion';

import { PreventAnimationBubble } from "./WrapAnimatePresence";
import { DEFAULT_EASE } from '../constants/transition';
import { secToMs } from '../shared';

export type TransitionVariant = 'wipe-y' | 'wipe-x' | 'slide-y' | 'wipe-slide-x' | 'fade';

interface TransitionProps {
    variant: TransitionVariant;
    children: any;
    className?: string;
    style?: object;
    cover?: boolean;
    initial?: boolean;
    preventInitial?: boolean;
    preventExit?: boolean;
    delay?: number;
    delayExit?: number;
    duration?: number;
    ease?: number[];
    preventChildExit?: boolean;
    preventTransitions?: boolean;
    onAnimationComplete?: () => void;
}

export default function Transition(props: TransitionProps) {
    const {
        variant,
        duration = 2,
        ease = DEFAULT_EASE,
        delay = 0,
        delayExit = 0,
        preventExit = false,
        preventChildExit = false,
        preventInitial = false,
        initial = true,
        children,
        preventTransitions = false,
        cover,
        className = '',
        style = {},
        onAnimationComplete = () => null
    } = props;

    const animation = {
        'fade': {
            initial: {
                opacity: 0,
            },
            animate: {
                opacity: 1,
            },
            exit: {
                opacity: 0,
            },
        },
        'wipe-x': {
            initial: {
                clipPath: initial ? `inset(0% 100% 0% 0%)` : 'inset(0% 0% 0% 0%)',
            },
            animate: {
                clipPath: 'inset(0% 0% 0% 0%)',
            },
            exit: {
                clipPath: `inset(0% 0% 0% 100%)`,
            },
        },
        'wipe-slide-x': {
            initial: {
                clipPath: initial ? `inset(0% 100% 0% 0%)` : 'inset(0% 0% 0% 0%)',
            },
            animate: {
                clipPath: 'inset(0% 0% 0% 0%)',
            },
            exit: {
                clipPath: `inset(0% 0% 0% 100%)`,
            },
        },
        'none': {},
        'wipe-y': {
            initial: {
                clipPath: initial ? `inset(0% 0% 100% 0%)` : 'inset(0%, 0%, 0%, 0%)',
            },
            animate: {
                clipPath: 'inset(0% 0% 0% 0%)'
            },
            exit: {
                clipPath: `inset(100% 0% 0% 0%)`,
            },
        },
        'slide-y': {
            initial: {y: '10%'},
            animate: {y: '0%'},
            exit: {y: '-10%'},
        },
        'slide-x-in': {
            initial: {x: '-10%'},
            animate: {x: '0%'},
            exit: {x: '0%'},
        }
    }

    const active = animation[variant];

    const shouldCover: MotionStyle = cover ? {
        position: 'absolute',
        left: '0',
        top: 0,
    } : {};

    const content = useMemo(() => {
        if (variant === 'wipe-slide-x') {
            return (
                <motion.div
                    className={className}
                    style={{
                        width: '100%',
                        height: '100%',
                        ...shouldCover,
                        ...style,
                    }}
                    transition={{
                        duration,
                        delay,
                        ease
                    }}
                    initial={animation['slide-x-in'].initial}
                    animate={animation['slide-x-in'].animate}
                    exit={preventExit ? animation['slide-x-in'].animate : animation['slide-x-in'].exit}
                >
                    {children}
                </motion.div>
            )
        }

        return children
    }, [variant, children]);


    return (
        <motion.div
            className={className}
            style={{
                width: '100%',
                height: '100%',
                ...shouldCover,
                ...style,
            }}
            transition={{
                duration,
                delay,
                ease
            }}
            initial={preventInitial ? active.animate : active?.initial}
            animate={active.animate}
            exit={preventExit ? active.animate : active.exit}
            onAnimationComplete={onAnimationComplete}
        >
            {
                preventChildExit ? (
                    <PreventAnimationBubble>
                        {content}
                    </PreventAnimationBubble>
                ) : content
            }

        </motion.div>
    )
}

export function calculateDuration(props: TransitionProps) {
    const {
        duration = 2,
    } = props;

    return secToMs(duration);
}
