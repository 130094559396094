import {
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    AnimatePresence,
    usePresence,
} from 'framer-motion';

import {
    hasTextToDisplay,
    MultiLangText,
    secToMs,
    setAnimationTimeout,
    ThemeType,
} from '../../shared';
import CodificationWithTranslations, {
    calculateDuration as calculateCodificationDuration,
    calculateExitDuration as calculateCodificationExitDuration,
    TranslationCodificationProps,
} from './CodificationWithTranslations';


const codificationProps: TranslationCodificationProps = {
    characterSwitchAmount: 4,
    characterNextTrigger: 2,
    timingConfig: {
        control: 'character',
        duration: 0.03,
    },
};
const languageTransitionDelay = 0.25;


type TextProps = {
    text: MultiLangText,
    languages: string[],
    pauseDuration: number,
    loop?: boolean,
    className?: string,
    theme?: ThemeType,
    onTextRewrapped?: (data: MultiLangText) => void,
};

export default function CodifiedText(props: TextProps) {
    const {
        text,
        languages,
        pauseDuration,
        loop,
        className,
        theme,
        onTextRewrapped,
    } = props;

    const [isPresent, safeToRemove] = usePresence();
    const [langIndex, setLangIndex] = useState(0);
    const [nextLangIndex, setNextLangIndex] = useState(0);

    const hasText = useMemo(() => text && hasTextToDisplay(text, languages), [text, languages]);
    const textComponent = useMemo(() => hasText ? (
        <CodificationWithTranslations
            text={text}
            theme={theme}
            languages={languages}
            langIndex={langIndex}
            codificationProps={codificationProps}
            loop={loop}
            runFinalTextAnimation
            languageTransitionDelay={languageTransitionDelay}
            onTyped={() => setNextLangIndex(i => {
                if (i < languages.length) {
                    const next = i + 1;
                    return loop ? next % languages.length : next;
                } else {
                    return i;
                }
            })}
            onTextRewrapped={onTextRewrapped}
        />
    )  : null, [text, languages, langIndex]);

    useEffect(() => {
        if (!onTextRewrapped && hasText) {
            return;
        }

        onTextRewrapped(text);
    }, []);

    useEffect(() => {
        if (nextLangIndex === langIndex) {
            return;
        }

        return setAnimationTimeout(() => {
            setLangIndex(nextLangIndex);
        }, secToMs(pauseDuration));
    }, [nextLangIndex]);

    // allow to remove the component when all texts are already displayd,
    // but a chapter is still playing
    useEffect(() => {
        if (isPresent || nextLangIndex < languages.length) {
            return;
        }

        safeToRemove();
    }, [isPresent]);

    return (
        <div className={className}>
            <AnimatePresence onExitComplete={isPresent ? undefined : safeToRemove}>
                {isPresent ? textComponent : null}
            </AnimatePresence>
        </div>
    );
}

export function calculateDuration(props: TextProps) {
    const {
        text,
        languages,
        pauseDuration,
    } = props;

    const hasText = text && hasTextToDisplay(text, languages);
    if (!languages || !hasText) {
        return 0;
    }

    const duration = calculateCodificationDuration({
        text,
        languages,
        codificationProps,
        languageTransitionDelay,
    });

    return duration + languages.length * pauseDuration;
}

export function calculateExitDuration(props: TextProps) {
    const {
        text,
        languages,
    } = props;

    const hasText = text && hasTextToDisplay(text, languages);
    if (!languages || !hasText) {
        return 0;
    }

    const duration = calculateCodificationExitDuration({
        text,
        languages,
        codificationProps,
        languageTransitionDelay,
    });

    return duration;
}

export function fitLanguagesInDuration(props: TextProps, duration: number) {
    const {
        languages,
    } = props;

    const codificationDuration = calculateDuration(props);

    const estimatedOneLangDuration = secToMs(codificationDuration / languages.length);
    const amountOfLanguages = codificationDuration > 0
        ? Math.max(1, Math.floor(duration / estimatedOneLangDuration))
        : 1;

    const fittedLanguages = [];
    for (let n = 0; n < amountOfLanguages; n++) {
        fittedLanguages.push(languages[n % languages.length]);
    }

    return fittedLanguages;
}
