import {MediaType} from "../../../../jordan/shared";
import Media from "../../../../jordan/components/Media";
import {usePx} from "./ScreenWrapper";
import {ABSOLUTE_FILL} from "../../../../core/animations/fast-lane-2/helpers";
import {StatusType} from "./Status";
import {motion} from "framer-motion";

export default function Background({media, status}: { media: Array<MediaType>, status: StatusType | string }) {
    const px = usePx();

    const showAlert = status === 'do_not_enter' || status === 'booked';

    return (
        <>
            <Media
                dim
                style={{
                    ...ABSOLUTE_FILL,
                    zIndex: 0,
                    opacity: showAlert ? 0.7 : 1,
                }}
                media={media}
                width={px(1080)}
                height={px(1920)}
                preventInitial
                theme="dark"
            />
            <motion.div
                style={{
                    ...ABSOLUTE_FILL,
                    zIndex: 1,
                    backgroundColor: '#000',
                }}
                initial={false}
                animate={{
                    opacity: showAlert ? 0.7 : 0,
                }}
            />
            <motion.div
                style={{
                    ...ABSOLUTE_FILL,
                    zIndex: 1,
                    backgroundColor: '#ff0000',
                    mixBlendMode: 'overlay',
                }}
                initial={false}
                animate={{
                    opacity: showAlert ? 1 : 0,
                }}
            />
        </>
    )
}