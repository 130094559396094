import {
  Uniform,
  VideoTexture,
  WebGLRenderer,
  Color } from 'three';
import * as THREE from 'three';
import gsap from 'gsap';

import components  from './components';
import WebGLTemplateLayer from './WebGLTemplateLayer';

import defaultSwoosh from 'url:/src/touchpoints/live/lib/assets/swoosh.png';

export default class UniversalLiveTemplate {
  constructor (name) {
    this.wrapper = document.createElement('div');
    this.wrapper.id = name;
    this.wrapper.classList.add('chapter-wrapper');
    this.wrapper.innerHTML += components.partial;
    this.wrapper.querySelector('.live-app')?.classList.add(name);
    document.body.appendChild(this.wrapper);

    this.name = name;
    this.app = this.wrapper.querySelector(`.live-app.${this.name}`);
    this.textures = [];
    this.texturesURLs = [];
    this.videoKeys = [];

    this.running = false;

    this.width = window.innerWidth;
    this.height = window.innerHeight;
  }

  setReferencesDimensions (refWidth, refHeight) {
    this.refWidth = refWidth;
    this.refHeight = refHeight;
  }

  relWidth (w) {
    return w * (this.width / (this.refWidth || this.width));
  }

  relHeight (h) {
    return h * (this.height / (this.refHeight || this.height));
  }

  setCustomSize (width, height) {
    this.width = width;
    this.height = height;

    this.app.style.width = `${this.width}px`;
    this.app.style.height = `${this.height}px`;

    const main = document.querySelector(`.live-app.${this.name} .main-container`);
    main.style.width = `${this.width}px`;
    main.style.height = `${this.height}px`;

    const overlay = document.querySelector(`.live-app.${this.name} .overlay-container`);
    overlay.style.width = `${this.width}px`;
    overlay.style.height = `${this.height}px`;
  }

  setupTimeline (onComplete) {
    this.tl = gsap.timeline({
      onComplete,
      paused: true,
      defaults: { ease: 'power2.inOut' }
    });
    if (typeof this.populateTimeline === 'function') this.populateTimeline();
  }

  createVisualLayer (shader, uniforms={}) {
    const canvas = document.querySelector(`.live-app.${this.name} .LLS-canvas`);

    const width = this.width;
    const height = this.height;

    const textures = this.textures;
    const texturesURLs = this.texturesURLs;

    const renderer = new WebGLRenderer({ canvas, antialias: true, alpha: true, });
    renderer.setSize(width, height, false);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor(0xFFFFFF, 0);

    function setupUniforms () {
      return {
        time: new Uniform(0),
        pixa: new Uniform(1),
        pixr: new Uniform(window.devicePixelRatio),
        mosaicAmount: new Uniform(0.5),
        mosaicColor: new Uniform(new Color('#FFFFFF')),
        mosaicAlpha: new Uniform(0),
        ...uniforms
      }
    }

    function setupTextures (createTexture) {
      /*
        data: { src, key, tex, pos, dim, offset, pixel, zoom, zoomVF, opacity }
      */
      texturesURLs.forEach((data, i) => {
        createTexture({
          key: `img${i}`,
          ...data,
          src: data.src || defaultSwoosh,
        });
      });
      textures.forEach(({ tex, key, isVideo }) => {
        createTexture({ tex, key, });
      });
    }

    this.visualLayer = new WebGLTemplateLayer(canvas, renderer, { setupUniforms, setupTextures }, shader, this.name);
  }

  setGLUni (key, value, update=false) {
    if (update) {
      this.visualLayer.uniforms[key].value = value
      if (this.visualLayer.uniforms[key].value instanceof Object) {
        this.visualLayer.uniforms[key].value.needsUpdate = true;
      }
    } else {
      this.visualLayer.uniforms[key] = new Uniform(value)
    }
  }

  setCornerMargin (margin) {
    this.app.querySelector('.content-slot').style.margin = `${margin}px`
  }

  setViewfinderMarks (size, stroke) {
    this.app.classList.add('has-marks');

    const vfs = document.querySelectorAll(`.live-app.${this.name} .viewfinder-mark`);
    vfs.forEach(vf => {
      if (size) {
        vf.style.width = `${size}px`;
        vf.style.height = `${size}px`;
      }
      if (stroke) {
        vf.style.borderWidth = `${stroke}px`;
      }
    })
  }

  getComponentNode ({ type, content, corner, className }) {
    let element = components[type]({ name: this.name, ...content });
    if (corner) {
      element.classList.add(corner);
    }
    if (className) {
      element.classList.add(className);
    }
    return element;
  }

  addNode (data) {
    const node = this.getComponentNode(data);
    const slot = (data.corner)
      ? document.querySelector(`.live-app.${this.name} .corner-slot.${data.corner} .content-slot`)
      : data.freeflow ? document.querySelector(`.live-app.${this.name} .overlay-container`) :
      data.parent ? document.querySelector(`.live-app.${this.name} .overlay-container .overlay-inner-wrapper`) : document.querySelector(`.live-app.${this.name} .main-container .main-layer`);
    slot.appendChild(node);
    return node;
  }

  updateNode (selector, data) {
    const node = this.getComponentNode(data);
    const slot = document.querySelector(`.live-app.${this.name} ${selector}`);
    slot.outerHTML = node;
  }

  setVideoTexture (asset, key) {
    this.wrapper.insertAdjacentHTML('beforeend', `<video preload muted crossOrigin="anonymous" id="video-${key}" playsinline src="${asset.url}"></video>`);
    const video = this.wrapper.querySelector(`#video-${key}`);
    video.style.display = 'none';
    video.width = asset.width || 2160;
    video.height = asset.height || 1080;
    const tex = new VideoTexture(video);
    this.videoTextureGram = tex;
    this.textures.push({ tex, key });
    this.videoKeys.push(key);
    console.log(this.textures)
  }

  registerTextureURL (data) {
    this.texturesURLs.push(data);
  }

  setMainSlot (data) {
    return this.addNode(data);
  }

  setTopLeftSlot (data) {
    return this.addNode({ corner: 'top-left', ...data});
  }
  setTopRightSlot (data) {
    return this.addNode({ corner: 'top-right', ...data});
  }
  setBottomRightSlot (data) {
    return this.addNode({ corner: 'bottom-right', ...data});
  }
  setBottomLeftSlot (data) {
    return this.addNode({ corner: 'bottom-left', ...data});
  }
  setCenterLeftSlot (data) {
    return this.addNode({ corner: 'center-left', ...data});
  }
  setCenterRightSlot (data) {
    return this.addNode({ corner: 'center-right', ...data});
  }

  

  getMainSlot (selector) {
    return document.querySelectorAll(`.live-app.${this.name} .main-container ${selector}`);
  }

  getOverlay () {
    return document.querySelector(`.live-app.${this.name} .overlay-container`);
  }

  getAllInOverlay (selector) {
    return document.querySelectorAll(`.live-app.${this.name} .overlay-container ${selector}`);
  }

  getTopLeftSlot (selector) {
    return document.querySelector(`.live-app.${this.name} .corner-slot.top-left ${selector}`);
  }
  getTopRightSlot (selector) {
    return document.querySelector(`.live-app.${this.name} .corner-slot.top-right ${selector}`);
  }
  getBottomRightSlot (selector) {
    return document.querySelector(`.live-app.${this.name} .corner-slot.bottom-right ${selector}`);
  }
  getBottomLeftSlot (selector) {
    return document.querySelector(`.live-app.${this.name} .corner-slot.bottom-left ${selector}`);
  }
  getCenterSlot (selector) {
    return document.querySelector(`.live-app.${this.name} .corner-slot.full-center ${selector}`);
  }
  start (keyframe) {
    if (typeof this.preStart === 'function') this.preStart();
    console.log(`${this.name} start.`);
    this.videoKeys?.forEach(key => {
      this.visualLayer.uniforms[key].value.image.currentTime = 0;
      this.visualLayer.uniforms[key].value.image.play();
    });
    this.visualLayer?.start(keyframe);
    this.tl?.play(this.keyframes?.[keyframe] ?? 0);
    this.running = true;
  }
  pause () {
    if (typeof this.preResume === 'function') this.preResume();
    this.videoKeys.forEach(key => {
      this.visualLayer.uniforms[key].value.image.pause();
    });
    this.visualLayer?.pause();
    this.tl?.pause();
    this.running = false;
  }
  resume () {
    if (typeof this.prePause === 'function') this.prePause();
    this.videoKeys.forEach(key => {
      this.visualLayer.uniforms[key].value.image.play();
    });
    this.visualLayer?.resume();
    this.tl?.resume();
    this.running = true;
  }
  stop () {
    if (typeof this.preStop === 'function') this.preStop();
    this.videoKeys?.forEach(key => {
      this.visualLayer.uniforms[key].value.image.pause();
      this.visualLayer.uniforms[key].value.image.currentTime = 0;
    });
    this.visualLayer?.stop();
    this.tl?.pause();
    this.running = false;
  }
};
