import {
    CSSProperties,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import {
    AnimatePresence,
    HTMLMotionProps,
    motion,
    useIsPresent,
    Variants
} from 'framer-motion';
import classNames from 'classnames';

import * as cssClassNames from '../../styles/screens/Services.module.scss';

import {
    DEFAULT_EASE,
    getLangText,
    hasTextToDisplay,
    MultiLangText,
    scaleText,
    secToMs,
    setAnimationTimeout
} from '../../shared';
import { DEFAULT_DURATION_S } from '../../constants/transition';
import CodificationWithTranslations from '../Codification/CodificationWithTranslations';
import useAreFontsLoaded from '../../hooks/useAreFontsLoaded';


export type ServiceItemProps = {
    title: MultiLangText,
    description: MultiLangText,
    language: string,
    rowHeight: number,
    toCodifyTitle?: boolean,
    className?: string,
    containerProps?: Omit<HTMLMotionProps<'div'>, 'style'> & { style?: CSSProperties },
    backgroundStyle?: CSSProperties,
    animationDuration?: number,
    delay?: number,
    onEntryAnimationComplete?: () => void,
    onAnimationComplete?: () => void,
    toExitCodification?: boolean,
    onExitComplete?: () => void,
}

const delayForHeightMeasurement = 0.1;

export default function ServiceItem(props: ServiceItemProps) {
    const {
        title,
        description,
        language,
        rowHeight,
        toCodifyTitle = false,
        className,
        containerProps = {},
        backgroundStyle = {},
        animationDuration = DEFAULT_DURATION_S,
        delay = 0,
        onAnimationComplete,
        onEntryAnimationComplete,
        toExitCodification = false,
        onExitComplete
    } = props;

    const titleToDisplay = useMemo(() => {
        if (!title || !hasTextToDisplay(title, [language])) {
            return {
                [language]: ''
            };
        }

        return title;
    }, [title, language]);

    const areFontsLoaded = useAreFontsLoaded();
    const descriptionLines = useMemo(() => description ? (
        getLangText(description, language).split('\n').map((text, index) => (
            <div key={index}>
                <span>{text}</span>
            </div>
        ))
    ) : [], [description, language]);

    const isPresent = useIsPresent();
    const titleRef = useRef<HTMLDivElement>();
    const descriptionRef = useRef<HTMLDivElement>();
    const [toShow, setToShow] = useState(delay === 0);
    const [descriptionHeight, setDescriptionHeight] = useState(0);
    const [titleHeight, setTitleHeight] = useState(0);

    useEffect(() => {
        if (toShow) {
            return;
        }

        return setAnimationTimeout(() => setToShow(true), secToMs(delay));
    }, []);

    useEffect(() => {
        if (!titleRef.current || !areFontsLoaded) {
            return;
        }

        scaleText(titleRef.current, 'span');

        const {height} = titleRef.current.getBoundingClientRect();
        setTitleHeight(Math.ceil(height / rowHeight) * rowHeight);
    }, [titleRef.current, areFontsLoaded]);

    useEffect(() => {
        if (!descriptionRef.current || !areFontsLoaded) {
            return;
        }

        scaleText(descriptionRef.current, 'span');

        const {height} = descriptionRef.current.getBoundingClientRect();
        setDescriptionHeight(Math.ceil(height / rowHeight) * rowHeight);
    }, [descriptionRef.current, areFontsLoaded]);

    const mainContainerAnimationHandler = (...args: []) => {
        if (containerProps.animate === 'show' && !toCodifyTitle) {
            onEntryAnimationComplete?.();
            return;
        }

        if (isPresent) {
            onAnimationComplete?.(...args);
            return;
        }

        onExitComplete?.();
    }

    if (!toShow) {
        return null;
    }

    const mainContainerVariants: Variants = {
        show: {
            clipPath: 'inset(0% 0% 0% 0%)',
            transition: {
                delay: delayForHeightMeasurement,
                duration: animationDuration,
                ease: DEFAULT_EASE,
            },
        },
        grow: ({expandedHeight: height}) => ({
            clipPath: 'inset(0% 0% 0% 0%)',
            height: `${height}px`,
        }),
        shrink: ({collapsedHeight: height}) => ({
            clipPath: 'inset(0% 0% 0% 0%)',
            height: `${height}px`,
        }),
        hide: {
            clipPath: 'inset(0% 100% 0% 0%)',
        },
    };
    const serviceItemBackgroundAnimationVariants: Variants = {
        hide: {
            transform: 'translateX(-100%)',
        },
        show: {
            transform: 'translateX(0%)',
            transition: {
                delay: delayForHeightMeasurement,
                duration: animationDuration,
                ease: DEFAULT_EASE,
            },
        }
    };
    const titleRowHeight = titleHeight || rowHeight;
    const gridDescription = descriptionHeight ? `${descriptionHeight}px` : 'auto';
    return (
        <motion.div
            className={classNames(cssClassNames.service_item, className)}
            variants={mainContainerVariants}
            transition={{
                duration: animationDuration,
                ease: DEFAULT_EASE,
            }}
            {...containerProps}
            style={{
                height: `${titleRowHeight}px`,
                gridTemplateRows: `${titleRowHeight}px ${gridDescription}`,
                ...containerProps.style
            }}
            custom={{
                collapsedHeight: titleRowHeight,
                expandedHeight: titleRowHeight + descriptionHeight,
            }}
            onAnimationComplete={mainContainerAnimationHandler}
        >
            <motion.div
                variants={serviceItemBackgroundAnimationVariants}
                transition={{
                    duration: animationDuration,
                    ease: DEFAULT_EASE,
                }}
                initial='hide'
                animate='show'
                exit='hide'
                className={cssClassNames.background}
                style={backgroundStyle}
            >
            </motion.div>
            <div
                className={cssClassNames.title}
                style={{
                    width: containerProps.style?.width
                }}
            >
                {toCodifyTitle ? (
                    <AnimatePresence>
                        <CodificationWithTranslations
                            text={titleToDisplay}
                            languages={[language]}
                            langIndex={0}
                            codificationProps={{
                                characterSwitchAmount: 4,
                                characterNextTrigger: 2,
                            }}
                            languageTransitionDelay={0}
                            runFinalTextAnimation={toExitCodification}
                            onLanguageContainerMeassuerd={sizes => {
                                const maxHeight = Math.max(...sizes.map(({height}) => height));
                                const newRowHeight = Math.ceil(maxHeight / rowHeight ) * rowHeight;
                                setTitleHeight(newRowHeight);
                            }}
                            onTyped={() => {
                                if (!isPresent) {
                                    return;
                                }

                                onEntryAnimationComplete?.();
                            }}
                        />
                    </AnimatePresence>
                ) : (
                    <div ref={titleRef}>
                        {getLangText(titleToDisplay, language).split('\n').map((text, index) => (
                            <div key={index}>
                                <span>{text}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div
                className={cssClassNames.description}
                style={{
                    width: containerProps.style?.width
                }}
            >
                <div ref={descriptionRef}>
                    {descriptionLines}
                </div>
            </div>
        </motion.div>
    );
};

