@use '../variables' as vars;

.two_sides {
    display: grid;
    width: 100vh;
    height: 100vw;
    transform-origin: top left;
    transform: rotate(90deg) translateY(-100%);
    grid-template-rows: 50% 50%;

    > * {
        height: 100%;

        &:last-child {
            transform: rotate(180deg);
        }
    }

    .text {
        padding: vars.to-vh(115, 2200) vars.to-vw(100, 1920);
    }

    .media {
        position: relative;
        padding: vars.to-vh(115, 2200) vars.to-vw(100, 1920);
    }
}

.text_container {
    @include vars.hoi-font;

    display: flex;
    align-items: center;
    width: 100%;
    font-size: vars.to-vh(177, 2200);
    line-height: vars.to-vh(160, 2200);
    color: white;

    > * {
        width: 100%;
        height: 100%;
    }
}
