import * as Hyperlive from '../../hyperlive/utils';
import devData from './devData.json';
import {ANIMATION_TYPES} from '../../../../constants';
import {DEFAULT_TRACK_WIDTH} from "./constants";

/**
	Function to configure fastlane base on config file and url params
 	Possible URL params:
 	occlusion1 - start,width - number, number (pixels)
 	occlusion2 - start,width - number, number (pixels)
 	.
 	.
 	.
 	occlusionN - start, width - number, number (pixels) - you can supply any number of occlusion zones
 	showRulers - boolean
*/
export const fastlaneConfig = () => {
	// TODO need to update the hyperlive response to match new devData
	const animation_json = Hyperlive.fetchAnimations();
	const channel = Hyperlive.fetchChannelInfo();
	const configData = {
		locations: animation_json.filter((a) => a.animation_type_id === ANIMATION_TYPES.FAST_LANE).sort((a, b) => {
			return Number(a.json_data.start_location) < Number(b.json_data.start_location) ? -1 : 1;
		}),
		...channel?.json_config,
	};

	const lanes = configData.locations.map(lane => {
		const start = Number(lane.json_data.start_location);
		return {
			mode: lane.json_data?.mode,
			start,
			end: start + DEFAULT_TRACK_WIDTH
		};
	});

	const info = {
		lanes,
		trackLength: Number(configData.track_length),
		trackHeight: Number(configData.track_height),
		staggerDelay: 0,
		renderWidth: Number(configData.render_width),
	};

	const waitFrames = [
		{ frame: 1, wait: 10000 },
		{ frame: 2, wait: 10000 },
		{ frame: 3, wait: 10000 },

		// { frame: 1, wait: 5000 },
		// { frame: 2, wait: 10000 },
		// { frame: 3, wait: 10000 },

		// { frame: 1, wait: 500 },
		// { frame: 2, wait: 100 },
		// { frame: 3, wait: 100 },
	];

	const urlSearchParams = new URLSearchParams(window.location.search);
	const params = Object.fromEntries(urlSearchParams.entries());

	let occlusionZones = Object.entries(params)
		.filter(([key]) => key.includes('occlusion'))
		.map(([_, value]) => {
			const [start, width] = value.split(',').map(val => Number(val));
			return { start, end: width + start, occlude: true };
		});

	if (!occlusionZones.length)
		occlusionZones =
			configData?.occlusions?.map(({ start, width }) => ({
				start,
				end: start + width,
				occlude: true,
			})) || [];

	const showRulers = params.showRulers === 'true';

	// All zones provided by config or url params
	const allZones = [...lanes, ...occlusionZones].sort((a, b) =>
		a.start > b.start ? 1 : -1,
	);

	// Calculate where the filler blocks must go based on input parameters
	const blockZones = allZones
		.reduce((blocks, current, i) => {
			if (i === 0 && current.start > 0) {
				return [{ start: 0, end: current.start }];
			} else if (i === 0) return [];
			else if (
				i === allZones.length - 1 &&
				current.end < info.trackLength
			) {
				const prevSpace =
					allZones[i - 1].end !== current.start
						? [{ start: allZones[i - 1].end, end: current.start }]
						: [];
				return [
					...blocks,
					...prevSpace,
					{ start: current.end, end: info.trackLength },
				];
			} else if (allZones[i - 1].end !== current.start) {
				return [
					...blocks,
					{ start: allZones[i - 1].end, end: current.start },
				];
			}
			return [...blocks];
		}, [])
		.map(block => ({ ...block, filler: true }));

	// All zones calculated: Filler zones, Occlusion zones, and Lanes
	const combinedZones = [...allZones, ...blockZones].sort((a, b) =>
		a.start > b.start ? 1 : -1,
	);

	// Lanes split block zones if greater than 6* 325
	const formattedLanes = combinedZones
		.filter(z => !z.occlude)
		.reduce((blocks, block) => {
			const blockZoneLength = 6 * 325;
			const length = block.end - block.start;
			const numBlocks = Math.max(Math.floor(length / blockZoneLength), 0);
			const remainder = length % blockZoneLength;

			const newBlocks = new Array(numBlocks).fill(0).map((_, i) => ({
				start: block.start + i * blockZoneLength,
				end: block.start + (i + 1) * blockZoneLength,
				filler: block.filler,
			}));

			const nextStart = block.start + newBlocks.length * blockZoneLength;
			newBlocks.push({
				start: nextStart,
				end: nextStart + remainder,
				filler: block.filler,
			});
			return [...blocks, ...newBlocks];
		}, []);

	return {
		animation_json: configData.locations,
		info,
		waitFrames,
		showRulers,
		zones: combinedZones,
		lanes: formattedLanes,
		persistentTitle: channel?.json_config?.persistent_title,
		circleGap: channel?.json_config?.circle_gap,
		size: channel?.json_config?.size,
	};
};