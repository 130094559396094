.two_columns_transition {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    > * {
        background: white;
    }
}
