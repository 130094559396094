import React from 'react';

import { ExperiencesHub } from '../../../rise/animations/hub/views/ExperiencesHub';
import SportHubDirectory from './Directory';


export default class ExperienceHubChapter extends SportHubDirectory {
    component = () => (
        <ExperiencesHub
            data={{
                json_data: this.data,
            }}
        />
    )
}
