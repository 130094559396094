import BaseApi from './BaseApi';

export default class HeartbeatsApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl, '/public/heartbeats');
  }

  send(params) {
    return this.request({
      method: 'POST',
      body: JSON.stringify({
        ...params,
        sent_at: Date.now() / 1000,
      }),
    });
  }
}
