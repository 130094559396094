import React from "react";
import { FastLaneSection } from "../types";
import { useFastLane } from "../store";
import SectionContentBody from "./section-content-body";
import SectionContentMedia from "./section-content-media";
import { ABSOLUTE_FILL } from "../helpers";
import SectionContentShoe from "./section-content-shoe";

interface SectionContentProps {
	section: FastLaneSection;
	index: number;
}

export default function FastLaneSectionContent({ section, index }: SectionContentProps) {
	const theme = useFastLane(f => f.getTheme());

	return (
		<>
			<SectionContentMedia section={section} index={index} />

			<SectionContentShoe section={section} index={index} />

			<div
				style={{
					...ABSOLUTE_FILL,
					width: section?.position?.width,
				}}
			>
				<div
					style={{
						...ABSOLUTE_FILL,
						left:  section.position?.contentOffsetLeft ?? 0,
					}}
				>
					{theme?.sectionGrid?.({ index })}
					<SectionContentBody section={section} index={index} />
				</div>
			</div>
		</>
	);
}