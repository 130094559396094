export default class BaseApi {
	constructor(baseUrl, path) {
		this.verifyParams({ baseUrl, path });

		this.baseUrl = baseUrl;
		this.path = path;
	}

	/**
	 * @private
	 */
	verifyParams(params) {
		if (!params) {
			throw new Error('Missing required parameters');
		}

		if (!params.baseUrl) {
			throw new Error('Missing required parameter: "baseUrl"');
		}

		if (!params.path) {
			throw new Error('Missing required parameter: "path"');
		}
	}

	async request(params) {
		const response = await fetch(`${this.baseUrl}api${this.path}`, {
			headers: {
				'Content-Type': 'application/json',
			},
			...params,
		});

		return response.json();
	}
}
