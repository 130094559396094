const BACKGROUND_1 = 'Intro1';
const BACKGROUND_2 = 'Intro2';

export default [
  // draw initial openning grid
  {
    "id": 0,
    "delayHorizontal": true,
    "mask": [
      {
        "grid": 0,
        "polyOnly": true,
        "horizontal": [
          "Equator",
          "Bottom"
        ],
        "name": "Logo",
        "vertical": [
          "Left Border",
          "Right Border"
        ]
      }
    ],
    "startColor": 160,
    "drawBorder": true,
    "delayVertical": false,
    "insetBorder": false,
    "circle": [
      {
        "startLinePositionX": "Right",
        "startPositionY": 50,
        "drawFull": false,
        "name": "right-small",
        "drawAngle": 360,
        "startBoundaryLineX": "Equator",
        "collapseAtEnd": false,
        "drawDirection": 1,
        "clockwise": false,
        "collapse": false,
        "drawFromAngle": 270
      },
      {
        "drawAngle": 0,
        "endPositionX": 0,
        "name": "top-left-large",
        "drawFromAngle": 0,
        "startPositionX": 0,
        "collapse": false,
        "drawFull": false,
        "startPositionY": 0,
        "drawDirection": 1,
        "endPositionY": 0,
        "startBoundaryLineY": "Equator",
        "clockwise": true
      }
    ],
    "horizontal": [
      {
        "extend": false,
        "drawFull": true,
        "drawFromPosition": 0,
        "drawToPosition": 100,
        "startColor": 176,
        "drawDirection": 1,
        "endPosition": 12.5,
        "startPosition": 101,
        "name": "Top"
      },
      {
        "drawFromPosition": 0,
        "drawFull": true,
        "startPosition": 101,
        "drawDirection": 1,
        "name": "Equator",
        "extend": false,
        "drawToPosition": 100,
        "endPosition": 50
      },
      {
        "drawDirection": 1,
        "name": "Bottom",
        "drawToPosition": 100,
        "drawFromPosition": 0,
        "endPosition": 75,
        "extend": false,
        "startPosition": 101,
        "drawFull": true
      }
    ],
    "name": "IntroStart",
    "vertical": [
      {
        "startPosition": 50,
        "name": "Left",
        "drawFull": true,
        "drawFromPosition": 0,
        "endPosition": 5.6,
        "drawToPosition": 100
      },
      {
        "drawFromLine": "Bottom Border",
        "name": "Equator",
        "extend": false,
        "drawToLine": "Top Border",
        "startPosition": 50,
        "drawFull": true,
        "drawDirection": 1
      },
      {
        "endPosition": 94.4,
        "extend": false,
        "drawFull": true,
        "drawToPosition": 100,
        "drawFromPosition": 0,
        "drawDirection": 1,
        "name": "Right",
        "startPosition": 50
      }
    ],
    "duration": "1000",
    "horizontalDelayDirection": 1
  },
  // show first background and draw left arcs
  {
    "name": "IntroResolve",
    "circle": [
      {
        "startLinePositionX": "Right",
        "drawFromAngle": 270,
        "drawDirection": 1,
        "clockwise": false,
        "name": "right-small",
        "drawFull": false,
        "collapse": false,
        "drawAngle": 180,
        "startBoundaryLineX": "Equator",
        "collapseAtEnd": false,
        "startPositionY": 50
      },
      {
        "collapse": false,
        "delay": 200,
        "clockwise": true,
        "startPositionY": 0,
        "drawDirection": 1,
        "drawFromAngle": 0,
        "drawFull": false,
        "startBoundaryLineY": "Equator",
        "drawAngle": 95,
        "duration": 500,
        "startPositionX": 5.5,
        "name": "top-left-large"
      }
    ],
    "startColor": 160,
    "duration": "1000",
    "horizontal": [
      {
        "name": "Top",
        "drawFromPosition": 0,
        "extend": false,
        "startPosition": 12.5,
        "startColor": 176,
        "drawFull": true,
        "drawDirection": 1,
        "endPosition": 12.5,
        "drawToPosition": 100
      },
      {
        "drawFromPosition": 0,
        "startPosition": 50,
        "extend": false,
        "endPosition": 50,
        "name": "Equator",
        "drawDirection": 1,
        "drawFull": true,
        "drawToPosition": 100
      },
      {
        "startPosition": 75,
        "endPosition": 75,
        "drawFromPosition": 0,
        "name": "Bottom",
        "extend": false,
        "drawFull": true,
        "drawDirection": 1,
        "drawToPosition": 100
      }
    ],
    "insetBorder": false,
    "id": 1,
    "vertical": [
      {
        "drawFull": true,
        "startPosition": 5.6,
        "drawToPosition": 100,
        "name": "Left",
        "endPosition": 5.6,
        "drawFromPosition": 0
      },
      {
        "drawFromPosition": 0,
        "name": "Equator",
        "drawDirection": 1,
        "startPosition": 50,
        "extend": false,
        "endPosition": 50,
        "drawToPosition": 100,
        "drawFull": true
      },
      {
        "drawToPosition": 100,
        "name": "Right",
        "drawFromPosition": 0,
        "drawFull": true,
        "endPosition": 94.4,
        "extend": false,
        "startPosition": 94.4,
        "drawDirection": 1
      }
    ],
    "mask": [
      {
        "name": "Logo",
        "grid": 0,
        "horizontal": [
          "Equator",
          "Bottom"
        ],
        "polyOnly": true,
        "vertical": [
          "Left Border",
          "Right Border"
        ]
      },
      {
        "fadeIn": true,
        "vertical": [
          "Left Border",
          "Right Border"
        ],
        "coverFill": true,
        "asset": BACKGROUND_1,
        "grid": 0,
        "horizontal": [
          "Bottom",
          "Bottom Border"
        ],
        "name": "Bottom"
      },
      {
        "grid": 0,
        "fadeDelay": 150,
        "vertical": [
          "Left Border",
          "Right Border"
        ],
        "fadeIn": true,
        "coverFill": true,
        "horizontal": [
          "Equator",
          "Bottom"
        ],
        "name": "Bottom Top Quater",
        "asset": BACKGROUND_1
      },
      {
        "horizontal": [
          "Top",
          "Equator"
        ],
        "grid": 0,
        "fadeIn": true,
        "fadeDelay": 300,
        "name": "Top Bottom Quarter",
        "asset": "0",
        "coverFill": true,
        "vertical": [
          "Left Border",
          "Right Border"
        ]
      },
      {
        "coverFill": true,
        "name": "Top",
        "asset": BACKGROUND_1,
        "fadeIn": true,
        "vertical": [
          "Left Border",
          "Right Border"
        ],
        "grid": 0,
        "fadeDelay": 450,
        "horizontal": [
          "Top Border",
          "Top"
        ]
      }
    ],
    "drawBorder": true
  },
  // draw more arcs
  {
    "horizontal": [
      {
        "extend": false,
        "drawDirection": 1,
        "name": "Top",
        "startPosition": 12.5,
        "drawFull": true,
        "startColor": 176,
        "drawFromPosition": 0,
        "drawToPosition": 100,
        "endPosition": 12.5
      },
      {
        "drawDirection": 1,
        "endPosition": 50,
        "startPosition": 50,
        "drawToPosition": 100,
        "extend": false,
        "name": "Equator",
        "drawFromPosition": 0,
        "drawFull": true
      },
      {
        "extend": false,
        "drawToPosition": 100,
        "endPosition": 75,
        "startPosition": 75,
        "name": "Bottom",
        "drawFromPosition": 0,
        "drawFull": true,
        "drawDirection": 1
      }
    ],
    "id": 2,
    "insetBorder": false,
    "mask": [
      {
        "name": "Background",
        "asset": BACKGROUND_1,
        "horizontal": [
          "Top Border",
          "Bottom Border"
        ],
        "coverFill": true,
        "vertical": [
          "Left Border",
          "Right Border"
        ],
        "grid": 0
      }
    ],
    "startColor": 160,
    "drawBorder": true,
    "vertical": [
      {
        "drawFromPosition": 0,
        "startPosition": 5.6,
        "drawToPosition": 100,
        "drawFull": true,
        "endPosition": 5.6,
        "name": "Left"
      },
      {
        "drawDirection": 1,
        "drawFromPosition": 0,
        "drawToPosition": 100,
        "endPosition": 50,
        "name": "Equator",
        "drawFull": true,
        "extend": false,
        "startPosition": 50
      },
      {
        "drawFromPosition": 0,
        "drawDirection": 1,
        "drawToPosition": 100,
        "name": "Right",
        "endPosition": 94.4,
        "startPosition": 94.4,
        "extend": false,
        "drawFull": true
      }
    ],
    "circle": [
      {
        "name": "left-small",
        "drawDirection": 1,
        "drawAngle": 180,
        "startLinePositionX": "Left",
        "startBoundaryLineX": "Equator",
        "startLinePositionY": "Bottom",
        "collapseAtEnd": false,
        "startColor": 0,
        "drawFromAngle": 270,
        "collapse": false,
        "clockwise": true,
        "drawFull": false
      },
      {
        "collapse": false,
        "startLinePositionX": "Right",
        "name": "right-small",
        "drawFromAngle": 270,
        "drawDirection": 1,
        "startPositionY": 50,
        "drawAngle": 180,
        "startBoundaryLineX": "Equator",
        "clockwise": false,
        "collapseAtEnd": false,
        "drawFull": true
      },
      {
        "drawAngle": 95,
        "drawFull": true,
        "collapse": false,
        "drawFromAngle": 0,
        "clockwise": true,
        "startPositionY": 0,
        "name": "top-left-large",
        "drawDirection": 1,
        "startPositionX": 5.5,
        "startBoundaryLineY": "Equator"
      },
      {
        "delay": 300,
        "name": "top-left-small",
        "clockwise": false,
        "startPositionY": 0,
        "startPositionX": 5.5,
        "collapse": false,
        "drawAngle": 95,
        "startBoundaryLineX": "Equator",
        "drawDirection": 1,
        "drawFromAngle": 95,
        "drawFull": false,
        "duration": 500
      }
    ],
    "duration": "1000"
  },
  // animate frosted glass (1/2) on top of the first background and draw more arcs
  {
    "horizontal": [
      {
        "name": "Top",
        "drawFull": true,
        "drawToPosition": 0,
        "extend": false,
        "drawFromPosition": 0,
        "endAlpha": 0,
        "startPosition": 12.5,
        "delay": 500,
        "startAlpha": 1,
        "drawDirection": 1
      },
      {
        "name": "Top Blur Bottom",
        "drawFull": true,
        "startAlpha": 0.01,
        "endPosition": 25,
        "drawToPosition": 94.4,
        "drawFromPosition": 50,
        "startPosition": 0
      },
      {
        "extend": false,
        "startPosition": 100,
        "drawFull": true,
        "drawFromPosition": 5.6,
        "drawToPosition": 50,
        "startAlpha": 0.01,
        "endPosition": 50,
        "name": "Bottom Blur Top"
      },
      {
        "drawFromPosition": 0,
        "drawToPosition": 100,
        "drawFull": true,
        "startPosition": 50,
        "name": "Equator"
      },
      {
        "name": "Bottom",
        "drawFull": true,
        "drawFromPosition": 0,
        "startAlpha": 1,
        "delay": 700,
        "drawDirection": 1,
        "drawToPosition": 0,
        "endAlpha": 0,
        "startPosition": 75,
        "extend": false
      }
    ],
    "insetBorder": false,
    "id": 3,
    "mask": [
      {
        "name": "bottom left donut",
        "horizontal": [
          "Top Border",
          "Bottom Border"
        ],
        "assetPositionWithMask": false,
        "grid": 0,
        "vertical": [
          "Left Border",
          "Right Border"
        ],
        "fadeOut": true,
        "polyOnly": false,
        "circle": [
          "left-large",
          "left-small"
        ],
        "_colorFill": true,
        "asset": BACKGROUND_1,
        "assetStartPosition": [
          0,
          0
        ]
      },
      {
        "pin": "top",
        "name": "Background",
        "assetPositionWithMask": false,
        "horizontal": [
          "Top Border",
          "Bottom Border"
        ],
        "asset": BACKGROUND_1,
        "vertical": [
          "Left Border",
          "Right Border"
        ],
        "polyOnly": false,
        "grid": 0,
        "_colorFill": true,
        "coverFill": true
      },
      {
        "vertical": [
          "Equator",
          "Right"
        ],
        "grid": 0,
        "horizontal": [
          "Top Border",
          "Top Blur Bottom"
        ],
        "name": "Top Blur New",
        "asset": BACKGROUND_1,
        "filter": "blur",
        "coverFill": true
      },
      {
        "vertical": [
          "Left",
          "Equator"
        ],
        "filter": "blur",
        "horizontal": [
          "Bottom Blur Top",
          "Bottom Border"
        ],
        "coverFill": true,
        "grid": 0,
        "name": "Bottom Blur",
        "asset": BACKGROUND_1
      }
    ],
    "duration": 1000,
    "circle": [
      {
        "drawFromAngle": 270,
        "name": "left-small",
        "drawDirection": 1,
        "drawFull": true,
        "clockwise": true,
        "duration": 1500,
        "startLinePositionX": "Left",
        "collapse": false,
        "startBoundaryLineX": "Equator",
        "drawAngle": 360,
        "startLinePositionY": "Bottom"
      },
      {
        "drawDirection": 1,
        "startBoundaryLineX": "Equator",
        "clockwise": false,
        "startPositionY": 50,
        "drawFromAngle": 270,
        "drawFull": true,
        "name": "right-small",
        "collapse": false,
        "drawAngle": 180,
        "startLinePositionX": "Right"
      },
      {
        "drawFromAngle": 180,
        "collapse": false,
        "drawFull": true,
        "collapseAtEnd": false,
        "drawDirection": 1,
        "drawAngle": 360,
        "clockwise": true,
        "startPositionX": 5.5,
        "name": "top-left-large",
        "duration": 750,
        "delay": 750,
        "startBoundaryLineX": "Right",
        "startPositionY": 0
      },
      {
        "collapse": false,
        "drawDirection": 1,
        "clockwise": true,
        "startLinePositionX": "Right",
        "drawFromAngle": 90,
        "name": "right-large",
        "startBoundaryLineX": "Left",
        "drawAngle": 180,
        "startLinePositionY": "Equator",
        "drawFull": false
      },
      {
        "drawDirection": 1,
        "startBoundaryLineX": "Equator",
        "collapseAtEnd": false,
        "drawFromAngle": 180,
        "name": "top-left-small",
        "delay": 1500,
        "startPositionY": 0,
        "clockwise": false,
        "collapse": false,
        "drawFull": true,
        "drawAngle": 360,
        "duration": 500,
        "startPositionX": 5.5
      },
      {
        "startLinePositionY": "Bottom",
        "drawFromAngle": 90,
        "duration": 1000,
        "startBoundaryLineX": "Right",
        "name": "left-large",
        "collapseAtEnd": false,
        "drawDirection": 1,
        "clockwise": false,
        "startLinePositionX": "Left",
        "drawFull": true,
        "collapse": false,
        "drawAngle": 360
      }
    ],
    "vertical": [
      {
        "drawFull": true,
        "drawFromPosition": 0,
        "drawToPosition": 101,
        "name": "Left",
        "startPosition": 5.6,
        "extend": false
      },
      {
        "name": "Equator",
        "drawToPosition": 101,
        "startAlpha": 0.01,
        "extend": false,
        "startPosition": 50,
        "drawFromPosition": 0,
        "drawFull": true
      },
      {
        "drawFull": true,
        "endPosition": 94.4,
        "name": "Right",
        "startPosition": 94.4,
        "drawFromPosition": 0,
        "drawToPosition": 101
      }
    ],
    "drawBorder": true
  },
  // animate frosted glass (2/2) on top of the first background and remove somore arcs
  {
    "delayHorizontal": false,
    "horizontal": [
      {
        "name": "Bottom Mask Top",
        "drawDirection": 1,
        "endPosition": -25,
        "drawToPosition": 100,
        "drawFromPosition": 0,
        "startPosition": 50,
        "drawFull": true,
        "startAlpha": 0.01
      },
      {
        "drawFromPosition": 0,
        "startPosition": 12.5,
        "endAlpha": 0,
        "drawFull": true,
        "drawToPosition": 0,
        "startAlpha": 1,
        "extend": false,
        "delay": 500,
        "drawDirection": 1,
        "name": "Top"
      },
      {
        "name": "Equator",
        "drawDirection": 1,
        "startPosition": 50,
        "extend": false,
        "drawFull": true,
        "drawToPosition": 100,
        "drawFromPosition": 0
      },
      {
        "endAlpha": 0,
        "delay": 700,
        "drawDirection": 1,
        "drawFull": true,
        "name": "Bottom",
        "drawFromPosition": 0,
        "extend": false,
        "startAlpha": 1,
        "drawToPosition": 0,
        "startPosition": 75
      },
      {
        "drawFull": true,
        "startPosition": 100,
        "drawFromPosition": 0,
        "delay": 50,
        "name": "Bottom Mask Bottom",
        "endPosition": 0,
        "drawToPosition": 100,
        "startAlpha": 0.01
      },
      {
        "startPosition": 0,
        "endPosition": 100,
        "drawFull": true,
        "drawToPosition": 100,
        "drawFromPosition": 0,
        "startAlpha": 0.01,
        "delay": 150,
        "name": "Top Mask Top"
      },
      {
        "drawFull": true,
        "name": "Top Mask Bottom",
        "startPosition": 25,
        "drawToPosition": 100,
        "drawFromPosition": 0,
        "startAlpha": 0.01,
        "endPosition": 125
      }
    ],
    "vertical": [
      {
        "drawToPosition": 100,
        "extend": false,
        "name": "Left",
        "drawFull": true,
        "startAlpha": 0.01,
        "delay": 500,
        "drawFromPosition": 0,
        "startPosition": 5.6
      },
      {
        "drawFromPosition": 50,
        "drawToPosition": 0,
        "name": "center aux 1",
        "startPosition": 50,
        "drawFull": false
      },
      {
        "name": "center aux 2",
        "drawFull": false,
        "drawToPosition": 100,
        "drawFromPosition": 50,
        "startPosition": 50
      },
      {
        "drawFull": true,
        "startPosition": 50,
        "drawFromPosition": 0,
        "extend": false,
        "startAlpha": 0.01,
        "drawToPosition": 100,
        "delay": 500,
        "name": "Equator"
      },
      {
        "delay": 500,
        "name": "Right",
        "drawFromPosition": 0,
        "startAlpha": 0.01,
        "drawToPosition": 100,
        "drawFull": true,
        "startPosition": 94.4
      }
    ],
    "drawBorder": true,
    "insetBorder": false,
    "mask": [
      {
        "asset": BACKGROUND_1,
        "_colorFill": true,
        "vertical": [
          "Left Border",
          "Right Border"
        ],
        "name": "Background",
        "grid": 0,
        "polyOnly": false,
        "coverFill": true,
        "assetPositionWithMask": false,
        "horizontal": [
          "Top Border",
          "Bottom Border"
        ],
        "assetStartPosition": [
          0,
          0
        ]
      },
      {
        "name": "Blur Top",
        "coverFill": true,
        "asset": BACKGROUND_1,
        "container": "canvas",
        "horizontal": [
          "Top Mask Bottom",
          "Top Mask Top"
        ],
        "grid": 0,
        "filter": "blur",
        "vertical": [
          "Right",
          "Equator"
        ]
      },
      {
        "name": "Blur Bottom",
        "horizontal": [
          "Bottom Mask Top",
          "Bottom Mask Bottom"
        ],
        "coverFill": true,
        "grid": 0,
        "filter": "blur",
        "vertical": [
          "Left",
          "Equator"
        ],
        "container": "canvas",
        "asset": BACKGROUND_1
      }
    ],
    "id": 4,
    "duration": "1500",
    "circle": [
      {
        "drawAngle": 200,
        "clockwise": false,
        "collapse": true,
        "drawFromAngle": 90,
        "startBoundaryLineX": "Equator",
        "name": "left-small",
        "drawDirection": 1,
        "drawFull": true,
        "startLinePositionX": "Left",
        "startLinePositionY": "Bottom"
      },
      {
        "startBoundaryLineX": "Equator",
        "startPositionY": 50,
        "drawFromAngle": 270,
        "drawAngle": 360,
        "collapse": false,
        "name": "right-small",
        "drawFull": true,
        "clockwise": false,
        "startLinePositionX": "Right",
        "drawDirection": 1
      },
      {
        "collapse": true,
        "collapseAtEnd": false,
        "drawDirection": 1,
        "startPositionY": 0,
        "startPositionX": 5.5,
        "drawFromAngle": 360,
        "startBoundaryLineX": "Right",
        "drawFull": true,
        "clockwise": true,
        "drawAngle": 270,
        "duration": 750,
        "name": "top-left-large"
      },
      {
        "drawFromAngle": 90,
        "startLinePositionX": "Right",
        "name": "right-large",
        "startLinePositionY": "Equator",
        "collapse": false,
        "drawFull": true,
        "drawAngle": 180,
        "drawDirection": 1,
        "startBoundaryLineX": "Left",
        "clockwise": true
      },
      {
        "drawFromAngle": 100,
        "drawFull": true,
        "clockwise": false,
        "drawAngle": 190,
        "collapse": true,
        "drawDirection": 1,
        "name": "top-left-small",
        "duration": 500,
        "collapseAtEnd": false,
        "startPositionY": 0,
        "startBoundaryLineX": "Equator",
        "startPositionX": 5.5
      },
      {
        "collapse": true,
        "startLinePositionY": "Bottom",
        "drawFromAngle": 260,
        "drawAngle": 200,
        "name": "left-large",
        "startLinePositionX": "Left",
        "startBoundaryLineX": "Right",
        "clockwise": true,
        "duration": 1000,
        "drawFull": true,
        "drawDirection": 1
      }
    ]
  },
  // show the second background on top of the first background
  {
    "mask": [
      {
        "name": "Background",
        "asset": BACKGROUND_1,
        "assetStartPosition": [
          0,
          0
        ],
        "grid": 0,
        "polyOnly": false,
        "assetPositionWithMask": false,
        "vertical": [
          "Left Border",
          "Right Border"
        ],
        "horizontal": [
          "Top Border",
          "Bottom Border"
        ],
        "coverFill": true,
        "_colorFill": true
      },
      {
        "fadeIn": true,
        "asset": BACKGROUND_2,
        "vertical": [
          "Left Border",
          "Right Border"
        ],
        "horizontal": [
          "Bottom Border",
          "Bottom Quarter"
        ],
        "coverFill": true,
        "grid": 0,
        "name": "Bottom"
      },
      {
        "vertical": [
          "Left Border",
          "Right Border"
        ],
        "asset": BACKGROUND_2,
        "name": "Bottom Top Quarter",
        "horizontal": [
          "Bottom Quarter",
          "Equator"
        ],
        "fadeDelay": 150,
        "fadeIn": true,
        "grid": 0,
        "coverFill": true
      },
      {
        "name": "Top Bottom Quarter",
        "coverFill": true,
        "grid": 0,
        "asset": BACKGROUND_2,
        "fadeIn": true,
        "fadeDelay": 300,
        "horizontal": [
          "Equator",
          "Top Quarter"
        ],
        "vertical": [
          "Left Border",
          "Right Border"
        ]
      },
      {
        "horizontal": [
          "Top Border",
          "Top Quarter"
        ],
        "grid": 0,
        "coverFill": true,
        "asset": BACKGROUND_2,
        "fadeIn": true,
        "name": "Top",
        "vertical": [
          "Left Border",
          "Right Border"
        ],
        "fadeDelay": 450
      }
    ],
    "delayHorizontal": false,
    "vertical": [
      {
        "drawToPosition": 100,
        "delay": 500,
        "drawFull": true,
        "name": "Left",
        "startPosition": 5.6,
        "extend": false,
        "endPosition": 5.6,
        "startAlpha": 0.01,
        "drawFromPosition": 0
      },
      {
        "drawFull": true,
        "endPosition": 50,
        "startPosition": 50,
        "drawToPosition": 0,
        "drawFromPosition": 50,
        "name": "center aux 1"
      },
      {
        "endPosition": 50,
        "drawFromPosition": 50,
        "startPosition": 50,
        "name": "center aux 2",
        "drawFull": true,
        "drawToPosition": 100
      },
      {
        "drawToPosition": 100,
        "startAlpha": 0.01,
        "endPosition": 50,
        "drawFull": true,
        "startPosition": 50,
        "drawFromPosition": 0,
        "extend": false,
        "delay": 500,
        "name": "Equator"
      },
      {
        "delay": 500,
        "endPosition": 94.4,
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "startAlpha": 0.01,
        "name": "Right",
        "startPosition": 94.4
      }
    ],
    "duration": "1500",
    "insetBorder": false,
    "circle": [
      {
        "name": "right-small",
        "collapse": false,
        "startBoundaryLineX": "Equator",
        "drawFull": true,
        "clockwise": false,
        "startPositionY": 50,
        "drawFromAngle": 270,
        "drawDirection": 1,
        "drawAngle": 360,
        "startLinePositionX": "Right"
      },
      {
        "drawFull": true,
        "clockwise": true,
        "drawFromAngle": 90,
        "startBoundaryLineX": "Left",
        "name": "right-large",
        "drawDirection": 1,
        "drawAngle": 180,
        "collapse": false,
        "startLinePositionX": "Right",
        "startLinePositionY": "Equator"
      }
    ],
    "id": 5,
    "horizontal": [
      {
        "startAlpha": 1,
        "drawFull": true,
        "drawDirection": 1,
        "endPosition": 12.5,
        "delay": 500,
        "drawFromPosition": 0,
        "drawToPosition": 0,
        "extend": false,
        "endAlpha": 0,
        "startPosition": 12.5,
        "name": "Top"
      },
      {
        "drawFull": true,
        "startPosition": 25,
        "startAlpha": 0.01,
        "drawFromPosition": 0,
        "name": "Top Quarter",
        "drawToPosition": 100
      },
      {
        "startPosition": 50,
        "drawFromPosition": 0,
        "drawDirection": 1,
        "name": "Equator",
        "drawToPosition": 100,
        "extend": false,
        "endPosition": 50,
        "drawFull": true
      },
      {
        "name": "Bottom",
        "startPosition": 75,
        "drawDirection": 1,
        "extend": false,
        "startAlpha": 1,
        "drawFromPosition": 0,
        "endAlpha": 0,
        "drawFull": true,
        "drawToPosition": 0,
        "endPosition": 75,
        "delay": 700
      },
      {
        "startAlpha": 0.01,
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "name": "Bottom Quarter",
        "startPosition": 75
      }
    ],
    "drawBorder": true
  },
  // hold the second background
  {
    "id": 6,
    "delayHorizontal": false,
    "insetBorder": false,
    "horizontal": [
      {
        "drawToPosition": 0,
        "drawDirection": 1,
        "endAlpha": 0,
        "drawFromPosition": 0,
        "delay": 500,
        "drawFull": true,
        "startAlpha": 1,
        "startPosition": 12.5,
        "name": "Top",
        "extend": false
      },
      {
        "drawFromPosition": 0,
        "extend": false,
        "startPosition": 50,
        "name": "Equator",
        "delay": 800,
        "drawFull": true,
        "endPosition": 12.5,
        "drawToPosition": 100,
        "drawDirection": 1
      },
      {
        "delay": 700,
        "startAlpha": 1,
        "drawDirection": 1,
        "extend": false,
        "endAlpha": 0,
        "startPosition": 75,
        "name": "Bottom",
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 0
      },
      {
        "startPosition": 50,
        "drawFull": true,
        "endPosition": 75,
        "extend": false,
        "drawToPosition": 100,
        "drawFromPosition": 0,
        "name": "equator 2",
        "drawDirection": 1,
        "delay": 800
      }
    ],
    "delayVertical": false,
    "duration": 1000,
    "drawBorder": true,
    "mask": [
      {
        "horizontal": [
          "Top Border",
          "Bottom Border"
        ],
        "coverFill": true,
        "name": "Background",
        "vertical": [
          "Left Border",
          "Right Border"
        ],
        "grid": 0,
        "assetStartPosition": [
          50,
          "35"
        ],
        "asset": BACKGROUND_2
      }
    ],
    "circle": [
      {
        "startLinePositionY": "Bottom",
        "drawFromAngle": 90,
        "drawFull": true,
        "collapse": true,
        "clockwise": false,
        "drawDirection": 1,
        "name": "left-small",
        "startLinePositionX": "Left",
        "startBoundaryLineX": "Equator",
        "drawAngle": 0
      },
      {
        "name": "right-small",
        "collapse": true,
        "drawFromAngle": 270,
        "startPositionY": 50,
        "clockwise": false,
        "startLinePositionX": "Right",
        "drawDirection": 1,
        "drawAngle": 360,
        "startBoundaryLineX": "Equator",
        "drawFull": true
      },
      {
        "delay": 750,
        "startBoundaryLineX": "Right",
        "drawDirection": 1,
        "startPositionX": 5.5,
        "name": "top-left-large",
        "collapse": true,
        "duration": 750,
        "collapseAtEnd": false,
        "drawAngle": 0,
        "startPositionY": 0,
        "drawFromAngle": 180,
        "clockwise": true,
        "drawFull": true
      },
      {
        "drawFromAngle": 90,
        "collapse": true,
        "startBoundaryLineX": "Left",
        "drawAngle": 360,
        "drawDirection": 1,
        "name": "right-large",
        "drawFull": true,
        "startLinePositionX": "Right",
        "startLinePositionY": "Equator",
        "clockwise": true
      },
      {
        "startPositionX": 5.5,
        "collapse": true,
        "startPositionY": 0,
        "delay": 1500,
        "clockwise": false,
        "drawAngle": 0,
        "startBoundaryLineX": "Equator",
        "name": "top-left-small",
        "drawFull": true,
        "drawDirection": 1,
        "collapseAtEnd": false,
        "duration": 500,
        "drawFromAngle": 180
      },
      {
        "startBoundaryLineX": "Right",
        "drawFull": true,
        "collapse": true,
        "drawDirection": 1,
        "startLinePositionX": "Left",
        "drawFromAngle": 260,
        "startLinePositionY": "Bottom",
        "drawAngle": 0,
        "clockwise": true,
        "name": "left-large",
        "duration": 1000
      }
    ],
    "vertical": [
      {
        "extend": false,
        "drawDirection": -1,
        "drawToPosition": 0,
        "delay": 500,
        "startAlpha": 0,
        "startPosition": 5.6,
        "name": "Left",
        "drawFromPosition": 0,
        "drawFull": true,
        "endAlpha": 0
      },
      {
        "drawFromPosition": 0,
        "name": "center aux 1",
        "endPosition": 5.6,
        "drawFull": true,
        "drawToPosition": 100,
        "startPosition": 50
      },
      {
        "drawToPosition": 0,
        "name": "Equator",
        "startPosition": 50,
        "drawFull": true,
        "drawFromPosition": 0
      },
      {
        "drawFromPosition": 0,
        "endAlpha": 0,
        "drawFull": true,
        "name": "Right",
        "drawDirection": -1,
        "extend": false,
        "startAlpha": 1,
        "delay": 500,
        "startPosition": 94.4,
        "drawToPosition": 0
      },
      {
        "startPosition": 50,
        "drawFull": true,
        "drawToPosition": 100,
        "drawFromPosition": 0,
        "name": "center aux 2",
        "endPosition": 94.4
      }
    ]
  },
  // hide the second background
  {
    "mask": [
      {
        "coverFill": true,
        "horizontal": [
          "Top Border",
          "Bottom Border"
        ],
        "grid": 0,
        "vertical": [
          "center aux 1",
          "center aux 2"
        ],
        "asset": BACKGROUND_2,
        "name": "Center"
      },
      {
        "name": "left",
        "horizontal": [
          "Top Border",
          "Bottom Border"
        ],
        "asset": BACKGROUND_2,
        "fadeDelay": 500,
        "coverFill": true,
        "container": "canvas",
        "grid": 0,
        "vertical": [
          "Left Border",
          "Left"
        ],
        "fadeOut": true
      },
      {
        "name": "right",
        "fadeDelay": 500,
        "horizontal": [
          "Top Border",
          "Bottom Border"
        ],
        "container": "canvas",
        "asset": BACKGROUND_2,
        "fadeOut": true,
        "coverFill": true,
        "grid": 0,
        "vertical": [
          "Right Border",
          "Right"
        ]
      }
    ],
    "insetBorder": false,
    "vertical": [
      {
        "drawFull": true,
        "startPosition": 5.6,
        "name": "Left",
        "drawFromPosition": 0,
        "drawToPosition": 100
      },
      {
        "drawToPosition": 0,
        "drawFromPosition": 0,
        "drawFull": true,
        "startPosition": 50,
        "name": "Equator"
      },
      {
        "drawToPosition": 100,
        "name": "center aux 2",
        "drawFromPosition": 0,
        "startPosition": 94.4,
        "endPosition": 50,
        "drawFull": true
      },
      {
        "drawFull": true,
        "drawToPosition": 100,
        "startPosition": 5.6,
        "name": "center aux 1",
        "endPosition": 50,
        "drawFromPosition": 0
      },
      {
        "drawFromPosition": 0,
        "drawFull": true,
        "drawToPosition": 100,
        "startPosition": 94.4,
        "name": "Right"
      }
    ],
    "id": 7,
    "circle": [
      {
        "startLinePositionY": "Bottom",
        "drawDirection": 1,
        "clockwise": false,
        "drawAngle": 0,
        "collapse": true,
        "drawFull": true,
        "name": "left-small",
        "startLinePositionX": "Left",
        "drawFromAngle": 90,
        "startBoundaryLineX": "Equator"
      },
      {
        "collapse": true,
        "startBoundaryLineX": "Equator",
        "clockwise": false,
        "drawDirection": 1,
        "drawFull": true,
        "drawFromAngle": 270,
        "startLinePositionX": "Right",
        "drawAngle": 0,
        "name": "right-small",
        "startPositionY": 50
      },
      {
        "duration": 750,
        "startPositionX": 5.5,
        "name": "top-left-large",
        "collapse": true,
        "drawFull": true,
        "drawFromAngle": 180,
        "clockwise": true,
        "collapseAtEnd": false,
        "drawDirection": 1,
        "drawAngle": 0,
        "startBoundaryLineX": "Right",
        "startPositionY": 0,
        "delay": 750
      },
      {
        "drawDirection": 1,
        "clockwise": true,
        "drawAngle": 0,
        "startLinePositionY": "Equator",
        "drawFull": true,
        "name": "right-large",
        "startLinePositionX": "Right",
        "collapse": true,
        "startBoundaryLineX": "Left",
        "drawFromAngle": 90
      },
      {
        "duration": 500,
        "drawAngle": 0,
        "startPositionY": 0,
        "name": "top-left-small",
        "startPositionX": 5.5,
        "delay": 1500,
        "collapse": true,
        "drawFromAngle": 180,
        "drawFull": true,
        "collapseAtEnd": false,
        "clockwise": false,
        "startBoundaryLineX": "Equator",
        "drawDirection": 1
      },
      {
        "duration": 1000,
        "startBoundaryLineX": "Right",
        "name": "left-large",
        "clockwise": true,
        "collapse": true,
        "startLinePositionX": "Left",
        "drawFromAngle": 260,
        "drawAngle": 0,
        "drawFull": true,
        "drawDirection": 1,
        "startLinePositionY": "Bottom"
      }
    ],
    "horizontal": [
      {
        "startPosition": 12.5,
        "endAlpha": 0,
        "extend": false,
        "startAlpha": 1,
        "drawFull": true,
        "drawDirection": 1,
        "name": "Top",
        "drawFromPosition": 0,
        "delay": 500,
        "drawToPosition": 0
      },
      {
        "startPosition": 75,
        "extend": false,
        "drawFromPosition": 0,
        "endPosition": 50,
        "drawToPosition": 100,
        "drawDirection": 1,
        "name": "equator 2",
        "drawFull": true
      },
      {
        "drawToPosition": 100,
        "endPosition": 50,
        "extend": false,
        "startPosition": 12.5,
        "drawFull": true,
        "drawFromPosition": 0,
        "drawDirection": 1,
        "name": "Equator"
      },
      {
        "drawDirection": 1,
        "drawFull": true,
        "drawToPosition": 0,
        "delay": 700,
        "startPosition": 75,
        "drawFromPosition": 0,
        "name": "Bottom",
        "startAlpha": 1,
        "extend": false,
        "endAlpha": 0
      }
    ],
    "duration": 1000,
    "drawBorder": true
  }
]
