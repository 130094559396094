import {countDown, MediaType, toTitleCase} from "../shared";
import {fetchDrops, SNKRS_CAL_CONFIG} from "../../hoi/animations/snkrs-calendar/js/dataService";
import {JordanConfig} from "./ChapterRunner";
import {DropInterface, getPlayArray, SnkrsCalProps} from "../screens/SnkrsCal";

export const BG_COLOR = 'rgb(241,242,240)';
export const DEFAULT_PLAY_RANGE = '0,5';
export const MAIN_SCREEN_DURATION = 3;
export const SNKR_DETAIL_DURATION = 10;
const snkrsIcon = new URL('../assets/images/snkrs-app-icon.png?as=webp&width=120', import.meta.url);
export const SNKRS_URL = `https://www.nike.com/launch`;
export type SnkrsCalMode = 'drops' | 'stories';

export function loadDrops({marketplace = 'US', play_range = DEFAULT_PLAY_RANGE, locale = 'US'}: {marketplace?: string, play_range?: string, locale?: string}) {
    const config = SNKRS_CAL_CONFIG[marketplace];

    return new Promise((resolve, reject) => {
        fetchDrops({
            inStock: "true",
            onlyJordan: true,
            ...config,
            onComplete: ({drops, inStockMode}) => {
                const hydrated = drops.map((drop) => {
                    return {
                        ...drop,
                        colorDescription: drop.colorDescription.split('/').slice(0, 2).join(' / '),
                        url: config?.launchUrl ? `${config?.launchUrl}${drop.urlSlug}` : undefined,
                        date_label: dateFormat(drop, {locale}),
                        key: drop.key,
                        images: drop.images.map((image): MediaType => ({
                            resource_type: 'image',
                            url: image.url,
                        }))
                    } as DropInterface;
                });

                const filtered = hydrated.filter(({brand}) => brand?.toUpperCase() === 'JORDAN');
                const results = filtered.length < 6 ? hydrated : filtered;
                const deDuped = results.reduce((acc, curr) => {
                    const drop = acc.filter(i => i.key === curr.key).length > 0 ? [] : [curr];

                    return [
                        ...acc,
                        ...drop
                    ]
                }, []);
                resolve(deDuped.slice(0, 6));
                bubbleUpDrops(deDuped, config);
            }
        });
    });
}

export function bubbleUpDrops(drops, config) {
    window.parent.postMessage({
        type: 'drops_loaded',
        drops,
        config,
    }, '*');
}

export function getDuration({play_range = DEFAULT_PLAY_RANGE, drops, mode,}: SnkrsCalProps, config: JordanConfig) {
    const {snkrs} = config.duration;

    return getPlayArray(play_range).reduce((acc, a, i) => {
        const specificDuration = mode === 'stories' ? snkrs.story : snkrs.drop;
        return acc + snkrs.overview + specificDuration + 1_000;
    }, 0) + snkrs.overview;
}

export function dateFormat(drop, {
    format = 'simple',
    locale = 'US',
    available_copy = 'Released'
}: { format?: 'simple' | 'complex', locale?: string, available_copy?: string } = {}) {
    if (drop?.date_label?.trim()) {
        return drop.date_label;
    }

    if (!drop?.startEntryDate || !drop?.startEntryDate?.getTime || isNaN(drop.startEntryDate)) {
        return '';
    }
    const now = new Date();
    const diffTime = Math.abs(now.getTime() - drop.startEntryDate.getTime());
    const diffDays = diffTime / (1000 * 3600 * 24);

    if (format === 'complex') {
        if (diffDays < 1) {
            return countDown(drop.startEntryDate);
        }

        if (diffDays < 8) {
            return drop.startEntryDate.toLocaleDateString(locale, {weekday: 'long'})
        }
    }

    const dirty = new Intl.DateTimeFormat(locale, {dateStyle: 'long'}).format(drop.startEntryDate);
    const formatted = dirty.replace(drop.startEntryDate.getFullYear(), '').trimEnd().replace(/,(\s+)?$/, '');
    const titleCased = toTitleCase(formatted);

    if (drop.startEntryDate <= now) {
        return `${available_copy} ${titleCased}`;
    }

    return titleCased;
}