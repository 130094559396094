import Vitals from './src/Vitals';
import { TOUCHPOINT_TYPES } from './src/constants';

export class ChannelVitals extends Vitals {
	constructor(params) {
		super({
			...params,
			type: TOUCHPOINT_TYPES.CHANNEL,
		});
	}
}

export class PixelMapVitals extends Vitals {
	constructor(params) {
		super({
			...params,
			type: TOUCHPOINT_TYPES.PIXEL_MAP,
		});
	}
}

export class InsideTrackVitals extends Vitals {
	constructor(params) {
		super({
			...params,
			type: TOUCHPOINT_TYPES.INSIDE_TRACK,
		});
	}
}

export class GVCVitals extends Vitals {
	constructor(params) {
		super({
			...params,
			type: TOUCHPOINT_TYPES.GVC,
		});
	}
}
