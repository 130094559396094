import { Codification } from '../../../../rise/components';
import { CSSProperties, useState } from 'react';

import useFitText from '../../../hooks/useFitText';
import TextReveal from '../../../components/text-reveal/text-releal';


interface TypographyProps {
  text: string;
  reverse?: boolean;
  start?: boolean;
  primary?: boolean;
  codificationSpeed?: 'fast' | 'normal';
  typographyStyle?: CSSProperties,
  instant?: boolean;
  disableCodification?: boolean;
  style?: CSSProperties;
  delay?: number;
}

export default function Typography({
                                     text,
                                     primary = true,
                                     reverse = false,
                                     start = true,
                                     disableCodification = false,
                                     codificationSpeed,
                                     instant,
                                     delay = 1,
                                     typographyStyle,
                                     style,
                                   }: TypographyProps) {
  // ignore typescript errors from this point on
  const Component = Codification as any;

  const [element, setElement] = useState<HTMLElement>(null);
  useFitText(element);

  const characterTimeout = codificationSpeed === 'fast' ? 8 : 20;

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {
        disableCodification ? (
          <TextReveal
            style={style}
            content={text}
            instant={instant}
            delay={delay}
          />
        ) : (
          <Component
            ref={node => setElement(node)}
            text={text}
            typography={{
              primary,
              tag: 'span',
              typeStyle: typographyStyle,
            }}
            characterSwitchAmount={4}
            characterNextTrigger={2}
            characterTimeout={characterTimeout}
            start={start}
            reverse={reverse}
            toNotBreakWord
          />
        )
      }

    </div>
  );
}
