import { isEmpty, isUndefined, includes, isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
var isBetween = require('dayjs/plugin/isBetween');
var advancedFormat = require('dayjs/plugin/advancedFormat');
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(advancedFormat);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);

import { fetchEvents } from './js/dataService';
import NSILSchedule from './NSILSchedule';
import { getChannel } from '../../../rise/api';
import NsilScheduleV2 from "./NsilScheduleV2";

export default function App({ data, channel, testing }) {
  const { title, supertitle, status, air_table } = useMemo(() => {
    return {
      title: data?.title ?? 'OPEN',
      supertitle: data?.supertitle ?? 'ALL DAY',
      status: data?.status ?? 'default',
      air_table: data?.air_table ?? false,
    };
  }, [data]);

  const [offset, setOffset] = useState(null);
  const [eventData, setEventData] = useState([]);
  const [currentEvent, setCurrentEvent] = useState({});
  const [showUpcoming, setShowUpcoming] = useState(true);
  const [modTitle, setModTitle] = useState(title);
  const [modSupertitle, setModSupertitle] = useState(supertitle);
  const [modStatus, setModStatus] = useState(status);
  const [channelData, setChannelData] = useState({});
  const [roomName, setRoomName] = useState('Court 5');

  let refreshPage = null;

  const now = dayjs().tz('America/Los_Angeles');

  // loads the data
  useEffect(() => {
    if (air_table) {
      loadChannel();
      loadEvents(offset);
    }
  }, []);

  // when the channel data is loaded
  // set the room name and refresh the events
  useEffect(() => {
    if (!isEmpty(channelData)) {
      setRoomName(channelData.channels[0].json_config.room_name);
      const refreshEvents = setInterval(() => {
        setEventData([]);
        setOffset(null);
        loadEvents(null);
      }, channelData.channels[0].json_config.refresh_minutes * 60000);

      return () => {
        clearInterval(refreshEvents);
      };
    }
  }, [channelData]);

  // when the offset is updated
  // load the events
  useEffect(() => {
    if (offset) {
      loadEvents(offset);
    }
  }, [offset]);

  // when the event data is updated
  // find the current event
  useEffect(() => {
    if (!isNil(refreshPage)) clearInterval(refreshPage);
    refreshPage = setInterval(() => {
      findCurrentEvent();
    }, 60000);

    return () => {
      clearInterval(refreshPage);
    };
  }, [eventData]);

  // loads the events
  async function loadEvents(offset = null) {
    fetchEvents(offset, testing).then(response => {
      let temp = [];
      if (!isNil(offset)) temp = eventData;
      temp.push(...response.records);
      setEventData(temp);
      findCurrentEvent();
      if (response.offset) setOffset(response.offset);
    });
  }

  // loads the channel data
  async function loadChannel() {
    const temp = await getChannel(channel);
    setChannelData(temp);
  }

  // finds the current event
  function findCurrentEvent() {
    if (air_table) {
      let eventsToday = [];
      let roomEvents = [];
      let bookedEvents = [];

      bookedEvents = eventData.filter(event => {
        if (!isUndefined(event.fields['Status'])) {
          return event.fields['Status'].match(/Booked/gm);
        }
      });

      roomEvents = bookedEvents.filter(event => {
        if (!isUndefined(event.fields['Space Name'])) {
          return event.fields['Space Name'].find(element => {
            return includes(element, roomName);
          });
        }
      });

      if (roomEvents.length > 0) {
        eventsToday = roomEvents.filter(event => {
          if (
            !isUndefined(event.fields['Event Start']) &&
            !isUndefined(event.fields['Event End'])
          ) {
            return (
              now.isSameOrAfter(
                dayjs(event.fields['Event Start'])
                  .tz('America/Los_Angeles')
                  .subtract(5, 'minute'),
              ) &&
              now.isSameOrBefore(
                dayjs(event.fields['Event End'])
                  .tz('America/Los_Angeles')
                  .add(5, 'minute'),
              )
            );
          } else {
            return false;
          }
        });
      }

      if (eventsToday.length > 0) {
        setShowUpcoming(false);
        setCurrentEvent(eventsToday[0]);
        setModStatus('quiet');
        return;
      }

      if (isEmpty(currentEvent)) {
        setShowUpcoming(true);
        setModStatus('open');
        // setModSupertitle('');
        // setModTitle('OPEN');
      }
    }
  }

  let words = [];
  let characters = 0;
  if (!isEmpty(currentEvent)) {
    words = currentEvent?.fields['Team Name']?.split(' ');
    characters = currentEvent?.fields['Team Name'].length;
  }
  let fontTest = false;
  if (words.length > 0) fontTest = words.find(word => word.length > 8);
  if (characters > 30) fontTest = true;

  const poc =
    !isEmpty(currentEvent) &&
    !isEmpty(currentEvent?.fields?.['Point of Contact'])
      ? currentEvent?.fields?.['Point of Contact']
      : '';

  const superTitleProp = !isEmpty(currentEvent) &&
  !isUndefined(currentEvent.fields['Event Start']) &&
  !isUndefined(currentEvent.fields['Event End'])
    ? `${dayjs(currentEvent.fields['Event Start'])
      .tz('America/Los_Angeles')
      .format('h:mmA')} — ${dayjs(currentEvent.fields['Event End'])
      .tz('America/Los_Angeles')
      .format('h:mmA')} \n ${poc}`
    : modSupertitle;

  const titleProp = !isEmpty(currentEvent) ? currentEvent.fields['Team Name'] : modTitle;

  const statusProp = !isEmpty(currentEvent) &&
  !isUndefined(currentEvent.fields['Activity Monitor Status'])
    ? currentEvent.fields['Activity Monitor Status']
      .trim()
      .toLowerCase()
      .replace(/\s/gm, '_')
    : modStatus;

  return (
    <NsilScheduleV2
      backgroundMedia={data?.background_media}
      lockup={data?.lockup}
      supertitle={superTitleProp}
      title={titleProp}
      status={statusProp}
      showUpcoming={showUpcoming ? 'no-event' : 'current-event'}
      currentMonth={now.format('MMMM')}
      currentDate={now.format('Do')}
      smallFont={fontTest}
      theme={data?.theme}
    />
  )

  return (
    <div className="NSILSchedule">
      <NSILSchedule
        supertitle={superTitleProp}
        title={titleProp}
        status={statusProp}
        showUpcoming={showUpcoming ? 'no-event' : 'current-event'}
        currentMonth={now.format('MMMM')}
        currentDate={now.format('Do')}
        smallFont={fontTest}
      />
    </div>
  );
}
