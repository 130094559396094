import {MediaType} from "../jordan/shared";
import {getCombined, getGlobalChapters} from "./api";
import {WeatherApi} from "../../utils/weather";
import {get} from "lodash";

export const eventType = ["local_event", "nrc", "ntc"] as const;
export type EventType = (typeof eventType)[number];

export function useCity() {
    return get(window, 'animation_store.json_meta.city_display_name', '');
}

export interface Chapter {
    // pre_index is the index of the chapter before the intro was added
    pre_index?: number;
    template: string;
    pillar?: PillarType;
    details?: [string, string];
    event_type?: EventType | string;
    duration?: number;
    entries?: Array<Chapter>;
    title?: string;
    subtitle?: string;
    tags?: Array<string>
    cta?: string;
    source?: 'api' | 'authored' | 'generated';

    pillar_color?: PillarColor;
    media?: Array<MediaType>;

    bottom_section?: {
        title: string;
        super_title: string;
        media: Array<MediaType>
    };

    [x: string | number | symbol]: unknown;
}

export interface AnimationData {
    chapters: Array<Chapter>;
}

export const pillars = ["mindfulness", "movement", "rest", "connection", "nutrition"] as const;
export type PillarType = (typeof pillars)[number];

export interface PillarColor {
    name: PillarType;
    color: string;
    gradient: Array<string>;
}

export const pillarColors: Array<PillarColor> = [
    {
        name: "mindfulness",
        color: "#E6FE52",
        gradient: ["#0045c3", "#3ba234", "#b8af84"],
    },
    {
        name: "movement",
        color: "#00FF00",
        gradient: ["rgb(151,133,160)", "rgb(226,53,0)", "rgb(102,83,56)"],
    },
    {
        name: "rest",
        color: "#D9FDA8",
        gradient: ["rgb(0,143,49)", "rgb(0,128,128)", "rgb(170,134,178)"],
    },
    {
        name: "connection",
        color: "#FF5A0E",
        gradient: ["rgb(118,22,121)", "rgb(216,143,44)", "rgb(211,188,137)"],
    },
    {
        name: "nutrition",
        color: "#0025FF",
        gradient: ["rgb(232,91,0)", "rgb(182,194,128)", "rgb(192,170,0)"],
    },
];

export async function prepareData(chapters: AnimationData, {excludeIntros = false, globalChannel}: {
    excludeIntros: boolean
    globalChannel?: string
}): Promise<AnimationData> {
    const query = new URLSearchParams(window.location.search);
    const app = query.get('app');
    const startAt = Number(query.get('start_at') ?? 0);
    const isPlayingInternally = query.has('chapter');
    const endAt = isPlayingInternally ? undefined : Number(query.get('end_at') ?? 6);
    const tags = String(query.get('tags') ?? '').split(',').filter(i => i.length > 0);
    const limit = Number(query.get('dynamic_limit') ?? 100);
    excludeIntros = excludeIntros ? true : query.get('skip_intro') === 'true';

    if (app && app !== "none") {
        chapters.chapters = [];
    }

    // fetch data from api
    const apiData = query.get('include-app') === 'true' ? await getCombined(app, limit) : [];
    const globalData = globalChannel ? await getGlobalChapters(globalChannel) : [];

    let entries = [
        ...chapters.chapters,
        ...globalData.filter((entry: Chapter) => {
            // entry.tags is array string check if tags is a subset of entry.tags
            console.log(entry.tags, tags);

            if (tags.length > 0) {
                return tags.every((tag) => {
                    return entry.tags?.includes(tag);
                });
            }
            return true;
        }),
        ...apiData,
    ];

    chapters.chapters = entries.slice(startAt, endAt).map((event) => {
        if (event.template === "athlete") {
            return {
                ...event,
                template: 'raw_event',
            }
        }
        return event;
    });

    // we add an intro chapter for every 3 chapters
    const chaptersWithIntro = chapters.chapters.reduce((acc, chapter, index) => {
        if (index % 3 === 0 && !excludeIntros) {
            acc.push({
                pre_index: index,
                template: "intro",
                entries: chapters.chapters.slice(index, index + 3),
                duration: 8,
                source: 'generated',
            });
        }
        acc.push({...chapter, pre_index: index, duration: 12});
        return acc;
    }, [] as Array<Chapter>);

    return {chapters: chaptersWithIntro};
}

export function getFullEventType(eventType: EventType | undefined): string | undefined {
    return {
        local_event: "Local Event",
        nrc: "Nike Run Club",
        ntc: "Nike Training Club",
    }?.[eventType];
}

export const transitions = {
    slide: {
        initial: {
            opacity: 0,
            x: 100,
        },
        animate: {
            opacity: 1,
            x: 0,
        },
        exit: {
            opacity: 0,
            x: -100,
        }
    },
    slideIn: {
        initial: {
            opacity: 0,
            x: 100,
        },
        animate: {
            opacity: 1,
            x: 0,
        },
        exit: {
            opacity: 0,
            x: 0,
        }
    },
};

export function isDuration(str: string) {
    // contains a number followed by space and then "min(s)", "minute(s)" or "hour(s)"
    return str.match(/(\d+)(?:\s+)(min(?:ute)?s?|hour(?:s)?)/);
}