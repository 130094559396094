import {WebGLRenderer} from 'three';

if (module.hot) {
  module.hot.accept(() => {
    location.reload()
  })
}

import {Pane} from 'tweakpane';
import {debounce} from 'lodash';

import LookBuild from './chapters/look-build/look-build-app';
import PDPFullBody from './chapters/pdp-full-body/pdp-full-body-app';
import PDPHeadToe from './chapters/pdp-head-toe/pdp-head-toe-app';
import {dispatchRestart, pixelMapReady} from "../../../../utilities";
import {shouldPlayTakeover} from "../../../rise/animations/fastlane-kids-takeover/shared";

export default function (datas, demo = false) {
  console.log(datas);
  if (!datas.chapters) {
    return;
  }

  const apps = [];
  let appIndex = 0;
  let running = false;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const showGUI = urlParams.get('showGUI');
  const noAutoStart = urlParams.get('noAutoStart');
  const chapter = urlParams.get('chapter');
  const keyframe = urlParams.get('keyframe');

  window.SIZE_ID = (Math.min(window.innerWidth, window.innerHeight) > 719)
    ? 'LARGE'
    : (Math.min(window.innerWidth, window.innerHeight) > 479)
      ? 'MEDIUM'
      : 'SMALL'

  window.ORIENTATION = (window.innerHeight > window.innerWidth)
    ? 'PORTRAIT'
    : 'LANDSCAPE'

  document.body.style.background = 'black';

  const activeApps = {
    'look_book': !chapter || (chapter === 'look_book'),
    'pdp-full-body': !chapter || (chapter === 'pdp-full-body'),
    'pdp-head-toe': !chapter || (chapter === 'pdp-head-toe'),
  };
  const gui = new Pane({
    title: 'LLS Digital Mannequin',
    container: document.querySelector('.LLS-gui')
  });
  gui.hidden = !showGUI;

  gui.addButton({title: 'start', label: 'start (shift + space)'}).on('click', (e) => {
    appIndex = 0;
    apps[appIndex].start(keyframe);
    if (apps[appIndex].gui) {
      apps[appIndex].gui.hidden = !showGUI;
    }
  });
  gui.addButton({title: 'play', label: 'play (space)'}).on('click', (e) => {
    apps[appIndex].resume();
  });
  gui.addButton({title: 'pause', label: 'pause (space)'}).on('click', (e) => {
    apps[appIndex].pause();
  });

  const guiF1 = gui.addFolder({
    title: 'chapters list',
    expanded: demo
  });

  function addApp(name, app) {
    activeApps[name] && apps.push(app);
    guiF1.addInput(activeApps, name).on('change', (e) => {
      if (e.value) {
        apps.push(app);
      } else {
        const index = apps.indexOf(app);
        if (index !== -1) {
          apps.splice(index, 1);
        }
      }
    });
  }

  function nextApp() {
    if (apps[appIndex]) {
      apps[appIndex].wrapper?.classList.remove('active');
      apps[appIndex].stop();
    }
    appIndex = ((appIndex + 1) % apps.length);
    apps[appIndex].wrapper?.classList.add('active');
    apps[appIndex].start(keyframe);
  }

  if (datas.chapters.look_book
    && datas.chapters.look_book['1_landscape']?.assets?.length
    && datas.chapters.look_book['1_portrait']?.assets?.length
    && datas.chapters.look_book['2_portrait']?.assets?.length
    && datas.chapters.look_book['2_landscape']?.assets?.length
  ) {
    const canvas = document.createElement('canvas');
    canvas.classList.add('LLS-canvas');
    canvas.classList.add('legacy');
    document.body.appendChild(canvas);

    const renderer = new WebGLRenderer({canvas, antialias: true});
    renderer.setSize(canvas.clientWidth, canvas.clientHeight, false);
    renderer.setPixelRatio(window.devicePixelRatio);

    const lookBuild = new LookBuild(datas.chapters.look_book, canvas, renderer, nextApp, 'LOOKBOOK', gui);
    addApp('look_book', lookBuild);
  }
  if (!datas.chapters.full_body?.disabled && datas.chapters.full_body?.assets?.length) {
    const pdpFullBody = new PDPFullBody('PDP-FULL-BODY', datas.chapters.full_body, nextApp);
    addApp('pdp-full-body', pdpFullBody);
  }
  if (!datas.chapters.head_to_toe?.disabled && datas.chapters.head_to_toe?.assets?.length) {
    const pdpHeadToe = new PDPHeadToe('PDP-HEAD-TOE', datas, datas.chapters.head_to_toe, nextApp);
    addApp('pdp-head-toe', pdpHeadToe);
  }

  if (!datas.chapters.head_to_toe_2?.disabled && datas.chapters.head_to_toe_2?.assets?.length) {
    const pdpHeadToe = new PDPHeadToe('PDP-HEAD-TOE-2', datas, datas.chapters.head_to_toe_2, nextApp);
    addApp('pdp-head-toe', pdpHeadToe);
  }
  if (!datas.chapters.head_to_toe_3?.disabled && datas.chapters.head_to_toe_3?.assets?.length) {
    const pdpHeadToe = new PDPHeadToe('PDP-HEAD-TOE-3', datas, datas.chapters.head_to_toe_3, nextApp);
    addApp('pdp-head-toe', pdpHeadToe);
  }
  if (!datas.chapters.head_to_toe_4?.disabled && datas.chapters.head_to_toe_4?.assets?.length) {
    const pdpHeadToe = new PDPHeadToe('PDP-HEAD-TOE-4', datas, datas.chapters.head_to_toe_4, nextApp);
    addApp('pdp-head-toe', pdpHeadToe);
  }

  window.addEventListener('keypress', (e) => {
    if (e.code === 'Space') {
      if (e.shiftKey) {
        if (apps[appIndex].running) {
          apps[appIndex].stop();
        }
        appIndex = 0;
        if (apps[appIndex].gui) {
          apps[appIndex].gui.hidden = !apps[appIndex].gui.hidden;
        }
        apps[appIndex].start(keyframe);
      } else {
        if (apps[appIndex].running) {
          apps[appIndex].pause();
        } else {
          apps[appIndex].resume();
        }
      }
    } else if (e.code === 'KeyH') {
      gui.hidden = !gui.hidden;
      if (apps[appIndex].gui) {
        apps[appIndex].gui.hidden = !apps[appIndex].gui.hidden;
      }
    }
  }, false);

  function resetApps() {
    location.reload();
  }

  window.addEventListener('resize', debounce(resetApps, 500, {trailing: true, leading: false}), false);

  // window.dispatchEvent(new CustomEvent('animation_ready'));


  const sync = Number(urlParams.get('sync'));

  pixelMapReady(() => {
    if (!sync) {
      console.log('[EVENT] sync')
      apps[0].start(0);
    }
  });

  if (sync > 0) {
    shouldPlayTakeover(sync, () => {
      console.log('[URL] sync')
      apps[0].start(0);
    }, 2);
  }

  if (noAutoStart || !apps[appIndex]) {
    return;
  }

  apps[appIndex].start(keyframe)
};
