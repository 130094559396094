import React, { useMemo, useState, useEffect, CSSProperties } from 'react';
import { Perf } from 'r3f-perf';
import './style.scss';
import { TickerData } from './ticker/type';
import { calcTicker } from './ticker/shared';
import Ticker from './ticker/Ticker';
import CircleTicker from './ticker/CircleTicker';
import { Canvas } from '@react-three/fiber';

export let animate: boolean = true;
export let vDirection: number = 1;
export const clearAnimate = () => {
  animate = false;
};

type Props = {
  data: TickerData,
  style?: CSSProperties,
}

export default function CoreTicker({
  data,
  style = {},
}: Props) {
  const type = data?.type ?? 'normal';
  const [showPerf] = useState(false);
  const perf = useMemo(() => <Perf />, []);
  const [ticker, setTicker] = useState<JSX.Element>();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const origin = urlParams.get('origin');

  if ((type !== 'circle' || origin === 'pixel-map') && !data.ignore_pixelmap) {
    const height = Number(urlParams.get('height') ?? 48);
    const width = Number(urlParams.get('width') ?? 3_840);
    const isInternalWrapping = urlParams.get('wrapping-style') === 'internal';
    const screenWidth = Number(
      isInternalWrapping ? urlParams.get('screen-width') : width,
    );

    data.height = height;
    data.width = screenWidth;
    data.canvas_width = width;
  }

  const wrap = data.canvas_width > data.width;
  const rows = Math.ceil((data.canvas_width || data.width) / data.width);
  const height = type === 'circle' ? data.canvas_height || data.height : data.height;

  useEffect(() => {
    const interval = setInterval(() => {
      vDirection = vDirection * -1;
      animate = !animate;
    }, 5000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const calcContent = async () => {
      const content = await calcTicker(data);
      const ticker = (
        <Canvas
          gl={{ antialias: true }}
          flat={true}
          dpr={[1, 2]}
          orthographic
          linear={true}
          camera={{
            zoom: 1,
            position: [0, 0, 200],
            near: 0.1,
            far: 10000,
          }}
          style={{
            background: data.background,
            position: 'fixed',
            top: 0,
            left: 0,
            width: data.width,
            height: wrap ? height * rows : height,
            ...style,
          }}
        >
          {type === 'circle' ? (
            <CircleTicker key="circle-ticker" data={data} />
          ) : (
            <>
              {Object.keys(content).map((key, i) => {
                return (
                  <Ticker
                    key={`ticker-${i}`}
                    data={data}
                    wrap={wrap}
                    rows={rows}
                    content={content[key]}
                    vScrollDirection={i % 2 ? -1 : 1}
                    multiLanguage={Object.keys(content).length > 1}
                  />
                );
              })}
            </>
          )}
          {showPerf && perf}
        </Canvas>
      );
      setTicker(val => ticker);
    };
    calcContent();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ticker;
}
