// handles hijacking the takeover essentially preventing it from being
// bubbled up to the overarching takeover player

const HIJACK_EVENT_KEY = 'store_takeover_hijack';

export function hijackTakeover() {
  const event = new CustomEvent(HIJACK_EVENT_KEY);

  window.dispatchEvent(event);
}

export function listenForHijack(callback: Function) {
  const action = () => {
    callback();
  };

  window.addEventListener(HIJACK_EVENT_KEY, action);

  return () => {
    window.removeEventListener(HIJACK_EVENT_KEY, action);
  };
}