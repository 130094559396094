import classes from "../screens/Styles.module.scss";
import Typography from "./Typography";
import Transition from "./Transition";
import {stringIsFilled} from "../shared";
import React, {CSSProperties, useMemo} from "react";

interface TextSectionProps {
    headline: string;
    eyebrow: string;
    body: string;
    color: string;
    contentWidth: number;
    style?: CSSProperties
}

export default function TextSection({headline, eyebrow, body, color, contentWidth, style}: TextSectionProps) {
    const isLongTitle = useMemo(() => {
        return headline.includes('\n') || headline.length > 14;
    }, [headline]);

    return (
        <div
            style={{
                top: '1080px',
                width: contentWidth,
                overflowX: 'hidden',
                overflowY: 'visible',
                minHeight: 600,
                marginTop: '40px',
                position: 'absolute',
                zIndex: 11,
                ...style,
            }}
            className={classes.removeScrollbar}
        >
            <div style={{width: 'inherit'}}>
                <Typography
                    color={color}
                    variant={'jordan'}
                    size={32}
                    style={{
                        fontWeight: 600,
                        marginBottom: '30px',
                        minHeight: 32,
                    }}
                >
                    {eyebrow}
                </Typography>

                <Transition variant={'wipe-x'} delay={1} duration={1} style={{marginLeft: 0}}>
                    <Typography
                        color={color === 'near-black' ? 'black' : color}
                        variant={'jordan-condensed'}
                        size={isLongTitle ? 180 : 200}
                        uppercase
                        style={{
                            // maxWidth: 600
                            marginTop: -5,
                            paddingTop: 5
                        }}
                    >
                        {headline}
                    </Typography>
                </Transition>

            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '28px'}}>

                {/* Membership */}
                <div style={{width: '550px'}}>
                    {
                        stringIsFilled(body) && (
                            <Typography
                                variant={'pp-ultralight'}
                                size={42}
                                color={color}
                                style={{
                                    lineHeight: '120%'
                                }}
                            >
                                {body}
                            </Typography>
                        )
                    }
                </div>
            </div>
        </div>
    )
}