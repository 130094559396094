import ReactDOM from 'react-dom/client';
import App from './app';
import { mergeChapters } from '/src/touchpoints/rise/hyperlive/utils';
import {channelConfig} from "../../shared";
const {json_data} = mergeChapters();

const params = new URLSearchParams(window.location.search)
const chapter = params.get('chapter');
const openChapter = ["", null, undefined].includes(chapter) ? null : chapter;
const playOnceParam = params.get('playOnce');
const playOnce = playOnceParam === 'true';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App config={channelConfig({forceAssetHeight: true})} openChapter={openChapter} playOnce={playOnce} data={json_data} />);
