import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion, TargetAndTransition  } from 'framer-motion';
import classNames from 'classnames';

import * as cssClassNames from '../styles/screens/TitleCard.module.scss';

import { DEFAULT_DURATION_S } from '../constants/transition';
import { DEFAULT_EASE, MultiLangText, themeToCSS, ThemeType } from '../shared';
import CodificationWithTranslations, {
    calculateDuration as calculateCodificationDuration,
    TranslationCodificationProps,
} from './Codification/CodificationWithTranslations';
import Grid from './Grid';


const codificationProps: TranslationCodificationProps = {
    characterSwitchAmount: 4,
    characterNextTrigger: 2,
    timingConfig: {
        control: 'character',
        duration: 0.03,
    },
};
const languageTransitionDelay = 0.25;

type TitleTextProps = {
    text: MultiLangText,
    languages: string[],
    langIndex: number,
    cellLength: number,
    cols: number,
    rows: number,
    gridLineThickness: number,
    theme: ThemeType,
    className?: string,
    exit?: TargetAndTransition,
    delay?: number,
    onTyped?: () => void,
};

export default function TitleText(props: TitleTextProps) {
    const {
        text,
        languages,
        langIndex,
        cellLength,
        cols,
        rows,
        gridLineThickness,
        theme,
        exit,
        className,
        delay = 0,
        onTyped,
    } = props;

    const titleContainerRef = useRef<HTMLDivElement>();
    const [maxSize, setMaxSize] = useState(null);
    const [titleBoundingRect, setTitleBoundingRect] = useState(null);
    const [titleStyle, setTitleStyle] = useState(null);
    const themeStyle = themeToCSS(theme);

    const titleComponent = useMemo(() => (
        <CodificationWithTranslations
            text={text}
            languages={languages}
            langIndex={langIndex}
            className={cssClassNames.title_text}
            codificationProps={codificationProps}
            delay={delay / 2}
            runFinalTextAnimation={false}
            languageTransitionDelay={languageTransitionDelay}
            onLanguageContainerMeassuerd={sizes => {
                let maxWidth = 0;
                let maxHeight = 0;

                sizes.forEach(({width, height}) => {
                    maxWidth = Math.max(maxWidth, width);
                    maxHeight = Math.max(maxHeight, height);
                });

                setMaxSize({
                    width: maxWidth,
                    height: maxHeight,
                });
            }}
            onTyped={onTyped}
        />
    ), [text, languages, langIndex]);

    useEffect(() => {
        if (!maxSize || !titleContainerRef.current ) {
            return;
        }

        const titleStyle = window.getComputedStyle(titleContainerRef.current);
        const paddingTop = parseFloat(titleStyle.paddingTop);
        const paddingRight = parseFloat(titleStyle.paddingRight);
        const paddingBottom = parseFloat(titleStyle.paddingBottom);
        const paddingLeft = parseFloat(titleStyle.paddingLeft);
        const rect = {
            x: 0,
            y: 1,
            rows: Math.min(Math.ceil((maxSize.height + paddingTop + paddingBottom) / cellLength), rows),
            cols: Math.min(Math.ceil((maxSize.width + paddingRight + paddingLeft) / cellLength), cols),
        };
        setTitleBoundingRect(rect);

        const halfGridLineThickness = gridLineThickness / 2;
        setTitleStyle({
            ...themeStyle,
            marginTop: -halfGridLineThickness,
            marginLeft: -halfGridLineThickness,
            borderWidth: gridLineThickness,
            borderBottomStyle: 'solid',
            borderTopStyle: 'solid',
            borderRightStyle: rect.cols === cols ? 'none' : 'solid',
            top: rect.y * cellLength,
            left: rect.x * cellLength,
            height: rect.rows * cellLength - gridLineThickness,
            width: rect.cols * cellLength,
        });
    }, [maxSize]);

    return (
        <motion.div
            className={classNames(cssClassNames.animation_container, className)}
            style={titleStyle}
            initial={{
                clipPath: 'inset(0% 0% 0% 0%)'
            }}
            transition={{
                duration: DEFAULT_DURATION_S,
                delay: DEFAULT_DURATION_S,
                ease: DEFAULT_EASE
            }}
            exit={exit}
        >
            {titleStyle ? (
                <Grid
                    className={cssClassNames.title_grid}
                    width={titleStyle.width}
                    height={titleStyle.height}
                    cols={titleBoundingRect.cols}
                    rows={titleBoundingRect.rows}
                    initialAnimation='visible'
                    animation='outAlternating'
                    lineStyle={{
                        strokeWidth: gridLineThickness,
                        stroke: themeStyle.color
                    }}
                />
            ) : null}
            <div
                ref={titleContainerRef}
                className={cssClassNames.title_container}
            >
                <div style={{
                    width: maxSize?.width,
                    height: maxSize?.height,
                }}>
                    <AnimatePresence>
                        {titleComponent}
                    </AnimatePresence>
                </div>
            </div>
        </motion.div>
    )
}

type TitleTextPropsForCalculations = Pick<TitleTextProps, 'text' | 'languages'>;

export function calculateDuration(props: TitleTextPropsForCalculations) {
    const {
        text,
        languages,
    } = props;

    return calculateCodificationDuration({
        text,
        languages,
        codificationProps,
        languageTransitionDelay,
    });
}
