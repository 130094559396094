import { CSSProperties } from 'react';

export const DEFAULT_BORDER = `1px solid color-mix(in srgb, currentColor 80%, transparent)`;
export const SOFTENED_BORDER = `2px solid color-mix(in srgb, currentColor 70%, transparent)`;
export const DEFAULT_GRADIENT = 'linear-gradient(160deg, #e77833 0%, #dd1d1d 68.33%)';

export function randomString() {
    return Math.random().toString(36).substr(2, 5);
}

export function fairlyUnique() {
    return `${Date.now()}-${randomString()}`;
}

export async function wait(ms: number) {
    return new Promise(resolve => setAnimationTimeout(resolve, ms));
}

export function setAnimationTimeout(fn: Function, timeOut: number) {
    let request: number;
    let start: number;

    const step = (timeStamp: number) => {
        start = start || timeStamp;
        const ellapsed = timeStamp - start;

        if (ellapsed < timeOut) {
            request = window.requestAnimationFrame(step);
        } else {
            request = undefined;
            fn();
        }
    };

    request = window.requestAnimationFrame(step);

    return () => {
        if (!request) {
            return;
        }

        window.cancelAnimationFrame(request);
        request = undefined;
    }
}

export function useRpx() {
    const WIDTH = 1920;

    const multiplier = window.innerWidth / WIDTH;

    return (value: number, raw: boolean = false) => {
        const val = value * multiplier;
        return raw ? val : `${val}px`;
    }
}

export function isPortrait() {
    return window.innerHeight > window.innerWidth;
}

type ComponentThemeProps = {
    background?: string,
    text?: string,
    titleContainerStyle?: CSSProperties,
    descriptionContainerStyle?: CSSProperties,
    descriptionStyle?: CSSProperties,
    stepCounterBackgroundSecondary?: string,
    gridWithoutRoundCorners?: boolean,
};

export function themeToStyles(theme: string): ComponentThemeProps {
    switch (theme) {
        case 'hoi':
            return {
                background: 'white',
                text: 'black',
                titleContainerStyle: {
                    lineHeight: '1em',
                },
                descriptionContainerStyle: {
                    padding: '0.6em 0.6125em',
                },
                descriptionStyle: {
                    fontFamily: 'helvetica-neue-hoi-ehq',
                },
                stepCounterBackgroundSecondary: 'var(--hoi-background-red)',
                gridWithoutRoundCorners: true,
            };
        case 'one':
            return {
                background: 'black',
                text: 'white',
            };
        default:
            return {};
    }
}
