import {ThemeType} from "../shared";
import Transition from "../components/Transition";
import Background from "../components/Background";
import {useMemo} from "react";
import Typography from "../components/Typography";
import GlitchTypography from "../components/GlitchText/GlitchTypography";
import {ArrowRaw} from "../assets/icons/Arrow";

interface StoreDirectoryProps {
    theme: ThemeType;
    title: string;
    levels: Array<Level>;
}

interface Level {
    identifier?: string;
    entries: Array<LevelEntry>;
}

interface LevelEntry {
    identifier?: string;
    label: string;
}

const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

function DirectoryEntry({color, entry: {identifier = '', label}}: { color: string, entry: LevelEntry }) {
    const isArrow = useMemo(() => {
        const regex = /[\u2190-\u21FF]|[\u27F0-\u27FF]|[\u2900-\u297F]|[\u2B00-\u2BFF]/;
        return regex.test(identifier);
    }, [identifier]);

    return (
        <div style={{display: "flex", alignItems: "center", marginBottom: 39}}>
            <div
                style={{
                    border: `2px solid ${color}`,
                    borderRadius: "50%",
                    width: 77,
                    height: 77,
                    flexShrink: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: 24,
                    color: color,
                }}
            >
                {
                    isArrow ? (
                        <ArrowRaw/>
                    ) : (
                        <Typography
                            variant={"pp-ultralight"}
                            color={color}
                            size={46}
                            style={{
                                transform: "translateY(5px)"
                            }}
                        >
                            {identifier}
                        </Typography>
                    )
                }
            </div>
            <Typography variant={"jordan"} color={color} size={60}>
                {label}
            </Typography>
        </div>
    );
}

export default function StoreDirectory({title, theme, levels}: StoreDirectoryProps) {
    const color = theme === 'dark' ? 'white' : 'black';
    const level = useMemo(() => {
        return levels?.[0];
    }, [levels?.length]);

    const shouldGlitch = useMemo(() => {
        return false
        // return !containsChinese(title);
    }, [title]);


    return (
        <Transition variant={'wipe-x'} cover>
            <Background
                variant={theme === 'dark' ? 'black-print-wash' : 'light-dust'}
            >
                <div
                    style={{
                        marginLeft: 44,
                        marginTop: 50,
                    }}
                >
                    <div>
                        {
                            shouldGlitch ? (
                                <GlitchTypography
                                    variant={'jordan-condensed'}
                                    color={color}
                                    size={280}
                                >
                                    {title}
                                </GlitchTypography>
                            ) : (
                                <Typography
                                    variant={'jordan-condensed'}
                                    color={color}
                                    size={280}
                                    uppercase
                                >
                                    {title}
                                </Typography>
                            )
                        }

                    </div>

                    {
                        level && (
                            <div style={{marginTop: 210, display: "flex"}}>
                                {
                                    level.identifier && (
                                        <>
                                            <div style={{
                                                height: 258,
                                                width: 8,
                                                position: 'absolute',
                                                left: 0,
                                                marginTop: -15,
                                                background: color
                                            }}></div>

                                            <Typography
                                                variant={'jordan-condensed'}
                                                size={320}
                                                color={color}
                                                style={{width: '200px'}}
                                            >
                                                {level.identifier}
                                            </Typography>

                                        </>
                                    )
                                }

                                <div style={{width: '950px'}}>
                                    {level.entries?.map((entry, i) => (
                                        <DirectoryEntry key={i} color={color} entry={entry}/>
                                    ))}
                                </div>
                            </div>
                        )
                    }
                </div>
                <div style={{
                    position: 'absolute',
                    bottom: 44,
                    left: '0',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Footer theme={theme}/>
                </div>
            </Background>
        </Transition>
    )
}

function Footer({theme}: { theme: ThemeType }) {
    const color = theme === 'dark' ? 'white' : 'black';

    return (
        <div style={{height: 50, position: 'relative'}}>
            <svg
                x="0px" y="0px"
                viewBox="0 0 1602.9 67.7"
                style={{width: 994, height: 'auto'}}
            >
                <g>
                    <polygon fill={color}
                             points="276.1,36.4 260.8,36.4 260.8,52.3 15.3,52.3 15.3,36.4 0,36.4 0,67.7 648.9,67.7 648.9,52.3 276.1,52.3"/>
                    <polygon fill={color}
                             points="1587.6,36.4 1587.6,52.3 1342.1,52.3 1342.1,36.4 1326.8,36.4 1326.8,52.3 954,52.3 954,67.7 1602.9,67.7 1602.9,36.4"/>
                    <path fill={color}
                          d="M800.5,0C770,0,744.8,22.9,741,52.3h-45v15.3h208.9V52.3h-45C856.1,22.9,830.9,0,800.5,0z M800.5,15.3 c22,0,40.2,16,43.9,37h-87.7C760.3,31.4,778.5,15.3,800.5,15.3z"/>
                </g>
            </svg>
        </div>
    );
}