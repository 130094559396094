import React, {
    CSSProperties,
    useCallback,
    useEffect,
    useState
} from 'react';
import { AnimatePresence } from 'framer-motion';

import * as AppEventsService from '../../services/app-events.service';
import TemplateEventsEnum from '../../enums/TemplateEvents.enum';

import { pixelMapReady } from '../../utilities';
import useOnWindowResize from '../core/hooks/useOnWindowResize';

import * as cssClassNames from './ChapterPlayer.module.scss';

import {
    AnimationData,
} from './Chapter';
import useChapterPlayer from './useChapterPlayer';


type ChapterPlayerProps = {
    chapters: AnimationData[];
    playOnce?: boolean;
    style?: CSSProperties,
};

export default function ChapterPlayer(props: ChapterPlayerProps) {
    const {
        chapters: chaptersData,
        playOnce,
        style,
    } = props;

    // "chapters" must be an array to have the possibility to have few chapters at the same time.
    // This is mostly needed for the transition between two chapters.
    const [chapters, setChapters] = useState([]);
    const [resetTimestamp, setResetTimestamp] = useState(Date.now());

    pixelMapReady(() => setResetTimestamp(Date.now()));

    useEffect(() => {
        setChapters([]);
    }, [chaptersData]);

    useOnWindowResize(useCallback(() => {
        setChapters([]);
        setResetTimestamp(Date.now());
    }, [setResetTimestamp]));

    useChapterPlayer({
        chaptersData,
        resetTimestamp,
        playOnce,
        onNextChapter(data, index, chapterCounter) {
            AppEventsService.sendEvent(TemplateEventsEnum.ChaptersRender, index);

            const {
                Component,
            } = data;

            const newChapter = (
                <Component
                    // key={Date.now()}
                  key={chapterCounter}
                />
            );

            setChapters(currentChapters => [...currentChapters, newChapter]);
        },
        onExitChapter() {
            setChapters(currentChapters => {
                currentChapters.splice(0, 1);
                return [...currentChapters];
            });
        },
    });

    return (
        <div
            className={cssClassNames.player}
            style={style}
        >
            <AnimatePresence>
                {chapters}
            </AnimatePresence>
        </div>
    );
}
