import React, { useState } from 'react';
import '../../styles/main.scss';
import './styles/scrollticker.scss';
import { NIKE_EASE_CSS } from '../../constants/nikerise';
import { DynamicComponent } from './DynamicComponent';
import TickerRow from './TickerRow';
import inv = PIXI.groupD8.inv;

const TIME_MULTIPLIER = 2;
const elementHeightPercentage = 0.7;

type Effect = 'codification' | 'solarization';

type Segment = {
	type: string,
	content: string,
};

export type TickerProps = {
	segments: Segment[],
	languages?: string[],
	// the time of the entire sequence in seconds
	time?: number,
	timeFactor?: number,
	// the space between each text section as a multiple of `time`
	space?: number,
	// invert color scheme
	invert?: boolean,
	height: number,
	screenWidth: number,
	totalWidth: number,
	top: number,
	left: number,
	inset: number,
	// the time of the solarization/codification effect as a multiple of `time`
	effectTime?: number,
	// the time each language shows before switching as a multiple of `time`
	textTime?: number,
	// which effects should run, can be one or multiple
	effectTypes?: Effect[],
	// stagger between the start of effects
	effectStaggerTime?: number,
	// vertical inset amount for the solarization effect as a percentage of the height
	solarizationInset?: number,
	effectOccurrenceMultiplier?: number,
}

const Ticker = ({
	segments = [],
	languages = ['KO', 'EN'],
	invert = false,
	space = 2.5,
	height,
	screenWidth,
	totalWidth,
	top,
	left,
	inset,
	effectOccurrenceMultiplier,
	time = 20,
	effectTime: effectTimePercent = 1,
	textTime: textTimePercent = 0.5,
	effectTypes = ['solarization', 'codification'],
	effectStaggerTime = 0.25,
	solarizationInset = 0.064,
	timeFactor = 1,
}: TickerProps) => {
	const [repeatedZones, setRepeatedZones] = React.useState([]);
	const [activeLanguage, setActiveLanguage] = React.useState(0);
	const [effectIndex, setEffectIndex] = React.useState(
		segments.findIndex(segment => segment.type !== 'divider'),
	);
	const [width, setWidth] = React.useState(
		Array(languages.length).fill(totalWidth),
	);

	const ref = React.useRef<HTMLElement[]>([]);
	const elementSize = elementHeightPercentage * height;

	const spacePx = React.useMemo(() => height * space, [height, space]);
	const effectTime = React.useMemo(() => time * effectTimePercent, [
		time,
		effectTimePercent,
	]);
	const textTime = React.useMemo(() => time * textTimePercent, [
		time,
		textTimePercent,
	]);

	const animationTime = React.useMemo(() => {
		if (!repeatedZones[activeLanguage]) {
			return;
		}
		return Math.round(totalWidth / (time * TIME_MULTIPLIER));
	}, [repeatedZones, time, activeLanguage]);

	const getRepeatedZones = React.useCallback(() => {
		const values = ref.current.map(el => {
			let { width: elWidth } = el.getBoundingClientRect();
			if (elWidth === 0) {
				elWidth = totalWidth;
			}
			const repeatCount = Math.ceil(totalWidth / elWidth);
			return {
				width: repeatCount * elWidth + repeatCount * spacePx,
				repeatedZones: Array(repeatCount)
					.fill(segments)
					.flat(),
			};
		});
		setWidth(values.map(({ width }) => width));
		setRepeatedZones(values.map(({ repeatedZones }) => repeatedZones));
	}, [
		setRepeatedZones,
		setWidth,
		ref.current,
		totalWidth,
		segments,
		spacePx,
	]);

	React.useEffect(() => {
		return () => {
			ref.current = [];
		}
	}, []);

	React.useEffect(() => {
		setTimeout(getRepeatedZones, 200);
	}, [getRepeatedZones]);

	React.useEffect(() => {
		const timer = setInterval(
			() =>
				setActiveLanguage(curr => {
					const index = curr + 1;
					return index >= languages.length ? 0 : index;
				}),
			textTime * 1000,
		);
		return () => clearInterval(timer);
	}, [setActiveLanguage, textTime, languages]);

	React.useEffect(() => {
		const getNextIndex = currentIndex => {
			const index = currentIndex + 1;
			if (index >= segments.length) {
				return getNextIndex(-1);
			}

			// skip swooshes
			if (segments[index].type === 'divider' && segments[index].content === 'swoosh') {
				return getNextIndex(index);
			}
			return index;
		};
		const timer = setInterval(
			() => setEffectIndex(getNextIndex),
			effectTime * 1000,
		);
		return () => clearInterval(timer);
	}, [setEffectIndex, effectTime, segments]);

	// just used for dev to pause the animation
	React.useEffect(() => {
		const handler = e => {
			if (e.metaKey && e.key === 'p') {
				e.preventDefault();
				document
					.querySelectorAll('.ticker-row')
					.forEach(el => el.classList.toggle('paused'));
			}
		};
		window.addEventListener('keydown', handler);
		return () => window.removeEventListener('keydown', handler);
	});

	const setRef = React.useCallback(
		index => el => {
			ref.current[index] = el;
		},
		[ref],
	);

	const repeatArr = React.useMemo(
		() => [...Array(Math.ceil(totalWidth / screenWidth))],
		[screenWidth, totalWidth],
	);

	return (
		<>
			<div id={'shadow-renderer'} style={{opacity: 0}}>
				{languages.map((language, languageIndex) => (
					<div
						key={languageIndex}
						className={`scroll-ticker-wrapper`}
						style={{
							width: width[languageIndex],
						}}
					>
						<div className="sizer-wrapper">
							<div
								ref={setRef(languageIndex)}
								className="scroll-ticker-row"
								style={{
									columnGap: `${spacePx}px`,
								}}
							>
								{segments.map((zone, i) => (
									<DynamicComponent
										{...zone}
										language={language}
										key={i}
										elementSize={elementSize}
										height={height}
									/>
								))}
							</div>
						</div>
					</div>
				))}
			</div>
			<div
				className="scroll-ticker-pixelmap"
				style={{
					width: screenWidth,
					top,
					height: repeatArr.length * height,
				}}
			>
				{repeatArr.map((_, repeatIndex) => (
					<div
						key={repeatIndex}
						className="scroll-ticker-wrapper"
						style={{
							top: repeatIndex * height,
							width: totalWidth,
							left: -1 * repeatIndex * screenWidth,
							// start high and lower the zIndex as the languages go down
						}}
					>
						<div
							style={{
								position: 'relative',
								height,
								'--time': `${animationTime * (1 / timeFactor)}s`,
								'--solarization-time': `${effectTime}s`,
								'--ease': NIKE_EASE_CSS,
								backgroundColor: invert ? 'black' : 'white',
							}}
						>
							{languages.map((language, languageIndex) => (
								<div
									key={languageIndex}
									className={`scroll-ticker-wrapper-inner ${
										invert ? 'invert' : ''
									}`}
									style={{
										width: Math.round(width[languageIndex]),
									}}
								>
									<TickerRow
										spacePx={spacePx}
										repeatedZones={repeatedZones}
										zoneLength={segments.length}
										languageIndex={languageIndex}
										language={language}
										elementSize={elementSize}
										effectIndex={effectIndex}
										height={height}
										left={left}
										inset={inset}
										activeLanguage={activeLanguage}
										effectOccurrenceMultiplier={effectOccurrenceMultiplier}
										effectTypes={effectTypes}
										effectStaggerTime={effectStaggerTime}
										solarizationInset={solarizationInset}
									/>
								</div>
							))}
						</div>
					</div>
				))}
			</div>
		</>
	);
};

export default Ticker;
