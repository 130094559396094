import * as localForage from "localforage";
import {MediaType} from "./shared";
import _ from "lodash";
import md5 from 'md5';
import {fetchAnimations} from '../rise/hyperlive/utils';
import {storage as lStorage} from '../../utilities';
import { HoiConfig } from './components/ChapterRunner';

export function createStorage() {
    localForage.config({
        driver: localForage.INDEXEDDB, // Force WebSQL; same as using setDriver()
        name: 'HOI',
        version: 1.0,
        size: 5 * 1024 * 1024, // Size of database, in bytes. WebSQL-only for now.
        storeName: 'HoiImages', // Should be alphanumeric, with underscores.
        description: 'HOI Asset Storage'
    });

    const instance = localForage.createInstance({
        name: "HoiImages",
    });

    return {instance};
}

export async function clearStorageIfNeeded(): Promise<any> {
    const storageKey = 'hoi_last_checked';
    const latestAnimationDate = fetchAnimations()?.sort((a, b) => {
        return b.updated_at - a.updated_at;
    })?.[0]?.updated_at;


    const storedDate = lStorage.get(storageKey);

    if (latestAnimationDate > storedDate || !storedDate) {
        console.log('clearing asset cache');
        lStorage.set(storageKey, latestAnimationDate);

        return localForage.clear();
    }
}

export async function storeImageBlob(canvas: HTMLCanvasElement, name: string) {
    return canvas.toBlob(async (blob)=>{
        await localForage.setItem(md5(name), blob);
    })
}

export async function loadImageBlobUrl(name: string) {
    const blob = await localForage.getItem<Blob>(md5(name));
    if(!blob){
        return '';
    }
    return URL.createObjectURL(blob);
}

export async function loadAsset(media: MediaType, forceWebp = true, forceHeight?: number, dontCache?: boolean) {
    const key = media?.etag ?? md5(media.url);
    const notVideo = media.resource_type === 'image';
    const shouldResize = notVideo || !media?.dontTransform;

    const sizedUrl = forceHeight && shouldResize ?
        media.url.replace(/(w_|h_)\d+/, `h_${forceHeight}`)
        : media.url;

    const url = (forceWebp && sizedUrl?.replace) ?
        sizedUrl
            // .replace('.mp4', '.webp')
            .replace('.png', '.webp')
            .replace('.jpg', '.webp')
        : sizedUrl;

    media.url = url;

    try {
        const item = await localForage.getItem<Blob>(key);
        if (!item) {
            throw new Error();
        }
        media.blob = item;
    } catch (err) {
        const blob = await fetch(url)
            .then((response) => response.blob());

        // prevent caching.
        if (dontCache) {
            return media;
        }
        media.blob = await localForage.setItem(key, blob);
    }

    return media;
}

interface PreloaderConfig extends HoiConfig {
    dontCache?: boolean,
    forceWebp?: boolean,
}

type DataType = Date | (MediaType & {
    subType?: 'image' | 'video' | 'm3u8-video',
});

export async function preloadAllAssets(data: DataType | DataType[], config?: PreloaderConfig, depthLimit = 6, depth = 0) {
    if (depth >= depthLimit) {
        return data;
    }

    if (_.isArray(data)) {
        return await Promise.all(data.map((d) => {
            return preloadAllAssets(d, config, depthLimit, depth + 1);
        }));
    }

    if (_.isObject(data)) {
        if (data instanceof Date) {
            return data;
        }

        if (data?.url && data?.subType) {
            data.resource_type = data.subType;
        }

        if (data?.etag || (data?.url && data?.resource_type)) {
            return await loadAsset(data, undefined, config?.forceAssetHeight, config?.dontCache);
        }

        let d: any = {};

        for (const [key, value] of Object.entries(data)) {
            d[key] = await preloadAllAssets(value, config, depthLimit, depth + 1);
        }

        return d;
    }

    return data;
}
