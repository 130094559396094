@use './product/common' as common;

.campaign_intro {
    background: white;

    > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.overlay {
    display: flex;
    align-items: center;
    justify-content: center;
}

.media {
    padding: common.$title-padding;
}

.text_container {
    @include common.text_container;

    display: block;
    height: auto;
    color: white;
}
