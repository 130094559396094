import { motion } from "framer-motion";
import { ReactNode, useEffect, useState } from "react";
import { DEFAULT_EASE } from "../../../../jordan/shared";
import { ABSOLUTE_FILL } from "../helpers";

interface TickerEntriesContainerProps {
	children: ReactNode;
}

export default function TickerEntriesContainer({ children }: TickerEntriesContainerProps) {
	const [verticalSection, setVerticalSection] = useState(0);

	useEffect(() => {
		const id = setInterval(() => {
			setVerticalSection(v => v === 0 ? 1 : 0);
		}, 7_000);
		return () => clearInterval(id);
	}, []);

	return (
		<motion.div
			style={{
				...ABSOLUTE_FILL,
				width: "200%",
				height: "200%",
			}}
			animate={{
				y: verticalSection === 0 ? 0 : "-50%",
			}}
			transition={{
				ease: DEFAULT_EASE,
				duration: 1,
			}}
		>
			<div
				style={{
					...ABSOLUTE_FILL,
					height: "50%",
					background: 'black',
				}}
			/>
			<div
				style={{
					...ABSOLUTE_FILL,
					height: "50%",
					top: "50%",
					background: 'var(--primary-color, white)',
				}}
			/>
			{children}
		</motion.div>
	);
}
