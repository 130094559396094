import { ThemeConfig } from "../types";
import { DefaultFullGrid, DefaultSectionGrid } from "./theme-defaults";
import SectionGridHoi from "../components/section-grid-hoi";

const hoiTheme: ThemeConfig = {
	id: "rise",
	name: "Rise",
	backgroundColor: "#000000",
	textColor: "#ffffff",
	hideGridsWhenPlayingMedia: true,
	gridColor: `rgba(255, 255, 255, .5)`,
	maxMediaWidth: 648,
	gridOffset: {
		circle: 0,
		horizontal: 0,
		vertical: 0,
	},
	gridLineThickness: {
		circle: 2,
		horizontal: 2,
		vertical: 2,
	},
	fullGrid: DefaultFullGrid,
	sectionGrid: SectionGridHoi,
	codification: ["heading", "ticker"],
	enableScrim: true,
	showQr: true,
	footer: {
		showIconDividers: true,
	},
	sequence: {
		headlineSwitchDuration: 6_000,
		// mediaDuration: 6_000,
		// mediaGap: 1_000,
		// playMediaInterval: 12_000,
		// playMediaInterval: 2 * 60_000,
	},
	style: {
		headline: {
			// fontSize: 51,
			fontSize: 45,
			lineHeight: 40,
			marginTop: 8,
			fontWeight: 'bold',
			fontFamily: "helvetica-neue-hoi",
			textTransform: "uppercase",
		},
		eyebrow: {
			fontFamily: 'helvetica-neue',
			fontSize: 14,
			lineHeight: 14.4,
			fontWeight: 500,
			// letterSpacing: '-0.02em',
			// textTransform: "uppercase",
		},
		qrContent: {
			fontFamily: 'helvetica-neue',
			fontSize: 13,
			lineHeight: 13,
			letterSpacing: '-0.02em',
			fontWeight: 500,
		},
		ticker: {
			fontFamily: "helvetica-neue-hoi",
			fontSize: 34,
			lineHeight: 34,
			paddingTop: '12px',
			fontWeight: 'bold',
			textTransform: 'uppercase',
		}
	},
};

export default hoiTheme;