import {motion} from 'framer-motion';
import {MediaType} from "../../../../jordan/shared";
import Media from "../../../../jordan/components/Media";
import {useEffect, useMemo, useRef, useState} from "react";
import {DEFAULT_BORDER, fairlyUnique, useRpx} from "../shared";
import {DEFAULT_TRANSITION} from '../../../../rise/constants/transition';

interface ImageScrollerProps {
    media: Array<MediaType>;
    direction: 'up' | 'down';
    columnCount: number;
    index: number;
}

export default function ImageScroller({media, direction, columnCount, index}: ImageScrollerProps) {
    const id = useMemo(() => {
        return fairlyUnique();
    }, []);
    const rpx = useRpx();
    const ref = useRef<HTMLDivElement>(null);
    const [size, setSize] = useState(0);

    useEffect(() => {
        if (!ref.current) return;

        setSize(columnCount === 1 ? (480 * 2) : 480);
    }, [ref.current, columnCount, index]);

    const y = useMemo(() => {
        return columnCount === 1
            ? (180 - 1)  // -1 is to compensate top border width
            : (180 - 480);
    }, [size, columnCount]);

    const dir = direction === 'up' ? -1 : 1;

    return (
        <motion.div
            key={id}
            ref={ref}
            initial={{
                y: rpx(1080 * dir),
                opacity: 0,
            }}
            animate={{
                y: rpx(y),
                opacity: 1,
            }}
            exit={{
                y: rpx(-2000 * dir),
                opacity: 0
            }}
            transition={{
                ...DEFAULT_TRANSITION,
                duration: 1.5,
            }}
            style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '200%',
                width: `${100 / columnCount}%`,
                borderRight: index === 0 ? DEFAULT_BORDER : 'none',
                background: 'white',
            }}
        >
            {media.map((item, index) => (
                <Media
                    key={`mediascroller-${index}`}
                    media={[item]}
                    width={size - 1}
                    height={size}
                    cover={'fit'}
                    preventExit
                    preventInitial
                    preventTransitions
                    preventInitialSlide
                    style={{
                        borderTop: index === 0 ? DEFAULT_BORDER : 'none',
                        borderBottom: DEFAULT_BORDER,
                        flexShrink: 0,
                        boxSizing: 'border-box'
                    }}
                    transparent
                />
            ))}
        </motion.div>
    )
}
