import {FastLaneSection} from "./types";

export const DOUBLE_LANE_WIDTH = 648;
export const LANE_WIDTH = DOUBLE_LANE_WIDTH / 2;
export const LANE_HEIGHT = 240;
export const TICKER_HEIGHT = 50;

export const EMPTY_SECTION: FastLaneSection = {
    heading: [''],
    ticker: {primary: '', secondary: ''},
    position: {
        left: 0,
        width: 0,
    }
}