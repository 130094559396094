import React, {CSSProperties, useEffect, useMemo, useState} from "react";
import {keyframe, MediaType, preferredAsset} from "../shared";
import Media, {MediaEffect} from "../components/Media";
import {motion} from "framer-motion";
import {DEFAULT_EASE} from '../../rise/constants/transition';
import Transition from "../components/Transition";
import {CenterCourtTicker} from "../components/CenterCourtTicker";
import CenterCourtVerticalTicker from "../components/CenterCourtVerticalTicker";
import {JordanConfig} from "../components/ChapterRunner";
import {TickerType} from "../components/VerticalTicker";
import WidescreenQrSection from "../components/WidescreenQrSection";
import {QrCodeInterface} from "../components/QrSection";

const defaultTickerUrl = new URL('../demo/center-court/luka-ticker.png', import.meta.url);

export function centerCourtSeasonalDuration(speed = 1) {
    return (((3 * 6) + 4) * 1_000) * speed;
}

interface CenterCourtSeasonalProps {
    primary: Array<MediaType>;
    secondary: Array<MediaType>;
    ticker: any;
    config: JordanConfig;
    preventProcessing: boolean;
    variant: 'full' | 'split';
    vertical_ticker: TickerType;
    custom_ticker?: MediaType;
    qr_code?: QrCodeInterface;
}

const FORCE_FRAME = 0;

export default function CenterCourtSeasonal({
                                                primary,
                                                secondary,
                                                ticker,
                                                config,
                                                preventProcessing,
                                                variant = 'full',
                                                vertical_ticker,
                                                custom_ticker,
                                                qr_code,
                                            }: CenterCourtSeasonalProps) {
    const [frameIndex, setFrameIndex] = useState<number>(FORCE_FRAME);
    const showCircularTicker = useMemo(() => {
        return !config.meta?.isRectangular;
    }, []);

    const speed = useMemo(() => config?.duration?.cc_seasonal_speed, [config]);
    const nextFrame = () => setFrameIndex(i => i + 1);

    const playsWhen = (...indexes) => {
        return indexes.includes(frameIndex);
    };

    useEffect(() => {
        if (FORCE_FRAME) {
            return;
        }
        const {trigger, clear} = keyframe(3, {speed});

        trigger
        (0, nextFrame) // double y action split
            (3, nextFrame) // product
            (3, nextFrame) // double split x
            (3, nextFrame) // product
            (3, nextFrame) // three split
            (3, nextFrame) // product
            (3, () => null) // full action

        return clear;
    }, []);


    return (
        <Transition
            cover
            variant={'wipe-x'}
            delayExit={1.8}
            preventChildExit
        >
            {
                showCircularTicker && (
                    <CenterCourtTicker
                        width={config?.meta?.width}
                        type={ticker?.type}
                        url={ticker?.asset?.url ?? defaultTickerUrl.toString()}
                        data={ticker?.data}
                    />
                )
            }

            {
                (!showCircularTicker && vertical_ticker) && (
                    <CenterCourtVerticalTicker verticalTicker={vertical_ticker} customTicker={custom_ticker}/>
                )
            }

            {
                variant == 'full' && (
                    <FullSequence
                        preventProcessing={preventProcessing}
                        frameIndex={frameIndex}
                        playsWhen={playsWhen}
                        primary={primary}
                        secondary={secondary}
                    />
                )
            }

            {
                variant == 'split' && (
                    <SplitSequence
                        preventProcessing={preventProcessing}
                        frameIndex={frameIndex}
                        playsWhen={playsWhen}
                        primary={primary}
                        secondary={secondary}
                    />
                )
            }

            <WidescreenQrSection qr_code={qr_code}/>
        </Transition>
    );
}

function FullSequence({playsWhen, preventProcessing, frameIndex, primary, secondary}) {
    // create boards
    // should be max 6 moments
    //  - 3 primary (full images)
    //  - 3 secondary ()
    //

    return (
        <>
            <>
                <SlideFrame
                    preventProcessing={preventProcessing}
                    media={preferredAsset(primary, 0)}
                    effect={'pan-right'}
                    hide={!playsWhen(0, 1, 2, 6)}
                    // instant
                    initial={false}

                    width={'100%'}
                    x={'0%'}

                    // playsWhen(0) ? '0%' : '-100%'
                    y={{
                        0: '0%',
                        5: '0%',
                        6: '0%',
                    }?.[frameIndex] ?? '-100%'}
                />
                <SlideFrame
                    preventProcessing={preventProcessing}
                    media={preferredAsset(primary, 1)}
                    effect={'zoom-out-center'}
                    hide={!playsWhen(0, 1, 2)}
                    // instant
                    initial={false}

                    width={'100%'}
                    x={playsWhen(2) ? '-100%' : '0%'}
                    y={playsWhen(1, 2) ? '0%' : '100%'}
                />

                <SlideFrame
                    preventProcessing={preventProcessing}
                    media={preferredAsset(primary, 2, 0)}
                    effect={'zoom-top'}
                    hide={!playsWhen(1, 2, 3)}
                    // instant
                    initial={false}

                    width={'100%'}
                    x={{
                        0: '100%',
                        1: '100%',
                        2: '0%',
                        3: '0%',
                    }?.[frameIndex]}
                    y={{
                        3: '100%',
                        4: '100%', // out of frame
                    }?.[frameIndex] ?? '0%'}
                />


                {/* Split Images*/}

                <SlideFrame
                    preventProcessing={preventProcessing}
                    media={preferredAsset(secondary, 0) ?? preferredAsset(primary, 0)}
                    effect={'pan-left'}
                    hide={!playsWhen(3, 4, 5, 6)}
                    // instant
                    initial={false}

                    width={{
                        4: '50%',
                        5: '33.33%',
                        6: '33.33%',
                    }?.[frameIndex] ?? '100%'}
                    x={'0%'}
                    y={{
                        2: '-100%',
                        3: '0%',
                        6: '-100%',
                    }?.[frameIndex] ?? '0%'}
                />


                <SlideFrame
                    preventProcessing={preventProcessing}
                    media={preferredAsset(secondary, 1) ?? preferredAsset(primary, 1)}
                    effect={'pan-left'}
                    hide={!playsWhen(3, 4, 5, 6)}
                    // instant
                    initial={false}

                    width={{
                        2: '100%',
                        3: '100%',
                        4: '50%',
                        5: '33.33%',
                        6: '33.33%',
                    }?.[frameIndex] ?? '0%'}
                    x={'100%'}
                    y={{
                        6: '100%',
                    }?.[frameIndex] ?? '0%'}
                />

                <SlideFrame
                    preventProcessing={preventProcessing}
                    media={preferredAsset(secondary, 2, 0) ?? preferredAsset(primary, 2, 0)}
                    hide={!playsWhen(4, 5, 6)}
                    // instant
                    initial={false}

                    width={{
                        3: '50%',
                        4: '50%',
                        5: '33.33%',
                        6: '33.33%',
                    }?.[frameIndex] ?? '0%'}
                    x={'200%'}
                    y={{
                        6: '-100%',
                    }?.[frameIndex] ?? '0%'}
                />

            </>
        </>
    );
}

function SplitSequence({playsWhen, preventProcessing, frameIndex, primary, secondary}) {
    return (
        <>
            {/*FRAME 1*/}
            {
                playsWhen(0, 1, 2) && (
                    <>
                        <Frame
                            preventProcessing={preventProcessing}
                            effect={'pan-right'}
                            hide={!playsWhen(0, 2)}
                            instant
                            initial={false}
                            media={preferredAsset(secondary, 0)}
                            direction={frameIndex === 0 ? 'x' : 'y'}
                            state={25}
                        />
                        <Frame
                            preventProcessing={preventProcessing}
                            effect={'zoom-out-center'}
                            hide={!playsWhen(0, 2)}
                            instant
                            initial={false}
                            media={preferredAsset(secondary, 1)}
                            direction={frameIndex === 0 ? 'x' : 'y'}
                            state={75}
                        />
                        )
                    </>
                )
            }

            {/*FRAME 2*/}
            {
                playsWhen(0, 1) && (
                    <>
                        <Frame
                            preventProcessing={preventProcessing}
                            effect={'zoom-pan-left'}
                            hide={!playsWhen(1)}
                            instant
                            initial={false}
                            media={preferredAsset(primary, 0)}
                            direction={'x'}
                            state={50}
                        />
                    </>
                )
            }


            {/*FRAME 3*/}
            {
                playsWhen(2, 3) && (
                    <>
                        <Frame
                            preventProcessing={preventProcessing}
                            effect={'zoom-out-center'}
                            hide={!playsWhen(3)}
                            instant
                            initial={false}
                            media={preferredAsset(primary, 1)}
                            direction={'x'}
                            state={50}
                        />
                    </>
                )
            }

            {/*FRAME 4*/}
            {
                playsWhen(3, 4) && (
                    <>
                        <Frame
                            preventProcessing={preventProcessing}
                            effect={'pan-right'}
                            hide={!playsWhen(4)}
                            instant
                            initial={false}
                            media={preferredAsset(secondary, 2, 0)}
                            direction={'x'}
                            state={25}
                        />
                        <Frame
                            preventProcessing={preventProcessing}
                            hide={!playsWhen(4)}
                            instant
                            initial={false}
                            media={preferredAsset(secondary, 3, 1)}
                            direction={'x'}
                            state={75}
                        />
                        <Frame
                            preventProcessing={preventProcessing}
                            effect={'zoom-center'}
                            hide={!playsWhen(4)}
                            instant
                            initial={false}
                            media={preferredAsset(secondary, 4) ?? preferredAsset(primary, 2, 1)}
                            direction={'y'}
                            state={25}
                        />
                    </>
                )
            }

            {/*FRAME 5*/}
            {
                playsWhen(4, 5) && (
                    <>
                        <Frame
                            preventProcessing={preventProcessing}
                            effect={'zoom-pan-left'}
                            hide={!playsWhen(5)}
                            instant
                            initial={false}
                            media={preferredAsset(primary, 2, 0)}
                            direction={'x'}
                            state={50}
                        />
                    </>
                )
            }

            {/*FRAME 6*/}
            {
                playsWhen(5, 6) && (
                    <>
                        <Frame
                            preventProcessing={preventProcessing}
                            effect={'zoom-out-center'}
                            hide={!playsWhen(6)}
                            instant
                            initial={false}
                            media={preferredAsset(primary, 3, 1)}
                            direction={'x'}
                            state={50}
                        />
                    </>
                )
            }
        </>
    )
}

const wipes = {
    x: {
        0: 'inset(0% 100% 0% 0%)',
        25: 'inset(0% 50% 0% 0%)',
        50: 'inset(0% 0% 0% 0%)',
        75: 'inset(0% 0% 0% 50%)',
        100: 'inset(0% 0% 0% 100%)',
    },
    y: {
        0: 'inset(0% 0% 100% 0%)',
        25: 'inset(0% 0% 50% 0%)',
        50: 'inset(0% 0% 0% 0%)',
        75: 'inset(50% 0% 0% 0%)',
        100: 'inset(100% 0% 0% 0%)',
    }
}


interface FrameProps {
    media: MediaType;
    hide?: boolean;
    effect?: MediaEffect;
    instant?: boolean;
    state: number;
    direction?: string;
    filter?: 'red' | 'bw';
    initial?: boolean;
    preventProcessing?: boolean;
    mediaStyle?: any;
    width?: number | string;
    x?: number | string;
    y?: number | string;
}

function SlideFrame({media, effect, mediaStyle, preventProcessing, initial, hide, instant, width, x, y}: any) {
    return (
        <motion.div
            style={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                opacity: hide ? 0 : 1,
            }}
            initial={initial}

            animate={{
                width,
                x,
                y,
            }}

            exit={{
                transition: {
                    delay: 4,
                }
            }}

            transition={{
                ease: DEFAULT_EASE,
                duration: instant ? 0 : 1,
            }}
        >
            <Media
                effect={effect}
                preventInitial={true}
                // preventExit={true}
                style={mediaStyle}
                media={[media]}
                width={'100%'}
                height={'100%'}
                theme={'dark'}
                mediaContainerStyle={preventProcessing ? {
                    filter: 'none',
                } : {}}
            />
        </motion.div>
    )
}

function Frame({
                   media,
                   state,
                   direction = 'x',
                   filter,
                   hide,
                   effect,
                   instant,
                   initial,
                   preventProcessing,
               }: FrameProps) {

    if (!media) {
        return null;
    }

    const mediaStyle = preventProcessing ? {} : {
        mixBlendMode: 'multiply',
        filter: filter ? 'contrast(0.8) brightness(0.9) grayscale(80%)' : 'contrast(0.7) brightness(0.9)',
    } as CSSProperties;

    return (
        <motion.div
            style={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                opacity: hide ? 0 : 1,
            }}
            initial={initial}

            animate={{
                clipPath: wipes?.[direction]?.[state] ?? wipes.x["25"]
            }}

            exit={{
                transition: {
                    delay: 4,
                }
            }}

            transition={{
                ease: DEFAULT_EASE,
                duration: instant ? 0 : 1,
            }}
        >
            <Media
                effect={effect}
                preventInitial={true}
                // preventExit={true}
                style={mediaStyle}
                media={[media]}
                width={'100%'}
                height={'100%'}
                theme={'dark'}
                mediaContainerStyle={preventProcessing ? {
                    filter: 'none',
                } : {}}
            />
            {
                filter && filter === 'red' && (
                    <div style={{
                        position: 'absolute',
                        background: '#bd2525',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        zIndex: -1
                    }}></div>
                )
            }
        </motion.div>
    )
}