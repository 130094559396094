import React, {ReactNode} from "react";
import {DEFAULT_BORDER} from "../shared";

export default function Split({left, right, leftWidth}: {left: ReactNode, right: ReactNode, leftWidth?: number}) {
    return (
        <div
            style={{
                display: 'flex',
                height: '100%',
                width: '100%',
                position: 'relative',
            }}
        >
            <div
                style={{
                    width: leftWidth || '50%',
                    borderRight: DEFAULT_BORDER,
                }}
            >
                {left}
            </div>
            <div
                style={{
                    width: '50%',
                }}
            >
                {right}
            </div>
        </div>
    )
}