import UnknownChapter from './UnknownChapter';
import NtcChapter from './NtcChapter';
import SportHubDirectory from './SportHub/Directory';
import SportHubExperience from './SportHub/Experience';
import MembershipChapter from './MembershipChapter';
import ApparelPodChapter from './ApparelPodChapter';
import MediaChapter from './MediaChapter';
import UniversalTakeoverPlayer from './UniversalTakeoverPlayer';


export const REGISTER = {
    unknown: UnknownChapter,
    ntc: NtcChapter,
    sport_hub_directory: SportHubDirectory,
    sport_hub_experience: SportHubExperience,
    membership: MembershipChapter,
    apparel_pod: ApparelPodChapter,
    media: MediaChapter,
    universal_takeover: UniversalTakeoverPlayer,
};

export function getChapter(key: string) {
    return REGISTER[key] || REGISTER.unknown;
}
