import loadFonts from '../../../utils/loadFonts';
import Chapter from '../Chapter';


export default async function preloadFonts(chapters: Chapter[]): Promise<void> {
    const fontsToLoad = new Set<string>();

    chapters.forEach(chapter => {
        chapter.fonts.forEach(font => fontsToLoad.add(font));
    });

    await loadFonts(Array.from(fontsToLoad));
}
