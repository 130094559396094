if (module.hot) {
  module.hot.accept(() => {
    location.reload()
  })
}

import {
  Uniform,
  Vector2,
  Texture,
  Color
} from 'three';
import * as THREE from 'three';
import gsap from 'gsap';
import {ScrambleTextPlugin} from '/src/touchpoints/live/lib/js/vendors/ScrambleTextPlugin.min.js';

import UniversalLiveTemplate from '/src/touchpoints/live/lib/js/UniversalLiveTemplate';

import vert from '/src/touchpoints/live/lib/glsl/shader.vert';
import frag from './life365.frag';
import CanvasGrid from '/src/touchpoints/live/lib/js/components/CanvasGrid';
import presetTL from './life365-text-timeline';
import CanvasCenteredText from '/src/touchpoints/live/lib/js/components/CanvasCenteredText';
import './L365.scss'

gsap.registerPlugin(ScrambleTextPlugin);

class L365Media extends UniversalLiveTemplate {
  constructor(datas, duration, lang, hasRangeFinder, total) {
    super(`${datas.type}-${datas.index}`);
    this.datas = datas;
    this.index = datas.index;
    this.wrapper.classList.add('chapter-L365');
    this.lang = this.datas.content?.[`${lang}`] ? lang : 'EN';
    this.overlayWrapper = this.addNode({type: 'overlayWrapper', freeflow: true});
    this.transition_type = datas.transition_type;
    this.duration = duration;
    this.total = total;
    this.isVideo = this.datas?.media?.resource_type == 'video';
    const {content = {}} = this.datas;
    const center = new Vector2(
      (window.innerWidth),
      (window.innerHeight)
    );

    if (this.datas.transition_type === null || this.datas.transition_type === undefined) {
      this.showViewFinder = hasRangeFinder;
    } else {
      this.showViewFinder = this.datas.transition_type == 'range';
    }

    if (this.datas.show_view_finder) {
      this.viewFinderMarks = this.addNode({
        type: 'viewFinderMarks',
        freeflow: true,
        content: {stroke: '2px', size: 100}
      });
    }

    if (this.datas.color === 'white') {
      this.app.classList.add('light-mode');
    }

    let res = new THREE.Vector2(100, 100);
    let textOverlayRes = new THREE.Vector2(100, 100);
    let img0Res = new THREE.Vector2(100, 100);
    let gridImageRes = new THREE.Vector2(100, 100);
    let previousTextureRes = new THREE.Vector2(100, 100);
    if (this.datas.media) {
      if (this.datas.media.resource_type == "video") {
        this.setVideoTexture(this.datas.media, 'img0');
      } else {
        this.registerTextureURL({
          src: this.datas.media.url
        });
      }
      res = new THREE.Vector2(this.datas.media.width, this.datas.media.height);
      textOverlayRes = new THREE.Vector2(this.datas.media.width, this.datas.media.height);
      img0Res = new THREE.Vector2(this.datas.media.width * 2, this.datas.media.height * 2);
      gridImageRes = new THREE.Vector2(this.datas.media.width, this.datas.media.height);
      previousTextureRes = new THREE.Vector2(this.datas.media.width, this.datas.media.height);
    }
    const uniforms = {
      seed: (Math.random() * 1000),
      res: new THREE.Uniform(res),
      offset: new THREE.Uniform(new THREE.Vector2(0)),
      mosaic: new THREE.Uniform(1),
      pixels: new THREE.Uniform(10),
      zoom: new THREE.Uniform(1),
      opacity: new THREE.Uniform(1),
      time: new THREE.Uniform(0),
      isVideo: new THREE.Uniform(false),
      pixr: new THREE.Uniform(window.devicePixelRatio),
      rangeFinder: new THREE.Uniform(new THREE.Vector2(0, 0)),
      pixRF: new THREE.Uniform(0),
      zoomRF: new THREE.Uniform(1),
      isCutAnim: new THREE.Uniform((this.transition_type && this.transition_type === 'cut') ? false : true),
      hasViewFinder: new THREE.Uniform(this.showViewFinder),
      textOverlayRes: new THREE.Uniform(textOverlayRes),
      img0Res: new THREE.Uniform(img0Res),
      gridImageRes: new THREE.Uniform(gridImageRes),
      previousTextureRes: new THREE.Uniform(previousTextureRes),
      index: new THREE.Uniform(this.index),
    }

    this.createVisualLayer({frag, vert}, uniforms);


    this.visualLayer.noResUpdate = true;
    this.visualLayer.renderer.setPixelRatio(window.devicePixelRatio);
    if (this.showViewFinder) {
      this.rangeFinder = this.addNode({
        type: 'rangeFinder', freeflow: true, content: {
          size: `${window.innerHeight * 2}`,
          stroke: 5
        }
      });

      this.rangeFinder.style.width = `${window.innerHeight * 2}px`
      this.rangeFinder.style.height = `${window.innerHeight * 2}px`
    }

    this.canvasGrid = CanvasGrid({
      width: window.innerWidth,
      height: window.innerHeight,
      size: 320
    })


    this.setGLUni('gridImage', new Texture());
    this.imgGrid = new Image();
    this.imgGrid.crossOrigin = 'anonymous';
    this.imgGrid.src = this.canvasGrid;
    this.imgGrid.addEventListener('load', (e) => {
      this.setGLUni('gridImage', new Texture(this.showViewFinder ? this.imgGrid : this.img), true);
    });

    this.setGLUni('textO', new Texture());
    this.img = new Image();
    this.img.crossOrigin = 'anonymous';
    this.img.src = this.datas.media?.url;
    this.img.addEventListener('load', (e) => {
      console.log(this.img)
      this.setGLUni('textO', new Texture(this.img), true);
    });

    this.setGLUni('textOverlay', new Texture());
    if (this.datas.content) {
      this.newTexture = CanvasCenteredText({
        width: window.innerWidth,
        height: window.innerHeight,
        text: this.datas.content?.[`${lang}`],
        color: this.datas.color
      });

      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = this.newTexture;

      img.addEventListener('load', (e) => {
        this.setGLUni('textOverlay', new Texture(img), true);
      });
    }

    if (this.datas.logo) {
      this.setTopLeftSlot({
        type: 'imgLogo',
        content: {src: this.datas.logo.url}
      });
    }

    this.setTopRightSlot({
      type: 'verticalText',
      content: {text: this.datas.top_right?.[`${lang}`] ? this.datas.top_right?.[`${lang}`] : this.datas.top_right?.['EN'] || ' '}
    });

    this.setBottomRightSlot({
      type: 'verticalText',
      content: {text: this.datas.bottom_right?.[`${this.lang}`] || ' '}
    });

    if (this.datas.use_swatches) {
      this.addNode({
        type: 'swatchList',
        parent: true,
        content: {
          size: 40,
          groupSize: 3,
          swatches: [
            {
              color: this.datas.color_0 || 'black'
            }, {
              color: this.datas.color_1 || 'white'
            }, {
              color: this.datas.color_2 || 'black'
            }
          ]
        }
      });
    }

    this.setupTimeline();

    window.addEventListener('keypress', (e) => {
      if (e.code === 'Space') {
        this.tl.paused(!this.tl.paused());
      }
    });
  }

  returnTexture() {
    if (this.isVideo) {
      return this.videoTextureGram;
    } else {
      return this.datas.media?.url;
    }
  }

  returnType() {
    return this.isVideo;
  }

  returnSize() {
    return this.datas.media ? [this.datas.media.width, this.datas.media.height] : [100, 100];
  }

  setPreTextureSize(size) {
    this.setGLUni('previousTextureRes', new THREE.Vector2(size[0], size[1]), true);
  }


  preTexture(texture, isVideo) {
    if (isVideo) {
      this.previousTexture = texture;
      this.setGLUni('previousTexture', texture);
    } else {
      this.previousTexture = texture == 'grid' ? this.canvasGrid : texture;
      this.setGLUni('previousTexture', new Texture());
      const img02 = new Image();
      img02.crossOrigin = 'anonymous';
      img02.src = this.previousTexture;
      img02.addEventListener('load', (e) => {
        this.setGLUni('previousTexture', new Texture(img02), true);
      });
    }
    if (this.showViewFinder) {
      this.rangeFinder = this.addNode({
        type: 'rangeFinder', freeflow: true, content: {
          size: `${window.innerHeight * 2}`,
          stroke: 5
        }
      });

      this.rangeFinder.style.width = `${window.innerHeight * 2}px`
      this.rangeFinder.style.height = `${window.innerHeight * 2}px`
      presetTL.bind(this)(this.lang, () => {
      });

    }
  }


  populateTimeline() {
    this.tl.add(gsap.set(this.visualLayer.uniforms.pixa, {value: 500}), 0);
    this.tl.add(gsap.set(this.visualLayer.uniforms.mosaicAmount, {value: this.rangeFinder ? 2 : 1}), 0);
    this.tl.add(gsap.to(this.visualLayer.uniforms.pixa, {
      value: this.rangeFinder ? 20 : 1,
      duration: 1,
      ease: 'power3.out'
    }), 0);
    this.tl.add(gsap.to(this.visualLayer.uniforms.mosaicAmount, {value: 0, duration: 1, ease: 'power3.out'}), 0);
    if (!this.showViewFinder) {
      presetTL.bind(this)(this.lang, this.showViewFinder, () => {
      });

    }
    if (this.index == 0) {
      this.wrapper.style.zIndex = 0;
    }

    this.tl.add(this.stop.bind(this), this.duration);
  }

  preStop() {
    if (this.index == (this.total - 1)) {
      this.wrapper.classList.remove('active');
    } else {
      setTimeout(() => {
        this.wrapper.classList.remove('active');
      }, 2000);
    }
  }

  preStart() {

    if (this.index == 0) {
      this.wrapper.style.zIndex = 10;
    }
    if (this.showViewFinder) {

      if (this.index == 0) {
        this.setGLUni('gridImage', new Texture(this.imgGrid), true);
        setTimeout(() => {

          if (this.isVideo) {
            this.setGLUni('gridImage', this.videoTextureGram);
          } else {
            this.setGLUni('gridImage', new Texture(this.img), true);
          }
        }, 1195)
      } else {
        this.setGLUni('gridImage', new Texture(this.img), true);
      }


    } else {
      this.setGLUni('gridImage', new Texture(this.img), true);
    }
    setTimeout(() => {
      // if(this.index == 0) {
      //   this.wrapper.style.zIndex = 0;
      // }
      this.wrapper.classList.add('active');
    }, 150);

    setTimeout(() => {
      if (this.index == 0) {
        this.wrapper.style.zIndex = 0;
      }
    }, 5000);

  }
}

export default L365Media;
