import * as cssClassNames from '../styles/screens/LayeredMedia.module.scss';
import * as cssGridCellsClassNames from '../styles/components/GridCells.module.scss';

import { MediaType } from '../shared';
import { BaseChapterProps } from '../components/ChapterRenderer';
import Media, {
    calculateDuration as calculateMediaDuration,
    calculateExitDuration as calculateMediaExitDuration,
} from './Media';
import Grid from '../components/Grid';
import GridCells from '../components/GridCells';


const grids = {
    grid: Grid,
    gridCells: GridCells,
};


export type LayeredMediaProps = BaseChapterProps & {
    media?: MediaType[],
    gridVariant?: string,
    overlays?: any[],
};

export default function LayeredMedia(props: LayeredMediaProps) {
    const {
        height,
        width,
        media,
        gridVariant,
        overlays = [],
    } = props;

    const layers = [];

    if (media) {
        layers.push(
            <Media
                {...props}
                media={media}
            />
        );
    }

    const gridBuilder = gridVariants[gridVariant];
    if (gridBuilder) {
        const gridProps = gridBuilder(height, width);
        gridProps.forEach(({template, props}) => {
            const GridRenderer = grids[template];
            layers.push(<GridRenderer {...props} />)
        });
    }

    layers.push(...overlays);

    return (
        <div>
            {layers.map((layer, index) => (
                <div
                    key={index}
                    className={cssClassNames.layer}
                >
                    {layer}
                </div>
            ))}
        </div>
    );
}


const lineWidth = 100 / 1920; // in vh
const dashArrayValue = lineWidth * 2;
const strokeWidth = `${lineWidth}vh`;
const strokeDasharray = `${dashArrayValue}vh ${dashArrayValue}vh`;

const gridVariants = {
    0: (height: number, width: number) => {
        const cellSizeBase = 40 / 1920 * height;
        const totalCols = Math.ceil(width / cellSizeBase);
        const smallCellSize = width / totalCols;

        const anchorPoint = {
            left: Math.ceil(840 / 1080 * width / smallCellSize) * smallCellSize,
            top: 1150 / 1920 * height,
        };

        const smallHeightBase = (1 - anchorPoint.top / 1920) * height;
        const smallWidthBase = anchorPoint.left;
        const smallCols = Math.ceil(smallWidthBase / smallCellSize);
        const smallRows = Math.ceil(smallHeightBase / smallCellSize);

        const bigCellSize = smallCellSize * 6;
        const bigHeightBase = anchorPoint.top + bigCellSize;
        const bigWidthBase = 720 / 1080 * width;
        const bigRows = Math.ceil(bigHeightBase / bigCellSize);
        const bigCols = Math.floor(bigWidthBase / bigCellSize);
        const bigHeight = bigRows * bigCellSize;
        const bigTop = height - anchorPoint.top - bigCellSize;

        return [
            {
                template: 'grid',
                props: {
                    cols: smallCols,
                    rows: smallRows,
                    height: smallRows * smallCellSize,
                    width: smallCols * smallCellSize,
                    style: {
                        opacity: 1,
                        left: 0,
                        top: `${anchorPoint.top}px`,
                    },
                    lineStyle: {
                        stroke: '#A9AAAD',
                        strokeWidth,
                        strokeDasharray,
                    },
                    toDrawBoundaries: true,
                    initialAnimation: 'visible',
                },
            },
            {
                template: 'gridCells',
                props: {
                    cols: smallCols,
                    rows: smallRows,
                    cellHeight: smallCellSize,
                    cellWidth: smallCellSize,
                    style: {
                        opacity: 1,
                        left: 0,
                        top: `${anchorPoint.top}px`,
                    },
                    children: dot(),
                },
            },
            {
                template: 'grid',
                props: {
                    cols: bigCols,
                    rows: bigRows,
                    height: bigHeight,
                    width: bigCols * bigCellSize,
                    style: {
                        opacity: 0.7,
                        right: `${width - anchorPoint.left}px`,
                        bottom: `${bigTop}px`,
                    },
                    lineStyle: {
                        stroke: '#ffffff80',
                        strokeWidth,
                    },
                    toDrawBoundaries: true,
                    initialAnimation: 'visible',
                },
            },
            {
                template: 'grid',
                props: {
                    cols: 1,
                    rows: bigRows,
                    height: bigHeight,
                    width: bigCellSize / 2,
                    style: {
                        opacity: 0.7,
                        left: `${anchorPoint.left}px`,
                        bottom: `${bigTop}px`,
                    },
                    lineStyle: {
                        stroke: '#ffffff80',
                        strokeWidth,
                    },
                    toDrawBoundaries: true,
                    initialAnimation: 'visible',
                },
            },
        ];
    },
    1: (height: number, width: number) => {
        const cellSizeBase = 40 / 1920 * height;
        const totalCols = Math.ceil(width / cellSizeBase);
        const smallCellSize = width / totalCols;

        const smallHeightBase = 600 / 1920 * height;
        const smallRows = Math.ceil(smallHeightBase / smallCellSize);
        const smallHeight = smallRows * smallCellSize;

        const bigCellSize = smallCellSize * 6;
        const bigHeightBase = (1 - 840 / 1920) * height;
        const bigWidthBase = 720 / 1080 * width;
        const bigRows = Math.ceil(bigHeightBase / bigCellSize);
        const bigCols = Math.floor(bigWidthBase / bigCellSize);

        return [
            {
                template: 'grid',
                props: {
                    cols: totalCols,
                    rows: smallRows,
                    height: smallHeight,
                    width: totalCols * smallCellSize,
                    style: {
                        opacity: 0.6,
                        left: 0,
                        bottom: 0,
                    },
                    lineStyle: {
                        stroke: '#A9AAAD',
                        strokeWidth,
                        strokeDasharray,
                    },
                    toDrawBoundaries: true,
                    initialAnimation: 'visible',
                },
            },
            {
                template: 'gridCells',
                props: {
                    cols: totalCols,
                    rows: smallRows,
                    cellHeight: smallCellSize,
                    cellWidth: smallCellSize,
                    style: {
                        opacity: 0.6,
                        left: 0,
                        top: 'unset',
                        bottom: 0,
                    },
                    children: dot(),
                },
            },
            {
                template: 'grid',
                props: {
                    cols: bigCols,
                    rows: bigRows,
                    height: bigRows * bigCellSize,
                    width: bigCols * bigCellSize,
                    style: {
                        opacity: 0.6,
                        right: `${-bigCellSize / 2}px`,
                        bottom: `${((smallHeight / bigCellSize) % 1 - 1) * bigCellSize}px`,
                    },
                    lineStyle: {
                        stroke: '#ffffff',
                        strokeWidth,
                    },
                    toDrawBoundaries: true,
                    initialAnimation: 'visible',
                },
            },
        ];
    },
    2: (height: number, width: number) => {
        const opacity = 0.6;
        const gridHeight = 720 / 1920 * height;

        const mediumCellSizeBase = 120 / 1920 * height;
        const mediumCols = Math.ceil(width / mediumCellSizeBase);
        const mediumCellSize = width / mediumCols;
        const mediumRows = Math.ceil(gridHeight / mediumCellSize);
        const mediumHeight = mediumRows * mediumCellSize;

        const bigCellSize = mediumCellSize * 2;;
        const bigGridWidthBase = 720 / 1080 * width;
        const bigRows = Math.ceil(gridHeight / bigCellSize);
        const bigCols = Math.ceil(bigGridWidthBase / bigCellSize);

        return [
            {
                template: 'grid',
                props: {
                    cols: mediumCols,
                    rows: mediumRows,
                    height: mediumHeight,
                    width: mediumCols * mediumCellSize,
                    style: {
                        bottom: 0,
                        right: 0,
                        opacity,
                    },
                    lineStyle: {
                        stroke: '#ffffff',
                        strokeWidth,
                    },
                    toDrawBoundaries: true,
                    initialAnimation: 'visible',
                },
            },
            {
                template: 'grid',
                props: {
                    cols: bigCols,
                    rows: bigRows,
                    height: bigRows * bigCellSize,
                    width: bigCols * bigCellSize,
                    toDrawBoundaries: true,
                    style: {
                        bottom: `${mediumHeight - 0.5}px`,
                        right: 0,
                        opacity,
                    },
                    lineStyle: {
                        stroke: '#ffffff',
                        strokeWidth,
                    },
                    initialAnimation: 'visible',
                },
            }
        ];
    },
    3: (height: number, width: number) => {
        const opacity = 0.6;

        const cellSizeBase = 40 / 1920 * height;
        const totalCols = Math.ceil(width / cellSizeBase);
        const smallCellSize = width / totalCols;

        const smallHeightBase = height / 2;
        const smallWidthBase = 600 / 1080 * width;
        const smallCols = Math.ceil(smallWidthBase / smallCellSize);
        const smallRows = Math.ceil(smallHeightBase / smallCellSize);

        const mediumWidthBase = 640 / 1080 * width;
        const mediumHeightBase = 560 / 1920 * height;
        const mediumCellSize = smallCellSize * 2;
        const mediumCols = Math.ceil(mediumWidthBase / mediumCellSize);
        const mediumRows = Math.ceil(mediumHeightBase / mediumCellSize);

        return [
            {
                template: 'grid',
                props: {
                    rows: smallRows,
                    cols: smallCols,
                    height: smallRows * smallCellSize,
                    width: smallCols * smallCellSize,
                    style: {
                        right: 0,
                        bottom: 0,
                        opacity,
                    },
                    lineStyle: {
                        stroke: '#ffffff',
                        strokeWidth,
                        strokeDasharray,
                    },
                    toDrawBoundaries: true,
                    initialAnimation: 'visible',
                },
            },
            {
                template: 'gridCells',
                props: {
                    rows: smallRows,
                    cols: smallCols,
                    cellWidth: smallCellSize,
                    cellHeight: smallCellSize,
                    style: {
                        left: 'unset',
                        top: 'unset',
                        right: 0,
                        bottom: 0,
                        opacity,
                    },
                    children: dot(),
                },
            },
            {
                template: 'grid',
                props: {
                    rows: mediumRows,
                    cols: mediumCols,
                    height: mediumRows * mediumCellSize,
                    width: mediumCols * mediumCellSize,
                    style: {
                        left: 0,
                        bottom: `${mediumCellSize}px`,
                        opacity,
                    },
                    lineStyle: {
                        stroke: '#ffffff',
                        strokeWidth,
                    },
                    toDrawBoundaries: true,
                    initialAnimation: 'visible',
                },
            },
        ];
    },
    4: (height: number, width: number) => {
        const opacity = 0.5;
        const gridHeight = 720 / 1920 * height;

        const mediumCellSizeBase = 120 / 1920 * height;
        const mediumCols = Math.ceil(width / mediumCellSizeBase);
        const mediumCellSize = width / mediumCols;
        const mediumRows = Math.ceil(gridHeight / mediumCellSize);
        const mediumHeight = mediumRows * mediumCellSize;

        const smallCellSize = mediumCellSize / 3;
        const smallCols = mediumCols * 3;
        const smallRows = mediumRows * 3;

        const bigCellSize = mediumCellSize * 2;;
        const bigGridWidthBase = 720 / 1080 * width;
        const bigRows = Math.ceil(gridHeight / bigCellSize);
        const bigCols = Math.ceil(bigGridWidthBase / bigCellSize);

        return [
            {
                template: 'gridCells',
                props: {
                    cols: smallCols,
                    rows: smallRows,
                    cellHeight: smallCellSize,
                    cellWidth: smallCellSize,
                    style: {
                        top: 'unset',
                        bottom: 0,
                        right: 0,
                        opacity,
                    },
                    lineStyle: {
                        strokeWidth,
                    },
                    children: dot(),
                },
            },
            {
                template: 'grid',
                props: {
                    cols: smallCols,
                    rows: smallRows,
                    height: mediumHeight,
                    width: smallCols * smallCellSize,
                    style: {
                        bottom: 0,
                        right: 0,
                        opacity,
                        color: '#ffffff',
                    },
                    lineStyle: {
                        stroke: '#ffffff',
                        strokeWidth,
                        strokeDasharray,
                    },
                    toDrawBoundaries: true,
                    initialAnimation: 'visible',
                },
            },
            {
                template: 'grid',
                props: {
                    cols: mediumCols,
                    rows: mediumRows,
                    height: mediumHeight,
                    width: mediumCols * mediumCellSize,
                    style: {
                        bottom: 0,
                        right: 0,
                        opacity,
                    },
                    lineStyle: {
                        stroke: '#ffffff',
                        strokeWidth,
                    },
                    toDrawBoundaries: true,
                    initialAnimation: 'visible',
                },
            },
            {
                template: 'grid',
                props: {
                    cols: bigCols,
                    rows: bigRows,
                    height: bigRows * bigCellSize,
                    width: bigCols * bigCellSize,
                    toDrawBoundaries: true,
                    style: {
                        bottom: `${mediumHeight - 0.5}px`,
                        right: 0,
                        opacity,
                    },
                    lineStyle: {
                        stroke: '#ffffff',
                        strokeWidth,
                    },
                    initialAnimation: 'visible',
                },
            }
        ];
    },
    5: (height: number, width: number) => {
        const cellSizeRef = 40/1920;

        const cellSize = cellSizeRef * height;
        const rows = Math.ceil((height - 2) / cellSize);
        const cols = Math.ceil(width / cellSize);
        const gridWidth = cols * cellSize;
        const gridHeight = rows * cellSize;
        const gridXOffset = -(gridWidth - width) / 2;

        return [
            {
                template: 'grid',
                props: {
                    rows,
                    cols,
                    height: gridHeight,
                    width: gridWidth,
                    style: {
                        opacity: 0.6,
                        left: `${gridXOffset}px`,
                    },
                    lineStyle: {
                        stroke: '#A9AAAD',
                        strokeWidth,
                        strokeDasharray,
                    },
                    toDrawBoundaries: true,
                    initialAnimation: 'visible',
                },
            },
            {
                template: 'gridCells',
                props: {
                    rows,
                    cols,
                    cellHeight: cellSize,
                    cellWidth: cellSize,
                    style: {
                        opacity: 0.6,
                        left: `${gridXOffset}px`,
                    },
                    children: dot(),
                },
            },
        ];
    },
};

const dot = () => (
    <div
        className={cssGridCellsClassNames.dot}
        style={{
            background: '#ffffff',
        }}
    ></div>
);


export function calculateDuration(props: LayeredMediaProps) {
    const {
        media,
    } = props;

    const mediaDuration = calculateMediaDuration({media});

    return mediaDuration;
}

export function calculateExitDuration(props: LayeredMediaProps) {
    const {
        media,
    } = props;

    return calculateMediaExitDuration({media});
}
