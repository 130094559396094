import React, {useMemo} from 'react';
import Transition from "../components/Transition";
import {MediaType, stringFallback, ThemeType, timedMedia} from "../shared";
import Background from "../components/Background";
import Media from "../components/Media";
import Typography from "../components/Typography";
import {getColor} from "../styles/color";
import Scan from "../components/Scan";
import Line from "../components/Line";
import FamilyTicker from "../components/FamilyTicker";
import Arrow from "../assets/icons/Arrow";
import QrSection, {QrCodeInterface} from "../components/QrSection";
import GlitchTypography from "../components/GlitchText/GlitchTypography";
import {getChannelInfo} from '../../rise/hyperlive/utils';
import {containsChinese} from "../../../utilities";

interface MembershipProps {
    title: string;
    membership: string;
    duration: number;
    media: Array<MediaType>;
    qr_code?: QrCodeInterface;
    footer: string;
    theme: ThemeType;
}

const defaultMembership = 'Sign up for Nike Membership and connect with the Jordan fam to get access to the hottest drops, Members-only events and services, plus new spaces to create and connect with basketball culture.';

const theme: ThemeType = 'dark';

export default function Membership({
                                       title = '',
                                       membership = '',
                                       footer = '',
                                       media = [],
                                       qr_code,
                                       duration,
                                       // theme = 'dark',
                                       ...other
                                   }: MembershipProps) {
    const mainTitle = useMemo(() => stringFallback(title, ''), [title]);
    const membershipBody = useMemo(() => stringFallback(membership, defaultMembership), [membership]);
    const footerCopy = useMemo(() => stringFallback(footer, 'FLY WITH THE FAM'), [footer]);
    const color = useMemo(() => theme === 'dark' ? 'white' : 'black', [theme])
    const lineStyle = useMemo(() => ({marginTop: 36, height: 'calc(100% - 36px - 36px)'}), []);
    const mediaTimed = useMemo(() => timedMedia(media, duration), [media]);
    const tickerDuration = useMemo(() => getChannelInfo('json_config.duration.vertical_ticker'), [theme]);

    return (
        <Transition variant={'wipe-x'} cover preventChildExit>
            <Background
                variant={theme === 'dark' ? 'dark-dust' : 'light-dust'}
            >
                <MegaTitle
                    color={color}
                    title={mainTitle}
                />

                {/* LINE 1 */}
                <div style={{marginLeft: 30, ...lineStyle}}>
                    <Line theme={theme}/>
                </div>

                {/* LINE 2 */}
                <div style={{width: 200, position: 'relative'}}>
                    <div style={{marginTop: 36, marginLeft: '30px', width: '100%', textAlign: 'center'}}>
                        <Typography variant={'jordan'} size={20} color={color}>
                            JORDAN FAMILY
                        </Typography>
                    </div>

                    <div style={{position: "absolute", left: 30, bottom: 40}}>
                        <QrSection qrCode={qr_code} theme={theme} />
                    </div>

                </div>

                {/* LINE 3 */}
                <div style={{marginLeft: 23, ...lineStyle}}>
                    <Line theme={theme}/>
                </div>

                {/* CENTER */}
                <div style={{margin: '86px 30px', position: 'relative'}}>
                    <Media
                        preventInitial
                        useInternalTiming
                        media={mediaTimed}
                        width={666}
                        height={1012}
                        theme={theme}
                        dim
                    />
                    <CenterContent color={color} body={membershipBody}/>

                    <div style={{position: 'absolute', bottom: -50, width: '95%'}}>
                        <Typography variant={'jordan'} align={"right"} size={20} color={color}>
                            {footerCopy}
                        </Typography>
                    </div>
                </div>


                <FamilyTicker theme={theme} duration={tickerDuration}/>
            </Background>
        </Transition>
    )
}

interface MegaTitleProps {
    title: string;
    color: 'black' | 'white';
}

function MegaTitle({title, color}: MegaTitleProps) {
    const shouldGlitch = useMemo(() => {
        return false;
        // return containsChinese(title) === false;
    }, [title]);

    return (
        <div
            style={{
                position: 'absolute',
                top: 491,
                left: 60,
                zIndex: 10
            }}
        >
            {
                shouldGlitch ? (
                    <GlitchTypography  variant={'jordan-condensed'} size={400} color={color}>
                        {title}
                    </GlitchTypography>
                ) : (
                    <Typography variant={'jordan-condensed'} size={400} color={color} uppercase>
                        {title}
                    </Typography>
                )
            }

        </div>
    )
}

interface CenterContentProps {
    color: 'black' | 'white';
    body: string;
}

function CenterContent({color, body}: CenterContentProps) {
    return (
        <div style={{position: 'relative', marginTop: 178}}>
            <div style={{position: 'absolute', left: -200, display: 'flex', gap: '10px', color: getColor(color)}}>
                <Arrow/>
                <Arrow/>
            </div>
            <div style={{width: 584}}>
                <Typography variant={'pp-ultralight'} size={38} color={color}>
                    {body}
                </Typography>
            </div>

        </div>
    )
}