import { useFastLane } from "../store";
import SectionContainer from "./section-container";
import {ABSOLUTE_FILL} from "../helpers";
import {DisplayType, FastLaneSection, PixelMap} from "../types";
import React, {ReactNode, useMemo} from "react";
import TickerEntriesContainer from "./ticker-entries-container";
import {useDisplayType, usePixelMap} from "../store/fast-lane-context";
import {EMPTY_SECTION} from "../shared";

function generateFirstEntry(curr: FastLaneSection, pixelMap: PixelMap, isEditor: boolean) {
	// only pad when the lane is offset to the right (to fill the blank space)
	// and is not in the editor mode
	const offsetLeft = isEditor ? 0 : Math.min(-1 * pixelMap.offsetLeft, 0)

	// calculate the width
	const firstWidth = curr.position.width - curr.position.left;
	const widthCompensated = (firstWidth) + (-1 * offsetLeft);

	return {
		...EMPTY_SECTION,
		position: {
			...EMPTY_SECTION.position,
			left: offsetLeft,
			width: widthCompensated,
		}
	}
}

function generateLastEntry(lastLeft: number, pixelMap: PixelMap) {
	const lastWidth = pixelMap.contentWidth - lastLeft;

	return {
		...EMPTY_SECTION,
		position: {
			...EMPTY_SECTION.position,
			left: lastLeft,
			width: lastWidth,
		}
	}
}

function padEntries(entries: Array<FastLaneSection>, pixelMap: PixelMap, isEditor: boolean) {

	return entries.reduce((acc, curr, index) => {
		let first: FastLaneSection | null = index === 0 ? generateFirstEntry(curr, pixelMap, isEditor) : null;

		const lastLeft = curr.position.left + curr.position.width;
		const last = index === entries.length - 1 ? generateLastEntry(lastLeft, pixelMap) : null;

		return [
			...acc,
			first,
			curr,
			last,
		].filter(Boolean);
	}, []);
}

export default function Entries() {
	const takeover = useFastLane(f => f.takeover);
	const entries = useFastLane(f => f.entries);
	const display = useDisplayType();
	const isEditor = useFastLane(f => f.isEditor);
	const pixelMap = usePixelMap();

	const paddedEntries = useMemo(() => {
		if (display === 'ticker') {
			return padEntries(entries, pixelMap, isEditor);
		}

		return entries;
	}, [entries, display, pixelMap, isEditor]);

	const components: Record<DisplayType, ({children}: {children: ReactNode}) => JSX.Element> = {
		'fast-lane': ({children}) => (
			<div style={{...ABSOLUTE_FILL, zIndex: takeover?.variant === 'background' ? 1 : undefined }}>
				{children}
			</div>
		),
		'ticker': ({children}) => (
			<TickerEntriesContainer>
				{children}
			</TickerEntriesContainer>
		)
	};

	const Component = components[display];

	return (
		<Component>
			{paddedEntries.map((entry, index) =>
				<SectionContainer
					key={`entry-${index}`}
					section={entry}
					index={index}
				/>,
			)}
		</Component>
	);
}