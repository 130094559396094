import Typography from "./Typography";
import {SnkrsCalMode} from "./SnkrsCalHelpers";
import {useMemo} from "react";
import {containsChinese} from "../../../utilities";

export default function SnkrsCalFooter({mode, footerCopy = "SNKRS\nUpcoming Drops"}: { mode: SnkrsCalMode, footerCopy?: string }) {
    const [line1 = '', line2 = ''] = useMemo(()=> {
        return footerCopy?.split('\n');
    }, [footerCopy]);

    const hasChinese = useMemo(() => {
        return containsChinese(line1);
    }, [line1]);

    return (
        <>
            {
                mode === 'drops' && (
                    <div
                        style={{
                            height: '212px',
                            width: '100%',
                            background: 'white',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            paddingLeft: '50px'
                        }}
                    >
                        <div>
                            <Typography
                                size={48}
                                variant={hasChinese ? 'pp-ultralight' : 'pp-ultralight-italic'}
                                color={'black'}
                            >
                                {line1}
                            </Typography>
                            <Typography size={48} variant={'pp-ultralight'} color={'black'}>
                                {line2}
                            </Typography>
                        </div>
                    </div>
                )
            }
        </>
    )
}