import MediaChapter from './MediaChapter';
import type { UniversalTakeover } from '../../../services/takeover-service/universal-takeover';
import { findBestMedia } from '../../../services/takeover-service/utils';

export default class UniversalTakeoverPlayer extends MediaChapter {
  component = () => {
    const media = findBestMedia(this.chapter().assets, {
      width: this.context?.width ?? window.innerWidth,
      height: this.context?.height ?? window.innerHeight,
    });

    return this.renderComponent([media], true);
  };

  chapter = () => {
    return this.data as UniversalTakeover
  }

  calculateDuration(): number {
    return this.chapter().duration;
  }
}