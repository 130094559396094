import { ANIMATION_TYPES } from '../../constants';
import {getChannel} from "../../touchpoints/rise/api";
import InitializeTakeover from "../../touchpoints/jordan/animations/takeover/entry";
import { get } from "lodash";
import ChapterPlayerTakeoverEntry from '../../touchpoints/chapter-player/takeover-entry';
import type {
    Animation,
    AnimationData,
    BaseTakeoverChapterType,
    Channel,
    ChannelResult,
    ChannelsEntity,
    Store,
} from './types';
import { initTakeoverChapter, triggerTakeover } from './utils';
import { listenForHijack } from './takeover-hijacker';
import socketListener from '../../utils/websocket';

const TAKEOVER_INITIALIZER = {
    [ANIMATION_TYPES.WOF_CORE]: InitializeTakeover,
    [ANIMATION_TYPES.CHAPTER_PLAYER]: ChapterPlayerTakeoverEntry,
}

export default class StoreTakeover {
    private channel: Channel;
    private store: Store;
    private animation: Animation;
    private animationChannel: ChannelsEntity;
    private compareTo: string;
    private isHijacked: boolean;

    constructor(channel: Channel, store: Store) {
        this.channel = channel;
        this.store = store;

        this.execute();
    }

    updateChannel(channel: Channel) {
        this.channel = channel;
    }

    execute({afterFetch}: { afterFetch?: Function } = {}): void {
        const channel = this.getDominantChannel();

        if (!channel) {
            this.channel = null;
            this.animationChannel = null
            this.compareTo = null;

            return;
        }

        listenForHijack(() => {
            this.isHijacked = true;
        });


        this.loadAnimations((data: AnimationData) => {
            this.compareTo = JSON.stringify(data);

            this.run();

            const schedule = data.takeover_scheduling;

            if (!schedule?.type || (!schedule?.due && !schedule?.interval)) {
                return;
            }

            const takeoverChapter = data?.chapters?.[0] ?? undefined;
            initTakeoverChapter(takeoverChapter);

            // setTimeout(() => {
            //     const scope = this.isHijacked ? 'INTERNAL_TAKEOVER' : 'FULL_TAKEOVER';
            //     triggerTakeover(scope, 'start', takeoverChapter as BaseTakeoverChapterType);
            // }, 2_000);

            const url = this.channel?.takeover?.ws_listener_url ?? 'wss://ws.development.nikesportpulse.com/';

            socketListener({
                url,
                filter: (data: any) => {
                    return data.channel.endsWith(this.animationChannel.slug);
                },
                onMessage: (message: any) => {
                    if (message.event !== 'play') {
                        return;
                    }
                    const scope = this.isHijacked ? 'INTERNAL_TAKEOVER' : 'FULL_TAKEOVER';
                    triggerTakeover(scope, 'start', takeoverChapter as BaseTakeoverChapterType);
                },
                reconnectInterval: 1000,
            });
            //
            // if (schedule?.type === 'date') {
            //     shouldPlayTakeoverDue(
            //         new Date(schedule.due),
            //         () => {
            //             const scope = this.isHijacked ? 'INTERNAL_TAKEOVER' : 'FULL_TAKEOVER';
            //             triggerTakeover(scope, 'start', takeoverChapter);
            //         }
            //     );
            // }

            // if (schedule?.type === 'interval') {
            //     shouldPlayTakeover(schedule.interval / 1_000 / 60, () => {
            //         const scope = this.isHijacked ? 'INTERNAL_TAKEOVER' : 'FULL_TAKEOVER';
            //         triggerTakeover(scope, 'start', takeoverChapter);
            //     });
            // }
        });
    }

    /**
     * @deprecated
     */
    getLegacyDominantChannel(): string | undefined {
        const storeTakeoverChannels = this.store?.json_meta?.store_takeover?.channels;

        if (!storeTakeoverChannels?.length) {
            return;
        }

        const storeTakeover = storeTakeoverChannels?.[0] ?? null;
        const channelTakeover = this.channel.json_config?.store_takeover ?? null;

        if (!channelTakeover && !storeTakeover || (channelTakeover === false || channelTakeover?.channel === false)) {
            return;
        }

        if (channelTakeover?.channel) {
            return channelTakeover.channel;
        }

        if (storeTakeover) {
            return storeTakeover;
        }

        return;
    }

    getDominantChannel(): string | undefined {
        const configuredTakeoverChannelSlug = get(this.channel, 'takeover.slug', null);
        if (configuredTakeoverChannelSlug) {
            return configuredTakeoverChannelSlug;
        }

        /**
         * left to support backward compatibility of old takeover configuration stored in store/channels JSON configs
         */
        return this.getLegacyDominantChannel();
    }

    async loadAnimations(callback: (schedule: AnimationData) => void, settings: {includeEmptyResult?: boolean} = {}) {
        const channel = this.getDominantChannel();

        if (!channel) {
            if (settings?.includeEmptyResult) {
                callback(null);
            }
            return;
        }

        getChannel(channel).then((data: ChannelResult) => {
            const channel = data.channels[0];
            const animation = channel.schedule?.[0].animation;
            const schedule = animation?.json_data;
            // make sure there's at least a bit of scheduling in here.
            if (!schedule && !settings?.includeEmptyResult) {
                return;
            }

            this.animationChannel = channel;
            this.animation = animation;
            callback(schedule);
        }).catch(() => {
            console.warn(`Could not load channel [${channel}] for store-takeover, make sure you use an existing channel.`)
            if (settings?.includeEmptyResult) {
                callback(null);
                return;
            }
        });
    }

    run() {
        // will find the right animation type
        // append overlay to dom
        // inject the template into it
        // make sure it has handlers for `start_at` and `end_at`
        // this will likely be an array
        // when the time is due
        // kick of the template (so make sure it has an event handler of sorts)
        // when done just hard cut out and wait for the next one
        if (this.isHijacked) {
            return;
        }

        const takeoverInitializer = TAKEOVER_INITIALIZER[this.animation.animation_type_id]
            || TAKEOVER_INITIALIZER[ANIMATION_TYPES.WOF_CORE];
        takeoverInitializer?.(this.animation.json_data, this.animationChannel ?? {});
    }

    isDifferent(callback: Function) {
        this.loadAnimations((data) => {
            if(!data && !this.compareTo) {
                callback(false);
                return;
            }

            callback(JSON.stringify(data) !== this.compareTo)
        }, {includeEmptyResult: true})
    }

    async reload(): Promise<void> {
        this.isDifferent((isDifferent: boolean) => {
            if (isDifferent) {
                window.location.href =
                    window.location.pathname +
                    window.location.search +
                    window.location.hash;
            }
        });
    }
}
