import {AnimatePresence} from "framer-motion";
import { DEFAULT_GRADIENT, setAnimationTimeout } from "../shared";
import Transition from "../../../../jordan/components/Transition";
import {CSSProperties, useEffect, useState} from "react";


const ANIMATION_STATE = {
    INITIAL: 'initial',
    WIPE_ON_COLOR: 'wipe-on-color',
    BLACK_VISIBLE: 'black-visible',
    WIPE_ON_COLOR_REVERSE: 'wipe-on-color-reverse',
    WAIT: 'wait',
} as const;

const animationSequesnce = [
    {
        delay: 0,
        nextState: ANIMATION_STATE.WIPE_ON_COLOR,
    },
    {
        delay: 1000,
        nextState: ANIMATION_STATE.BLACK_VISIBLE,
    },
    {
        delay: 4000,
        nextState: ANIMATION_STATE.WIPE_ON_COLOR_REVERSE,
    },
    {
        delay: 880,
        nextState: ANIMATION_STATE.WAIT,
    },
    {
        delay: 20,
        nextState: ANIMATION_STATE.INITIAL,
    },
]

type animationStateType = (typeof ANIMATION_STATE)[keyof typeof ANIMATION_STATE];

type StepCounterProps = {
    step: string,
    backgroundBase?: string,
    backgroundSecondary?: string,
}

export default function StepCounter(props: StepCounterProps) {
    const {
        step,
        backgroundBase,
        backgroundSecondary,
    } = props;

    const [animationState, setAnimationState] = useState<animationStateType>(ANIMATION_STATE.INITIAL);
    const [animationStepIndex, setAnimationStepIndex] = useState(0);

    const position: CSSProperties = {
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
    };

    useEffect(() => {
        setAnimationStepIndex(0);
    }, [step]);

    useEffect(() => {
        if (animationStepIndex >= animationSequesnce.length) {
            return;
        }

        const animationStep = animationSequesnce[animationStepIndex];
        if (!animationStep) {
            return;
        }

        return setAnimationTimeout(() => {
            setAnimationState(animationStep.nextState);
            setAnimationStepIndex(i => ++i);
        }, animationStep.delay);
    }, [animationStepIndex]);

    const outlineBackgroundStyle = {
        ...position,
        background: backgroundSecondary || DEFAULT_GRADIENT,
        zIndex: 2,
    };

    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                position: 'relative',
            }}
        >

            <AnimatePresence>
                {
                    ['wipe-on-color'].includes(animationState) && (
                        <Transition
                            key={'wipe-on-color'}
                            variant={'wipe-x'}
                            duration={1}
                            style={outlineBackgroundStyle}
                        >
                            <Outlined value={step} color={'#fff'}/>
                        </Transition>
                    )
                }

                {
                    ['black-visible', 'wipe-on-color-reverse'].includes(animationState) && (
                        <div
                            key={'black-visible'}
                            style={{
                                ...position,
                                background: backgroundBase,
                                zIndex: 1
                            }}
                        >
                            <Outlined value={step} color={'#000'}/>
                        </div>
                    )
                }

                {
                    ['wipe-on-color-reverse', 'wait'].includes(animationState) && (
                        <Transition
                            key={'wipe-on-color-reverse'}
                            variant={'wipe-x-reverse'}
                            duration={1}
                            style={outlineBackgroundStyle}
                        >
                            <Outlined value={step} color={'#fff'}/>
                        </Transition>
                    )
                }
            </AnimatePresence>


        </div>
    )
}

function Outlined({value, color = '#fff'}: { value: string, color?: string }) {
    return (
        <svg
            style={{
                height: '100%',
                width: '100%',
            }}
            viewBox="0 0 10 10"
        >
            <text
                x="5"
                y="6"
                textAnchor="middle"
                alignmentBaseline="middle"
                fontSize="9"
                fill="none"
                strokeWidth=".12"
                stroke={color}
                fontFamily="FUTURA"
            >
                {value}
            </text>
        </svg>
    )
}