import {EntryType} from "../types";
import React from "react";
import Header from "../components/header";
import {useMembership} from "../store";
import Entry from "../components/entry";
import {useRpx} from "../shared";
import {motion} from "framer-motion";
import {DEFAULT_TRANSITION} from "../../../../rise/constants/transition";

export default function Membership() {
    const entries = useMembership(state => state.animationData?.entries);
    const active = useMembership(state => state.activeEntryIndex);
    const rpx = useRpx();

    return (
        <div
            style={{
                height: '100vh',
                fontFamily: 'palatino',
                padding: rpx(40),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <Header/>

            <motion.div
                layout={'position'}
                initial={false}
                transition={{
                    ...DEFAULT_TRANSITION,
                    duration: .5,
                }}
            >
                {
                    entries?.map((entry: EntryType, index: number) => (
                        <Entry
                            key={index}
                            entry={entry}
                            isActive={active === index}
                        />
                    ))
                }
            </motion.div>
        </div>
    )
}
