import Transition from "../components/Transition";
import {useEffect, useMemo, useState} from "react";
import {
    keyframe,
    MediaType,
} from "../shared";
import SnkrsCalDropListEntry from "../components/SnkrsCalDropListEntry";
import {getStoreLanguages} from '../../rise/hyperlive/utils';
import {JordanConfig} from "../components/ChapterRunner";
import {SnkrsCalDetail} from "../components/SnkrsCalDetail";
import {BG_COLOR, SnkrsCalMode} from "../components/SnkrsCalHelpers";
import SnkrsCalFooter from "../components/SnkrsCalFooter";
import {AnimatePresence, motion} from "framer-motion";
import {DEFAULT_TRANSITION} from "../../rise/constants/transition";
import {useDerivedPlayMode} from "./SnkrsCal.hooks";
import {Swoosh} from "../../house-of-innovation/components/Icons";
import {get} from "lodash";

export interface SnkrsCalProps {
    drops: Array<DropInterface>;
    count?: number;
    play_range?: string;
    mode: SnkrsCalMode;
    index: number;
    hide_dates?: boolean;
    cta_label?: string;
    locale?: string;
    config: JordanConfig;
    available_copy?: string;
    qr_url?: string;
    play_mode: 'grid' | 'carousel';
    footer_copy?: string;
    concept?: 'wof' | 'one-nike';
}

export interface DropInterface {
    scc?: string;
    fulltitle: string;
    subtitle: string;
    slug: string;
    url?: string;
    key: string;
    colorDescription: string;
    description: string;
    startEntryDate: Date;
    images: Array<MediaType>;
    cover: MediaType;
    qr_link?: string;

    // Stories
    title?: string;
}

export function getPlayArray(play_range: string, limit?: number) {
    const [start, end] = play_range.split(',').map(i => Number(i));
    const limited = limit !== undefined ? limit - 1 : end;

    return [...new Array((limited - start) + 1)].map((a, i) => i + start);
}

export default function SnkrsCal(props: SnkrsCalProps) {
    const {
        drops,
        play_range = '0,5',
        count = 6,
        mode = 'drops',
        index,
        hide_dates = false,
        cta_label,
        qr_url,
        locale: givenLocale,
        config,
        available_copy,
        play_mode = 'grid',
        footer_copy,
        concept: forcedConcept ,
    } = props;

    const concept = useMemo(() => {
        const fromStore = get(window, 'animation_store.json_data.store_type', 'WOF')?.toLowerCase() === 'wof' ? 'wof' : 'one-nike';
        return forcedConcept ?? fromStore;
    }, [forcedConcept]);

    const filtered = useMemo(() => {
        return drops?.slice(0, count);
    }, [drops, count]);
    const derivedPlayMode = useDerivedPlayMode(play_mode, filtered);

    const {snkrs} = config.duration;
    const locale = useMemo(() => {
        if (givenLocale) {
            return givenLocale;
        }

        return getStoreLanguages()?.[0] ?? 'US';
    }, [givenLocale]);
    const [selected, setSelected] = useState(null);
    const [runner, setRunner] = useState(0);

    useEffect(() => {
        const {trigger, clear} = keyframe(0);
        const playLength = getPlayArray(play_range, drops.length);
        const modeDuration = ((mode === 'drops' ? snkrs.drop : snkrs.story) / 1_000) + 1;
        const overview = derivedPlayMode === 'carousel' ? 0 : snkrs.overview / 1_000;

        const retrigger = playLength.reduce((trigger, index) => {
            return trigger
            (overview, () => setSelected(index))
            (modeDuration, () => setSelected(null));
        }, trigger);

        retrigger(6, () => setRunner(i => i + 1));

        return clear;
    }, [runner, derivedPlayMode]);

    useEffect(() => {
        if (derivedPlayMode === 'carousel' && selected === null && filtered.length > 0) {
            setSelected(0);
        }
    }, [selected, derivedPlayMode]);

    return (
        <Transition variant={'wipe-x'} style={{position: 'absolute'}}>
            <div
                style={{
                    background: concept === 'wof' ? BG_COLOR : '#fff',
                    height: '100%',
                    width: '100%',
                }}
            >
                {
                    concept === 'one-nike' && (
                        <div style={{position: 'absolute', top: 80, left: 40, zIndex: 99}}>
                            <Swoosh color={'black'}/>
                        </div>
                    )
                }

                <div style={{
                    width: '100%',
                    height: mode === 'stories' ? '100%' : '1708px',
                    display: 'flex',
                    flexWrap: 'wrap',
                }}>
                    {
                        derivedPlayMode === 'grid' && filtered.map((drop, i) =>
                            <SnkrsCalDropListEntry
                                locale={locale}
                                hide_dates={hide_dates}
                                index={index}
                                mode={mode}
                                key={drop.key}
                                drop={drop}
                                available_copy={available_copy}
                                onTop={selected === i}
                                concept={concept}
                            />
                        )
                    }
                </div>

                {
                    concept === 'wof' && (
                        <SnkrsCalFooter
                            mode={mode}
                            footerCopy={footer_copy}
                        />
                    )
                }


                <AnimatePresence
                    mode={'popLayout'}
                >
                    <motion.div
                        key={derivedPlayMode === 'carousel' ? selected : undefined}
                        initial={{
                            x: derivedPlayMode === 'carousel' ? '100%' : undefined,
                            opacity: 0,
                        }}
                        animate={{
                            x: derivedPlayMode === 'carousel' ? '0%' : undefined,
                            opacity: 1,
                        }}
                        exit={{
                            x: derivedPlayMode === 'carousel' ? '-100%' : undefined,
                            opacity: 0
                        }}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                        }}
                        transition={{
                            ...DEFAULT_TRANSITION,
                        }}
                    >
                        <SnkrsCalDetail
                            drop={filtered?.[selected]}
                            config={config}
                            mode={mode}
                            show={selected !== null}
                            index={index}
                            hide_dates={hide_dates}
                            cta_label={cta_label}
                            qr_url={qr_url}
                            locale={locale}
                            available_copy={available_copy}
                            play_mode={derivedPlayMode}
                            concept={concept}
                        />
                    </motion.div>
                </AnimatePresence>
            </div>
        </Transition>
    )
}