import { hijackTakeover } from './takeover-hijacker';
import type { BaseTakeoverChapterType } from './types';
import { type EffectCallback, useEffect, useRef, useState } from 'react';
import type { MediaType } from '../../touchpoints/jordan/shared';
import { filter } from 'lodash';

export function shouldPlayTakeoverDue(dueDateTime: Date, callback: Function, precision = 3) {
  const round = (d: Date) => {
    return Math.floor(Number(d) / 1_000);
  };
  const due = round(dueDateTime);
  let triggered = false;

  const check = () => {
    const isDue = round(new Date()) === due;

    if (triggered) {
      return;
    }

    if (isDue && triggered === false) {
      triggered = true;
      callback();

      setTimeout(() => {
        triggered = false;
      }, 1_000);
    }
    requestAnimationFrame(check);
  };

  check();
}

const EVENT_TRIGGER_KEY = 'store_takeover';

type TakeoverCallbackType = 'start' | 'stop';
export type TakeoverScope = 'FULL_TAKEOVER' | 'INTERNAL_TAKEOVER';
type TakeoverCallbackParams = { type: TakeoverCallbackType, chapter?: BaseTakeoverChapterType, scope?: TakeoverScope };
type TakeoverCallback = (params: TakeoverCallbackParams) => void;
type TakeoverEvent = CustomEvent<TakeoverCallbackParams>;

export function listenForTakeover(
  scope: TakeoverScope = 'FULL_TAKEOVER',
  callback: TakeoverCallback,
): EffectCallback {
  const action = (e: TakeoverEvent) => {
    if (e.detail?.scope !== scope) {
      return;
    }

    callback({ type: e.detail?.type, chapter: e.detail.chapter });
  };

  if (scope === 'INTERNAL_TAKEOVER') {
    hijackTakeover();
  }

  window.addEventListener(EVENT_TRIGGER_KEY, action);

  return () => {
    window.removeEventListener(EVENT_TRIGGER_KEY, action);
  };
}

export function triggerTakeover(scope: TakeoverScope, type: TakeoverCallbackType, chapter?: BaseTakeoverChapterType) {
  console.log('triggering Takeover', chapter);
  const event = new CustomEvent(EVENT_TRIGGER_KEY, {
    detail: { type, chapter, scope },
  });

  if (chapter?.duration) {
    setTimeout(() => {
      triggerTakeover(scope, 'stop');
    }, chapter?.duration * 1_000);
  }

  window.dispatchEvent(event);
}

const INIT_TAKEOVER_CHAPTER = 'init-takeover-chapter';
export const takeoverChapter = new Promise((resolve) => {
  const listener = ({ detail }) => {
    resolve(detail.chapter);

    window.removeEventListener(INIT_TAKEOVER_CHAPTER, listener);
  };

  window.addEventListener(INIT_TAKEOVER_CHAPTER, listener);
});

export const initTakeoverChapter = chapter => {
  const event = new CustomEvent(INIT_TAKEOVER_CHAPTER, {
    detail: { chapter },
  });

  window.dispatchEvent(event);
};

type TakeoverListeners<T, A> = {
  start: (chapter: T, context: A) => void,
  stop: () => void,
  initialize?: (chapter: T) => Promise<A>,
}

export function useTakeover<T, A>({ start, stop, initialize }: TakeoverListeners<T, A>) {
  const [chapter, setChapter] = useState<T>(null);
  const mainChapter = useRef<T>(null);
  const context = useRef<A>(null);

  useEffect(() => {
    if (!chapter) {
      return;
    }

    console.log('initializing chapter', chapter);
    initialize?.(chapter as T).then((data: A ) => {
      context.current = data;
    });
  }, [chapter]);

  useEffect(() => {
    takeoverChapter.then(chapter => {
      mainChapter.current = chapter as T;
      setChapter(chapter as T);
    });

    const destructor = listenForTakeover('INTERNAL_TAKEOVER', ({ type, chapter }) => {
      switch (type) {
        case 'start':
          start(chapter as T, context?.current);
          break;

        case 'stop':
        default:
          stop();
          break;
      }
    });

    return () => {
      destructor();
    };
  }, []);

  return {
    chapter,
  };
}

interface BestMediaConfig {
  width?: number;
  height?: number;
  tag?: string;
}

/**
 * Ranks media based on the following criteria:
 */
export function findBestMedia(list: Array<MediaType> = [], { width, height, tag }: BestMediaConfig = {}): MediaType {
  const filtered = list
    .filter(i => i?.url)
    .filter(i => !i?.destination?.startsWith('gvc'));

  try {
    const media = filtered.map(media => {
      const hasWidthAndHeight = width && height && media?.width && media?.height;
      return {
        media,
        tagMatch: tag && media?.destination === tag,
        aspectRatioDistance: hasWidthAndHeight ? Math.abs(media.width / media.height - width / height) : Infinity,
        sizeDistance: hasWidthAndHeight ? Math.abs(media.width - width) + Math.abs(media.height - height) : Infinity,
      };
    }).reduce((acc, curr) => {
      // prefer tag match, then size, then aspect ratio
      if (curr.tagMatch) {
        return curr;
      }

      if (curr.sizeDistance < acc.sizeDistance) {
        return curr;
      }

      if (curr.aspectRatioDistance < acc.aspectRatioDistance) {
        return curr;
      }

      return acc;
    }, { tagMatch: false, aspectRatioDistance: Infinity, sizeDistance: Infinity, media: {} as MediaType });

    const selected = media?.media?.url ?  media?.media : filtered?.[0];
    console.log('best media', selected);

    return selected ? optimizeMedia(selected, { width, height }) : null;
  } catch (e)  {
    console.error('findBestMedia error', e);
    return filtered?.[0] ?? list?.[0];
  }
}

export function optimizeMedia(media: MediaType, { width, height }: BestMediaConfig = {}): MediaType {
  try {
    const QUALITY = 65;
    const quality = QUALITY;
    // use cloudinary to improve reduce
    // https://res.cloudinary.com/jbboerner/video/upload/v1716922833/nikehyperlive-development/1716922831910.mp4

    const url = new URL(media.url);
    const parts = url.pathname.split('/');

    // Identify the 'upload' index and insert transformations
    const uploadIndex = parts.findIndex(part => part === 'upload');
    if (uploadIndex === -1) return media; // 'upload' segment not found

    let transformations = [];
    // if (width) transformations.push(`w_${width}`);
    if (height) transformations.push(`h_${height}`);
    if (quality) transformations.push(`q_${quality}`);

    // Insert transformations into the URL
    if (transformations.length > 0) {
      parts.splice(uploadIndex + 1, 0, transformations.join(','));
    }

    url.pathname = parts.join('/');
    return { ...media, url: url.toString() };
  } catch (e) {
    console.error('optimizeMedia error', e);
    return media;
  }
}