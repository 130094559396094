import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { clamp } from "lodash";
import { motion } from 'framer-motion';
import './PixelMap.scss';
import * as AppEventsService from '../../services/app-events.service';
import PixelMapEventsEnum from '../../enums/PixelMapEvents.enum';
import { captureIframeSnapshot } from "../../utils/capture-preview-snapshot.service";

class PixelMap extends Component {
	static propTypes = {
		testMode: PropTypes.bool,
		backgroundColor: PropTypes.string,
		children: PropTypes.arrayOf(PropTypes.node),
		mode: PropTypes.oneOf(['clear-row', 'back-to-back', 'custom']),
		config: PropTypes.shape({
			width: PropTypes.number,
			height: PropTypes.number,
		}),
		transform: PropTypes.object,
	}

	constructor(props) {
		super(props);

    	this.pixelMapContainerRef = React.createRef();

    	this.state = {
			readyCount: 0,
		};
	}

	componentDidMount() {
		console.log(this.props.children);

		AppEventsService.sendEvent(PixelMapEventsEnum.Loaded);

		// this.restartAnimation(2_000);
		this.waitForAnimationReady();

		addEventListener('resize', () => {
			AppEventsService.sendEvent(PixelMapEventsEnum.Restart);
			this.forceUpdate();
			this.restartAnimation();
		});

		addEventListener('focus', () => {
			AppEventsService.sendEvent(PixelMapEventsEnum.Restart);
			console.log('regained focus')
			this.restartAnimation();
		});

		addEventListener('message', (event) => {
			if (event?.data?.event === PixelMapEventsEnum.RequestPreviewSnapshot) {
				this.sendPreviewSnapshotEvent(event.data.payload);
			}
		});
	}

	sendPreviewSnapshotEvent = async (eventPayload) => {
		const {
			format,
			requestId,
		} = eventPayload;

		try {
			const capturedPreviewImage = await captureIframeSnapshot(this.pixelMapContainerRef, format);
			AppEventsService.sendEvent(PixelMapEventsEnum.PreviewSnapshot, { requestId, capturedPreviewImage });
		} catch (error) {
			AppEventsService.sendEvent(PixelMapEventsEnum.PreviewSnapshotError, { requestId, message: 'Unable to capture Preview Image.' });
			throw error;
		}
	}

	waitForAnimationReady = () => {
		const iframes = [...document.querySelectorAll('iframe')];

		const playIfReady = () => {
			const {readyCount} = this.state;
			if (readyCount === iframes.length) {
				this.restartAnimation();
			}
			AppEventsService.sendEvent(PixelMapEventsEnum.Start);
		}

		iframes.forEach((iframe) => {
			iframe.contentWindow?.addEventListener('animation_ready', () => {
				this.setState(({readyCount}) => ({readyCount: readyCount + 1}), playIfReady);
			})
		})
	}

	restartAnimation = (delay = 300) => {
		const {timingFunction} = this.props;

		setTimeout(() => {
			const event = new CustomEvent('restart_animation');

			[...document.querySelectorAll('iframe')].forEach((iframe, index) => {
				const run = () => iframe.contentWindow.dispatchEvent(event);

				if (!timingFunction) {
					run();
				}

				// console.log('func', func);

				const func = eval(timingFunction?.value ?? '() => 0');

				let duration = 0;

				try {
					duration = func(0, 0, index);
				} finally {
					setTimeout(run, duration);
				}

			});

			window.dispatchEvent(event);
		}, delay);
	}

	/**
	 * Is the width of the element. When wrapped is never wider than page.
	 */
	wrappedWidth = (section) => {
		return clamp(section?.props?.width, this.getMaxWidth(section));
	}

	/**
	 * Is the height after wrapping. If something even wraps a little a new column should be made.
	 */
	wrappedHeight = (child) => child.props.height * this.wrapCount(child)

	/**
	 * The number of times the element wraps.
	 */
	wrapCount = (section) => {
		const {width = this.getMaxWidth(section)} = section?.props;

		return Math.ceil(width / this.getMaxWidth(section));
	}

	shouldTestMode = ({props}) => {
		if (props.testOff) {
			return false;
		}
		return this.props.testMode;
	}

	getMaxWidth = (child = null) => {
		return child?.props?.sectionWidth ?? this.props?.config?.width ?? window.innerWidth;
	}

	getStyle = () => {
		const {config, transform, backgroundColor} = this.props;

		return {
			...config?.width ? {width: `${config.width}px`} : {},
			...config?.height ? {height: `${config.height}px`} : {},
			overflow: 'hidden',
			...transform,
			backgroundColor,
		};
	}

	render() {
		const {children} = this.props;

		return (
			<motion.div
				ref={this.pixelMapContainerRef}
				style={this.getStyle()}
			>
				{(children && children.constructor === Array) ? children.map(this.renderChild) : this.renderChild(children, 0)}
			</motion.div>
		);
	}

	renderChild = (child = null, i) => {
		const width = Number(child.props?.width)

		if (!child || width < 10 || width > 50_000) {
			return null;
		}

		const {wrapsInternally} = child.props;
		const wrapCount = this.wrapCount(child);
		const wrappedElements = new Array((wrapsInternally && !this.props.testMode) ? 1 : wrapCount).fill(0).map((_, i) => i);
		const height = this.wrappedHeight(child);

		return (
			<div
				key={i}
				style={{
					width: this.wrappedWidth(child),
					height,
					position: 'relative',
				}}
			>
				<div>
					{wrappedElements.map((j) => this.renderWrapped(child, i, j, wrapsInternally ? height : null))}
				</div>
			</div>
		)
	}

	renderWrapped = (child, i, j, height) => {
		const fullHeight = height ?? child.props.height
		const testMode = this.shouldTestMode(child);

		const leftOffset = (this.getMaxWidth(child) * j) * -1;

		const width = child?.props?.sectionWidth ? child.props.sectionWidth * (j + 1) : child.props.width;
		const iframeWidthOverride = (child.props.wrapsInternally && child?.props?.sectionWidth) ? child?.props.sectionWidth : undefined;

		return (
			<motion.div
				key={`${i}-${j}`}
				initial={false}
				style={{
					position: 'absolute',

					// set these to the actual width and height.
					width,
					height: fullHeight,
					overflow: child?.props?.sectionWidth ? 'hidden' : undefined,

					// calculate these, so that we can place them correctly inside of the container.
					top: child.props.height * j,

					// width / window
					left: leftOffset,

					// test colors
					// background: testMode && indexedColor(i),
					// background: testMode && `linear-gradient(to right, ${indexedColor(i)}, #999)`,
					// background: testMode && `${indexedColor(i)}`,
					color: testMode && 'white',
					// border: testMode && `3px solid ${indexedColor(i)}`,
					boxSizing: 'border-box',
					display: testMode
				}}
				className={'PixelMap-Item'}
				animate={child.props?.transform ?? {}}
			>
				{/*{testMode ? (<div style={{fontSize: child.props.height / 2}}>{i + 1}</div>) : child.props.children}*/}
				{/*{child && child.constructor === Array ? child.map(wrappedProps) : wrappedProps(child, 0)}*/}
				{React.Children.map(child.props.children, child => {
					if (React.isValidElement(child) && child.type === 'iframe') {
						return React.cloneElement(child, {
							height: fullHeight,
							...iframeWidthOverride ? {width: iframeWidthOverride} : {},
						});
					}
					return child;
				})}
			</motion.div>
		)
	}
}

export default PixelMap;
