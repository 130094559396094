import useController from "../data/use-controller";
import {useEffect, useMemo, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";
import {useFastLane} from "../store";
import {DEFAULT_EASE} from "../../../../jordan/shared";
import {ABSOLUTE_FILL, convertPixelPitchForTicker, useChannel, useFarthestPoint} from "../helpers";
import {useDisplayType, usePixelMap} from "../store/fast-lane-context";

export default function EditorLayer() {
    const channel = useChannel();
    const {currentValue} = useController({channel});
    const entries = useFastLane(s => s.entries);
    const display = useDisplayType();
    const pixelMap = usePixelMap();
    const shouldListen = useFastLane(s => s.shouldListen());
    const [hoverX, setHoverX] = useState(0);
    const [clickX, setClickX] = useState(undefined);
    const farthestPoint = useFarthestPoint();

    useEffect(() => {
        if (!currentValue || currentValue.group !== 'editor') {
            return;
        }

        if (currentValue.id === 'hover') {
            setHoverX(currentValue.data?.x);
        }
        if (currentValue.id === 'set-click') {
            setClickX(currentValue.data?.x);
        }
        if (currentValue.id === 'unset-click') {
            setClickX(undefined);
        }
    }, [currentValue?.data]);

    const hoverSection = useMemo(() => {
        const dominantX = clickX ?? hoverX;
        let entry: any = null;

        for (let i = 0; i < entries.length; i++) {
            const e = entries[i];

            if (e.position.left <= dominantX) {
                entry = e;
            }

            if (e.position.left > dominantX) {
                break;
            }
        }

        return entry;
    }, [hoverX, clickX, entries]);

    const hoverPosition = useMemo(() => {
        const offset = pixelMap?.offsetLeft ?? 0;
        const left = hoverSection?.position?.left;
        const width = hoverSection?.position?.width ?? 0;

        if (left === undefined || left === null) {
            return {
                left: undefined,
                width: undefined,
            }
        }

        if (display === 'ticker') {
            return {
                left: convertPixelPitchForTicker(left + offset, pixelMap),
                width: convertPixelPitchForTicker(width, pixelMap),
            }
        }

        return {
            left: left + offset,
            width,
        }
    }, [hoverSection?.position, display]);

    return (
        <div
            style={{
                ...ABSOLUTE_FILL,
                width: farthestPoint,
                height: '100%',
                zIndex: 9,
            }}
        >
            <AnimatePresence>
                {
                    shouldListen && clickX && (
                        <>
                            <motion.div
                                key={'left'}
                                style={{
                                    ...ABSOLUTE_FILL,
                                    right: undefined,
                                    width: undefined,
                                    zIndex: 4,
                                    background: 'rgba(100, 100, 100, .4)',
                                    backdropFilter: 'blur(10px)',
                                }}
                                initial={{
                                    width: 0,
                                    opacity: 0,
                                }}
                                animate={{
                                    width: hoverPosition.left,
                                    opacity: 1,
                                }}
                                exit={{
                                    opacity: 0,
                                    width: '0%',
                                }}
                                transition={{
                                    duration: .2,
                                    ease: 'easeOut',
                                }}
                            />
                            <motion.div
                                key={'right'}
                                style={{
                                    ...ABSOLUTE_FILL,
                                    right: '0px',
                                    width: 'unset',
                                    zIndex: 4,
                                    background: 'rgba(100, 100, 100, .4)',
                                    backdropFilter: 'blur(10px)',
                                }}
                                initial={{
                                    opacity: 0,
                                    left: '100%',
                                }}
                                animate={{
                                    opacity: 1,
                                    left: hoverPosition.left + hoverPosition.width,
                                }}
                                exit={{
                                    opacity: 0,
                                    left: '100%',
                                }}
                                transition={{
                                    duration: .2,
                                    ease: 'easeOut',
                                }}
                            />
                        </>
                    )
                }
            </AnimatePresence>

            <AnimatePresence>
                {
                    shouldListen && hoverPosition && (
                        <motion.div
                            initial={{
                                x: 0,
                                width: 0,
                                opacity: 0,
                            }}
                            animate={{
                                x: hoverPosition.left ?? 0,
                                width: hoverPosition.width ?? 0,
                                opacity: hoverPosition.left === undefined ? 0 : 1,
                            }}
                            exit={{
                                x: 0,
                                width: 0,
                                opacity: 0,
                            }}
                            style={{
                                height: '100%',
                                overflow: 'hidden',
                                position: 'absolute',
                            }}
                            transition={{
                                duration: .2,
                                ease: 'easeOut',
                            }}
                        >
                            {
                                !clickX && (
                                    <motion.div
                                        animate={{
                                            background: ['rgba(150, 150, 150, .6)', 'rgba(150, 150, 150, 0)'],
                                        }}
                                        style={{
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%',
                                            boxShadow: 'inset 0 0px 40px rgba(200, 200, 200, .8)',
                                        }}
                                        transition={{
                                            duration: .75,
                                            ease: DEFAULT_EASE,
                                            repeat: Infinity,
                                            repeatType: 'reverse',
                                        }}
                                    />
                                )
                            }
                            {/*<motion.div*/}
                            {/*    animate={{*/}
                            {/*        hoverX: hoverX - hoverSection?.position.left ?? 0,*/}
                            {/*    }}*/}
                            {/*    style={{*/}
                            {/*        width: 5,*/}
                            {/*        height: '100%',*/}
                            {/*        background: 'rgba(255, 255, 255, .4)',*/}
                            {/*        boxShadow: '0 0 80px rgba(255, 255, 255, .4)',*/}
                            {/*    }}*/}
                            {/*></motion.div>*/}

                        </motion.div>
                    )
                }
            </AnimatePresence>
            {/*<motion.div animate={{hoverX}} style={{position: 'absolute', background: 'red', width: 200, height: '100%'}}></motion.div>*/}
        </div>
    )
}