import React, {
    CSSProperties,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    animate,
    AnimatePresence,
    clamp,
    Easing,
    MotionValue,
    useMotionValue,
    usePresence,
} from 'framer-motion';
import classNames from 'classnames';
import { isObject } from 'lodash';

import * as cssClassNames from '../../styles/components/CodificationWithTranslations.module.scss';

import {
    getLangText,
    MultiLangText,
    ThemeType,
} from '../../shared';
import useAreFontsLoaded from '../../hooks/useAreFontsLoaded';
import Codification, {
    calcTotalDuration,
    CodificationProps,
} from './Codification';
import PhantomTranslation from './PhantomTranslation';
import rewrapText from './rewrapText';


type Size = {
    width: number,
    height: number,
};

export type TranslationCodificationProps = Partial<Omit<CodificationProps, 'text' | 'delay'>>;

export type CodificationWithTranslationsProps = {
    text: MultiLangText,
    languages: string[],
    codificationProps: TranslationCodificationProps,
    langIndex: number,
    languageTransitionDelay: number,
    duration?: number,
    loop?: boolean,
    delay?: number,
    ease?: Easing,
    theme?: ThemeType,
    className?: string,
    runFinalTextAnimation?: boolean,
    onLanguageContainerMeassuerd?: (sizes: Size[]) => void,
    onTextRewrapped?: (rewrappedText: MultiLangText) => void,
    onReadyToAnimate?: () => void,
    onTyped?: () => void,
};

export default function CodificationWithTranslations(props: CodificationWithTranslationsProps) {
    const {
        text,
        languages,
        codificationProps,
        langIndex,
        languageTransitionDelay,
        duration,
        loop,
        theme,
        delay = 0,
        ease = 'linear',
        className,
        runFinalTextAnimation = true,
        onLanguageContainerMeassuerd,
        onTextRewrapped,
        onReadyToAnimate,
        onTyped,
    } = props;

    const textRef = useRef<HTMLElement>();
    const [processedLines, setProcessedLines] = useState<string[][]>([]);
    const [scale, setScale] = useState(1);
    const areFontsLoaded = useAreFontsLoaded();
    const translations = useMemo(() => orderMultiLanguageText(text, languages), [text, languages]);
    const [isPresent, safeToRemove] = usePresence();

    useEffect(() => {
        if (!textRef.current || !areFontsLoaded) {
            return;
        }

        if (onLanguageContainerMeassuerd) {
            const sizes = [];
            for (let i = 0; i < textRef.current.children.length; i++) {
                const element = textRef.current.children[i];
                const {width, height} = element.getBoundingClientRect();
                sizes[i] = {
                    width,
                    height,
                };
            }
            onLanguageContainerMeassuerd(sizes);
        }

        const {
            lines,
            scale,
        } = rewrapText(translations, textRef.current);
        setProcessedLines(lines);
        setScale(scale);
        onTextRewrapped?.(arrayToMultiLangText(lines, languages));
    }, [textRef.current, areFontsLoaded]);

    const textStyle = useMemo(() => {
        if (scale === 1) {
            return undefined;
        }

        const style = window.getComputedStyle(textRef.current);
        return {
            fontSize: style.fontSize,
            lineHeight: style.lineHeight,
        };
    }, [scale]);

    const translationComponents = useMemo(() => languages.map((lang, index) => {
        const isLastLang = !loop && index === languages.length - 1;
        return (
            <LineWithTranslations
                key={index}
                lines={processedLines.map(line => line[index])}
                delay={!loop && index === 0 ? delay : languageTransitionDelay}
                duration={duration}
                theme={theme}
                enterProgressScales={processedLines.map(translations => {
                   return Math.max(translations[index].length, (translations[index-1] || '').length)
                })}
                exitProgressScales={processedLines.map(translations => {
                   return Math.max(translations[index].length, (translations[index+1] || '').length)
                })}
                ease={ease}
                codificationProps={codificationProps}
                toNotReverseExit={!isLastLang}
                toExit={!isLastLang || runFinalTextAnimation}
                toExitAllLines={isLastLang}
                onReadyToAnimate={index === 0 ? onReadyToAnimate : undefined}
                onTyped={onTyped}
            />
        )
    }), [languages, processedLines]);

    if (processedLines.length === 0) {
        return (
            <PhantomTranslation
                ref={textRef}
                translations={translations}
                className={className}
            />
        );
    }

    return (
        <div
            className={classNames(cssClassNames.language_container, className)}
            style={textStyle}
        >
            <AnimatePresence onExitComplete={isPresent ? undefined : safeToRemove}>
                {isPresent ? translationComponents[langIndex] : null}
            </AnimatePresence>
        </div>
    );
}


function orderMultiLanguageText(text: MultiLangText, languages: string[]) {
    return languages.map(lang => getLangText(text, lang));
}

function arrayToMultiLangText(lines: string[][], languages: string[]): MultiLangText {
    const text: MultiLangText = {};
    languages.forEach((lang, index) => {
        const langLines = lines.map(line => line[index])
        text[lang] = langLines.join('\n');
    });

    return text;
}

type LineWithTranslationsType = {
    codificationProps: TranslationCodificationProps,
    lines: string[],
    delay: number,
    duration: number,
    enterProgressScales: number[],
    exitProgressScales: number[],
    ease?: Easing,
    theme?: ThemeType,
    toExit: boolean,
    toExitAllLines: boolean,
    toNotReverseExit: boolean,
    onReadyToAnimate?: () => void,
    onTyped?: () => void,
};

export function codificationThemeToCSS(theme: ThemeType): CSSProperties {
    switch (theme) {
        case 'dark':
            return {
                color: 'black',
            };

        case 'light':
            return {
                color: 'white',
            };
        default:
            return {};
    }
}

function LineWithTranslations(props: LineWithTranslationsType) {
    const {
        codificationProps,
        lines,
        delay,
        duration,
        enterProgressScales,
        exitProgressScales,
        theme,
        ease = 'linear',
        toExit,
        toExitAllLines,
        toNotReverseExit,
        onReadyToAnimate,
        onTyped,
    } = props;

    const [isPresent, safeToRemove] = usePresence();
    const lineMotionValues: MotionValue[] = useMemo(() => lines.map(() => new MotionValue()), [lines]);
    const codificationMotionValue = useMotionValue(0);
    const isPresentRef = useRef(isPresent);
    const themeStyle = codificationThemeToCSS(theme);

    useEffect(() => {
        return codificationMotionValue.onChange(value => {
            const scales = isPresentRef.current ? enterProgressScales : exitProgressScales;
            lineMotionValues.forEach((mv, index) => {
                const progress = !isPresentRef.current && toExitAllLines ? value : clamp(0, 1, value - index);
                const scale = scales[index];
                mv.set(progress * scale);
            });
        });
    }, []);

    useEffect(() => {
        if (!isPresent && !toExit) {
            safeToRemove();
            return;
        }

        isPresentRef.current = isPresent;

        const toNotReverse = isPresent || toNotReverseExit;
        if (toNotReverse) {
            codificationMotionValue.set(0);
        }

        let totalDuration = duration;
        let amountOfLinesToUpdate = lines.length;
        if (!isPresent && toExitAllLines) {
            if (duration === undefined) {
                const longestLine = lines
                    .reduce((line, result) => result.length >= line.length ? result : line, '')
                    .replace(/\s/g, '');
                totalDuration = calcTotalDuration(codificationProps, longestLine.length);
            }
        } else {
            const scales = isPresent ? enterProgressScales : exitProgressScales;
            amountOfLinesToUpdate = scales.reduce((result, current, index) => current > 0 ? index : result, 0) + 1;

            if (duration === undefined) {
                totalDuration = calcTotalDuration(codificationProps, scales.reduce((res, amount) => res + amount));
            }
        }

        const anim = animate(codificationMotionValue, toNotReverse ? amountOfLinesToUpdate : 0, {
            duration: totalDuration * amountOfLinesToUpdate / lines.length,
            delay: isPresent ? delay : 0,
            ease,
            onComplete: isPresent ? onTyped : safeToRemove,
        });

        return () => anim.stop();
    }, [isPresent]);

    const style = useMemo(() => {
        if (isObject(codificationProps?.style)) {
            return {
                ...codificationProps.style,
                ...themeStyle,
            };
        }

        return themeStyle;
    }, [codificationProps]);

    return (
        <div>
            {lines.map((text, index) => (
                <Codification
                    {...{
                        ...codificationProps,
                        style,
                        key: index,
                        text,
                        reverse: !isPresent && toNotReverseExit,
                        externalAnimationTimeLine: lineMotionValues[index],
                        onReadyToAnimate: index === lines.length - 1 ? onReadyToAnimate : undefined,
                    }}
                />
            ))}
        </div>
    );
}

type CodificationPropsForCalculations = Omit<CodificationWithTranslationsProps, 'langIndex'>;

export function calculateDuration(props: CodificationPropsForCalculations) {
    const {
        text,
        languages,
        codificationProps,
        languageTransitionDelay,
    } = props;

    const codificationDurations = languages.map(lang => {
        const string = getLangText(text, lang).replace(/[\s\n]/g, '');
        const duration = calcTotalDuration(codificationProps, string.length);

        return duration;
    }, 0);

    let duration = languageTransitionDelay * (languages.length - 1);
    for (let i = 0; i < languages.length; i++) {
        duration += Math.max(
            codificationDurations[i - 1] || 0,
            codificationDurations[i]
        );
    }

    return duration;
}

export function calculateExitDuration(props: CodificationPropsForCalculations) {
    const {
        text,
        languages,
        codificationProps,
    } = props;

    const lang = languages[languages.length - 1];
    const lines = getLangText(text, lang).split('\n');
    const longestLine = lines
        .reduce((line, result) => result.length >= line.length ? result : line, '')
        .replace(/\s/g, '');
    const duration = calcTotalDuration(codificationProps, longestLine.length);

    return duration;
}
