import { DEFAULT_BORDER, isPortrait, useRpx } from '../shared';
import { useNbyStore } from '../store';
import { PricingEntryType } from '../types';
import { useMemo, useRef } from 'react';
import Media from '../../../../jordan/components/Media';

export default function PricingRight({ theme }: { theme?: string }) {
  const rpx = useRpx();
  const portrait = isPortrait();
  const callout = useNbyStore(state => state.animationData.pricingCallout);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: portrait && 'space-between',
      }}
    >
      <div
        style={{
          width: '100%',
          flex: 1,
          height: '100%',
        }}
      >
        <PricingItems theme={theme} />
      </div>
      <div
        style={{
          borderTop: theme === 'one' ? undefined : DEFAULT_BORDER,
          height: portrait ? 422 : rpx(311),
          flexShrink: 0,
          position: 'relative',
        }}
      >
        {
          portrait && theme !== 'one' && (
            <div
              style={{
                fontFamily: 'HelveticaNeue',
                fontWeight: 700,
                fontSize: portrait ? 44 : rpx(40),
                width: 308,
                marginLeft: 48,
                marginTop: 60,
                zIndex: 30,
                position: 'relative',
              }}
            >
              {callout}
            </div>
          )
        }

        {
          portrait && theme === 'one' && (
            <div
              style={{
                fontFamily: 'HelveticaNeue',
                fontWeight: 500,
                fontSize: portrait ? 35 : rpx(35),
                bottom: 44,
                left: 44,
                zIndex: 30,
                position: 'absolute',
                whiteSpace: 'pre-line',
              }}
            >
              {callout}
            </div>
          )
        }
        {/* Mask the border*/}
        <div
          style={{
            width: 2,
            height: '100%',
            position: 'absolute',
            left: -2,
            top: 0,
            backgroundColor: '#fff',
            zIndex: 34,
          }}
        />

        {
          theme === 'one' ? null : <Images />
        }

      </div>
    </div>
  );
}

function PricingItems({theme}: {theme?: string}) {
  const items = useNbyStore(state => state.animationData.pricingEntries);
  const rpx = useRpx();
  const portrait = isPortrait();

  return (
    <div
      style={{
        marginTop: portrait ? 80 : rpx(56),
      }}
    >
      {
        items?.map((item, index) => (
          <PricingItem item={item} key={index} theme={theme} />
        ))
      }
    </div>
  );
}

function PricingItem({ item, theme }: { item: PricingEntryType, theme?: string }) {
  const rpx = useRpx();
  const portrait = isPortrait();

  const fontSize = useMemo(() => {
    if (theme === 'one')  {
      return 60;
    }

    return portrait ? 44 : rpx(40, true);
  }, [portrait, theme]) as number;

  const boldWeight = useMemo(() => {
    if (theme === 'one') {
      return 500;
    }

    return 700;
  }, [theme]) as number;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        margin: portrait ? `52px 48px` : `${rpx(50)} ${rpx(50)}`,
        fontSize,
      }}
    >
      <div>
        <div
          style={{
            fontFamily: 'HelveticaNeue',
            fontWeight: boldWeight,
          }}
        >
          {item.title}
        </div>
        <div
          style={{
            fontFamily: 'HelveticaNeue',
            fontWeight: 400,
            fontSize: theme === 'one' ? fontSize * 50 / 60 : undefined,
            marginTop: theme === 'one' ? 20 : undefined,
          }}
        >
          {item.description}
        </div>
      </div>

      <div
        style={{
          fontFamily: 'HelveticaNeue',
          fontWeight: boldWeight,
        }}
      >
        {item.price}
      </div>
    </div>
  );
}

function Images() {
  const rpx = useRpx();
  const ref = useRef<HTMLDivElement>(null);
  const media = useNbyStore(state => state.animationData?.pricingMedia ?? []);

  return (
    <div
      ref={ref}
      style={{
        marginTop: isPortrait() ? -60 : 0,
        height: '100%',
        width: '100%',
        display: 'flex',
      }}
    >
      {media.map((item, index) => (
        <Media
          key={`mediascroller-${index}`}
          media={[item]}
          width={`${100 / media.filter(i => i?.url).length}%`}
          height={'100%'}
          cover={'fit'}
          preventExit
          preventInitial
          preventTransitions
          preventInitialSlide
          style={{
            // borderTop: index === 0 ? DEFAULT_BORDER : 'none',
            // borderBottom: DEFAULT_BORDER,
            flexShrink: 0,
          }}
        />
      ))}
    </div>
  );
}