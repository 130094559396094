import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';

import { useTakeover } from './utils';
import { DEFAULT_EASE } from '../../touchpoints/rise/constants/transition';
import { TickerData } from '../../touchpoints/core/animations/ticker/ticker/type';
import CoreTicker from '../../touchpoints/core/animations/ticker/CoreTicker';
import type { UniversalTakeover } from './universal-takeover';

export default function useTickerTakeover(props: Partial<TickerData>) {
  const [showTakeover, setShowTakeover] = useState(false);
  const { chapter } = useTakeover<UniversalTakeover>({
    start: () => {
      setShowTakeover(true);
    },
    stop: () => {
      setShowTakeover(false);
    },
  });

  const primaryColor = usePrimaryColor(chapter);
  const tickerComponent = useTickerTakeoverComponent({
    props,
    chapter,
    showTakeover,
  });

  return {
    component: tickerComponent,
    primaryColor: !tickerComponent && showTakeover ? primaryColor : undefined,
  };
}

function usePrimaryColor(chapter?: UniversalTakeover) {
  const [primaryColor, setPrimaryColor] = useState<string>('#fff');

  useEffect(() => {
    if (!chapter) {
      return;
    }

    setPrimaryColor(chapter.primary_color);
  }, [chapter]);

  return primaryColor;
}


interface TickerTakeoverComponentProps {
  props: Partial<TickerData>;
  chapter?: UniversalTakeover;
  showTakeover: boolean;
}

function useTickerTakeoverComponent({ props, chapter, showTakeover }: TickerTakeoverComponentProps) {
  const ticker = useMemo(() => {
    const data = get(chapter, 'ticker');

    if (!data) {
      return null;
    }

    return (
      <CoreTicker
        data={{
          direction: 'left',
          // letterspacing: 0.01,
          spacing: 0,
          squash: 1,
          speed: 1,
          font: 'jordan-condensed',
          background: 'black',
          type: 'normal',
          preFill: true,
          ignore_pixelmap: true,

          ...props,
          ...data,
        }}
        style={{
          position: 'absolute',
        }}
      />
    );
  }, [chapter?.ticker]);

  return useMemo(() => ticker ? (
    <motion.div
      initial={false}
      animate={{
        opacity: showTakeover ? 1 : 0,
      }}
      transition={{
        ease: DEFAULT_EASE,
        duration: .5,
      }}
    >
      {ticker}
    </motion.div>
  ) : null, [ticker, showTakeover]);
}