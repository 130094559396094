@use './codification' as *;


.language_container {
    position: relative;

    & > *:not(:first-child) {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }
}
