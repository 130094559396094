@use '../variables' as vars;

.floor {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-rows: 50% 50%;
    line-height: 1em;

    &.active {
        height: 100%;

        .description-background {
            background: black;
            border-color: white;
        }

        .number {
            background: black;
            letter-spacing: -0.2em;
            margin-left: -0.2em;

            &[data-level='-1'] :global(.text-iteration) span {
                &::first-letter {
                    color: transparent;
                }

                &::before {
                    content: '-';
                    position: absolute;
                    font-size: 95%;
                    margin: 1% 7.0%;
                }
            }
        }
    }

    .floor-description {
        grid-column-start: 1;
        grid-column-end: span 3;
        grid-row-start: 1;
        grid-row-end: span 2;
        margin: 0.5px;
        position: relative;
    }

    .description-background {
        width: 100%;
        height: 100%;
        position: absolute;
        background: white;
        border-inline-end: 1px solid black;
        transform: translateX(1px);
    }

    .description-text {
        padding: vars.to-vh(60, 1920) vars.to-vw(56, 1080);
    }

    .floor-direction {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        > svg {
            height: 45%;
            width: 45%;
        }
    }

    .number {
        transform: translateY(calc(0.157em * var(--hoi-font-vertical-correction)));
        place-self: center;
        font-size: 170%;
        line-height: 1em;
    }
}
