export default async function loadFonts(fonts: string[] = legacyList) {
    const div = document.createElement('div');
    div.innerHTML = fonts.map(font => `<span style="font-family: '${font}';"></span>`).join('');
    document.body.append(div);

    await new Promise(resolve => {
        document.fonts.ready.finally(() => resolve());
    });
}

const legacyList = [
    'futura',
    'helvetica',
    'palatino',
    'noto',
    'trade',
    'noto-sans-sc',
    'helvetica-neue-medium',
    'helvetica-neue-hoi',
    'HelveticaNeue',
    'NeuePixelGrotesk',
    'NIKE_FUTURA',
    'NOTO_SANS',
    'CHINA_FUTURA',
    'COMP_FUTURA',
];
