import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    motion,
    AnimatePresence,
    Variants,
} from 'framer-motion';
import classNames from 'classnames';

import * as cssClassNames from '../styles/screens/Carousel.module.scss';

import { DEFAULT_DURATION_S } from '../constants/transition';
import {
    DEFAULT_EASE,
    getMediaDuration,
    hasTextToDisplay,
    MediaType,
    MultiLangText,
    secToMs,
    setAnimationTimeout,
} from '../shared';
import { BaseChapterProps } from '../components/ChapterRenderer';
import GridWithCells from '../components/GridWithCells';
import MediaRenderer, { VideoPlayerMode } from '../components/MediaRenderer';
import CodificationWithTranslations, {
    TranslationCodificationProps,
    calculateDuration as calculateCodificationDuration,
} from '../components/Codification/CodificationWithTranslations';


const itemTransitionDuration = DEFAULT_DURATION_S;
const zoomItemDuration = DEFAULT_DURATION_S;
const pause = 4;
const wipeTextDuration = DEFAULT_DURATION_S;

const cellLength = 68;
const gridYOffset = -2;
const itemHeightFactor = 1326 / 1920;
const itemWidthFactor = 884 / 1080;
const topFocusOffsetFactor = 297 / 1920;
const listVariants: Variants = {
    initial: ({amount, itemHeight}) => ({
        transform: `translateY(${-amount * itemHeight}px)`,
    }),
    end: ({amount, itemHeight}) => ({
        transform: `translateY(${amount * itemHeight}px)`,
    }),
    slide: ({index, gap, itemHeight, topOffset}) => ({
        transform: `translateY(${-index * (itemHeight + gap) + topOffset}px)`,
    }),
    'pre-focus': ({index, gap, itemHeight, topOffset}) => ({
        transform: `translateY(${-index * (itemHeight + gap) + topOffset}px)`,
    }),
    focus: ({index, gap, itemHeight}) => ({
        transform: `translateY(${-index * (itemHeight + gap)}px)`,
    }),
};
const carouselItemSequence = ['slide', 'pre-focus', 'focus', 'slide'];
const focusStepIndex = carouselItemSequence.indexOf('focus');

export type CarouselListItemProps = {
    media?: MediaType,
    title?: MultiLangText,
    description?: MultiLangText,
};

export type CarouselProps = BaseChapterProps & {
    list?: CarouselListItemProps[],
    gridVariant?: string,
};

export default function Carousel(props: CarouselProps) {
    const {
        list = [],
        gridVariant,
        languages,
        height,
        width,
    } = props;

    const rows = Math.ceil((height - gridYOffset) / cellLength);
    const cols = Math.ceil(width / cellLength) + 1;
    const gridWidth = cols * cellLength;
    const gridHeight = rows * cellLength;
    const gridXOffset = -(gridWidth - width) / 2
    const itemHeight = height * itemHeightFactor;
    const itemWidth = width * itemWidthFactor;
    const extraTextBottomOffset = height - gridHeight + cellLength - gridYOffset;

    const grid = useMemo(() => {
        if (gridVariant === 'off') {
            return null;
        }

        return (
            <div
                className={cssClassNames.grid_container}
                style={{
                    top: `${gridYOffset}px`,
                    left: `${gridXOffset}px`,
                }}
            >
                <GridWithCells
                    height={gridHeight}
                    width={gridWidth}
                    cols={cols}
                    rows={rows}
                    cellLength={cellLength}
                />
            </div>
        );
    }, [gridVariant, rows, cols, gridHeight, gridWidth, cellLength]);

    const itemsContainerRef = useRef();
    const itemInfoRef = useRef<HTMLDivElement>();
    const [textContainerHeight, setTextContainerHeight] = useState(0);
    const gapBetweenItems = useMemo(() => {
        if (!itemsContainerRef.current) {
            return 0;
        }

        const gap = window.getComputedStyle(itemsContainerRef.current).gap;
        return parseFloat(gap);
    }, [itemsContainerRef.current]);
    const [itemSequenceIndex, setItemSequenceIndex] = useState(-1)
    const [preActiveIndex, setPreActiveIndex] = useState(-1);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [nextActiveIndex, setNextActiveIndex] = useState(0);
    const [nextTextActiveIndex, setNextTextActiveIndex] = useState(0);
    const itemVariants = useMemo(() => ({
        small: {
            height: `${itemHeight}px`,
            width: `${itemWidth}px`,
            gridTemplateRows: 'auto 0px',
            clipPath: 'inset(0 0 0px)',
        },
        big: {
            height,
            width,
            clipPath: 'inset(0 0 0px)',
        },
        withDescription: ({textContainerHeight}) => ({
            gridTemplateRows: `auto ${textContainerHeight}px`,
            clipPath: `inset(0 0 ${textContainerHeight}px)`,
        }),
    }), [height, width, textContainerHeight]);

    const items = useMemo(() => list.map((item, index) => {
        const isActive = activeIndex === index;

        return (
            <CarouselItem
                key={index}
                languages={languages}
                {...item}
                height={height}
                variants={itemVariants}
                custom={{
                    textContainerHeight,
                }}
                isActive={isActive}
                onHideText={() => {
                    setNextTextActiveIndex(i => ++i);
                }}
                onAnimationComplete={() => {
                    setActiveIndex(-1);

                    if (nextActiveIndex >= items.length - 1) {
                        return;
                    }

                    setAnimationTimeout(() => setNextActiveIndex(i => ++i), secToMs(zoomItemDuration));
                }}
            />
        );
    }), [list, nextActiveIndex, activeIndex]);

    useEffect(() => {
        if (nextActiveIndex === preActiveIndex) {
            return;
        }

        return setAnimationTimeout(() => {
            setPreActiveIndex(nextActiveIndex);
        }, secToMs(itemTransitionDuration));
    }, [nextActiveIndex]);

    useEffect(() => {
        if (activeIndex !== -1) {
            return;
        }

        setItemSequenceIndex(i => ++i);
    }, [activeIndex]);

    const onItemsContainerAnimationComplete = useCallback((label: string) => {
        if (['pre-focus', 'focus'].includes(label)) {
            return;
        }

        setItemSequenceIndex(i => (++i % carouselItemSequence.length));
    }, []);

    return (
        <div className={cssClassNames.Carousel}>
            <motion.div
                ref={itemsContainerRef}
                className={cssClassNames.list}
                initial='initial'
                animate={carouselItemSequence[itemSequenceIndex]}
                exit='end'
                variants={listVariants}
                custom={{
                    itemHeight,
                    topOffset: topFocusOffsetFactor * height,
                    amount: items.length,
                    gap: gapBetweenItems,
                    index: nextActiveIndex,
                }}
                transition={{
                    duration: itemTransitionDuration,
                    ease: DEFAULT_EASE,
                }}
                onAnimationComplete={onItemsContainerAnimationComplete}
            >
                {items}
            </motion.div>

            {grid}

            <div
                ref={itemInfoRef}
                style={{
                    top: `${height - textContainerHeight}px`,
                }}
            >
                <AnimatePresence>
                    {nextTextActiveIndex === preActiveIndex ? (
                        <TextComponent
                            {...list[preActiveIndex]}
                            languages={languages}
                            width={width}
                            onReadyToAnimate={() => {
                                const {
                                    height: textHeight,
                                } = itemInfoRef.current.getBoundingClientRect();
                                const newHeight = Math.floor(textHeight / cellLength) * cellLength + extraTextBottomOffset;

                                setTextContainerHeight(newHeight);
                                setActiveIndex(preActiveIndex);
                                setItemSequenceIndex(focusStepIndex);
                            }}
                        />
                    ) : null}
                </AnimatePresence>
            </div>
        </div>
    );
}

const languageTransitionDelay = 0.25;
const codificationProps: TranslationCodificationProps = {
    characterSwitchAmount: 2,
    characterNextTrigger: 1,
    timingConfig: {
        control: 'character',
        duration: 0.03,
    },
};

const mediaDriftSpeedNormalized = 10 / 1920;
const mediaDriftDuration = 20;

type ItemProps = CarouselListItemProps & {
    languages: string[],
    height: number,
    variants: Variants,
    custom: any,
    isActive?: boolean,
    onHideText?: () => void,
    onAnimationComplete?: () => void,
}

function CarouselItem(props: ItemProps) {
    const {
        media,
        title,
        description,
        languages,
        height,
        variants,
        custom,
        isActive = false,
        onHideText,
        onAnimationComplete,
    } = props;

    const mediaDriftTarget = mediaDriftSpeedNormalized * height * mediaDriftDuration;

    const hasTitle = useMemo(() => title && hasTextToDisplay(title, languages), [title, languages]);
    const hasDescription = useMemo(() => description && hasTextToDisplay(description, languages), [description, languages]);
    const hasText = hasTitle || hasDescription;
    const [animate, setAnimate] = useState<string | string[]>();
    const [mediaPlayerMode, setMediaPlayerMode] = useState<VideoPlayerMode>('pause');

    useEffect(() => {
        if (!isActive) {
            setAnimate('small');
            return;
        }

        setAnimate(hasText ? ['big', 'withDescription'] : 'big');
        setMediaPlayerMode('play');

        const timeout = media && getMediaDuration(media) || pause;
        const timeouts = [
            setAnimationTimeout(() => {
                onHideText?.();
            }, secToMs(timeout - wipeTextDuration)),
            setAnimationTimeout(() => {
                setMediaPlayerMode('pause');
                onAnimationComplete?.();
            }, secToMs(timeout)),
        ];

        return () => {
            while (timeouts.length > 0) {
                timeouts.pop()();
            }
        };
    }, [isActive]);


    const isMediaImage = media?.resource_type === 'image';
    const toDriftMedia = isActive && isMediaImage;

    return (
        <motion.div
            className={cssClassNames.CarouselItem}
            initial='small'
            animate={animate}
            variants={variants}
            custom={custom}
            transition={{
                duration: zoomItemDuration,
                ease: DEFAULT_EASE,
            }}
        >
            {media ? (
                <div>
                    <motion.div
                        style={{
                            height: isMediaImage ? `calc(100% + ${mediaDriftTarget}px)` : '100%',
                        }}
                        animate={toDriftMedia ? {
                            y: ['0px', `-${mediaDriftTarget}px`],
                            transition: {
                                duration: mediaDriftDuration,
                                ease: 'linear',
                                repeatType: 'mirror',
                                repeat: Infinity,
                            }
                        } : undefined}
                        exit={{
                            y: '0px'
                        }}
                        transition={{
                            duration: DEFAULT_DURATION_S,
                            ease: DEFAULT_EASE
                        }}
                    >
                        <MediaRenderer
                            media={media}
                            videoPlayerMode={mediaPlayerMode}
                        />
                    </motion.div>
                </div>
            ) : null}
        </motion.div>
    );
}


type TextComponentType = CarouselListItemProps & {
    languages: string[],
    width: number,
    onReadyToAnimate?: () => void,
}

function TextComponent(props: TextComponentType) {
    const {
        title,
        description,
        languages,
        width,
        onReadyToAnimate
    } = props;

    const hasTitle = useMemo(() => title && hasTextToDisplay(title, languages), [title, languages]);
    const hasDescription = useMemo(() => description && hasTextToDisplay(description, languages), [description, languages]);
    const hasText = hasTitle || hasDescription;
    const [langIndex, setLangIndex] = useState(0);
    const [nextLangIndex, setNextLangIndex] = useState(0);

    const titleComponent = useMemo(() => {
        return hasTitle ? (
            <CodificationWithTranslations
                text={title}
                languages={languages}
                langIndex={langIndex}
                delay={zoomItemDuration}
                ease={DEFAULT_EASE}
                codificationProps={codificationProps}
                runFinalTextAnimation
                languageTransitionDelay={languageTransitionDelay}
                onReadyToAnimate={hasDescription ? undefined : onReadyToAnimate}
                onTyped={hasDescription ? undefined : () => setNextLangIndex(i => i + 1)}
            />
        ) : null;
    }, [title, languages, langIndex, hasDescription]);

    const descriptionComponent = useMemo(() => {
        return hasDescription ? (
            <CodificationWithTranslations
                text={description}
                languages={languages}
                langIndex={langIndex}
                delay={zoomItemDuration}
                ease={DEFAULT_EASE}
                codificationProps={codificationProps}
                runFinalTextAnimation
                languageTransitionDelay={languageTransitionDelay}
                onReadyToAnimate={onReadyToAnimate}
                onTyped={() => setNextLangIndex(i => i + 1)}
            />
        ) : null;
    }, [description, languages, langIndex]);

    useEffect(() => {
        if (nextLangIndex === langIndex) {
            return;
        }

        return setAnimationTimeout(() => {
            if (nextLangIndex >= languages.length) {
                return;
            }

            setLangIndex(nextLangIndex)
        }, secToMs(pause));
    }, [nextLangIndex]);

    useEffect(() => {
        if (hasText) {
            return;
        }

        onReadyToAnimate?.();
    }, []);

    return hasText ? (
        <motion.div
            className={cssClassNames.text}
            style={{
                width: `${width}px`,
            }}
            initial={{ clipPath: 'inset(0% 0% 0% 0%)' }}
            animate={{
                x: ['100%', '0%'],
                transition: {
                    duration: DEFAULT_DURATION_S,
                    delay: zoomItemDuration,
                    ease: DEFAULT_EASE,
                }
            }}
            exit={{ clipPath: 'inset(0% 100% 0% 0%)' }}
            transition={{
                duration: wipeTextDuration,
                ease: DEFAULT_EASE,
            }}
        >
            <div
                className={classNames(cssClassNames.title)}
            >
                <AnimatePresence>
                    {titleComponent}
                </AnimatePresence>
            </div>
            <div
                className={classNames(cssClassNames.description)}
            >
                <AnimatePresence>
                    {descriptionComponent}
                </AnimatePresence>
            </div>
        </motion.div>
    ) : null;
}

export function calculateDuration(data: CarouselProps) {
    const {
        list = [],
        languages,
    } = data;
    const itemTransitionsDuration = itemTransitionDuration * list.length;

    const itemsDuration = list.reduce((result, data) => result + calcItemDuration(data || {}, languages), 0);
    const total = itemTransitionsDuration + itemsDuration;

    const duration = secToMs(total) + calculateExitDuration(data);

    return duration;
}

function calcItemDuration(data: CarouselListItemProps, languages: string[]) {
    const {
        title,
        description,
        media,
    } = data;

    let titleDuration = 0;
    const hasTitile = title && hasTextToDisplay(title, languages);
    if (hasTitile) {
        titleDuration = calculateCodificationDuration({
            text: title,
            languages,
            codificationProps,
            languageTransitionDelay,
        });
    }

    let descriptionDuration = 0;
    const hasDescription = description && hasTextToDisplay(description, languages);
    if (hasDescription) {
        descriptionDuration = calculateCodificationDuration({
            text: description,
            languages,
            codificationProps,
            languageTransitionDelay,
        });
    }

    const textDuration = (hasTitile || hasDescription)
        ? Math.max(titleDuration, descriptionDuration) + pause * languages.length + wipeTextDuration
        : 0;

    const mediaDuration = media && getMediaDuration(media);

    const mainDuration = mediaDuration > 0 ? mediaDuration : textDuration;

    const duration = zoomItemDuration + mainDuration;

    // 0.1 i for extra calculations by transitioning from 'pre-focus' to 'focus'
    return duration + 0.1;
}

export function calculateExitDuration(data: CarouselProps) {
    return secToMs(itemTransitionDuration);
}
