import {FastLaneSection} from "../types";
import Typography from "./typography";
import {useFastLane} from "../store";
import {rpx, useTranslatable} from "../helpers";
import {Fragment, useMemo} from "react";
import color from "color";
import {motion} from "framer-motion";
import {DEFAULT_EASE} from "../../../../jordan/shared";

interface FooterSectionProps {
    section: FastLaneSection;
    index: number;
}

export default function SectionFooter({section, index}: FooterSectionProps) {
    const theme = useFastLane(f => f.getTheme());
    const mediaPlaying = useFastLane(f => f.isMediaPlaying(index));
    const opacity = (mediaPlaying && theme.hideGridsWhenPlayingMedia) ? 0 : 1;
    const isDark = useFastLane(f => f.isBackgroundDark());

    const isWhite = useMemo(() => {
        const bg = theme.backgroundColor;
        const c = color(bg).hex();
        return c.toLowerCase() === "#ffffff";
    }, [theme]);

    const translatableContent = useMemo(() => {
        return section.footer?.content;
    }, [section.footer?.content]);

    const content = useTranslatable(translatableContent);

    const isLogo = section.footer?.variant === "logo";

    if (!theme.showQr || !section.footer?.content) {
        return null;
    }

    return (
        <motion.div
            style={{
                position: "absolute",
                bottom: isLogo ? rpx(18) : rpx(13),
                left: 0,
                color: theme.textColor,
            }}
            animate={{opacity}}
            transition={{
                duration: 0.5,
                ease: DEFAULT_EASE,
            }}
        >
            {
                content && isLogo && (
                    <Typography
                        style={{
                            ...theme.style.qrContent,
                            marginBottom: rpx(6, "positioning"),
                        }}
                        text={content}
                    />
                )
            }
            <motion.div
                style={{
                    display: "flex",
                    gap: rpx(10, "positioning"),
                    alignItems: "center",
                }}
            >
                {
                    section.footer?.icons?.filter(i => i?.url)?.map((icon, idx) => (
                        <Fragment key={`section_footer_icon${idx}`}>
                            <img
                                src={icon?.url}
                                style={{
                                    width: isLogo ? undefined : rpx(53, "positioning"),
                                    height: isLogo ? rpx(11, "positioning") : rpx(53, "positioning"),
                                    borderRadius: isLogo ? undefined : rpx(7, "positioning"),
                                    border: isLogo ? undefined : `${rpx(1)} solid ${isWhite ? "black" : "transparent"}`,
                                    // invert the color if it is a logo and the theme is dark
                                    filter: isLogo && isDark ? "invert(1)" : undefined,
                                }}
                            />
                            {
                                theme.footer?.showIconDividers && isLogo && idx < (section.footer?.icons?.length ?? 0) - 1 && (
                                    <div
                                        style={{
                                            height: rpx(10, "positioning"),
                                            width: 1,
                                            backgroundColor: theme.textColor,
                                        }}
                                    />
                                )
                            }
                        </Fragment>
                    ))
                }

                {
                    content && !isLogo && (
                        <Typography
                            style={theme.style.qrContent}
                            text={content}
                        />
                    )
                }
            </motion.div>
        </motion.div>
    );
}