import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion, usePresence } from 'framer-motion';
import { isNumber, findIndex } from 'lodash';

import * as cssClassNames from '../styles/screens/Wayfinding.module.scss';

import { random } from '../../rise/utils/math';
import { GLYPHS } from '../components/Codification/glyphs';

import { MultiLangText, secToMs, setAnimationTimeout } from '../shared';
import { DEFAULT_DURATION_S, DEFAULT_EASE } from '../constants/transition';
import { BaseChapterProps } from '../components/ChapterRenderer';
import Transition from '../components/Transition';
import Grid from '../components/Grid';
import FloorComponent, { FloorDirection } from '../components/FloorComponent';
import TextIteration from '../components/TextIteration';

export type WayFindingProps = BaseChapterProps & {
    activeFloorIndex?: number;
    floors?: Array<Floor>;
}

type Floor = {
    level: string,
    title: MultiLangText,
    description: MultiLangText,
    locationId?: number,
};


const cellSizeNorm = 216 / 1080
const minCols = Math.floor(1 / cellSizeNorm);
const gridLineThickness = 1;

const coverDuration = 0.75;
const floorCounterDuration = 0.1;
const floorNumberDuration = 2;
const activeFloorZoomOutDuration = DEFAULT_DURATION_S;
const nextFloorBackgroundDelay = 0.15;
const floorBackgroundDuration = DEFAULT_DURATION_S;
const languageTransitionDelay = 0.1;
const languageDisplayDuration = 12;
const floorTitleDelay = 2;
const exitActiveFloorDuration = DEFAULT_DURATION_S / 2;
const gridDuration = DEFAULT_DURATION_S;


const getActiveFloorIndex = (data: WayFindingProps): number => {
    const channelLocationId = data?.config?.locationId;
    const floorIndex = findIndex(data?.floors, (floor: Floor) => floor.locationId === channelLocationId);

    if (floorIndex !== -1) {
        return floorIndex;
    }

    if (isNumber(data.activeFloorIndex)) {
        return data.activeFloorIndex;
    }

    return 0;
};

export default function Wayfinding(props: WayFindingProps) {
    const {
        width,
        height,
        languages = [],
        floors = [],
    } = props;
    const [isPresent, safeToRemove] = usePresence();

    const [showIntroWipe, setShowIntroWipe] = useState(true);
    const [showFloors, setShowFloors] = useState(true);
    const [showGrid, setShowGrid] = useState(true);

    const activeFloorIndex = getActiveFloorIndex(props);
    const intermittenCellSize = cellSizeNorm * Math.min(width, height);
    const cols = Math.max(minCols, Math.ceil(width / intermittenCellSize));
    const cellSize = width / cols;
    const rows = Math.ceil(height / cellSize);
    const backgroundWidth = cellSize * (cols - 2);
    const floorHeight = cellSize * 2;
    const floorGridTemplateColumns = new Array<string>(cols).fill(`${1/cols * 100}%`);
    const activeFloorProps = floors[activeFloorIndex];

    const floorsRange = floors.reduce((result, floor) => {
        const value = parseInt(floor?.level);
        if (value < result[0]) {
            result[0] = value;
        }
        if (value > result[1]) {
            result[1] = value;
        }

        return result;
    }, [Infinity, -Infinity]);

    const currentFloorNumberTexts = [];
    for (let value = floorsRange[0]; value <= floorsRange[1]; value++) {
        currentFloorNumberTexts.push(value);

        if (value === parseInt(activeFloorProps?.level)) {
            break;
        }
    }
    const showFloorsDelay = coverDuration + currentFloorNumberTexts.length * floorCounterDuration + floorNumberDuration + activeFloorZoomOutDuration;

    const floorComponents = floors.map((floor, index) => {
        if (!floor) {
            return null;
        }

        const isCurrent = index === activeFloorIndex;
        let direction: FloorDirection = 'down';
        if (isCurrent) {
            direction = 'current'
        } else if (index < activeFloorIndex) {
            direction = 'up';
        }

        return (
            <NotCurrentFloor
                key={index}
                index={index - activeFloorIndex}
                isCurrent={isCurrent}
                floor={floor}
                languages={languages}
                floorHeight={floorHeight}
                floorGridTemplateColumns={floorGridTemplateColumns}
                backgroundWidth={backgroundWidth}
                direction={direction}
                floorBackgroundDelay={showFloorsDelay}
            />
        );
    });

    return (
        <div className={cssClassNames.chapter}>
            {showIntroWipe ? (
                <Transition
                    variant={'wipe-y'}
                    cover
                    duration={coverDuration}
                    style={{
                        backgroundColor: 'black',
                        height: '100vh',
                    }}
                    preventExit
                    onAnimationComplete={() => setShowIntroWipe(false)}
                >
                    <div></div>
                </Transition>
            ) : (
                <>
                    <AnimatePresence onExitComplete={isPresent ? undefined : safeToRemove}>
                        {showGrid ? (
                            <Grid
                                width={cellSize * cols}
                                height={cellSize * rows}
                                rows={rows}
                                cols={cols}
                                minDuration={gridDuration / 2}
                                maxDuration={gridDuration}
                                initialAnimation='hidden'
                                animation='visible'
                                exitAnimation='out'
                                lineStyle={{
                                    strokeWidth: gridLineThickness,
                                    stroke: 'black'
                                }}
                            />
                        ) : null}
                    </AnimatePresence>
                    <div className={cssClassNames.floors}>
                        <AnimatePresence
                            onExitComplete={isPresent ? undefined : () => setShowGrid(false)}
                        >
                            {showFloors ? floorComponents : null}
                        </AnimatePresence>
                        <AnimatePresence
                            onExitComplete={isPresent ? undefined : () => setShowFloors(false)}
                        >
                            {isPresent ? (
                                <CurrentFloor
                                    key={`${activeFloorIndex}-active`}
                                    index={activeFloorIndex}
                                    floorHeight={floorHeight}
                                    floorGridTemplateColumns={floorGridTemplateColumns}
                                    floor={activeFloorProps}
                                    languages={languages}
                                    backgroundWidth={backgroundWidth}
                                    floorNumberTexts={currentFloorNumberTexts}
                                    width={width}
                                    cols={cols}
                                    floorBackgroundDelay={showFloorsDelay}
                                />
                            ) : null}
                        </AnimatePresence>
                    </div>
                </>
            )}
        </div>
    );
}

export function calculateDuration(data: WayFindingProps) {
    const {
        floors = [],
        languages = [],
    } = data;
    const activeFloorIndex = getActiveFloorIndex(data);

    const showMainFloorNumberDuration = (floors.length - activeFloorIndex) * floorCounterDuration;
    const entryDuration = coverDuration + showMainFloorNumberDuration + floorNumberDuration + activeFloorZoomOutDuration;

    const floorDuration = languageDisplayDuration;
    const floorsDuration = floorDuration * languages.length;

    const duration = entryDuration + floorsDuration;

    const exitDuration = calculateExitDuration(data);

    return secToMs(duration) + exitDuration;
}

export function calculateExitDuration(data: WayFindingProps) {
    const duration = exitActiveFloorDuration + floorNumberDuration + gridDuration;
    return secToMs(duration);
}


type NotCurrentFloorProps = {
    index: number,
    isCurrent: boolean,
    floor: Floor,
    languages: string[],
    floorHeight: number,
    floorGridTemplateColumns: string[],
    backgroundWidth: number,
    direction: FloorDirection,
    floorBackgroundDelay: number,
};

function NotCurrentFloor(props: NotCurrentFloorProps) {
    const {
        index,
        isCurrent,
        floor,
        languages,
        floorHeight,
        floorGridTemplateColumns,
        backgroundWidth,
        direction,
        floorBackgroundDelay,
    } = props;

    const { t } = useTranslation();

    const backgroundDelay = nextFloorBackgroundDelay * Math.abs(index);

    const exitTextToIterate = new Array(23);
    const exitFloorNumberDuration = 1;
    for (let i = 0; i < exitTextToIterate.length; i++) {
        exitTextToIterate[i] = t(`glyphs.${GLYPHS[random(0, GLYPHS.length - 1)]}`);
    }
    const floorNumber = !isCurrent ? (
        <TextIteration
            textToIterate={[floor.level]}
            exitTextToIterate={exitTextToIterate}
            iterationDuration={exitFloorNumberDuration / exitTextToIterate.length}
            animationProps={{
                exit: {
                    opacity: 0,
                    transition: {
                        duration: exitFloorNumberDuration
                    }
                }
            }}
        />
    ) : null;

    return (
        <FloorComponent
            level={floor.level}
            title={floor.title}
            languages={languages}
            delay={floorBackgroundDelay + floorTitleDelay}
            description={floor.description}
            languageTransitionDelay={languageTransitionDelay}
            languageDisplayDuration={languageDisplayDuration}
            direction={direction}
            gridLineThickness={gridLineThickness}
            floorGridTemplateColumns={floorGridTemplateColumns}
            style={{
                height: floorHeight,
            }}
            backgroundAnimationProps={{
                initial: {
                    transform: `translateX(-${backgroundWidth}px)`,
                },
                animate: {
                    transform: 'translateX(1px)',
                },
                transition: {
                    delay: floorBackgroundDelay + backgroundDelay + nextFloorBackgroundDelay,
                    duration: floorBackgroundDuration,
                    ease: DEFAULT_EASE
                },
                exit: {
                    transform: `translateX(-${backgroundWidth}px)`,
                    transition: {
                        delay: backgroundDelay,
                        duration: floorBackgroundDuration,
                        ease: DEFAULT_EASE
                    }
                }
            }}
        >
            {floorNumber}
        </FloorComponent>
    );
}


type CurrentFloorProps = {
    index: number,
    floorHeight: number,
    floorGridTemplateColumns: string[],
    floor: Floor,
    languages: string[],
    backgroundWidth: number,
    floorNumberTexts: string[],
    width: number,
    cols: number,
    floorBackgroundDelay: number,
};

function CurrentFloor(props: CurrentFloorProps) {
    const {
        index,
        floorHeight,
        floorGridTemplateColumns,
        floor,
        languages,
        backgroundWidth,
        floorNumberTexts,
        width,
        cols,
        floorBackgroundDelay,
    } = props;

    const zoomOutDelay = coverDuration + floorCounterDuration * floorNumberTexts.length + floorNumberDuration;
    const [toShowFloorNumber, setToShowFloorNumber] = useState(false);

    useEffect(() => setAnimationTimeout(() => setToShowFloorNumber(true), secToMs(coverDuration)));

    if (!floor) {
        return null;
    }

    return (
        <motion.div
            key='active'
            className={cssClassNames['active-floor-container']}
            style={{
                height: floorHeight,
                gridTemplateColumns: floorGridTemplateColumns.join(' '),
                gridRowStart: index + 1
            }}
            initial={initialFloorState[floor.level] || initialFloorState[3]}
            animate={{
                clipPath: 'inset(0% 0% 0% 0%)',
                transform: 'scale(1)',
                transition: {
                    transform: {
                        delay: zoomOutDelay,
                        duration: activeFloorZoomOutDuration,
                    }
                }
            }}
            transition={{
                ease: DEFAULT_EASE,
            }}
            exit={{
                clipPath: 'inset(0% 0% 100% 0%)',
                transition: {
                    delay: 0,
                    duration: exitActiveFloorDuration,
                },
            }}
            custom={{
                index,
                floorHeight,
                width,
            }}
        >
            <Grid
                className={cssClassNames.grid}
                height={floorHeight}
                width={width}
                rows={2}
                cols={cols}
                initialAnimation='visible'
                animation='visible'
                lineStyle={{
                    strokeWidth: gridLineThickness,
                    stroke: 'white'
                }}
            />
            <AnimatePresence>
                <FloorComponent
                    active
                    level={floor.level}
                    title={floor.title}
                    languages={languages}
                    delay={floorBackgroundDelay}
                    description={floor.description}
                    languageTransitionDelay={languageTransitionDelay}
                    languageDisplayDuration={languageDisplayDuration}
                    runFinalTextExitAnimation={false}
                    floorGridTemplateColumns={floorGridTemplateColumns}
                    gridLineThickness={gridLineThickness}
                    backgroundAnimationProps={{
                        initial: {
                            transform: `translateX(-${backgroundWidth}px)`,
                        },
                        animate: {
                            transform: 'translateX(1px)',
                        },
                        transition: {
                            delay: floorBackgroundDelay,
                            duration: floorBackgroundDuration,
                            ease: DEFAULT_EASE
                        }
                    }}
                >
                    <motion.div
                        animate={parseFloat(floor.level) < 0 ? {
                            letterSpacing: '0em',
                        } : undefined}
                        transition={{
                            delay: zoomOutDelay,
                            duration: activeFloorZoomOutDuration,
                            ease: 'easeIn'
                        }}
                    >
                        <AnimatePresence>
                            {toShowFloorNumber ? (
                                <TextIteration
                                    textToIterate={floorNumberTexts}
                                    iterationDuration={floorCounterDuration}
                                />
                            ) : null}
                        </AnimatePresence>
                    </motion.div>
                </FloorComponent>
            </AnimatePresence>
        </motion.div>
    )
}

const initialFloorState = {
    3: ({index, floorHeight, width}) => {
        const xOffsetToMatchLocation = -21;
        const yOffsetToMatchLocation = 45;

        const scale = 31.5;

        const s = scale - 1;
        const a = scale * floorHeight / 4 / s;

        const xOffset = a - (width / 2 - xOffsetToMatchLocation / 1080 * width) / s;

        const vhOffset = a + index * floorHeight / scale;
        const vhCoef = (1/2 - yOffsetToMatchLocation / 1920) / s;
        const yOrigin = `calc(${vhOffset}px - 100vh * ${vhCoef})`;

        return {
            clipPath: 'inset(0% 0% 0% 0%)',
            transform: `scale(${scale})`,
            transformOrigin: `calc(100% - ${xOffset}px) ${yOrigin}`,
        };
    },
    2: ({index, floorHeight, width}) => {
        const xOffsetToMatchLocation = -29.58;
        const yOffsetToMatchLocation = 25.13;

        const scale = 32.4;

        const s = scale - 1;
        const a = scale * floorHeight / 4 / s;

        const xOffset = a - (width / 2 - xOffsetToMatchLocation / 1080 * width) / s;

        const vhOffset = a + index * floorHeight / scale;
        const vhCoef = (1/2 - yOffsetToMatchLocation / 1920) / s;
        const yOrigin = `calc(${vhOffset}px - 100vh * ${vhCoef})`;

        return {
            clipPath: 'inset(0% 0% 0% 0%)',
            transform: `scale(${scale})`,
            transformOrigin: `calc(100% - ${xOffset}px) ${yOrigin}`,
        };
    },
    0: ({index, floorHeight, width}) => {
        const xOffsetToMatchLocation = 6.18;
        const yOffsetToMatchLocation = 70;

        const scale = 31.2;

        const s = scale - 1;
        const a = scale * floorHeight / 4 / s;

        const xOffset = a - (width / 2 - xOffsetToMatchLocation / 1080 * width) / s;

        const vhOffset = a + index * floorHeight / scale;
        const vhCoef = (1/2 - yOffsetToMatchLocation / 1920) / s;
        const yOrigin = `calc(${vhOffset}px - 100vh * ${vhCoef})`;

        return {
            clipPath: 'inset(0% 0% 0% 0%)',
            transform: `scale(${scale})`,
            transformOrigin: `calc(100% - ${xOffset}px) ${yOrigin}`,
        };
    },
    '-1': ({index, floorHeight, width}) => {
        const xOffsetToMatchLocation = 36;
        const yOffsetToMatchLocation = -62.68;

        const scale = 34;

        const s = scale - 1;
        const a = scale * floorHeight / 4 / s;

        const xOffset = a - (width / 2 - xOffsetToMatchLocation / 1080 * width) / s;

        const vhOffset = a + index * floorHeight / scale;
        const vhCoef = (1/2 - yOffsetToMatchLocation / 1920) / s;
        const yOrigin = `calc(${vhOffset}px - 100vh * ${vhCoef})`;

        return {
            clipPath: 'inset(0% 0% 0% 0%)',
            transform: `scale(${scale})`,
            transformOrigin: `calc(100% - ${xOffset}px) ${yOrigin}`,
        };
    },
};
