import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/react';

import { isArray } from 'lodash';
import { ChapterData } from '../types';
import { getChapter } from '../chapters/register';


export default async function transformChapters(chapters: ChapterData[], channel?: object) {
    const result: ChapterData[] = [];

    const promises = chapters.map(async chapterData => {
        if (!chapterData) {
            return;
        }

        const {
            template,
            ...data
        } = chapterData;

        const Chapter = getChapter(template);

        try {
            const newData = await Chapter.dataMiddleware(data, channel);
            const newChapters = isArray(newData) ? newData : [newData || chapterData];

            result.push(...newChapters);
        } catch (error) {
            console.warn(error);
            Sentry.withScope(scope => {
                scope.setLevel(Severity.Warning);
                Sentry.captureException(error);
            });
        }
    });

    await Promise.all(promises);

    return result;
}
