export default function setAnimationTimeout(fn: Function, timeOut: number) {
    let request: number;
    let start: number;

    const step = (timeStamp: number) => {
        start = start || timeStamp;
        const ellapsed = timeStamp - start;

        if (ellapsed < timeOut) {
            request = window.requestAnimationFrame(step);
        } else {
            request = undefined;
            fn();
        }
    };

    request = window.requestAnimationFrame(step);

    return () => {
        if (!request) {
            return;
        }

        window.cancelAnimationFrame(request);
        request = undefined;
    }
}
