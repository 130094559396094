import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion, usePresence } from 'framer-motion';

import * as cssClassNames from '../styles/screens/CampaignIntro.module.scss';

import {
    DEFAULT_EASE,
    getMediaDuration,
    MediaType,
    MultiLangText,
    secToMs,
    setAnimationTimeout,
} from '../shared';
import { DEFAULT_DURATION_S } from '../constants/transition';
import LayeredMedia, {
    LayeredMediaProps,
} from './LayeredMedia';
import MediaRenderer from '../components/MediaRenderer';
import CodifiedText, {
    calculateDuration as calculateCodificationDuration,
    calculateExitDuration as calculateExitCodificationDuration,
} from '../components/Codification/CodifiedText';


const mediaEntryDuration = DEFAULT_DURATION_S;
const fadeOverlayDuration = DEFAULT_DURATION_S;
const pause = 4;
const ease = DEFAULT_EASE;

const alignVerticallyKeyToFlex = {
    top: 'flex-start',
    center: 'center',
    bottom: 'flex-end',
};

const alignHorizontalKeyToFlex = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
};

type OverlayProps = {
    horizontalAlign: 'left' | 'center' | 'right',
    verticalAlign: 'top' | 'center' | 'bottom',
} & ({
    variant: 'media',
    data: MediaType,
} | {
    variant: 'text',
    data: MultiLangText,
});

export type CampaignIntroProps = Omit<LayeredMediaProps, 'media' | 'overlays'> & {
    media?: MediaType,
    overlay?: OverlayProps,
}

export default function CampaignIntro(props: CampaignIntroProps) {
    const {
        media,
        overlay,
        languages,
        onDurationsCalculated,
    } = props;

    // backward copatibility adapter
    migrateOverlayProps(overlay)

    const hasOverlay = overlay?.variant && overlay?.data;

    const [isPresent, safeToRemove] = usePresence();
    const [toShowOverlay, setToShowOverlay] = useState(!media);
    const [newOverlayProps, setNewOverlayProps] = useState<OverlayProps>(null);

    useEffect(() => {
        return media && setAnimationTimeout(() => setToShowOverlay(true), secToMs(mediaEntryDuration));
    }, []);

    useEffect(() => {
        if (toShowOverlay === false || !hasOverlay) {
            return
        }

        const timeout = calcOverlayDuration(overlay, languages);
        return setAnimationTimeout(() => setToShowOverlay(false), secToMs(timeout));
    }, [toShowOverlay]);

    useEffect(() => {
        if (media || isPresent) {
            return;
        }

        safeToRemove();
    }, [media, isPresent]);

    useEffect(() => {
        if (!onDurationsCalculated || (hasOverlay && !newOverlayProps)) {
            return;
        }

        const newData: CampaignIntroProps = {
            ...props,
            overlay: newOverlayProps || overlay,
        };
        const duration = calculateDuration(newData);
        const exitDuration = calculateExitDuration(newData);
        onDurationsCalculated({
            duration,
            exitDuration,
        });
    }, [newOverlayProps]);

    return (
        <div className={cssClassNames.campaign_intro}>
            <AnimatePresence onExitComplete={isPresent ? undefined : safeToRemove}>
                {isPresent ? (
                    <LayeredMedia
                        {...props}
                        media={[props.media]}
                    />
                ) : null}
            </AnimatePresence>
            {hasOverlay ? (
                <div
                    className={cssClassNames.overlay}
                    style={{
                        justifyContent: alignHorizontalKeyToFlex[overlay.horizontalAlign],
                        alignItems: alignVerticallyKeyToFlex[overlay.verticalAlign],
                    }}
                >
                    <AnimatePresence onExitComplete={isPresent ? undefined : safeToRemove}>
                        {isPresent && toShowOverlay ? (
                            <OverlaySelector
                                {...overlay}
                                languages={languages}
                                onDataUpdated={data => setNewOverlayProps({
                                    ...overlay,
                                    data,
                                })}
                            />
                        ) : null}
                    </AnimatePresence>
                </div>
            ) : null}
        </div>
    );
}

type OverlaySelectorProps = {
    languages: string[],
} & ({
    variant: 'text',
    data: MultiLangText,
    onDataUpdated: (data: MultiLangText) => void,
} | {
    variant: 'media',
    data: MediaType,
    onDataUpdated: (data: MediaType) => void,
})

function OverlaySelector(props: OverlaySelectorProps) {
    const {
        variant,
        data,
        languages,
        onDataUpdated,
    } = props;

    function selectComponent() {
        switch (variant) {
            case 'media':
                return (
                    <OverlayMedia
                        media={data}
                        onDataUpdated={onDataUpdated}
                    />
                );

            case 'text':
                return (
                    <div
                        className={cssClassNames.text_container}
                    >
                        <CodifiedText
                            text={data}
                            languages={languages}
                            pauseDuration={pause}
                            onTextRewrapped={onDataUpdated}
                        />
                    </div>
                );

            default:
                return null;
        }
    }

    return selectComponent();
}

type OverlayMediaProps = {
    media: MediaType,
    onDataUpdated?: (data: MediaType) => void,
}

function OverlayMedia(props: OverlayMediaProps) {
    const {
        media,
        onDataUpdated,
    } = props;

    useEffect(() => {
        onDataUpdated(media);
    }, []);

    return (
        <motion.div
            className={cssClassNames.media}
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1
            }}
            exit={{
                opacity: 0
            }}
            transition={{
                duration: fadeOverlayDuration,
                ease,
            }}
        >
            <div>
                <MediaRenderer
                    media={{
                        fit: 'fit',
                        ...media,
                    }}
                />
            </div>
        </motion.div>
    );
}


export function calculateDuration(data: CampaignIntroProps) {
    const {
        media,
        overlay,
        languages,
    } = data;

    // backward copatibility adapter
    migrateOverlayProps(overlay)

    const hasOverlay = overlay?.variant && overlay?.data;

    const mediaDuration = media ? getMediaContentDuration(media) : 0;
    const overlayDuration = hasOverlay ? calcOverlayDuration(overlay, languages) : 0;

    const total = mediaDuration > 0 ? mediaDuration : (mediaEntryDuration + overlayDuration);

    const exitDuration = calculateExitDuration(data);
    const result = secToMs(total) + exitDuration;

    return result;
}

export function calculateExitDuration(data: CampaignIntroProps) {
    const {
        media,
        overlay,
        languages,
    } = data;

    // backward copatibility adapter
    migrateOverlayProps(overlay)

    const hasOverlay = overlay?.variant && overlay?.data;

    const result = media || !hasOverlay ? mediaEntryDuration : calcOverlayExitDuration(overlay, languages);

    return secToMs(result);
}

function calcOverlayDuration(overlayProps: OverlayProps, languages?: string[]) {
    const {
        variant,
        data,
    } = overlayProps;

    switch (variant) {
        case 'media':
            return getMediaContentDuration(data) || pause;

        case 'text':
            return calculateCodificationDuration({
                text: data,
                languages,
                pauseDuration: pause,
            });

        default:
            return 0;
    }
}

function calcOverlayExitDuration(overlayProps: OverlayProps, languages?: string[]) {
    const {
        variant,
        data,
    } = overlayProps || {};

    switch (variant) {
        case 'media':
            return fadeOverlayDuration;

        case 'text':
            return calculateExitCodificationDuration({
                text: data,
                languages,
                pauseDuration: pause,
            });

        default:
            return 0;
    }
}

function getMediaContentDuration(media?: MediaType) {
    return media ? (getMediaDuration(media) || pause) : 0;
}

function migrateOverlayProps(props): OverlayProps {
    // from - overlay: MediaRendererProps
    // to - overlay: OverlayProps
    // @ts-ignore
    if (props?.media) {
        props.variant = 'media';
        // @ts-ignore
        props.data = props.media;
    }

    return props;
}
