.player {
    height: 100%;
    width: 100%;
    position: relative;

    > * {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
