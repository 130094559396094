import React, {CSSProperties, useCallback, useMemo} from 'react';
import {MediaType, qrUrlTrack, ThemeType} from "../shared";
import Typography from "./Typography";
import { get } from 'lodash';

export const QrCodeVariants = {
	withLogo: 'with_logo',
	large: 'large',
	text: 'text',
} as const;

export type QrCodeVariant = typeof QrCodeVariants[keyof typeof QrCodeVariants];

interface QrSectionProps {
    theme: ThemeType;
    qrCode: QrCodeInterface;
    preventDefaults?: boolean;
    codeStyle?: CSSProperties,
}

export interface QrCodeInterface {
    label?: string;
    disable?: boolean;
    assets: Array<MediaType>;
    hide_images?: boolean;
    variant?: QrCodeVariant;
}

const DEFAULT_ASSETS = [
    {
        "url": "https://res.cloudinary.com/jbboerner/image/upload/c_fill,h_400,w_400/b_transparent,c_mpad,h_401,w_401/v1698352881/nikehyperlive-staging/1698352880993.png?_a=ATCqVAA0",
        "width": 1000,
        "height": 1000,
        "resource_type": "image",
        "format": "png",
        "destination_url": "https://nike.com/nike-app",
        "original_url": "https://res.cloudinary.com/jbboerner/image/upload/v1698352881/nikehyperlive-staging/1698352880993.png"
    },
    // {
    //     'url': 'https://res.cloudinary.com/jbboerner/image/upload/v1670527810/nikehyperlive-staging/1670527810817.png?_a=ATO2BAA0',
    //     'etag': '78cd24d99d74f345e4baf320db9e049c',
    //     'width': 180,
    //     'format': 'png',
    //     'height': 180,
    //     'original_url': 'https://res.cloudinary.com/jbboerner/image/upload/v1670527810/nikehyperlive-staging/1670527810817.png',
    //     'resource_type': 'image',
    //     'name': 'Nike Swoosh Logo',
    // }
    // {
    //     "url": "https://static.nike.com.cn/sportpulse/image/upload/c_fill,h_200,w_200/b_transparent,c_mpad,h_201,w_201/v1666708309/nikehyperlive-staging/1666708309038.svg?_a=ATO2BAA0",
    //     "width": 86,
    //     "format": "svg",
    //     "height": 30,
    //     "original_url": "https://static.nike.com.cn/sportpulse/image/upload/v1666708309/nikehyperlive-staging/1666708309038.svg",
    //     "resource_type": "image"
    // }
]

export default function QrSection({qrCode, codeStyle = {}, theme, preventDefaults = false}: QrSectionProps) {
    if (Array.isArray(qrCode)) {
        return null;
    }

	const {
		assets,
		label,
		disable = false,
		variant = QrCodeVariants.large,
		hide_images = false
	} = qrCode ?? {};
	const color = theme === 'dark' ? 'white' : 'black';

    const getUrl = useCallback((media: MediaType) => {
        if (!media?.destination_url) {
            return media?.original_url ?? media.url
        }

        return qrUrlTrack(media.destination_url, {
            logo: variant === QrCodeVariants.large ? 'jumpman' : undefined,
        });
    }, [assets, variant]);

    // fallback to default assets when the uploaded assets are not set.
    const minAssets = useMemo(() => {
        return [...new Array(2)].map((asset, i) => {
            if (preventDefaults) {
                return assets?.[i];
            }
            return assets?.[i]?.url ? assets?.[i] : DEFAULT_ASSETS?.[i];
        }).filter(a => a);
    }, [assets]);

	const assetsToRender = useMemo(() => {
		if (variant === QrCodeVariants.withLogo) {
			return minAssets;
		}

		const qrAsset = get(minAssets, '[0]');
		return [qrAsset];
	}, [minAssets, variant]);

    if (disable || (preventDefaults && !qrCode?.assets?.length)) {
        return null;
    }

    return (
        <div className={variant}>
            <Typography size={18} variant={'jordan'} color={color} style={{lineHeight: '130%'}}>
                {label}
            </Typography>
            {
                hide_images === true ? null : (
					<div style={{display: 'flex', gap: '10px', marginTop: '14px'}}>
						{
							assetsToRender?.map((media: MediaType, i) => (
								<div
									key={`graphic_${i}`}
									style={{
										height: variant !== QrCodeVariants.large ? 72 : 130,
										width: variant !== QrCodeVariants.large ? 72 : 130,
									}}
								>
									<img
										style={{
											width: '100%',
											height: '100%',
											borderRadius: '5.27px',
											background: 'white',
                                            ...codeStyle,
										}}
										src={getUrl(media)}
									/>
								</div>
							))
						}
					</div>
                )
            }
        </div>
    )
}
