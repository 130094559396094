import { ThemeConfig } from "../types";
import { DefaultFullGrid, DefaultSectionGrid } from "./theme-defaults";

const wellThemeGrey: ThemeConfig = {
	id: "well",
	name: "Well",
	backgroundColor: "#909195",
	textColor: "#fff",
	hideGridsWhenPlayingMedia: false,
	gridColor: `rgba(0, 0, 0, 0)`,
	// backgroundColor: "rgb(125, 125, 125)",
	// textColor: "#fff",
	// gridColor: `rgba(255, 255, 255, .3)`,
	fullGrid: undefined,
	sectionGrid: undefined,
	codification: "none",
	sequence: {
		// skipTakeover: false,
		headlineSwitchDuration: 6_000,
		mediaDuration: 6_000,
		mediaGap: 1_000,
		playMediaInterval: 12_000,
	},
	enableScrim: false,
	showQr: true,
	style: {
		headline: {
			// marginTop: 'inherit',
			// fontSize: 51,
			// lineHeight: 40,
			// fontWeight: 500,
			fontSize: 49,
			marginTop: -5,
			lineHeight: 47,
			fontWeight: 500,
			fontFamily: "helvetica-neue",
		},
		eyebrow: {
			fontSize: 14,
			lineHeight: 14.4,
			fontWeight: 500,
			letterSpacing: "-0.02em",
			textTransform: "uppercase",
			fontFamily: "helvetica-neue",
		},
		qrContent: {
			fontFamily: 'helvetica-neue',
			fontSize: 13,
			lineHeight: 13,
			letterSpacing: '-0.02em',
			fontWeight: 500,
		},
		ticker: {
			fontFamily: 'brut-grotesque',
			fontWeight: 600,
			fontSize: 28,
			lineHeight: 35,
			textTransform: 'lowercase',
			letterSpacing: '-0.02em',
		}
	},
};

export default wellThemeGrey;