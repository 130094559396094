interface SocketListenerProps {
  url: string;
  filter: (message: any) => boolean;
  onMessage: (message: any) => void;
  reconnectInterval: number;
}

export default function socketListener(props: SocketListenerProps) {
  const { url, filter, onMessage, reconnectInterval } = props;

  const ws = new WebSocket(url);

  ws.onopen = () => {
    console.log('WebSocket connection opened');
  }

  ws.onmessage = (message) => {
    console.log('Received message:', message.data);
    const parsedMessage = JSON.parse(message.data);

    if (!filter(parsedMessage)) {
      return;
    }

    console.log('Message passed filter');
    onMessage(parsedMessage);
  }

  ws.onclose = () => {
    console.log('WebSocket connection closed. Reconnecting in', reconnectInterval, 'ms');
    setTimeout(() => {
      socketListener(props);
    }, reconnectInterval);
  }

  ws.onerror = (error) => {
    console.error('WebSocket error:', error);
  }
}