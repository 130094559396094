import {useRpx, useTranslatable} from "../shared";
import {EntryType} from "../types";
import Media from "../../../../jordan/components/Media";
import Collapsable from "./collapsable";
import Typography from "./typography";
import Line from "../components/line";


export default function Entry({entry, isActive}: { entry: EntryType, isActive: boolean }) {
    const {t} = useTranslatable();
    const rpx = useRpx();

    return (
        <div>
            <Line />
            <Typography
                size={72}
                font={'palatino'}
                style={{
                    minHeight: rpx(118),
                    whiteSpace: 'break-spaces',
                }}
            >
                {t(entry?.title)}
            </Typography>

            <Collapsable show={isActive}>
                <Typography
                    font={'helvetica-neue'}
                    size={30}
                    style={{
                        marginBottom: rpx(40),
                        marginTop: rpx(40),
                        maxWidth: '80%',
                        lineHeight: '120%',
                        whiteSpace: 'break-spaces',
                    }}
                >
                    {t(entry?.description)}
                </Typography>

                <Media media={entry?.media} width={'100%'} height={'40vh'}/>
            </Collapsable>
        </div>
    )
}
