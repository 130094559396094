import {useFastLane} from "../store";
import {SECOND, useChannel} from "../helpers";
import useController from "../data/use-controller";
import {useEffect} from "react";

export default function LiveEditorController() {
    const setEntries = useFastLane(f => f.setEntries);
    const shouldListen = useFastLane(f => f.shouldListen());
    const setMode = useFastLane(f => f.setMode);
    const enableTakeover = useFastLane(f => f.enableTakeover);
    const disableTakeover = useFastLane(f => f.disableTakeover);
    const playSequence = useFastLane(f => f.playSequence);
    const stopSequence = useFastLane(f => f.stopSequence);

    const channel = useChannel();
    const {currentValue, trigger} = useController({channel});

    function resetEditingMode() {
        trigger({
            id: 'editing-mode',
            data: 'offline',
        });
        setMode('offline');
    }

    useEffect(() => {
        const FIVE_MINUTES = 5 * 60 * SECOND
        const timeoutId = setTimeout(() => {
            resetEditingMode();
        }, FIVE_MINUTES);

        const closeTimeout = () => clearTimeout(timeoutId)

        if (currentValue?.id === 'editing-mode') {
            setMode(currentValue.data);
        }

        if (!shouldListen || currentValue?.group !== 'editor') {
            return closeTimeout;
        }

        if (
            currentValue?.id === 'update-entries' &&
            currentValue?.data?.length
        ) {
            setEntries(currentValue.data);
        }

        if (
            currentValue?.id === 'reset' &&
            !!currentValue?.data
        ) {
            stopSequence();
            disableTakeover();
        }

        if (
            currentValue?.id === 'update-takeover' &&
            !!currentValue?.data
        ) {
            stopSequence();
            disableTakeover();
            enableTakeover(currentValue.data);
        }

        if (
            currentValue?.id === 'update-sequence' &&
            !!currentValue?.data
        ) {
            stopSequence();
            disableTakeover();
            playSequence({
                ...currentValue.data,
                playMediaInterval: 1,
            });
        }

        return closeTimeout;
    }, [currentValue?.data, shouldListen]);

    return null;
}