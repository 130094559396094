import {useEffect, useMemo} from "react";
import { motion, useSpring, useTransform } from "framer-motion";
import { useFastLane } from "../store";
import {rpx, useResponsivePx} from "../helpers";
import {usePixelMap} from "../store/fast-lane-context";
import {LANE_HEIGHT} from "../shared";

const DRAW_DURATION = 4_500;

interface FullGridProps {
	laneIndex: number;
	totalLanes: number;
	animateLines?: boolean;
}

export default function FullGrid({ laneIndex, totalLanes, animateLines = true }: FullGridProps) {
	const theme = useFastLane(f => f.getTheme());
	const lineThickness = useFastLane(f => f.getGridLineThickness('horizontal'));
	const pixelMap = usePixelMap();
	const mediaPlaying = useFastLane(f => f.isMediaPlaying());
	const color = theme.gridColor;
	const responsivePx = useResponsivePx();

	const opacity = (mediaPlaying && theme.hideGridsWhenPlayingMedia) ? 0 : 1;

	useEffect(() => {
		setTimeout(() => {
			showValue.set(totalLanes);
		}, DRAW_DURATION * .5);

		// const id = setInterval(() => {
		// 	showValue.set(0);
		// 	setTimeout(() => {
		// 		showValue.set(totalLanes);
		// 	}, DRAW_DURATION * 2);
		// }, 60 * 1_000);
		//
		// return () => {
		// 	clearInterval(id);
		// };
	}, []);

	const showValue = useSpring(0, {
		duration: DRAW_DURATION,
	});

	const path = useTransform(showValue, [laneIndex, laneIndex + 1], ["inset(0% 100% 0% 0%)", "inset(0% 0% 0% 0%)"]);

	const compensatedWidth = useMemo(() => {
		return pixelMap.contentWidth * (1 / (pixelMap.contentHeight / LANE_HEIGHT))
	}, [pixelMap]);

	return (
		<div
			style={{
				position: "absolute",
				left: "0",
				top: theme?.gridOffset?.horizontal ?? 0,
				// top: 0,
				height: "100%",
				width: "100%",
				zIndex: 2,
			}}
		>
			<motion.svg
				width={'100%'}
				height={'100%'}
				style={{
					padding: `${responsivePx(10)} 0px`,
					clipPath: path,
					overflow: "visible",
				}}
				animate={{
					// opacity,
				}}
				viewBox={`0 0 ${compensatedWidth} 600`}
				transition={{
					duration: 2,
				}}
			>
				<line
					x1="-100%"
					y1="0%"
					x2="200%"
					y2="0%"
					stroke={color}
					strokeWidth={lineThickness}
				/>

				<line
					x1="-100%"
					y1="50%"
					x2="200%"
					y2="50%"
					stroke={color}
					strokeWidth={lineThickness}
				/>

				<line
					x1="-100%"
					y1="100%"
					x2="200%"
					y2="100%"
					stroke={color}
					strokeWidth={lineThickness}
				/>
			</motion.svg>
		</div>
	);
}