import { v4 as uuidv4 } from 'uuid';
import { storage } from './storage';

const STORAGE_KEY = 'device_uuid';

export const generate = () => {
  const deviceUUID = uuidv4();
  storage.set(STORAGE_KEY, deviceUUID);

  return deviceUUID;
}

export const retrieve = () => {
  let deviceUUID = storage.get(STORAGE_KEY);

  if (!deviceUUID) {
    deviceUUID = generate();
  }

  return deviceUUID;
};
