import {CSSProperties, useCallback, useEffect, useState} from "react";
import {PixelMap, ThemeConfig, Translatable} from "./types";
import { DefaultFullGrid, DefaultSectionGrid } from "./themes/theme-defaults";
import {usePixelMap} from "./store/fast-lane-context";
import {useFastLane} from "./store";
import {LANE_HEIGHT} from "./shared";

export interface WaitConfig {
	// allows the caller to get the timeout id so that it can be cleared
	// if the caller wants to cancel the wait
	getTimeout?: (timeout: number) => void;
}

export function wait(duration: number, config: WaitConfig = {}) {
	return new Promise((resolve) => {
		const timeout = setTimeout(resolve, duration);
		config.getTimeout?.(Number(timeout));
	});
}

export function useGetQuery(query: string, defaultValue: any = "") {
	return new URLSearchParams(window.location.search).get(query) || defaultValue;
}

export type PixelPurpose = "font" | "positioning";

export function rpx(value: number, category: PixelPurpose = "positioning", { raw = false }: { raw?: boolean } = {}) {
	const val = value;

	return raw ? val : `${val}px`;
}

export function useResponsivePx() {
	const pixelMap = usePixelMap();

	return useCallback((value: string | number, multiplier = 1) => {
		const px = typeof value === "string" ? parseFloat(value) : value;

		return px / LANE_HEIGHT * pixelMap.contentHeight * multiplier;
	}, [pixelMap]);
}

export const SECOND = 1_000;

export const keyframe = (initialDelay: number, config: { speed: number } = { speed: 1 }) => {
	let timeouts: Array<number> = [];
	let clears: Array<() => void> = [];
	const { speed = 1 } = config;

	const trigger = (after: number, action: TimerHandler) => {
		const id = setTimeout(action, ((after + initialDelay) * SECOND) * speed);
		timeouts.push(id);

		const { trigger, clear } = keyframe(initialDelay + after, config);
		clears.push(clear);
		return trigger;
	};

	return {
		trigger,
		clear: () => {
			timeouts.forEach(i => clearTimeout(i));
			clears.forEach(i => i());
		},
	};
};

export const ABSOLUTE_FILL: CSSProperties = {
	position: "absolute",
	top: 0,
	left: 0,
	height: "100%",
	width: "100%",
};

export function convertThemeToJson(theme: ThemeConfig): string {
	return JSON.stringify(theme, (key, value) => {
		if (["fullGrid", "sectionGrid"].includes(key)) {
			return typeof value === "function";
		}

		return value;
	}, 2);
}

export function convertJsonToTheme(json: string): ThemeConfig {
	return JSON.parse(json, (key, value) => {
		if (key === "fullGrid") {
			return value ? DefaultFullGrid : null;
		}

		if (key === "sectionGrid") {
			return value ? DefaultSectionGrid : null;
		}

		return value;
	});
}

export function getParam(name: string, url: string = window.location.href): string | null {
	let params = new URLSearchParams(new URL(url).search);
	return params.get(name);
}


export function useChannel(): string | undefined {
	return getParam('zchannel') ?? getParam('channel');
}

export function useFarthestPoint() {
	const pixelMap = usePixelMap();

	return pixelMap?.contentWidth;
}

export function useTranslatable(translatable: Translatable) {
	const currentLanguage = useFastLane(e => e.activeLanguage);
	const [content, setContent] = useState<string>(getString(translatable, currentLanguage));

	function getString(translatable: Translatable, currentLanguage: string | undefined) {
		if (typeof translatable === 'string') {
			return translatable;
		}

		const normalizedLang = currentLanguage?.toLowerCase() ?? 'en';

		return Object.entries(translatable).find(([lang, val]) => lang.toLowerCase() === normalizedLang)?.[1] ?? '';
	}

	useEffect(() => {
		setContent(getString(translatable, currentLanguage));
	}, [currentLanguage, translatable]);


	return content;
}

export function convertPixelPitchForTicker(value: number, pixelMap: PixelMap) {
	return (value / pixelMap.tickerPixelPitch) * pixelMap.fastLanePixelPitch
}