import React, {useEffect, useMemo, useState} from 'react';
import * as AppEventsService from '../../../../services/app-events.service';
import TemplateEventsEnum from '../../../../enums/TemplateEvents.enum';
import Container from "../../components/Container";
import "../../../rise/styles/normalize.css";
import ChapterRunner from "../../components/ChapterRunner";
import CenterCourtWof from "../../screens/CenterCourtWof";
import CenterCourtIntro from "../../screens/CenterCourtIntro";
import CenterCourtSeasonal from "../../screens/CenterCourtSeasonal";
import {ChapterRenderOptions} from "../../components/ChapterRenderer";
import {getChannelInfo} from '../../../rise/hyperlive/utils';
import Media from "../../screens/Media";
import {pixelMapReady} from "../../../../utilities";
import {shouldPlayTakeover} from "../../../rise/animations/fastlane-kids-takeover/shared";


const renderOptions: ChapterRenderOptions = {
    cc_intro: CenterCourtIntro,
    cc_wof: CenterCourtWof,
    cc_seasonal: CenterCourtSeasonal,
    media: Media,
};

export default function App({data, openChapter, config}) {
    const {width, height} = useMemo(() => {
        const width =  getChannelInfo('json_config.canvas_width', Math.round(window.innerWidth));
        const height = getChannelInfo('json_config.canvas_height', width);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (urlParams.get('zpreview') === 'true') {
            return {
                width: window.innerWidth,
                height: window.innerHeight,
            }
        }

        return {
            width,
            height,
        }
    }, []);
    const isRectangular = useMemo(() => getChannelInfo('json_config.rectangular', false), []);
    const gradient = useMemo(() => getChannelInfo('json_config.gradient', false), []);

    const [key, setKey] = useState(0);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sync = Number(urlParams.get('sync'));

        pixelMapReady(() => {
            setKey(i => i + 1);
        });

        AppEventsService.sendEvent(TemplateEventsEnum.InitEnd);

        if (sync > 0) {
            shouldPlayTakeover(sync, () => {
                // console.log('[URL] sync')
                setKey(i => i + 1);
            }, 2);
        }
    }, []);

    return (
        <>
            <Container
                width={width}
                height={height}
                circle={!isRectangular}
                framed={true}
                forceColor={'black'}
            >
                <ChapterRunner
                    key={key}
                    data={data}
                    openChapter={openChapter}
                    renderOptions={renderOptions}
                    config={{...config, meta: {width, height, isRectangular: isRectangular, gradient}}}
                />
            </Container>
        </>
    )
}