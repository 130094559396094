import {useEffect, useMemo, useRef, useState} from "react";
import {convertPixelPitchForTicker} from "../helpers";
import {FastLaneSection} from "../types";
import {useDisplayType, usePixelMap} from "../store/fast-lane-context";
import {useFastLane} from "../store";
import {useInView} from "framer-motion";

export function useCalculateDims({index, section}: { index: number, section: FastLaneSection }) {
    const left = useCalculateLeft(section);
    const width = useCalculateWidth({index, section});

    return {left, width};
}

export function useCalculateLeft(section: FastLaneSection) {
    const pixelMap = usePixelMap();
    const display = useDisplayType();
    const isEditor = useFastLane(f => f.isEditor);

    return useMemo(() => {
        const sectionLeft = section.position?.left ?? 0;
        const globalOffsetLeft = pixelMap?.offsetLeft ?? 0;
        const left = globalOffsetLeft + sectionLeft;

        if (isEditor || display !== "ticker") {
            return left;
        }

        const sectionOffsetLeft = section.position?.tickerOffsetLeft ?? 0;

        return convertPixelPitchForTicker(left + sectionOffsetLeft, pixelMap);
    }, [isEditor, display, pixelMap, section]);
}

export function useSpotlightOpacity(index: number) {
    const display = useDisplayType();
    const takeover = useFastLane(f => f.takeover);
    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
        if (!takeover?.enabled || display === 'ticker') {
            return;
        }
        const spotlightIndex = takeover?.spotlightEntryIndex;

        if (spotlightIndex === undefined || spotlightIndex === 'all') {
            return;
        }

        if (spotlightIndex !== index) {
            setOpacity(0.01);

            setTimeout(() => {
                setOpacity(1);
            }, takeover.takeOverDuration + 2_500);
        }

    }, [takeover, display]);

    return opacity;
}

export function useCalculateWidth({index, section}: { index: number, section: FastLaneSection }) {
    const nextSection = useFastLane(f => f.entries?.[index + 1]);

    return useMemo(() => {
        if (!nextSection?.position?.left) {
            return section.position?.width;
        }
        return nextSection?.position?.left - (section.position?.left ?? 0);
    }, [section, nextSection]);
}

export function useShowContent() {
    const containerRef = useRef(null);
    const isEditor = useFastLane(f => f.isEditor);
    const isInView = useInView(containerRef, {
        amount: "some",
    });
    const showContent = isEditor || isInView;

    return {containerRef, showContent};
}