import {useCallback, useEffect, useMemo, useState} from "react";
import {getLayoutId, truncate} from "../shared";
import Media from "./Media";
import Typography from "./Typography";
import {DropInterface, SnkrsCalProps} from "../screens/SnkrsCal";
import {containsJapanese} from "../../../utilities";
import {dateFormat, SnkrsCalMode} from "./SnkrsCalHelpers";

interface SnkrsCalDropListEntry {
    drop: DropInterface,
    mode: SnkrsCalMode,
    index: number,
    hide_dates?: boolean,
    locale: string,
    available_copy?: string,
    onTop: boolean,
    concept: SnkrsCalProps['concept'],
}

export default function SnkrsCalDropListEntry(props: SnkrsCalDropListEntry) {
    const {
        drop,
        mode,
        index,
        onTop = false,
        hide_dates = false,
        locale,
        available_copy,
        concept,
    } = props;

    const [wasOnTop, setWasOnTop] = useState(false);
    const [zIndex, setZindex] = useState(0);
    const url = useMemo(() => drop?.images?.[0], [drop]);
    const droppingAt = useMemo(() => dateFormat(drop, {locale, available_copy}), [drop]);

    useEffect(() => {
        // reset it
        if (onTop == false && wasOnTop === false) {
            setZindex(2);
            setTimeout(() => {
                setZindex(0);
            }, 1_000);

            setWasOnTop(onTop);
        }
    }, [onTop]);

    const hasJapanese = useCallback((content?: string) => {
        return containsJapanese(content);
    }, [drop]);

    const wofTitleFont = hasJapanese(drop.subtitle) ? 'pp-ultralight' : 'pp-ultralight-italic';

    return (
        <div
            style={{
                width: '50%',
                height: '33.33%',
                zIndex
            }}
        >
            {
                mode === 'drops' && (
                    <>
                        <Media
                            preventExit
                            preventInitial
                            preventInitialSlide
                            layoutId={getLayoutId(drop, index)}
                            style={{marginTop: '-80px'}}
                            transparent
                            media={[url]}
                            width={'100%'}
                            height={'100%'}
                        />
                        <div style={{marginLeft: 50, marginTop: '-80px'}}>
                            <Typography
                                size={30}
                                variant={concept === 'wof' ? wofTitleFont : 'neue'}
                                color={'black'}
                                style={{
                                    fontWeight: concept !== 'wof' ? 500 : undefined,
                                    marginTop: concept !== 'wof' ? 5 : undefined,
                                }}
                            >
                                {truncate(drop.subtitle, hasJapanese(drop?.subtitle) ? 20 : 35)}
                            </Typography>
                            <Typography
                                size={30}
                                variant={concept === 'wof' ? 'pp-ultralight' : 'neue'}
                                color={concept === 'wof' ? 'black' : '#777'}
                                style={{
                                    marginTop: concept !== 'wof' ? 20 : undefined,
                                }}
                            >
                                {truncate(drop.colorDescription, hasJapanese(drop?.colorDescription) ? 20 : 35)}
                            </Typography>
                            {
                                !hide_dates && (
                                    <Typography
                                        size={30}
                                        variant={concept === 'wof' ? 'pp-ultralight' : 'neue'}
                                        style={{
                                            marginTop: concept !== 'wof' ? 10 : undefined,
                                        }}
                                        color={concept === 'wof' ? 'black' : '#777'}
                                    >
                                        {droppingAt}
                                    </Typography>
                                )
                            }
                        </div>
                    </>
                )
            }


            {
                mode === 'stories' && (
                    <Media
                        preventInitial
                        preventExit
                        preventInitialSlide
                        layoutId={getLayoutId(drop, index)}
                        style={{marginTop: '0px'}}
                        transparent
                        media={[drop?.cover]}
                        width={'100%'}
                        height={'100%'}
                    />
                )
            }
        </div>
    )
}