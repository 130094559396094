.areas > * {
    --area-color: var(--area-custom-color, rgb(255, 111, 0));
    display: flex;
    align-items: end;
    justify-content: end;
    background: color-mix(in srgb, var(--area-color) 50%, transparent);
    border: 2px solid var(--area-color);
    box-sizing: border-box;

    span {
        background: var(--area-color);
        padding: 0 0.3em;

        &.vertical {
            transform: rotate(-90deg) translateX(100%);
            transform-origin: bottom right;
        }
    }
}
