import {
    DisplayType,
    FastLaneInstance,
    FastLaneSection,
    ThemeConfig,
    WaveDirection,
} from "../types";
import {get, set} from "./index";

export interface InitializeState {
    initialize: (initializeParams: InitializeParams) => void;
    initializeInstance: (initializeParams: FastLaneInstance, display: DisplayType) => void;
}

interface InitializeParams {
    isEditor: boolean;
    entries: Array<FastLaneSection>;
    theme: ThemeConfig;
    debugMode?: boolean;
    waveDirection?: WaveDirection;
    languages?: Array<string>
    takeoverSectionWidths?: Array<{
        index: number;
        width: number;
    }>
}

export const initializeState = (set: set, get: get): InitializeState => ({
    initialize: ({isEditor, debugMode, waveDirection, entries, theme, languages, takeoverSectionWidths}) => {
        set({
            isEditor,
            entries,
            theme,
            waveDirection: waveDirection ?? get().waveDirection,
            debugMode: debugMode ?? get().debugMode,
            languages: languages ?? [],
            takeoverSectionWidths: takeoverSectionWidths ?? [],
        });
    },

    initializeInstance: ({isEditor = false, entries, theme, waveDirection, languages, pixelMap, takeoverSectionWidths}: FastLaneInstance) => {
        get().initialize({
            isEditor,
            entries,
            theme,
            waveDirection,
            languages,
            takeoverSectionWidths,
        });
    }
});