import {DEFAULT_EASE, stringFallback} from "../shared";
import Typography from "./Typography";
import {motion} from "framer-motion";
import {SnkrsDropQr} from "./SnkrsDropQr";
import {SNKRS_URL} from "./SnkrsCalHelpers";
import {useCallback} from "react";
import {containsJapanese} from "../../../utilities";
import {DropInterface} from "../screens/SnkrsCal";

interface SnkrsCalDetailStoryContent {
    show: boolean;
    showCoverCard: boolean;
    drop: DropInterface;
    cta_label?: string;
    qr_url?: string
}

export default function SnkrsCalDetailStoryContent(props: SnkrsCalDetailStoryContent) {
    const {show, showCoverCard, drop, cta_label, qr_url} = props;

    const hasJapanese = useCallback((content?: string) => {
        return containsJapanese(content);
    }, [drop]);

    return (
        <motion.div
            initial={false}
            animate={{
                y: show ? '0%' : '110%',
                background: showCoverCard ? 'linear-gradient(rgba(255,255,255,1), rgba(255,255,255,1))' : 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.8))',
                height: showCoverCard ? 428 : 108,
            }}
            transition={{
                ease: DEFAULT_EASE,
                delay: 0,
                duration: showCoverCard ? .5 : 1,
            }}
            style={{
                zIndex: 2,
                position: 'absolute',
                bottom: 0,
                width: '100%',
                padding: '50px 30px',
            }}
        >
            <Typography
                variant={(showCoverCard && !hasJapanese(drop?.title ?? drop?.subtitle)) ? 'pp-ultralight-italic' : 'pp-ultralight'}
                color={showCoverCard ? 'black' : 'white'}
                size={42}>
                {stringFallback(drop?.title, drop?.subtitle)}
            </Typography>

            <motion.div
                style={{marginTop: 10}}
                animate={{
                    opacity: showCoverCard ? 1 : 0,
                }}
                transition={{
                    ease: DEFAULT_EASE,
                    duration: .5,
                }}
            >
                <Typography variant={'pp-ultralight'} color={showCoverCard ? 'black' : 'white'} size={42}>
                    ↑{drop?.subtitle}
                </Typography>
            </motion.div>

            <motion.div
                style={{
                    position: 'absolute',
                    bottom: 0,
                }}
                animate={{
                    opacity: showCoverCard ? 1 : 0,
                }}
                transition={{
                    ease: DEFAULT_EASE,
                    duration: .5,
                }}
            >
                <SnkrsDropQr cta={cta_label} url={stringFallback(qr_url, SNKRS_URL)}/>
            </motion.div>
        </motion.div>
    )
}