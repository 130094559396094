import React, {useMemo} from 'react';
import {color, getColor} from '../styles/color';
import {containsChinese} from "../../../utilities";

export interface TypographyProps {
    children: any;
    variant: 'jordan-condensed' | 'jordan-condensed-cn' | 'jordan' | 'pp-ultralight' | 'pp-ultralight-italic' | 'pp-regular' | 'mono' | 'brut' | 'neue' | 'futura';
    style?: any;
    size?: number | 'xl' | string;
    align?: 'center' | 'left' | 'right';
    color?: color | string;
    uppercase?: boolean;
    scaleX?: number;
}

interface TypographyGroup {
    value: string;
    lang: string;
}

export default function Typography({
                                       children,
                                       variant,
                                       style,
                                       uppercase,
                                       scaleX = 0.5,
                                       size,
                                       color = 'black',
                                       align = 'left'
                                   }: TypographyProps) {
    const content = useMemo<Array<TypographyGroup>>(() => {
        if (typeof children !== 'string') {
            return [{
                value: children,
                lang: 'en',
            }];
        }

        // function splitStrings(acc: Array<{ value: string, isSplit: boolean }>, char: string) {
        //     const isSplit = char === ' ' ?? char === '\n' ?? char === '\t';
        //     const [last = {value: '', isSplit: true}, ...rest] = [...acc].reverse();
        //
        //     if (isSplit) {
        //         return [
        //             ...rest, last, {
        //                 value: char,
        //                 isSplit: true,
        //             }
        //         ];
        //     }
        //
        //     if (last?.isSplit) {
        //         return [
        //             ...rest, last, {
        //                 value: char,
        //                 isSplit,
        //             }
        //         ];
        //     }
        //
        //     last.value += char;
        //
        //     return [
        //         ...rest,
        //         last
        //     ];
        // }

        // const t = children.split('').reduce(splitStrings, []);

        return children.split('').reduce((acc = [], curr, i) => {
            let [last, ...rest] = [...acc].reverse();

            let current: TypographyGroup = {
                value: curr,
                lang: containsChinese(curr) ? 'cn' : 'en',
            };

            // if (current.lang == last?.lang) {
            //     current.value = `${last.value}${curr}`;
            // } else {
            //     rest = [last, ...rest]
            // }

            rest = [last, ...rest]

            return [
                ...[...rest].reverse(),
                current,
                // {
                //     value: ' ',
                //     lang: 'en',
                // }
            ];
        }, []).filter(i => i);
    }, [children]);

    const compVariant = useMemo(() => {
        if (containsChinese(children) && variant === 'jordan-condensed') {
            return 'jordan-condensed-cn';
        }
        return variant;
    }, [children, variant]);

    const fontTypes = {
        'jordan-condensed': {
            fontFamily: 'jordan-display',
            fontWeight: 700,
            transform: `scaleX(${scaleX})`,
            transformOrigin: 'left',
            width: `${(1 / scaleX) * 100}%`, // because it's squished
            lineHeight: '80%',
            fontSize: {
                'xl': 184,
            }?.[size] ?? size,
            cnMultiplier: 1,
        },
        'jordan-condensed-cn': {
            fontFamily: 'Noto Sans SC',
            fontWeight: 700,
            transformOrigin: 'left',
            // width: `${(1 / 1.5) * 100}%`, // because it's squished
            width: `150%`, // because it's squished
            lineHeight: '100%',
            fontSize: {
                'xl': 184 * .84,
            }?.[size] ?? Number(size) * .84,
            transform: `scaleX(1.32) scaleY(0.96)`
        },
        'jordan': {
            fontFamily: 'jordan-display',
            fontWeight: 500,
            fontSize: size,
        },
        'brut': {
            fontFamily: 'Brut Grotesque',
            fontWeight: 'bold',
            fontSize: size,
            letterSpacing: '-.04em',
            lineHeight: `${Number(size) * .8}px`,
        },
        'neue': {
            fontFamily: 'Helvetica Neue',
            fontSize: size,
            lineHeight: `${Number(size) * .8}px`,
        },
        'pp-ultralight': {
            fontFamily: 'pp-ultralight',
            fontSize: size,
        },
        'pp-regular': {
            fontFamily: 'pp-regular',
            lineHeight: '120%',
            fontSize: size,
        },
        'pp-ultralight-italic': {
            fontFamily: 'pp-ultralight',
            fontStyle: 'italic',
            fontSize: size,
        },
        'mono': {
            fontFamily: 'monospace-typewriter',
            fontSize: size,
        },
        'futura': {
            fontFamily: 'COMP_FUTURA',
            fontSize: size,
        },
    };

    const styleVariant = fontTypes[variant];

    return (
        <div style={{
            ...styleVariant ?? {fontSize: size},
            textAlign: align,
            whiteSpace: 'break-spaces',
            verticalAlign: 'middle',
            color: color ? getColor?.(color) : undefined,
            textTransform: uppercase ? 'uppercase' : undefined,
            ...style,
        }}>
            {
                content.map(({value, lang}) => {
                    const shouldProcess = variant === 'jordan-condensed' && lang === 'cn';
                    const groupStyle = shouldProcess ? {
                        ...fontTypes['jordan-condensed-cn'],
                        color: 'transparent',
                        // color: '#777',
                        // fontSize: fontTypes['jordan-condensed-cn'].fontSize * (scaleX + .2),
                        // fontSize: fontTypes['jordan-condensed-cn'].fontSize,
                        fontSize: fontTypes['jordan-condensed-cn'].fontSize * 1.34,
                        lineHeight: '0.906268%',
                    } : {};

                    return (
                        <>
                            <span style={{display: 'inline', position: 'relative', ...groupStyle}}>
                                {
                                    shouldProcess && (
                                        <span style={{
                                            position: 'absolute',
                                            top: '31%',
                                            color: color,
                                            ...fontTypes['jordan-condensed-cn'],
                                        }}>
                                            {value}
                                        </span>
                                    )
                                }
                                {value}
                            </span>
                        </>
                    )
                })
            }
        </div>
    )
}