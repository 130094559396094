import {AnimatePresence, motion} from "framer-motion";
import {ABSOLUTE_FILL} from "../../../../core/animations/fast-lane-2/helpers";
import {ReactNode, useEffect} from "react";
import {DEFAULT_TRANSITION} from "../../../../rise/constants/transition";
import {usePx} from "./ScreenWrapper";

interface ModalProps {
    show: boolean;
    children: ReactNode;
    autoClose?: number;
    onClose?: () => void;
}

export default function Modal({show, children, autoClose, onClose}: ModalProps) {
    const px = usePx();

    useEffect(() => {
        if (!autoClose) {
            return
        }

        const timeout = setTimeout(() => onClose?.(), autoClose);

        return () => clearTimeout(timeout);
    }, [autoClose, onClose]);

    return (
        <AnimatePresence initial={false}>
            {
                show && (
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        style={{
                            ...ABSOLUTE_FILL,
                            backdropFilter: `blur(${px(40)}px)`,
                            zIndex: 55,
                        }}
                        transition={{...DEFAULT_TRANSITION}}
                    >
                        <motion.div
                            initial={{scale: 1.2}}
                            animate={{scale: 1}}
                            exit={{scale: 1.2}}
                            style={{...ABSOLUTE_FILL,}}
                            transition={{...DEFAULT_TRANSITION}}
                        >
                            {children}
                        </motion.div>
                    </motion.div>
                )
            }
        </AnimatePresence>
    )
}