import React, { CSSProperties, MutableRefObject, useEffect, useMemo, useRef, useState} from 'react';
import { motion, useTime, useTransform } from 'framer-motion';
import { debounce } from 'lodash';

import * as cssClassNames from '../styles/components/Ticker.module.scss';

import useAreFontsLoaded from '../hooks/useAreFontsLoaded';


type TickerProps = {
    children: any;
    direction?: 'forwards' | 'backwards';
    duration?: number;
    style?: CSSProperties;
};

export default function Ticker(props: TickerProps) {
    const {
        children,
        direction = 'forwards',
        duration = 10000,
        style = {},
    } = props;

    const [resizeTimstamp, setResizeTimestamp] = useState(Date.now());
    const container = useRef<HTMLDivElement>();
    const sampleRef = useRef<HTMLDivElement>();
    const [size, setSize] = useState<null | { width: number, height: number }>(null);
    const [amountOfCopies, setAmountOfCopies] = useState(0);
    const [sampleWidth, setSampleWidth] = useState(0);
    const areFontsLoaded = useAreFontsLoaded();

    useEffect(() => {
        const onResize = debounce(() => {
            setResizeTimestamp(Date.now());
        }, 100);

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, []);

    useEffect(() => {
        if (!container.current) {
            return;
        }

        setSize({
            height: container.current.clientHeight,
            width: container.current.clientWidth,
        });
    }, [container.current, resizeTimstamp]);

    useEffect(() => {
        if (!areFontsLoaded || !sampleRef.current) {
            return;
        }

        const {width: sampleWidth} = sampleRef.current.getBoundingClientRect();

        setSampleWidth(sampleWidth);

        if (sampleWidth) {
            setAmountOfCopies(Math.floor(size.width / sampleWidth + 1));
        }
    }, [areFontsLoaded, sampleRef.current, resizeTimstamp]);

    const createContent = (
        key: string | number,
        ref: MutableRefObject<HTMLDivElement> | null = null
    ) => (
        <div
            ref={ref}
            key={key}
            className={cssClassNames.sample}
        >
            {children}
        </div>
    );

    const sample = createContent(0, sampleRef);

    const copies = useMemo(() => {
        const result = new Array(amountOfCopies);
        for (let i = 0; i < amountOfCopies; i++) {
            result[i] = createContent(i);
        }
        return result;
    }, [amountOfCopies, resizeTimstamp]);

    const isBackwards = direction === 'backwards';
    const timeLine = useTransform(
        useTime(),
        [0, duration],
        isBackwards ? [0, -1] : [-1, 0],
        { clamp: false }
    );
    const x = useTransform(
        timeLine,
        (value: number) => {
            const offset = value % 1 * sampleWidth;
            return offset;
        },
    );

    return (
        <div
            ref={container}
            className={cssClassNames.ticker}
            style={style}
        >
            {size && (
                <motion.div
                    style={{ x }}
                    className={cssClassNames.content_container}
                >
                    {sample}
                    {copies}
                </motion.div>
            )}
        </div>
    );
}
