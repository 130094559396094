import React, {useMemo} from 'react';
import Transition from "../components/Transition";
import {MediaType, ThemeType} from "../shared";
import Background from "../components/Background";
import Media from "../components/Media";
import Typography from "../components/Typography";
import {getColor} from "../styles/color";
import VerticalTicker, {TickerType} from "../components/VerticalTicker";
import QrSection, {QrCodeInterface} from "../components/QrSection";
import GlitchTypography from "../components/GlitchText/GlitchTypography";
import TextSection from "../components/TextSection";
import {TICKER_INNER_FRAME_WIDTH} from "./Product";

interface LookbookProps {
    title: string;
    subtitle: string;
    supertitle: string;
    caption?: string;
    media: Array<MediaType>;
    qr_code?: QrCodeInterface;
    theme: ThemeType;
    ticker?: TickerType;
    variant?: 'slideshow' | 'exploded';
    custom_ticker?: MediaType;
}

export default function Lookbook({
                                     title = '',
                                     subtitle = '',
                                     supertitle = '',
                                     caption,
                                     qr_code,
                                     media = [],
                                     theme = 'dark',
                                     variant = 'exploded',
                                     ticker,
                                     custom_ticker,
                                     ...other
                                 }: LookbookProps) {
    const isLongTitle = useMemo(() => {
        return title.split('\n').length > 2 || title.length > 30;
    }, [title]);
    const height = useMemo(() => 1228, []);
    const color = useMemo(() => theme === 'dark' ? 'white' : 'black', [theme])

    return (
        <Transition variant={'wipe-x'} cover>
            <Background
                variant={theme === 'dark' ? 'black-print-wash' : 'light-dust'}
            >
                <div style={{}}>
                    {
                        variant !== 'exploded' && (
                            <>
                                <Media
                                    media={media}
                                    width={1080}
                                    height={height}
                                    theme={theme}
                                    delay={1}
                                />
                                {
                                    caption && (
                                        <Typography
                                            color={color}
                                            variant={'jordan'}
                                            size={31}
                                            style={{
                                                position: 'absolute', top: 1142, left: 36
                                            }}>
                                            {caption}
                                        </Typography>
                                    )
                                }
                            </>
                        )
                    }

                    {
                        variant === 'exploded' && <ExplodedImages media={media} theme={theme}/>
                    }


                    <TextSection
                        style={{
                            left: 36,
                        }}
                        headline={title}
                        eyebrow={supertitle}
                        body={subtitle}
                        color={color}
                        contentWidth={TICKER_INNER_FRAME_WIDTH + 60}
                    />

                    {/*<div style={{*/}
                    {/*    position: 'absolute',*/}
                    {/*    top: variant === 'exploded' ? '1058px' : '1220px',*/}
                    {/*    marginLeft: '36px',*/}
                    {/*    zIndex: 11*/}
                    {/*}}>*/}
                    {/*    <Typography variant={'jordan'} size={31} color={color}*/}
                    {/*                style={{marginBottom: 36, fontWeight: 600}}>*/}
                    {/*        {supertitle}*/}
                    {/*    </Typography>*/}
                    {/*    <GlitchTypography*/}
                    {/*        variant={'jordan-condensed'}*/}
                    {/*        size={(isLongTitle && variant !== 'exploded') ? 180 : 240}*/}
                    {/*        color={color}*/}
                    {/*        style={{marginBottom: 31, marginLeft: -16}}*/}
                    {/*    >*/}
                    {/*        {title}*/}
                    {/*    </GlitchTypography>*/}
                    {/*    <div>*/}
                    {/*        <Typography variant={'pp-ultralight'} size={42} color={color}>*/}
                    {/*            {subtitle}*/}
                    {/*        </Typography>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {
                        (!isLongTitle || true) && (
                            <div style={{position: 'absolute', bottom: '40px', left: '40px', color: getColor(color)}}>
                                <QrSection qrCode={qr_code} theme={theme}/>
                            </div>
                        )
                    }

                    {
                        ticker && (
                            <div style={{
                                position: 'absolute',
                                right: '0',
                                height: '100%',
                                width: 100,
                                zIndex: 8,
                                top: 0,
                                display: 'flex'
                            }}>
                                <VerticalTicker
                                    theme={theme}
                                    type={ticker}
                                    asset={custom_ticker}
                                />
                            </div>
                        )
                    }
                </div>

            </Background>
        </Transition>
    )
}

function ExplodedImages({media = [], theme}: { media: Array<MediaType>, theme: ThemeType }) {
    const getMedia = (index) => {
        const m = media?.[index];

        return m ? [m] : [];
    }

    const m1 = getMedia(0);
    const m2 = getMedia(1);
    const m3 = getMedia(2);
    const m4 = getMedia(3);

    return (
        <>
            {
                m1 && (
                    <div style={{position: 'absolute', top: 36, left: 36}}>
                        <Media
                            transparent
                            preventInitial
                            media={m1}
                            width={317}
                            height={356}
                            theme={theme}
                        />
                    </div>
                )
            }

            {
                m2 && (
                    <div style={{position: 'absolute', top: 104, left: 451}}>
                        <Media
                            transparent
                            preventInitial
                            media={m2}
                            width={539}
                            height={593}
                            theme={theme}
                        />
                    </div>
                )
            }

            {
                m3 && (
                    <div style={{position: 'absolute', top: 528, left: 36, zIndex: 8}}>
                        <Media
                            transparent
                            delay={.7}
                            media={m3}
                            width={610}
                            height={693}
                            theme={theme}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                top: 0,
                                background: `linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(0,0,0,.2) 100%)`,
                            }}
                        />
                    </div>
                )
            }

            {
                m4 && (
                    <div style={{position: 'absolute', top: 981, left: 513, zIndex: 6}}>
                        <Media
                            transparent
                            delay={.2}
                            media={m4}
                            width={488}
                            height={667}
                            theme={theme}
                        />
                    </div>
                )
            }
        </>
    )
}