import {rpx, useResponsivePx, useTranslatable} from "../helpers";
import Typography from "./typography";
import React, {useCallback, useEffect, useMemo} from "react";
import {useFastLane} from "../store";
import {CSS, FastLaneSection} from "../types";
import SectionFooter from "./section-footer";
import {usePixelMap} from "../store/fast-lane-context";
import {LANE_HEIGHT} from "../shared";

interface SectionContentBodyProps {
    section: FastLaneSection;
    index: number;
}

export default function SectionContentBody({section, index}: SectionContentBodyProps) {
    const codificationTrigger = useFastLane(f => f.codificationTrigger);
    const theme = useFastLane(f => f.getTheme());
    const translatableHeading = useFastLane(f => f.getActiveHeading(index));
    const activeHeading = useTranslatable(translatableHeading);
    const eyebrow = useTranslatable(section.eyebrow);
    const pixelMap = usePixelMap();
    const responsivePx = useResponsivePx();

    const responsive = useMemo(() => {
        return {
            showFooter: pixelMap.contentHeight > 120
        }
    }, [pixelMap]);

    const marginLeft = theme?.leftBorder?.marginRight ?? 0;
    const eyebrowStyle = useMemo(() => {
        const {fontSize, lineHeight, ...other} = theme.style.eyebrow;
        if (pixelMap.contentHeight > 120) {
            return {
                fontSize,
                lineHeight,
                minHeight: fontSize,
                marginLeft: rpx(3, 'positioning'),
                ...other
            };
        }

        return {
            fontSize: responsivePx(fontSize, 2),
            lineHeight: responsivePx(lineHeight, 2),
            minHeight: responsivePx(fontSize, 2),
            ...other,
        }
    }, [pixelMap, theme]);

    const headlineStyle = useMemo(() => {
        const {fontSize, lineHeight, ...other} = theme.style.headline;
        if (pixelMap.contentHeight > 120) {
            return {
                fontSize,
                lineHeight,
                minHeight: fontSize,
                ...other
            };
        }

        return {
            ...other,
            marginTop: responsivePx(5),
            fontSize: responsivePx(fontSize, 1.7),
            lineHeight: responsivePx(lineHeight, 1.7),
            minHeight: responsivePx(fontSize, 1.7),
        }
    }, [pixelMap, theme]);

    return (
        <div
            style={{
                //WebkitFontSmoothing: "antialiased",
                position: "absolute",
                top: responsivePx(13),
                left: Number(rpx(8, 'positioning', {raw: true})) + marginLeft,
                height: `calc(100% - ${responsivePx(13)}px)`,
                width: "100%",
                zIndex: 5,
            }}
        >
            <Typography
                style={{
                    ...eyebrowStyle,
                    marginBottom: rpx(2.5, 'positioning'),
                    color: theme.textColor,
                }}
                text={eyebrow}
                codification={theme.codification === 'all' || theme.codification.includes('eyebrow')}
            />
            <Typography
                style={{
                    ...headlineStyle,
                    color: theme.textColor,
                }}
                text={activeHeading}
                updater={codificationTrigger}
                codification={theme.codification === 'all' || theme.codification.includes('heading')}
            />

            {
                responsive.showFooter && (
                    <SectionFooter
                        section={section}
                        index={index}
                    />
                )
            }

        </div>
    )
}