import { FastLaneSection } from "../types";
import { useFastLane } from "../store";
import { ABSOLUTE_FILL } from "../helpers";
import Scrim from "./scrim";
import { AnimatePresence, motion } from "framer-motion";
import MediaPlayer from "./media-player";
import {DEFAULT_EASE} from "../../../../jordan/shared";

interface SectionContentMediaProps {
	section: FastLaneSection;
	index: number;
}

export default function SectionContentMedia({ section, index }: SectionContentMediaProps) {
	const theme = useFastLane(f => f.getTheme());
	const mediaPlaying = useFastLane(f => f.isMediaPlaying(index));
	const isDark = useFastLane(f => f.isBackgroundDark());

	return (
		<AnimatePresence>
			{
				!section?.media?.url || !mediaPlaying ? null : (
					<motion.div
						key={`media-${index}`}
						initial={{
							opacity: 0,
						}}
						animate={{
							opacity: 1,
						}}
						exit={{
							opacity: 0,
						}}
						transition={{
							ease: DEFAULT_EASE,
							duration: 0.5,
							delay: 0.3,
						}}
						style={{
							...ABSOLUTE_FILL,
							maxWidth: (theme?.maxMediaWidth !== undefined) ? theme?.maxMediaWidth + (section?.position?.contentOffsetLeft ?? 0) : undefined,
							zIndex: 4,
						}}
					>
						<MediaPlayer
							style={{
								...ABSOLUTE_FILL,
								overflow: "hidden",
								objectFit: "cover",
								maxWidth: "unset",
							}}
							media={section.media}
						/>

						{theme.enableScrim ? <Scrim useFilter isDark={isDark} /> : null}

					</motion.div>
				)
			}
		</AnimatePresence>
	);
}