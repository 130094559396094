import React, {useEffect, useMemo, useState} from "react";
import {ChapterType, prepareShowRunner} from "../shared";
import {ShowRunnerContext} from "../contexts/ShowRunner";
import WrapAnimatePresence from "./WrapAnimatePresence";
import ChapterRenderer from "./ChapterRenderer";

export interface JordanConfig {
    duration: {
        intro?: number;
        default?: number;
        interstitial?: number;
        snkrs: {
            overview?: number;
            drop?: number;
            drop_media_count?: number;
            story?: number;
            story_page_count?: number;
        },
        cc_seasonal_speed?: number;
        cc_wof_speed?: number;
    },
    forceAssetHeight?: number;
    meta?: Any;
}

interface ChapterRunnerProps {
    data?: any;
    openChapter?: null | number | string;
    config: JordanConfig;
    renderOptions?: any;
}

export default function ChapterRunner({data = {}, openChapter = null, renderOptions, config }: ChapterRunnerProps) {
    const [activeChapter, setActiveChapter] = useState<null | number>(0);
    const [chapters, setChapters] = useState<null | Array<ChapterType>>(null);

    useEffect(() => {
        prepareShowRunner(data, config).then((c) => {
            setChapters(c);

            if (openChapter) {
                const chapterIndex = c.findIndex((a) => {
                    return a?.data?.chapterIndex === Number(openChapter);
                });

                setActiveChapter(chapterIndex);
            }
        });
    }, []);

    useEffect(() => {
        if (openChapter !== null || !chapters) {
            return;
        }

        const currentChapter = contextInstance.getActiveChapter();
        if (!currentChapter) {
            return;
        }

        setTimeout(() => {
            if (openChapter !== null) {
                return;
            }
            setActiveChapter(i => i + 1);
        }, currentChapter.duration);
    }, [activeChapter, chapters]);

    const contextInstance = useMemo(() => {
        if (!chapters) {
            return;
        }
        const activeChapterIndex = activeChapter % chapters.length;

        return {
            chapters,
            activeChapterIndex,
            consecutiveChapterIndex: activeChapter,
            getActiveChapter: () => chapters[activeChapterIndex],
        }
    }, [chapters, activeChapter]);

    if (!chapters || !contextInstance.getActiveChapter()) {
        return null;
    }

    return (
        <ShowRunnerContext.Provider value={contextInstance as any}>
            <WrapAnimatePresence>
                <ChapterRenderer
                    renderOptions={renderOptions}
                    key={contextInstance.consecutiveChapterIndex}
                    index={contextInstance.activeChapterIndex}
                    chapter={contextInstance.getActiveChapter()}
                    config={config}
                />
            </WrapAnimatePresence>
        </ShowRunnerContext.Provider>
    )
}