import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import { AnimatePresence } from 'framer-motion';

import {
    MultiLangText,
    setAnimationTimeout,
    secToMs,
    hasTextToDisplay,
} from '../shared';
import { DEFAULT_DURATION_S } from '../constants/transition';
import { BaseChapterProps } from './ChapterRenderer';
import Grid from './Grid';
import TitleText, {
    calculateDuration as calculateTitleDuration
} from './TitleText';


export type TitleProps = BaseChapterProps & {
    title?: MultiLangText;
}

const cellSizeRef = 216 / 1080;
const gridLineThickness = 1;
const gridDuration = DEFAULT_DURATION_S;
const pause = 4;

export default function Title(props: TitleProps) {
    const {
        width,
        height,
        title,
        languages,
        theme,
    } = props;

    const cellSize = cellSizeRef * width;
    const cols = Math.ceil(width / cellSize);
    const rows = Math.ceil(height / cellSize);

    const hasTitle = useMemo(() => title && hasTextToDisplay(title, languages), [title, languages]);
    const [toShowTitle, setToShowTitle] = useState(false);
    const [langIndex, setLangIndex] = useState(0);
    const [nextLangIndex, setNextLangIndex] = useState(0);
    const [e2eScreenshot, setE2eScreenshot] = useState('');

    useEffect(() => {
        return setAnimationTimeout(() => setToShowTitle(true), secToMs(gridDuration));
    }, []);

    useEffect(() => {
        if (nextLangIndex === langIndex) {
            return;
        }

        return setAnimationTimeout(() => {
            setLangIndex(nextLangIndex);
        }, secToMs(pause));
    }, [nextLangIndex]);

    return (
        <>
            <Grid
                width={cellSize * cols}
                height={cellSize * rows}
                cols={cols}
                rows={rows}
                initialAnimation={'hidden'}
                animation={'in'}
                maxDuration={gridDuration}
            />
            {hasTitle ? (
                <AnimatePresence>
                    {toShowTitle ? (
                        <TitleText
                            text={title}
                            languages={languages}
                            theme={theme}
                            langIndex={langIndex}
                            cellLength={cellSize}
                            cols={cols}
                            rows={rows}
                            delay={gridDuration}
                            gridLineThickness={gridLineThickness}
                            className={e2eScreenshot}
                            onTyped={() => {
                                setNextLangIndex(i => {
                                    return (i >= languages.length - 1) ? i : (i + 1);
                                });
                                setE2eScreenshot(`e2e-screenshot-${langIndex}`);
                            }}
                        />
                    ) : null}
                </AnimatePresence>
            ) : null}
        </>
    );
}

export function calculateDuration(data: TitleProps) {
    const {
        title,
        languages,
    } = data;

    const hasTitle = hasTextToDisplay(title, languages);

    const entryDuration = gridDuration * (hasTitle ? 2 : 1);

    const titleDuraiton = hasTitle ? calculateTitleDuration({
        text: title,
        languages,
    }) : 0;
    const textAnimation = titleDuraiton + pause * (hasTitle ? languages.length : 1);

    const total = entryDuration + textAnimation;

    const exitDuration = calculateExitDuration(data);

    // setting +1 sec duration to get rid of exitTimeOffset
    // a chapter transition must be set in order to get rid from this +/-1sec
    return secToMs(total + 1) + exitDuration;
}

export function calculateExitDuration(data: TitleProps) {
    // setting -1 sec exit duration to get rid of exitTimeOffset
    return secToMs(-1);
}
