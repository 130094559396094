import {FastLaneInstance} from "../types";
import {useMemo} from "react";
import {LANE_WIDTH} from "../shared";
import LaneImage from "./lane-image";
import {canvasRowHeight} from "../../../../live/animations/community-board/Flip/shared";

interface LanePreview {
    instance: FastLaneInstance;
}

export default function LanePreview({instance}: LanePreview) {
    const fullWidth = useMemo(() => {
        return Number(instance.pixelMap["fast-lane"].contentWidth);
    }, [instance]);

    const poles = useMemo(() => {
        const poleCount = Math.ceil(fullWidth / LANE_WIDTH);
        return [...new Array(poleCount)].map((_, i) => i);
    }, [instance, fullWidth]);

    return (
        <div
            style={{
                display: 'flex',
                width: fullWidth,
                position: 'absolute',
            }}
        >
            {
                poles.map((pole) => (
                        <div
                            style={{
                                width: LANE_WIDTH,
                            }}
                        >
                            <LaneImage/>
                        </div>
                    ),
                )}
        </div>
    );
}