.pixelMapChannelSlot {
    border: 1px solid rgb(217, 217, 217);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    .dimensionsLabel {
        color: black;
    }
}
