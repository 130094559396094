type Props = {
    withoutRoundCorners?: boolean,
}

export default function TopGrid(props: Props) {
    const {
        withoutRoundCorners,
    } = props;

    return (
        <svg
        style={{
            transform: 'rotate(180deg)',
        }}
            width="648" height="189" viewBox="0 0 648 189" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1297_1166)">
                <path d="M0 190L0 1" stroke="#222222" strokeWidth="1.13944" strokeMiterlimit="10"/>
                <path d="M68 190L68 1" stroke="#222222" strokeWidth="1.13944" strokeMiterlimit="10"/>
                <path d="M205 190L205 1" stroke="#222222" strokeWidth="1.13944" strokeMiterlimit="10"/>
                <path d="M342 190L342 1" stroke="#222222" strokeWidth="1.13944" strokeMiterlimit="10"/>
                <path d="M137 190L137 1" stroke="#222222" strokeWidth="1.13944" strokeMiterlimit="10"/>
                <path d="M273 190L273 1" stroke="#222222" strokeWidth="1.13944" strokeMiterlimit="10"/>
                <path d="M410 190L410 1" stroke="#222222" strokeWidth="1.13944" strokeMiterlimit="10"/>
            </g>
            <g clipPath="url(#clip1_1297_1166)">
                <path d="M648.5 230.57C648.5 346.511 554.511 440.5 438.57 440.5L-1.49994 440.5L-1.49998 0.499996L438.57 0.499988C554.511 0.500008 648.5 94.4887 648.5 210.43L648.5 230.57Z" stroke="#222222"/>
                <path d="M585.5 220.5C585.5 307.209 515.209 377.5 428.5 377.5L-1.5 377.5L-1.50003 63.5L428.5 63.5C515.209 63.5 585.5 133.791 585.5 220.5Z" stroke="#222222"/>
                <path d="M521.5 220.5C521.5 272.415 479.415 314.5 427.5 314.5L-1.5 314.5L-1.50002 126.5L427.5 126.5C479.415 126.5 521.5 168.585 521.5 220.5Z" stroke="#222222"/>
            </g>
            <defs>
                <clipPath id="clip0_1297_1166">
                    <rect width="189" height="648" fill="white" transform="translate(648) rotate(90)"/>
                </clipPath>
                <clipPath id="clip1_1297_1166">
                    <rect width={withoutRoundCorners ? "409.5" : "648"} height="189" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
