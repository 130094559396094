@use '../variables' as vars;

$title-size: vars.to-vh(64, 1920);
$description-size: vars.to-vh(48, 1920);
$text-padding: vars.to-vh(40, 1920) vars.to-vw(56, 1920);

.services {
    @include vars.hoi-font;

    & > * {
        position: absolute;
        top: 0;
    }

    .list {
        display: grid;
        overflow: hidden;
        width: 100%;
    }

    .service_item {
        position: relative;
        width: 100%;
        display: grid;
        align-items: center;
        overflow: hidden;
        border-bottom-style: solid;

        &.active {
            position: absolute;
            top: 0;
            color: white;

            .background {
                background: vars.$red-color;
            }
        }

        & > * {
            position: relative;
        }

        .background {
            position: absolute;
            top: 0;
            height: 100%;
            width: calc(100% + 1px);
            background: white;
            border-right: 1px solid black;
        }

        .title {
            width: 100%;
            font-size: $title-size;
            white-space: pre-wrap;
            padding: $text-padding;
            overflow: hidden;
        }

        .description {
            width: 100%;
            font-size: $description-size;
            white-space: pre-wrap;
            padding: $text-padding;
        }
    }
}
