import { motion } from "framer-motion";
import {usePx} from "./ScreenWrapper";

interface ButtonProps {
    children: string;
    onClick: () => void;
}

export default function Button({children, onClick}: ButtonProps) {
    const px = usePx();

    return (
        <motion.div
            onClick={onClick}
            whileTap={{
                background: 'rgba(255, 255, 255, 0.6)',
            }}
            animate={{
                background: 'rgba(255, 255, 255, 0)',
            }}
            style={{
                border: `${px(2)}px solid white`,
                borderRadius: 500,
                padding: `${px(25)}px ${px(58)}px`,
                fontSize: px(28),
            }}
        >
            {children}
        </motion.div>
    )
}