import en from './en.json';

export const ENGLISH = {
	NAME: 'English',
	NATIVE: 'English',
	CODE: 'en',
	TRANSLATIONS: en,
};

export const LANGUAGES = [ENGLISH];
