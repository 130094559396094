import React, { CSSProperties, useMemo } from 'react';
import classNames from 'classnames';

import * as cssClassNames from '../styles/components/Ticker.module.scss';

import Ticker from './Ticker';
import { JumpMan, Swoosh } from './Icons';


export type TickerIconAliases = 'jumpman' | 'swoosh';

export type TickerContentType = 'text' | 'icon' |  'image';

export type TickerMediaContent = {
    url: string,
    etag: string,
    width: number,
    format: string,
    height: number,
    resource_type: string,
}

export type TickerRecordContent = string | TickerIconAliases | TickerMediaContent;

type TickerItemProps = {
    type: 'text';
    content: string;
} | {
    type: 'icon';
    content: TickerIconAliases;
} | {
    type: 'image';
    content: TickerMediaContent;
};

export type TickerRecord = TickerItemProps & {
    uid: string;
}

export type TickerRunnerProps = {
    duration?: number,
    style?: CSSProperties,
    background?: string,
    content?: TickerRecord[],
};

export default function TickerRunner(props: TickerRunnerProps) {
    const {
        duration = 30000,
        content = [],
    } = props;

    const style = {
        ...props.style,
        background: props.background || '',
    };

    const items = useMemo(() => {
        return content.map((item) => {
            switch (item.type) {
                case 'text':
                    return <TickerTextRecord key={item.uid} content={item.content} />;
                case 'icon':
                    return <TickerIconRecord key={item.uid} content={item.content} />;
                case 'image':
                    return <TickerImageRecord key={item.uid} content={item.content} />;
                default:
                    return null;
            }
        });
    }, [content]);

    return (
        <Ticker
            direction='backwards'
            duration={duration}
            style={style}
        >
            {items}
        </Ticker>
    );
}

type TickerItemRecordProps<T> = {
    content: T;
};

function TickerTextRecord(props: TickerItemRecordProps<string>) {
    return (
        <div className={cssClassNames.item}>
            <span>{props.content}</span>
        </div>
    )
}

function TickerIconRecord(props: TickerItemRecordProps<TickerIconAliases>) {
    const icon = useMemo(() => {
        switch (props.content) {
            case 'swoosh':
                return <Swoosh />;
            case 'jumpman':
                return <JumpMan />;
            default:
                return <Swoosh />;
        }
    }, [props.content]);

    return (
        <div className={classNames(cssClassNames.item, cssClassNames.icon)}>
            {icon}
        </div>
    )
}

function TickerImageRecord(props: TickerItemRecordProps<TickerMediaContent>) {
    const imageUrl = props.content?.url;

    if (!imageUrl) {
        return;
    }

    return (
        <div className={classNames(cssClassNames.item, cssClassNames.media)}>
            <img className={cssClassNames.image} src={imageUrl} alt='image' />
        </div>
    )
}
