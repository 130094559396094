import React, {
    JSXElementConstructor,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { AnimatePresence } from 'framer-motion';
import { debounce, get } from 'lodash';
import classNames from 'classnames';

import * as cssClassNames from '../styles/components/ChapterRunner.module.scss';

import {
    ChapterType,
    setAnimationTimeout,
    TransitionConfig,
    TransitionVariant
} from '../shared';
import TickerRunner, { TickerRunnerProps } from './TickerRunner';
import ChapterRenderer, { ChapterRenderOptions, DurationData } from './ChapterRenderer';
import * as TwoColumns from './transitions/TwoColumns';
import * as Transition from './Transition';
import * as AppEventsService from '../../../services/app-events.service';
import TemplateEventsEnum from '../../../enums/TemplateEvents.enum';


export interface HoiConfig {
    locationId: number | null,
    duration: {
        title?: number;
        default?: number;
        interstitial?: number;
    },
    forceAssetHeight?: number;
    meta?: any;
}

type ChapterRunnerProps = {
    chapters: ChapterType[];
    config: HoiConfig;
    openChapter?: null | number | string;
    renderOptions?: ChapterRenderOptions;
    globalTicker?: TickerRunnerProps;
    transition?: TransitionConfig;
    playOnce?: boolean;
};

export default function ChapterRunner(props: ChapterRunnerProps) {
    const {
        config,
        chapters: chaptersData,
        openChapter = null,
        renderOptions,
        globalTicker,
        transition: globalTransition,
        playOnce,
    } = props;
    const forcedChapterIndex = openChapter === null ? null : Number(openChapter);

    const [resizeTimstamp, setResizeTimestamp] = useState(Date.now());

    const mainContainerRef = useRef<HTMLDivElement>();
    const cancelCurrentAnimationTimeoutRef = useRef(() => {});
    const templateDurations = useMemo(() => new Map<string, DurationData>(), [chaptersData, resizeTimstamp]);
    const [isPreprocessing, setIsPreprocessing] = useState(true);
    const [chaptersToPreprocess, setChaptersToPreprocess] = useState([]);

    const [chapters, setChapters] = useState([]);
    const [chapterCounter, setChapterCounter] = useState(0);
    const [isFullScreenChapter, setIsFullScreenChapter] = useState(true);

    useEffect(() => {
        AppEventsService.sendEvent(TemplateEventsEnum.ChaptersInit);

        const onResize = debounce(() => {
            cancelCurrentAnimationTimeoutRef.current();

            setIsPreprocessing(true);
            setChapters([]);
            setResizeTimestamp(Date.now());
        }, 100);

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, []);

    useEffect(() => {
        if (!mainContainerRef.current) {
            return;
        }

        const isVerticalFontCorrectionNeeded = !navigator.platform.toLocaleLowerCase().includes('win');
        if (isVerticalFontCorrectionNeeded) {
            mainContainerRef.current.style.setProperty('--hoi-font-vertical-correction', '1');
        }
    }, [mainContainerRef.current]);

    useEffect(() => {
        const pendingChapters = chaptersData.reduce((result, chapterData, index) => {
            const {
                template,
                duration,
                exitDuration,
            } = chapterData;
            const renderConfig = renderOptions[template];
            const {
                render,
                toPreprocess = false,
            } = renderConfig || {};

            const key = getTemplateKey(chapterData, index);

            if (toPreprocess === false) {
                templateDurations.set(key, {
                    duration,
                    exitDuration,
                });
                return result;
            }

            result.push(
                <ChapterRenderer
                    key={index}
                    index={index}
                    render={render}
                    chapter={chapterData}
                    config={config}
                    onDurationsCalculated={durations => {
                        templateDurations.set(key, durations);

                        setIsPreprocessing(templateDurations.size !== chaptersData.length);
                    }}
                />
            );

            return result;
        }, []);

        setChaptersToPreprocess(pendingChapters);
        setIsPreprocessing(pendingChapters.length > 0);
    }, [chaptersData, resizeTimstamp]);

    useEffect(() => {
        if (isPreprocessing || playOnce && chapterCounter === chaptersData.length) {
            return;
        }

        const index = forcedChapterIndex ?? (chapterCounter % chaptersData.length);
        const chapterData = chaptersData[index];
        const {
            data,
            template,
        } = chapterData;
        const renderConfig = renderOptions[template];
        const {
            render,
            canLoop,
        } = renderConfig || {};
        const toLoopChapter = chaptersData.length === 1 && canLoop;

        const newChapter = (
            <ChapterRenderer
                key={chapterCounter}
                index={index}
                render={render}
                chapter={chapterData}
                config={config}
                loop={toLoopChapter}
            />
        );

        const transitionConfig = data.transition || globalTransition || {};
        const {
            variant: transitionVariant,
            props: transitionProps,
            overlapNext: transitionOverlapNext = 1,
        } = transitionConfig;
        const transitionDuration = calculateTransitionDuration(transitionVariant);
        const transitionOverlapNextDuration = transitionDuration * (1 - transitionOverlapNext);
        const Transition = selectTransitionComponent(transitionVariant);
        const component = Transition ? (
            <Transition
                key={chapterCounter}
                style={{
                    zIndex: 1,
                }}
                {...transitionProps}
            >
                {newChapter}
            </Transition>
        ) : newChapter;

        setIsFullScreenChapter(data.fullScreen === true);
        setChapters(currentChapters => [...currentChapters, component]);

        if (toLoopChapter) {
            return;
        }

        const nextChapterIndex = forcedChapterIndex ?? (index + 1) % chaptersData.length;
        const nextChapterData = chaptersData[nextChapterIndex];
        const nextTransitionConfig = nextChapterData?.data.transition || globalTransition || {};
        const {
            variant: nextTransitionVariant,
            overlapPrev: nextTransitionOverlapPrev = 1,
        } = nextTransitionConfig;
        const nextChapterTransitionDuration = calculateTransitionDuration(nextTransitionVariant);
        const nextChapterTransitionPrevOverlap = nextChapterTransitionDuration * nextTransitionOverlapPrev;

        const {
            duration: rawDuration,
            exitDuration,
        } = templateDurations.get(getTemplateKey(chapterData, index));
        const duration = rawDuration;

        const offsetNext = forcedChapterIndex === null ? nextChapterTransitionPrevOverlap : 0;
        const showNextAfter = duration - offsetNext;
        const exitCurrentAfter = duration - Math.max(exitDuration, transitionOverlapNextDuration);

        cancelCurrentAnimationTimeoutRef.current = setAnimationTimeout(() => {
            setChapters(currentChapters => {
                currentChapters.splice(0, 1);
                return [...currentChapters];
            });
        }, exitCurrentAfter);

        return setAnimationTimeout(() => {
            setChapterCounter(i => i + 1);
        }, showNextAfter);
    }, [isPreprocessing, chapterCounter, resizeTimstamp]);

    const tickerHeight = globalTicker ? get(globalTicker, 'height', '10%') : 0;
    const isTickerHasContent = useMemo(() => {
        const tickerContent = globalTicker?.content || [];
        return tickerContent.length > 0;
    }, [globalTicker]);
    const isTickerVisible = isTickerHasContent && !isFullScreenChapter;
    const mainGridArea = !isFullScreenChapter
        ? cssClassNames.main
        : [cssClassNames.main, cssClassNames.main, cssClassNames.footer, cssClassNames.footer].join('/');

    return (
        <div
            ref={mainContainerRef}
            className={classNames(cssClassNames.chapter_container, {
                [cssClassNames.preprocessing]: isPreprocessing
            })}
             style={{
                 gridTemplateRows: `auto ${tickerHeight}`,
             }}
        >
            <div
                className={cssClassNames.main_container}
                style={{
                    gridArea: mainGridArea,
                }}
            >
                {isPreprocessing ? chaptersToPreprocess : (
                    <AnimatePresence>
                        {chapters}
                    </AnimatePresence>
                )}
            </div>

            {isTickerVisible ? (
                <TickerRunner
                    {...globalTicker}
                    style={{
                        gridArea: cssClassNames.footer
                    }}
                />
            ) : null}
        </div>
    );
}

function getTemplateKey({data, template}: ChapterType, index: number) {
    return data.variant ? `${index}/${template}/${data.variant}` : `${index}/${template}`;
}

type TransitionPack = {
    default: JSXElementConstructor<any>,
    calculateDuration: (props: any) => number,
}

const transitionsMap: Record<TransitionVariant, TransitionPack> = {
    'two-columns': TwoColumns,
    'wipe': Transition,
};
function selectTransitionComponent(variant: TransitionVariant) {
    return transitionsMap[variant]?.default;
}

function calculateTransitionDuration(variant: TransitionVariant, props = {}): number {
    return transitionsMap[variant]?.calculateDuration(props) || 0;
}
