import {MediaType} from "../shared";
import Transition from "../components/Transition";
import Typography from "../components/Typography";
import VerticalTicker, {TickerType} from "../components/VerticalTicker";
import React, {Fragment, useEffect, useMemo, useState} from "react";
import StatEntry, {Stat} from "../components/Stat";
import Media from "../components/Media";
import {motion} from "framer-motion";
import {DEFAULT_TRANSITION} from "../../rise/constants/transition";
import QrSection, {QrCodeInterface} from "../components/QrSection";
import {getChannelInfo, getStoreLanguages} from "../../rise/hyperlive/utils";
import { get } from "lodash";

interface ShoeFinderProps {
    headline: string;
    entries: Array<ShoeEntry>;
    ticker?: TickerType;
    custom_ticker?: MediaType;
    qr_code?: QrCodeInterface;
}

interface ShoeEntry {
    name: string;
    media: Array<MediaType>;
    stats: {
        traction: number;
        cushion: number;
        responsiveness: number;
    };
}

export function shoeFinderDuration({duration}) {
    const d = duration ?? 60;

    return d * 1_000;
}

export default function ShoeFinder({headline, entries, qr_code, ticker, custom_ticker}: ShoeFinderProps) {
    return (
        <Transition variant={'wipe-x'} cover preventChildExit>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    display: 'flex',
                }}
            >
                <div
                    style={{
                        marginTop: 44,
                        color: 'black',
                        flexGrow: 1,
                        height: '100%',
                        // width: '100%',
                    }}
                >
                    <div
                        style={{
                            padding: '0 40px',
                            position: 'relative'
                        }}
                    >
                        <div
                            style={{
                                maxWidth: '800px',
                            }}
                        >
                            <Typography
                                variant={'jordan-condensed'}
                                size={180}
                            >
                                {headline}
                            </Typography>
                        </div>

                        <HorizontalLine/>

                        <Entries
                            entries={entries}
                        />
                    </div>

                </div>

                {
                    ticker && (
                        <div
                            style={{
                                display: 'flex',
                            }}
                        >
                            <VerticalTicker
                                type={ticker}
                                theme={'light'}
                                asset={custom_ticker}
                            />
                        </div>
                    )
                }


                <div
                    style={{
                        position: 'absolute',
                        bottom: '40px',
                        left: '40px',
                        color: 'black',
                    }}
                >
                    <QrSection
                        qrCode={qr_code}
                        theme={'light'}
                        codeStyle={{
                            border: '1.5px solid rgba(0, 0, 0, 0.3)'
                        }}
                    />
                </div>
            </div>
        </Transition>
    )
}

function HorizontalLine() {
    return (
        <div
            style={{
                height: '2px',
                width: '100%',
                margin: '36px 0',
                backgroundColor: '#000',
            }}
        />
    )
}

function Entries({entries}: { entries: Array<ShoeEntry> }) {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const id = setInterval(() => {
            setActiveIndex(i => (i + 1) % entries.length);
        }, 6_000);

        return () => {
            clearInterval(id);
        }
    }, []);

    return (
        <>
            {
                entries?.map((entry, index) => (
                    <Entry
                        key={index}
                        index={index}
                        entry={entry}
                        active={index === activeIndex}
                        renderBottomLine={index !== entries.length - 1}
                    />
                ))
            }
        </>
    )
}

function Entry({index, entry, active, renderBottomLine}: { index: number, entry: ShoeEntry, active: boolean, renderBottomLine?: boolean }) {
    const [primaryLang = 'en'] = getStoreLanguages();

    const media = useMemo(() => {
        return entry.media?.map((media: MediaType) => {
            return {
                ...media,
                url: media.url
            };
        })
    }, [entry]);

    const containsLineBreak = useMemo(() => {
        return entry.name.includes('\n') || entry.name?.length >= 16;
    }, [entry.name]);

    const maxHeight = useMemo(() => {
        if (active) {
            return 70;
        }

        return containsLineBreak ? 115 : 70;
    }, [active, containsLineBreak]);

    const statEntries: Array<Stat> = useMemo(() => {
        return Object.entries(entry.stats).map(([key, value]) => {
            const chineseTranslations = {
                min: '最小',
                max: '最大',
                cushion: '缓震度',
                traction: '抓地力',
                responsiveness: '回弹性'
            }

            function getTranslation(input: string) {
                if (primaryLang.toLowerCase() === 'cn') {
                    return get(chineseTranslations, input?.toLowerCase(), input);
                }

                return input;
            }

            return {
                label: getTranslation(key),
                value: value / 4,
                labels: [getTranslation('Min'), getTranslation('Max')],
            }
        });
    }, [entry.stats, primaryLang]);

    return (
        <Fragment key={`entry_${index}`}>
            <div
                key={index}
                style={{
                    display: 'flex',
                    marginBottom: '36px',
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        width: '50%',
                        marginRight: '40px',
                    }}
                >

                    <motion.div
                        animate={{
                            scale: active ? 1 : 0.6,
                            marginBottom: active ? 0 : -40,
                            maxHeight,
                        }}
                        style={{
                            fontFamily: 'jordan-display',
                            fontWeight: 500,
                            fontSize: 70,
                            transformOrigin: 'top left',
                        }}
                        transition={DEFAULT_TRANSITION}
                    >
                        {entry.name}
                    </motion.div>


                    <motion.div
                        style={{
                            width: 266,
                        }}
                        animate={{
                            // scale: active ? 1.2 : 1,
                            scale: active ? 1 : 0.8,
                            transformOrigin: 'top left',
                            marginTop: active ? 100 : 25,
                            marginBottom: active ? 0 : -40,
                        }}
                        transition={DEFAULT_TRANSITION}
                    >
                        {
                            statEntries.map((stat, i) => (
                                <StatEntry
                                    key={(index * 3) + i}
                                    index={(index * 3) + i}
                                    stat={stat}
                                />
                            ))
                        }
                    </motion.div>

                </div>

                <motion.div
                    style={{
                        position: 'absolute',
                        // opacity: 0,
                    }}
                    animate={{
                        scale: active ? 1 : 0.51,
                        transformOrigin: 'center right',
                        bottom: active ? 0 : -80,
                        right: active ? 0 : 10,
                    }}
                    transition={DEFAULT_TRANSITION}
                >
                    <Media
                        preventInitial
                        cover={'fit'}
                        media={media}
                        width={'520px'}
                        height={'277px'}
                    />
                </motion.div>

            </div>

            {renderBottomLine && (
                <HorizontalLine/>
            )}
        </Fragment>
    )
}