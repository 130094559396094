import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';

import AssetStorage, { AssetType } from '../../../utils/storage';
import Chapter from '../Chapter';


export default async function cacheAssets(chapters: Chapter[], storage: AssetStorage) {
    const assetsToCache = chapters.reduce<AssetType[]>((result, chapter) => {
        try {
            const toCache = chapter.getAssetsToCache();
            result.push(...toCache);
        } catch (error) {
            console.warn(error);
            Sentry.withScope(scope => {
                scope.setLevel(Severity.Warning);
                Sentry.captureException(error);
            });
        }

        return result;
    }, []);

    await Promise.all(assetsToCache.map(asset => storage.loadAsset(asset)));
}
