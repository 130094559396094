import * as localForage from 'localforage';
import md5 from 'md5';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/react';

import {storage as lStorage} from '../utilities';


export type AssetType = {
    url: string,
    etag?: string,
    resource_type: 'image' | 'video' | 'm3u8-video',
    dontTransform?: boolean,
    width?: number,
    height?: number,
    format?: string,
    thumbnail?: string,
    subType?: string,
    duration?: number,
    force_duration?: string | number | 'full';
}

export type CachedAssetType = AssetType & {
    blob: Blob,
}


export default class AssetStorage {
    protected storageId: string;
    protected localForage: LocalForage;

    constructor(config?: LocalForageOptions) {
        this.storageId = config?.name || 'default';
        this.localForage = localForage.createInstance({
            driver: localForage.INDEXEDDB, // Force WebSQL; same as using setDriver()
            name: this.storageId,
            version: 1.0,
            size: 5 * 1024 * 1024, // Size of database, in bytes. WebSQL-only for now.
            storeName: 'general_media', // Should be alphanumeric, with underscores.
            description: 'General asset storage',
            ...config
        });
    }

    async clearIfNeeded(latestAnimationDate: number): Promise<void> {
        const storageKey = `${this.storageId}_last_checked`;
        const storedDate = lStorage.get(storageKey);

        if (storedDate && latestAnimationDate < storedDate) {
            return;
        }

        lStorage.set(storageKey, latestAnimationDate);

        await this.localForage.clear();
    }

    async storeImageBlob(canvas: HTMLCanvasElement, name: string): Promise<void> {
        canvas.toBlob(async (blob)=>{
            await this.localForage.setItem(md5(name), blob);
        });
    }

    async loadImageBlobUrl(name: string): Promise<string> {
        const blob = await this.localForage.getItem<Blob>(md5(name));

        return blob ? URL.createObjectURL(blob) : '';
    }

    async loadAsset(asset: AssetType, forceWebp = true, forceHeight?: number, toCache?: boolean): Promise<CachedAssetType> {
        const media: CachedAssetType = {
            ...asset,
            blob: null,
        };

        const isImage = media.resource_type === 'image';
        const shouldResize = isImage || !media?.dontTransform;

        const sizedUrl = forceHeight && shouldResize
            ? media.url.replace(/(w_|h_)\d+/, `h_${forceHeight}`)
            : media.url;

        const url = (forceWebp && sizedUrl?.replace)
            ? sizedUrl
                // .replace('.mp4', '.webp')
                .replace('.png', '.webp')
                .replace('.jpg', '.webp')
            : sizedUrl;

        media.url = url;

        try {
            const key = media.etag ?? md5(media.url);
            let blob = await this.localForage.getItem<Blob>(key);

            if (!blob) {
                const response = await fetch(url);
                blob = await response.blob();

                if (toCache) {
                    blob = await this.localForage.setItem(key, blob);
                }
            }

            media.blob = blob;
        } catch (err) {
            console.warn(err);
            Sentry.withScope(scope => {
                scope.setLevel(Severity.Warning);
                Sentry.captureException(err);
            });
        }

        return media;
    }
}
