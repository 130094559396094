import HeartbeatsApi from './HeartbeatsApi';
import TouchpointsApi from './TouchpointsApi';
import SessionsApi from './SessionsApi';

export default class Api {
	constructor(baseUrl) {
		this.verifyParams({ baseUrl });

		this.heartbeats = new HeartbeatsApi(baseUrl);
		this.touchpoints = new TouchpointsApi(baseUrl);
		this.sessions = new SessionsApi(baseUrl);
	}

	/**
	 * @private
	 */
	verifyParams(params) {
		if (!params) {
			throw new Error('Missing required parameters');
		}

		if (!params.baseUrl) {
			throw new Error('Missing required parameter: "baseUrl"');
		}
	}
}
