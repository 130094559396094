import { ThemeConfig } from "../../types";
import { DefaultFullGrid, DefaultSectionGrid } from "../theme-defaults";

const wellTheme: ThemeConfig = {
	id: "well",
	name: "Well",
	backgroundColor: "#c0c0c0",
	textColor: "#fff",
	hideGridsWhenPlayingMedia: true,
	gridColor: `rgba(0, 0, 0, 0)`,
	gridLineThickness: {
		circle: 2,
		horizontal: 2,
		vertical: 2,
	},
	sequence: {
		forceHeadlineIndex: 1,
		skipTakeover: true,
	},
	// backgroundColor: "rgb(125, 125, 125)",
	// textColor: "#fff",
	// gridColor: `rgba(255, 255, 255, .3)`,
	fullGrid: undefined,
	sectionGrid: undefined,
	codification: "none",
	enableScrim: false,
	showQr: true,
	footer: {
		showIconDividers: true,
	},
	style: {
		headline: {
			marginTop: 'inherit',
			fontSize: 51,
			lineHeight: 40,
			fontWeight: 500,
			fontFamily: "helvetica-neue",
		},
		eyebrow: {
			fontSize: 14,
			lineHeight: 14.4,
			fontWeight: 500,
			letterSpacing: "-0.02em",
			textTransform: "uppercase",
			fontFamily: "helvetica-neue",
		},
		qrContent: {
			fontFamily: 'helvetica-neue',
			fontSize: 13,
			lineHeight: 13,
			letterSpacing: '-0.02em',
			fontWeight: 500,
		},
		ticker: {
			fontFamily: 'brut-grotesque',
			fontWeight: 600,
			fontSize: 28,
			lineHeight: 35,
			textTransform: 'lowercase',
			letterSpacing: '-0.02em',
		}
	},
};

export default wellTheme;