import {
    useCallback,
    useEffect,
    useState,
} from 'react';


export default function useAreFontsLoaded() {
    const [areFontsLoaded, setAreFontsLoaded] = useState(false);
    const onFontsLoaded = useCallback(() => {
        setAreFontsLoaded(true);
    }, []);
    const onFontsLoading = useCallback(() => {
        setAreFontsLoaded(false);
    }, []);

    useEffect(() => {
        document.fonts.ready.then(onFontsLoaded);
        document.fonts.addEventListener('loading', onFontsLoading);
        document.fonts.addEventListener('loadingdone', onFontsLoaded);
        document.fonts.addEventListener('loadingerror', onFontsLoaded);

        return () => {
            document.fonts.removeEventListener('loading', onFontsLoading);
            document.fonts.removeEventListener('loadingdone', onFontsLoaded);
            document.fonts.removeEventListener('loadingerror', onFontsLoaded);
        };
    }, []);

    return areFontsLoaded;
};
