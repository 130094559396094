import React, {useEffect, useMemo, useState} from "react";
import {keyframe, MediaType, preferredAsset} from "../shared";
import Media, {MediaEffect} from "../components/Media";
import {motion} from "framer-motion";
import {DEFAULT_EASE} from '../../rise/constants/transition';
import Transition from "../components/Transition";
import {CenterCourtTicker} from "../components/CenterCourtTicker";
import VerticalTicker, {TickerType} from "../components/VerticalTicker";
import CenterCourtVerticalTicker from "../components/CenterCourtVerticalTicker";
import {JordanConfig} from "../components/ChapterRunner";
import QrSection, {QrCodeInterface} from "../components/QrSection";
import WidescreenQrSection from "../components/WidescreenQrSection";

const defaultTickerUrl = new URL('../demo/center-court/ticker1.png', import.meta.url);

export function centerCourtWofDuration(speed = 1) {
    return 20 * 1_000 * speed;
}

interface CenterCourtWofProps {
    primary: Array<MediaType>;
    secondary: Array<MediaType>;
    config: JordanConfig;
    ticker: any;
    vertical_ticker?: TickerType;
    custom_ticker?: MediaType;
    qr_code?: QrCodeInterface;
    color_filter: 'red' | 'none' | undefined;
}

export default function CenterCourtWof({
                                           primary,
                                           secondary,
                                           ticker,
                                           config,
                                           vertical_ticker,
                                           custom_ticker,
                                           qr_code,
                                           color_filter,
                                       }: CenterCourtWofProps) {
    const [frame1, setFrame1] = useState(true);
    const [frame2, setFrame2] = useState(null);
    const [frame3, setFrame3] = useState(null);
    const [frame4, setFrame4] = useState(null);
    const [frame5, setFrame5] = useState(null);
    const showCircularTicker = useMemo(() => {
        return !config.meta?.isRectangular;
    }, []);

    const primaryFilterColor = useMemo<FrameProps['filter']>(() => {
        if (color_filter === 'none') {
            return undefined;
        }

        return 'red';
    }, [color_filter]);

    const bwColorFilter = useMemo<FrameProps['filter']>(() => {
        if (color_filter === 'none') {
            return undefined;
        }

        return 'bw';
    }, [color_filter]);

    const speed = useMemo(() => config?.duration?.cc_wof_speed, [config]);

    useEffect(() => {
        const {trigger, clear} = keyframe(6, {speed});

        trigger(0, () => setFrame1(false));

        return clear;
    }, []);

    // Second Frame
    useEffect(() => {
        const {trigger, clear} = keyframe(1.5, {speed});

        trigger
        (0, () => setFrame2(100))
        (1, () => setFrame2(75))
        (2, () => setFrame2(50))
        (5.5, () => setFrame2(null));

        return clear;
    }, []);

    // Third Frame
    useEffect(() => {
        const {trigger, clear} = keyframe(6, {speed});

        trigger
        (0, () => setFrame3(100))
        (1, () => setFrame3(75))
        (2, () => setFrame3(50))
        (7.5, () => setFrame3(null));

        return clear;
    }, []);

    // Split Frame
    useEffect(() => {
        const {trigger, clear} = keyframe(12, {speed});

        trigger(0, () => setFrame4(true));

        return clear;
    }, [])

    // Last frame
    useEffect(() => {
        const {trigger, clear} = keyframe(14, {speed});

        trigger
        (0, () => setFrame5(100))
        (1, () => setFrame5(75))
        (2, () => setFrame5(50));

        return clear;
    }, [])

    return (
        <Transition variant={'wipe-x'} cover delayExit={1.7} preventChildExit>
            {
                showCircularTicker && (
                    <CenterCourtTicker
                        width={config?.meta?.width}
                        type={ticker?.type}
                        url={ticker?.asset?.url ?? defaultTickerUrl.toString()}
                        data={ticker?.data}
                    />
                )
            }

            {
                (!showCircularTicker && vertical_ticker) && (
                    <CenterCourtVerticalTicker verticalTicker={vertical_ticker} customTicker={custom_ticker}/>
                )
            }

            {/*Has Full Screen Moment*/}
            {frame1 &&
              <Frame
                effect={'zoom-center'}
                direction={'x'}
                filter={bwColorFilter}
                initial={false}
                media={primary?.[0]}
                state={50}
              />
            }

            {/*Has Full Screen Moment*/}
            {frame2 !== null &&
              <Frame
                direction={'y'}
                effect={'pan-left'}
                initial={false}
                filter={primaryFilterColor}
                media={primary?.[1]}
                state={frame2}
              />
            }

            {/*Has Full Screen Moment*/}
            {
                frame3 !== null &&
              <Frame
                direction={'x'}
                effect={'zoom-out-center'}
                filter={bwColorFilter}
                initial={false}
                media={preferredAsset(primary, 2, 0)} state={frame3}/>
            }

            {/*Have Partial Moments*/}
            <Frame
                hide={!frame4}
                effect={'zoom-pan-left'}
                direction={'y'}
                initial={false}
                filter={bwColorFilter}
                media={preferredAsset(secondary, 0)} state={75}
            />
            <Frame
                hide={!frame4}
                effect={'pan-right'}
                direction={'y'}
                initial={false}
                filter={bwColorFilter}
                media={preferredAsset(secondary, 1)} state={25}
            />

            {
                frame5 !== null &&
              <Frame
                direction={'x'}
                effect={'zoom-out-center'}
                filter={primaryFilterColor}
                initial={false}
                media={preferredAsset(primary, 3, 1, 0)} state={frame5}
              />
            }

            <WidescreenQrSection qr_code={qr_code}/>
        </Transition>
    );
}

const wipes = {
    x: {
        0: 'inset(0% 100% 0% 0%)',
        25: 'inset(0% 50% 0% 0%)',
        50: 'inset(0% 0% 0% 0%)',
        75: 'inset(0% 0% 0% 50%)',
        100: 'inset(0% 0% 0% 100%)',
    },
    y: {
        0: 'inset(0% 0% 100% 0%)',
        25: 'inset(0% 0% 50% 0%)',
        50: 'inset(0% 0% 0% 0%)',
        75: 'inset(50% 0% 0% 0%)',
        100: 'inset(100% 0% 0% 0%)',
    }
}

interface FrameProps {
    media: MediaType;
    effect?: MediaEffect;
    hide?: boolean;
    state: number;
    direction?: string;
    filter?: 'red' | 'bw';
    initial?: boolean;
}

function Frame({
                   media,
                   state,
                   direction = 'x',
                   filter,
                   hide,
                   initial,
                   effect,
               }: FrameProps) {
    const colorFilter = useMemo(() => {
        if (filter === 'red') {
            return 'contrast(0.9) brightness(1) grayscale(40%)';
        }

        if (filter === 'bw') {
            return 'contrast(0.9) brightness(1) grayscale(100%)';
        }

        return 'contrast(0.8) brightness(0.9)';
    }, [filter])

    if (!media) {
        return null;
    }

    return (
        <motion.div
            style={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                opacity: hide ? 0 : 1,
            }}
            initial={initial}

            animate={{
                clipPath: wipes?.[direction]?.[state] ?? wipes.x["25"]
            }}

            transition={{
                ease: DEFAULT_EASE,
                duration: 1,
            }}
        >
            <Media
                preventInitial={true}
                style={{
                    mixBlendMode: 'multiply',
                    filter: colorFilter,
                }}
                media={[media]}
                width={'100%'}
                height={'100%'}
                theme={'dark'}
                effect={effect}
            />
            {
                filter && filter === 'red' && (
                    <div style={{
                        position: 'absolute',
                        background: '#bd2525',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        zIndex: -1
                    }}></div>
                )
            }
        </motion.div>
    )
}