import { CSSProperties } from 'react';
import { cloneDeep, get } from 'lodash';
import { preloadFont } from 'troika-three-text';
import { Easing } from 'framer-motion';

import { clearStorageIfNeeded, createStorage, preloadAllAssets } from './storage';
import { HoiConfig } from './components/ChapterRunner';
import { fonts } from '../core/animations/ticker/ticker/shared';
import { NIKE_QR_BASE_URL } from '../../config';
import * as TitleCard from './screens/TitleCard';
import * as LayeredMedia from './screens/LayeredMedia';
import * as Services from './screens/Services';
import * as Wayfinding from './screens/Wayfinding';
import * as ProductIntro from './screens/ProductIntro';
import * as CampaignIntro from './screens/CampaignIntro';
import * as Carousel from './screens/Carousel';
import * as Podium from './screens/Podium';
import * as Nby from "./screens/Nby";
import * as NbyAttract from '../core/animations/nby/screens/attract';
import * as AppEventsService from '../../services/app-events.service';
import TemplateEventsEnum from '../../enums/TemplateEvents.enum';

export type ThemeType = 'dark' | 'light';

export function themeToCSS(theme: ThemeType): CSSProperties {
    switch (theme) {
        case 'dark':
            return {
                background: 'black',
                color: 'white',
            };

        case 'light':
        default:
            return {
                background: 'white',
                color: 'black',
            }
    }
}

export type MultiLangText = { [lang: string]: string };

export interface MediaType {
    blob?: Blob;
    etag?: string;
    url: string;
    original_url?: string;
    resource_type: 'image' | 'video' | 'm3u8-video';
    fit?: 'fit' | 'cover';
    force_duration?: string | number | 'full';
    duration?: number;
    width?: number;
    height?: number;
    format?: string;
    dontTransform?: boolean;
    destination_url?: string;
    toDim?: boolean;
}

export type CoverType = 'fill' | 'fit';

export type ChapterTemplateType =
    'title_card'
    | 'wayfinding'
    | 'media'
    | 'services'
    | 'product_intro'
    | 'campaign_intro'
    | 'carousel'
    | 'podium'
    | 'nby'
    | 'nby_attract_video'
;

export interface ChapterType {
    template: ChapterTemplateType;
    duration: number;
    exitDuration: number;
    data: any;
    skip?: boolean;
    fullScreen?: boolean;
    transition?: TransitionConfig;
}

export type TransitionVariant = 'two-columns' | 'wipe';

export type TransitionConfig = {
    variant: TransitionVariant,
};

export interface CenterCourtGradient {
    type: 'linear' | 'radial' | undefined;
    colors: Array<Array<string>>
}

async function initialize(): Promise<void> {
    createStorage();
    await clearStorageIfNeeded();

    const characters = '01234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    return new Promise((resolve, reject) => {
        preloadFont({ font: fonts['helvetica-neue-hoi_ehq'], characters }, resolve);
    });
}

function selectHandler(templateType: ChapterTemplateType) {
    switch (templateType) {
        case 'title_card':
            return TitleCard;

        case 'media':
            return LayeredMedia;

        case 'services':
            return Services;

        case 'wayfinding':
            return Wayfinding;

        case 'product_intro':
            return ProductIntro;

        case 'campaign_intro':
            return CampaignIntro;

        case 'carousel':
            return Carousel;

        case 'podium':
            return Podium;

        case 'nby':
            return Nby;

        case 'nby_attract_video':
            return NbyAttract;
    }
}

interface CanCalculateDuration {
    calculateDuration?: (chapterData: any, config: any) => number
    calculateExitDuration?: (chapterData: any) => number
}

function calculateDuration(templateType: ChapterTemplateType, chapterData, config): number {
    let duration = chapterData?.duration ?? config?.duration?.default;

    const customHandler = selectHandler(templateType) as CanCalculateDuration;

    return customHandler?.calculateDuration(chapterData, config) || duration;
};

function calculateExitDuration(templateType: ChapterTemplateType, chapterData) {
    const customHandler = selectHandler(templateType) as CanCalculateDuration;

    return customHandler?.calculateExitDuration?.(chapterData) || 0;
}

const mediaChapters: ChapterTemplateType[] = ['media', 'podium', 'campaign_intro'];
export async function prepareShowRunner(rawData: any, config?: HoiConfig): Promise<Array<ChapterType>> {
    AppEventsService.sendEvent(TemplateEventsEnum.DataLoad);
    await initialize();

    const {
        chapters = [],
    } = rawData;
    const filterDisabled = ({ disabled = false }) => disabled === false;
    const filteredChapters = chapters.filter(filterDisabled);
    const promises = filteredChapters.map(async (initialChapterData): Promise<ChapterType> => {
        let { template, ...data } = initialChapterData;

        const insideEditor = !get(window, 'channel');

        AppEventsService.sendEvent(TemplateEventsEnum.AssetsLoad);

        if (insideEditor) {
            console.log('dont cache in editor');

            preloadAllAssets(data, {
                ...config,
                dontCache: insideEditor,
                forceWebp: false,
                forceAssetHeight: 0,
            });
        } else {
            data = await preloadAllAssets(data, {
                ...config,
                dontCache: insideEditor
            });
        }

        if (mediaChapters.includes(template)) {
            if (filteredChapters.length > 1 && !data.transition) {
                data.transition = {
                    variant: 'wipe',
                    props: {
                        variant: 'wipe-x',
                        duration: 2,
                    },
                    overlapPrev: 0,
                    overlapNext: 0,
                };
            } else {
                data.preventInitialSlide = true;
            }
        }

        return {
            data,
            template,
            skip: false,
            duration: calculateDuration(template, data, config),
            exitDuration: calculateExitDuration(template, data),
        };
    });

    return Promise.all(promises);
}

export function stringIsFilled(str?: string) {
    return str && str.length > 0 && str.replaceAll(' ', '') !== '';
}

export function stringFallback(content: any, defaultValue?: string) {
    return ['', null, undefined, ' '].includes(content) ? defaultValue : content;
}

export const SECOND = 1_000;

export const keyframe = (initialDelay, config: { speed: number } = { speed: 1 }) => {
    let timeouts = [];
    let clears = [];
    const { speed = 1 } = config;

    const trigger = (after: number, action: TimerHandler) => {
        const id = setTimeout(action, ((after + initialDelay) * SECOND) * speed);
        timeouts.push(id);

        const { trigger, clear } = keyframe(initialDelay + after, config);
        clears.push(clear);
        return trigger;
    }

    return {
        trigger,
        clear: () => {
            timeouts.forEach(i => clearTimeout(i));
            clears.forEach(i => i());
        }
    }
}

/**
 * Gets the preferred item from array falls back in the order of indexes.
 *
 * @param array
 * @param indexes
 */
export const preferredAsset = (array: Array<MediaType>, ...indexes: Array<number>): MediaType | null => {
    const index = indexes.findIndex((i) => {
        return array?.[i]?.url;
    });
    return array?.[indexes[index]];
}

export function countDown(date) {
    const now = new Date().getTime();
    // Find the distance between now and the count down date
    var distance = date - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
}

export const DEFAULT_EASE: Easing = [0.4, 0, 0, 1];

export const fetchChannelInfo = () => {
	return cloneDeep(window?.channel?.channels?.[0])
}

const calculateSize = () => {
    const MAX_HEIGHT = 3_840;
    const height = window.innerHeight;

    return Math.min(height, MAX_HEIGHT );
}

export function channelConfig({ forceAssetHeight }: { forceAssetHeight?: boolean } = {}): HoiConfig {
    const channel = fetchChannelInfo() ?? {};

    return {
        duration: {
            default: get(channel, 'json_config.duration.default', 8) * SECOND,
            interstitial: get(channel, 'json_config.duration.interstitial', 3) * SECOND,
        },
        forceAssetHeight: forceAssetHeight ? calculateSize() : undefined,
        locationId: get(channel, 'location_id', null),
    }
}

export function timedMedia(media: Array<MediaType>, totalDuration): Array<MediaType> {
    const filtered = media.filter(i => i?.url);
    const duration = (totalDuration / SECOND) / filtered.length;

    return filtered.map((m, i) => {
        const isLast = (i + 1) === media.length;

        return {
            ...m,
            force_duration: isLast ? duration + 1 : duration
        }
    });
}

/**
 * Checks if the canvas is blank.
 *
 * @note May be performance issue.
 *
 * @param canvas
 */
export function isCanvasBlank(canvas): boolean {
    const blank = document.createElement('canvas');

    blank.width = canvas.width;
    blank.height = canvas.height;

    return canvas.toDataURL() === blank.toDataURL();
}

export function toTitleCase(str: string): string {
    return str.split(' ')
        .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
        .join(' ');
}

export function makeDynamicFontSize(factor): Function {
    return (size) => {
        return size * factor;
    }
}

export function getCoordinates(): string {
    const meta = get(window, 'animation_store.json_meta', {} as Window['animation_store']['json_meta']);

    const {
        n = "9.1873",
        e = "45.4628",
    } = meta?.coordinates ?? {};

    return `${n}°N ${e}°E`;
}

interface QrUrlConfig {
    width?: number;
    trackChannel?: boolean;
}

export function qrUrlTrack(destination: string, config: QrUrlConfig = {}): string {
    const { width = 140, trackChannel = true } = config;
    const channelSlug = trackChannel ? get(window, 'channel.channels[0].slug', null) : null;

    const url = new URL(NIKE_QR_BASE_URL);

    url.searchParams.set('width', width.toString());
    url.searchParams.set('url', destination);

    if (channelSlug) {
        url.searchParams.set('channel-slug', channelSlug);
    }

    return url.toString();
}

export function truncate(content: string, maxLength = 20) {
    return content?.length > maxLength ? `${content?.substring(0, maxLength)}...` : content;
}

export function longestWordInString(content: string): number {
    const words = content.match(/\b(\w+)\b/g);

    return words?.reduce((acc, curr) => {
        const wordLength = curr?.length ?? 0;

        return acc > wordLength ? acc : wordLength;
    }, 0) ?? 0;
}

export function secToMs(seconds: number) {
    return seconds * SECOND;
}


export function setAnimationTimeout(fn: Function, timeOut: number) {
    let request: number;
    let start: number;

    const step = (timeStamp: number) => {
        start = start || timeStamp;
        const ellapsed = timeStamp - start;

        if (ellapsed < timeOut) {
            request = window.requestAnimationFrame(step);
        } else {
            request = undefined;
            fn();
        }
    };

    request = window.requestAnimationFrame(step);

    return () => {
        if (!request) {
            return;
        }

        window.cancelAnimationFrame(request);
        request = undefined;
    }
}

export function getLangText(multiLangText: MultiLangText, lang: string) {
    return multiLangText[lang] || '';
}

export function hasTextToDisplay(multiLangText: MultiLangText, languages?: string[]) {
    return Boolean(languages?.find(lang => getLangText(multiLangText, lang).length > 0));
}

export function getMediaDuration(media: MediaType) {
    const {
        resource_type,
        force_duration,
        duration,
    } = media;
    const toShowFullVideo = resource_type === 'video' && force_duration === 'full';

    return toShowFullVideo ? duration : Number(force_duration || 0);
}

export function scaleText(container: HTMLElement, partSelector: string) {
    const {width} = container.getBoundingClientRect();
    let maxNeededWidth = width;

    const parts = container.querySelectorAll<HTMLSpanElement>(partSelector);
    for (const part of parts) {
        const wordWidth = part.getBoundingClientRect().width;
        maxNeededWidth = Math.max(maxNeededWidth, wordWidth);
    }

    const scale = width / maxNeededWidth;

    const style = window.getComputedStyle(container);
    container.style.fontSize = `calc(${style.fontSize} * ${scale})`;
    container.style.lineHeight = `calc(${style.lineHeight} * ${scale})`;

    return scale;
}
