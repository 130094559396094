import React from 'react';

import {
    getMediaDuration,
    MediaType,
    secToMs,
} from "../shared";
import MediaPlayer from '../components/Media';
import { HoiConfig } from "../components/ChapterRunner";
import { BaseChapterProps } from '../components/ChapterRenderer';

const defaultMediaDuration = 5; // in seconds

export type MediaProps = {
    media: Array<MediaType>;
    preventInitialSlide?: boolean,
    className?: string,
}

export function calculateDuration(props: MediaProps, config?: HoiConfig) {
    const {
        media,
    } = props;
    const defaultDuration = config?.meta?.gradient ? 5 : 0;

    const duration = media?.filter(i => i)?.reduce((acc, curr) => {
        const duration = getMediaDuration(curr) || defaultMediaDuration;
        return acc + duration;
    }, 0) ?? defaultDuration

    return secToMs(duration);
}

export function calculateExitDuration(props: MediaProps) {
    return 0;
}

type MediaScreenProps = BaseChapterProps & MediaProps;

export default function Media(props: MediaScreenProps) {
    const {
        media,
        preventInitialSlide,
        className,
    } = props;

    return (
        <MediaPlayer
            className={className}
            preventInitial
            preventInitialSlide={preventInitialSlide}
            media={media}
            width={'100%'}
            height={'100%'}
            useInternalTiming
            preventExit
            duration={defaultMediaDuration}
        />
    )
}
