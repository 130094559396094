import React, { forwardRef, useState, useEffect, useRef } from "react";
import cn from "classnames";

import "./_codification.scss";

import { EXCEPTIONS } from "./codification.js";

import Line from "./CodificationLine.component";

import Context from "./Codification.context";
import defaultProps from "./Codification.defaultProps";
import propTypes from "./Codification.propTypes";

const generateLines = (text = "") => {
	return text
		.replace("\t", "")
		.split("\n")
		.map((line) => {
			// if empty line then return space otherwise span will have no height
			if (line === '') {
				return [[' ']];
			}
			return line.split(" ").map((word) => {
				const characters = word.split("");
				let specialChar = "";

				return characters.reduce((result, char, index) => {
					const nextChar = characters[index + 1] || "";
					const isException = EXCEPTIONS.includes(char);

					if (nextChar && isException) {
						specialChar = `${specialChar}${char}`;
					}

					if (!isException || !nextChar) {
						result.push(`${specialChar}${char}`);
						specialChar = "";
					}

					return result;
				}, []);
			});
		});
};

const Codification = forwardRef(
	(
		{
			className,
			tag: Tag,
			text,
			typography,
			characterSwitchAmount,
			characterNextTrigger,
			setLanguageClassName = false,
			characterTimeout,
			start,
			reverse,
			align,
			delay,
			onEnded,
			isStatic,
			respectWhitespace = false,
		},
		ref
	) => {
		const cleanText = respectWhitespace ?
			text : text?.trim()?.length > 0 ? text.trim() : null;
		const initialReverse = useRef(reverse);
		const initialIsStatic = useRef(isStatic);
		const [lines, setLines] = useState(null);
		const [active, setActive] = useState(null);
		const [dir, setDir] = useState(1);

		const words = (lines && lines.flat()) || [];
		const characters = words.flat();
		const isAlignLeft = align === "left" || align === "";
		const isAlignRight = align === "right";

		const nextCharacter = () => {
			const next = active + dir;
			const character = characters[next];

			if (next > characters.length - 1 || next < 0) {
				setActive(null);
				onEnded({isReverse: reverse});
			} else if (character === " ") {
				setActive(next + dir);
			} else {
				setActive(next);
			}
		};

		useEffect(() => {
      if (cleanText) {
        setLines(generateLines(cleanText));
        setActive(null);
      }

			return () => {
				setLines(null);
			};
		}, [cleanText]);

		useEffect(() => {
      if (!cleanText && start) {
        onEnded();
        return;
      }

			let delayTimeout = null;

			if (!isStatic && lines && start && active === null) {
				// Only set start delay with initial reverse
				if (delay > 0 && reverse === initialReverse.current) {
					delayTimeout = setTimeout(() => {
						setActive(reverse ? characters.length - 1 : 0);
					}, delay);
				} else {
					setActive(reverse ? characters.length - 1 : 0);
				}
			}

			setDir(reverse ? -1 : 1);

			return () => {
				if (delayTimeout) {
					clearTimeout(delayTimeout);
				}
				setActive(null);
				setDir(1);
			};
		}, [start, reverse, lines, isStatic]);

    if (!cleanText) {
      return null;
    }

		return (
			<Context.Provider
				value={{
					text: cleanText,
					typography,
					lines,
					words,
					characters,
					active,
					repeat: characterSwitchAmount,
					trigger: characterNextTrigger,
					timeout: characterTimeout,
					dir,
					reverse,
					onTrigger: nextCharacter,
					isAlignLeft,
					isAlignRight,
					isStatic,
					initialIsStatic: initialIsStatic.current,
				}}
			>
				{lines && (
					<Tag
						ref={ref}
						className={cn("c-codification", className, {
							"c-codification--right": isAlignRight,
						})}
					>
						{lines.map((line, index) => {
							return (
								// eslint-disable-next-line react/no-array-index-key
								<Line
									respectWhitespace={respectWhitespace}
									key={`codification-line-${cleanText}-${index}`}
									words={line}
									index={index}
									setLanguageClassName={setLanguageClassName}
								/>
							);
						})}
					</Tag>
				)}
			</Context.Provider>
		);
	}
);

Codification.defaultProps = defaultProps.codification;
Codification.propTypes = propTypes.codification;

export default Codification;
