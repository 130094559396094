import {useMemo} from "react";
import FamilyTicker from "./FamilyTicker";
import {ThemeType} from "../shared";
import LocationTicker from "./LocationTicker";
import TimeZoneTicker from "./TimeZoneTicker";
import AltitudeTicker from "./AltitudeTicker";
import {getChannelInfo} from '../../rise/hyperlive/utils';
import CustomTicker from "./CustomTicker";
import WingsTicker from "./WingsTicker";
import WingsChinaTicker from "./WingsChina";

export type TickerType = 'family' | 'timezone' | 'location' | 'altitude' | 'custom' | 'wings' | 'wings_china' | undefined;

interface VerticalTickerProps {
    type?: TickerType;
    theme: ThemeType;
    asset: any;
    reverse?: boolean;
    custom?: object;
    duration?: number;
}

export default function VerticalTicker({type = 'altitude', theme, reverse = false, asset, custom = {}, duration}: VerticalTickerProps) {
    const tickerDuration = useMemo(() => getChannelInfo('json_config.duration.vertical_ticker') ?? duration, [type, theme, duration]);

    const components: Partial<Record<TickerType, any>> = {
        family: <FamilyTicker reverse={reverse} duration={tickerDuration} theme={theme} {...custom} />,
        location: <LocationTicker reverse={reverse} theme={theme} {...custom} />,
        timezone: <TimeZoneTicker reverse={reverse} duration={tickerDuration} theme={theme} {...custom} />,
        altitude: <AltitudeTicker reverse={reverse} duration={tickerDuration} theme={theme} {...custom} />,
        wings: <WingsTicker reverse={reverse} duration={tickerDuration} theme={theme} {...custom} />,
        wings_china: <WingsChinaTicker reverse={reverse} duration={tickerDuration} theme={theme} {...custom} />,
        custom: <CustomTicker reverse={reverse} duration={tickerDuration} theme={theme} asset={asset} {...custom} />
    };

    return (
        <div style={{width: 100}}>
            {components[type]}
        </div>
    );
}