import {FastLaneInstance} from "../types";
import LanePreview from "./lane-preview";
import FastLane from "../fast-lane";
import {useMemo} from "react";
import {ABSOLUTE_FILL} from "../helpers";
import {LANE_HEIGHT, TICKER_HEIGHT} from "../shared";

interface PreviewProps {
    instance: FastLaneInstance;
}

export default function Preview({instance}: PreviewProps) {
    const previewInstance = usePreviewInstance(instance);

    return (
        <div
            style={{
                overflow: "scroll",
                width: "100vw",
                position: "relative",
                height: "100vh",
            }}
        >
            <LanePreview instance={previewInstance}/>
            <div style={{...ABSOLUTE_FILL, top: '100px',}}>
                <FastLane
                    display={"ticker"}
                    preventInitializing={true}
                    instance={previewInstance}
                />
            </div>

            <div style={{...ABSOLUTE_FILL, top: '300px'}}>
                <FastLane
                    display={"fast-lane"}
                    instance={previewInstance}
                />
            </div>
        </div>
    );
}

/**
 * constructs an instance that can be used for the preview.
 * -  it forces full width to prevent wrapping
 * -  as well as a pixel pitch of 1 to prevent scaling
 * @param instance
 */
function usePreviewInstance(instance: FastLaneInstance) {
    return useMemo(() => {
        return {
            ...instance,
            pixelMap: {
                "fast-lane": {
                    ...instance.pixelMap["fast-lane"],
                    wrapperWidth: instance.pixelMap["fast-lane"].contentWidth,
                    offsetLeft: 0,
                    pixelPitch: 1,
                    tickerPixelPitch: 1,
                    fastLanePixelPitch: 1,
                },
                ticker: {
                    ...instance.pixelMap.ticker,
                    wrapperWidth: instance.pixelMap["fast-lane"].contentWidth,
                    contentWidth: instance.pixelMap["fast-lane"].contentWidth,
                    contentHeight: TICKER_HEIGHT,
                    wrapperHeight: TICKER_HEIGHT,
                    offsetLeft: 0,
                    pixelPitch: 1,
                    tickerPixelPitch: 1,
                    fastLanePixelPitch: 1,
                },
            },
        };
    }, [instance]);
}