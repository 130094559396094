import { useFastLane } from '../store/index';
import { useMemo } from 'react';
import { useFarthestPoint } from '../helpers';

export function useTakeoverTiling() {
  const takeover = useFastLane(f => f.takeover);
  const entries = useFastLane(f => f.entries);
  const farRight = useFarthestPoint();

  return useMemo(() => {
    if (!takeover.tilingWidth) {
      const repeatCount = takeover.forceRepeatCount ?? entries.length;
      const width = farRight / repeatCount;

      return {
        repeatCount,
        width,
      }
    }

    const repeatCount = Math.ceil(farRight / takeover.tilingWidth);
    const width = takeover.tilingWidth;

    return {
      repeatCount,
      width,
    }
  }, [takeover, entries]);

}