import { get, set } from "./index";

export interface MediaState {
	mediaPlaying: Array<number> | undefined;
	enableMedia: (index?: number) => void;
	disableMedia: (index?: number) => void;
	isMediaPlaying: (index?: number) => boolean;
}

export const mediaState = (set: set, get: get): MediaState => ({
	mediaPlaying: [],
	enableMedia: (index?: number) => {
		if (index === undefined) {
			const indexes = get().entries.map((_, i) => i);

			return set({ mediaPlaying: indexes });
		}

		const current = (get().mediaPlaying || []);

		set({
			mediaPlaying: [
				...current,
				index
			]
		});
	},
	disableMedia: (index?: number) => {
		if (index === undefined) {
			return set({ mediaPlaying: [] });
		}

		set({ mediaPlaying: get().mediaPlaying?.filter((i) => i !== index) });
	},
	isMediaPlaying: (index?: number) => {
		if (index === undefined) {
			return (get().mediaPlaying || []).filter(i => i !== undefined).length > 0;
		}

		return (get().mediaPlaying || []).includes(index);
	},

});