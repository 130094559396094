import {Ref, RefObject, useLayoutEffect, useState} from "react";

export function useContainerWidth<T extends HTMLElement>(container: RefObject<T>) {
    const [width, setWidth] = useState(1080);

    useLayoutEffect(() => {
        if (!container.current) {
            return;
        }

        const resizeHandler = () => {
            setWidth(container?.current?.clientWidth ?? 1080);
        };

        resizeHandler();

        window.addEventListener('resize', resizeHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, []);

    return width;
}