import setAnimationTimeout from '../core/utils/setAnimationTimeout';
import main from './main';
import { listenForTakeover } from '../../services/takeover-service/utils';
import { getScreenDims } from './helpers/get-dims';

export default async function TakeoverEntry(data, channel) {
    const container = document.getElementById('takeover-root');
    const dims = getScreenDims();

    const {
        render,
        clear,
        duration,
    } = await main(container, {
        rawAnimationData: data,
        channel,
        height: dims?.height,
        width: dims?.width,
    });

    listenForTakeover('FULL_TAKEOVER', ({type}) => {
        switch (type) {
            case 'start':
                render();
                setAnimationTimeout(() => clear(), duration * 1000);
                break;

            default:
                clear();
                break;
        }
    })
}
