type Props = {
    withoutRoundCorners?: boolean,
};

export default function MiddleGrid(props: Props) {
    const {
        withoutRoundCorners = true,
    } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="432"
            height="218"
            fill="none"
            viewBox="0 0 432 218"
        >
            <g
                stroke="#222"
                strokeMiterlimit="10"
                strokeWidth="1.139"
                clipPath="url(#clip0_1297_1356)"
            >
                <path d="M296 219V0M228 219V0M364 219V0"></path>
            </g>
            <g stroke="#222" strokeWidth="1.153" clipPath="url(#clip1_1297_1356)"
               transform={withoutRoundCorners ? 'translate(-26 0)' : undefined}
            >
                <path d="M145.577-36c0-59.88 48.542-108.423 108.423-108.423h499.439V72.423H254c-59.88 0-108.423-48.542-108.423-108.423z"></path>
                <path d="M72.577-36c0-99.645 80.778-180.423 180.423-180.423h829.42v360.846l-829.42.001C153.355 144.423 72.577 63.645 72.577-36z"></path>
                <path d="M-.423-47.282c0-133.731 108.41-242.141 242.141-242.141H1417.42v506.847H241.718C107.987 217.424-.423 109.013-.423-24.718v-22.564z"></path>
            </g>
            <defs>
                <clipPath id="clip0_1297_1356">
                    <path
                        fill="#fff"
                        d="M0 0H218V432H0z"
                        transform="rotate(-90 109 109)"
                    ></path>
                </clipPath>
                <clipPath id="clip1_1297_1356">
                    {withoutRoundCorners ? (
                        <path
                            fill="#fff"
                            d="M0 0H218V202H0z"
                            transform="rotate(90 228 228)"
                        ></path>
                    ) : (
                        <path
                            fill="#fff"
                            d="M0 0H218V432H0z"
                            transform="rotate(90 216 216)"
                        ></path>
                    )}
                </clipPath>
            </defs>
        </svg>
    )
}
