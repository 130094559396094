import useWebSocket, {ReadyState} from "react-use-websocket";
import {Value} from "./controller-values";
import {useMemo} from "react";
import {WEBSOCKET_URL} from "../../../../../config";

const WS_IDENTIFIER = "fl_controller:";

interface MessageData {
    channel?: string;
    trigger: string;

    [key: string]: any;
}

function getData(message: any): MessageData {
    try {
        return JSON.parse(message.data);
    } catch (e) {
        return null;
    }
}

export default function useController({channel}: { channel?: string } = {}) {
    const {sendMessage, lastMessage, readyState} = useWebSocket(WEBSOCKET_URL, {
        share: true,
        fromSocketIO: false,
        reconnectInterval: 3000,
        reconnectAttempts: 30,
        heartbeat: true,
        retryOnError: true,
        shouldReconnect: (closeEvent) => true,
        filter: (message) => {
            const data = getData(message);

            if (!data) {
                return false;
            }

            return channel === data?.channel;
        },
    });

    const connectionStatus = {
        [ReadyState.CONNECTING]: "Connecting",
        [ReadyState.OPEN]: "Connected",
        [ReadyState.CLOSING]: "Closing",
        [ReadyState.CLOSED]: "Closed",
        [ReadyState.UNINSTANTIATED]: "Uninstantiated",
    }[readyState];

    const currentValue = useMemo<null | Value>(() => {
        if (!lastMessage?.data) {
            return null;
        }

        const data = getData(lastMessage);

        const {trigger, ...other} = data;
        const id = trigger.replace(WS_IDENTIFIER, "");

        return {
            id,
            ...other,
        };
    }, [lastMessage?.data]);


    return {
        readyState,
        status: connectionStatus,
        trigger: (value: Value) => {
            const {id, ...rest} = value;

            const data = {
                channel,
                trigger: `${WS_IDENTIFIER}${id}`,
                timestamp: Date.now(),
                ...rest,
            };

            const message = {
                message: "sendmessage",
                data: JSON.stringify(data),
            };
            sendMessage(JSON.stringify(message));
        },
        currentValue,
    };
}