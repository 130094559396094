import { scaleText } from '../../shared';
import * as cssClassNames from '../../styles/components/PhantomText.module.scss';


export default function rewrapText(translations: string[], containerElement: HTMLElement) {
    const computedStyle = window.getComputedStyle(containerElement);

    const scale = scaleText(containerElement, `.${cssClassNames.word}`);

    containerElement.classList.add(cssClassNames.nowrap);

    const widthStyle = parseFloat(computedStyle.width);
    const {
        width,
        height
    } = containerElement.getBoundingClientRect();
    const isVerticalMode = Math.round(widthStyle * 100) === Math.round(height * 100);
    const containerWidth = isVerticalMode ? height : width;

    let spaceWidth = 0;
    const space = containerElement.querySelector(`.${cssClassNames.space}`);
    if (space) {
        const {
            width,
            height,
        } = space.getBoundingClientRect();
        spaceWidth = isVerticalMode ? height : width;
    }

    const rewrappedTanslations: string[][] = [];
    const translationElements = containerElement.querySelectorAll(`.${cssClassNames.text}`);
    for (let trIndex = 0; trIndex < translations.length; trIndex++) {
        const translationContaner = translationElements[trIndex];

        const rewrappedLines: string[][] = [];
        const lines = Array.from(translationContaner.querySelectorAll(`.${cssClassNames.line}`));
        for (const line of lines) {
            const words = line.querySelectorAll<HTMLSpanElement>(`.${cssClassNames.word}`);

            let wordsInLine: string[] = [];
            rewrappedLines.push(wordsInLine);
            let occupiedWidth = 0;
            for (let w = 0; w < words.length; w++) {
                const wordElement = words[w];
                const word = wordElement.innerText;
                const {
                    width,
                    height,
                } = wordElement.getBoundingClientRect();
                const wordWidth = isVerticalMode ? height : width;
                const goingToOccupie = occupiedWidth + wordWidth;

                if (goingToOccupie <= containerWidth || w === 0) {
                    wordsInLine.push(word);
                    occupiedWidth = goingToOccupie + spaceWidth;
                    continue;
                }

                occupiedWidth = wordWidth + spaceWidth;
                wordsInLine = [word];
                rewrappedLines.push(wordsInLine);
            }
        }

        rewrappedTanslations.push(rewrappedLines.map(words => words.join(' ')));
    }

    const maxAmountOfLines = rewrappedTanslations.reduce((result, current) => {
        return Math.max(result, current.length);
    }, 0);
    const newTranslatedLines: string[][] = [];
    for (let n = 0; n < maxAmountOfLines; n++) {
        const translatedLine: string[] = [];
        for (let tr = 0; tr < translations.length; tr++) {
            translatedLine[tr] = rewrappedTanslations[tr][n] || '';
        }
        newTranslatedLines[n] = translatedLine;
    }

    return {
        lines: newTranslatedLines,
        scale,
    };
}
