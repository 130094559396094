@use '../../../../styles/utils' as utils;

.nby_attract {
    position: absolute;
    height: 100%;
    width: 100%;

    > * {
        position: absolute;
        height: 100%;
        width: 100%;
    }

    .background_medias {
        display: flex;
        width: 100vw;

        > * {
            width: 99.99vw;
        }
    }

    .overlay > * {
        position: absolute;
    }

    &:not([data-orientation]),  &[data-orientation = ''],  &[data-orientation = 'portrait'] {
        .patch {
            img, video {
                height: utils.to-vh(420, 1920) !important;
                min-height: utils.to-vh(420, 1920) !important;
                max-height: utils.to-vh(420, 1920) !important;
                width: utils.to-vh(420, 1920) !important;
                min-width: utils.to-vh(420, 1920) !important;
                max-width: utils.to-vh(420, 1920) !important;
            }
        }

        .animation-variant_1 {
            > * {
                &:nth-child(1) {
                    top: utils.to-vh(60, 1920);
                    right: utils.to-vw(200, 1080);

                    img, video {
                        height: utils.to-vh(1040, 1920);
                        min-width: utils.to-vw(880, 1080);
                        max-width: 100vw;
                    }
                }

                &:nth-child(2) {
                    right: utils.to-vw(120, 1080);
                    bottom: utils.to-vh(205, 1920);

                    img, video {
                        height: utils.to-vh(488, 1920);
                        width: utils.to-vh(488, 1920);
                    }
                }

                &:nth-child(3) {
                    left: 0;
                    top: 100vh;

                    img, video {
                        min-height: utils.to-vh(300, 1920);
                        max-height: utils.to-vh(540, 1920);
                        width: utils.to-vh(300, 1920);
                    }
                }

                &:nth-child(4) {
                    left: utils.to-vw(-23, 1080);
                    bottom: utils.to-vh(443.43, 1920);
                    z-index: 1;

                    img, video {
                        min-height: utils.to-vh(655, 1920);
                        max-height: utils.to-vh(800, 1920);
                        width: utils.to-vw(368, 1080);
                        height: unset;
                    }
                }

                &:nth-child(5) {
                    right: utils.to-vw(90, 1080);
                    bottom: utils.to-vh(558.43, 1920);

                    img, video {
                        height: utils.to-vh(455, 1920);
                        max-width: utils.to-vw(792, 1080);
                        min-width: utils.to-vw(640, 1080);
                    }
                }
            }
        }

        .animation-variant_2 {
            > * {
                &:nth-child(1) {
                    top: utils.to-vh(170, 1920);
                    right: 0;

                    img, video {
                        height: utils.to-vh(770, 1920);
                        min-width: utils.to-vw(880, 1080);
                        max-width: utils.to-vw(1020, 1080);
                    }
                }

                &:nth-child(2) {
                    bottom: utils.to-vh(280, 1920);

                    img, video {
                        height: utils.to-vh(448, 1920);
                        width: utils.to-vh(448, 1920);
                    }
                }

                &:nth-child(3) {
                    left: 0;
                    top: 100vh;

                    img, video {
                        min-height: utils.to-vh(300, 1920);
                        max-height: utils.to-vh(540, 1920);
                        width: utils.to-vh(300, 1920);
                    }
                }

                &:nth-child(4) {
                    right: utils.to-vw(50, 1080);
                    top: utils.to-vh(115, 1920);

                    img, video {
                        min-height: utils.to-vh(488, 1920);
                        max-height: utils.to-vh(611, 1920);
                        width: utils.to-vh(488, 1920);
                    }
                }

                &:nth-child(5) {
                    bottom: utils.to-vh(300, 1920);

                    img, video {
                        height: utils.to-vh(800, 1920);
                        min-width: utils.to-vw(300, 1080);
                        max-width: 100vw;
                    }
                }
            }
        }

        .animation-variant_3 {
            > * {
                &:nth-child(1) {
                    top: utils.to-vh(160, 1920);
                    right: 0;

                    img, video {
                        height: utils.to-vh(934, 1920);
                        min-width: utils.to-vw(622, 1080);
                        max-width: 100vw;
                    }
                }

                &:nth-child(2) {
                    bottom: utils.to-vh(220, 1920);

                    img, video {
                        height: utils.to-vh(468, 1920);
                        width: utils.to-vw(624, 1080);
                    }
                }

                &:nth-child(3) {
                    right: 0;
                    top: 100vh;

                    img, video {
                        min-height: utils.to-vh(300, 1920);
                        max-height: utils.to-vh(540, 1920);
                        width: utils.to-vh(300, 1920);
                    }
                }

                &:nth-child(4) {
                    left: utils.to-vw(50, 1080);
                    top: utils.to-vh(115, 1920);

                    img, video {
                        min-height: utils.to-vh(488, 1920);
                        max-height: utils.to-vh(611, 1920);
                        width: utils.to-vh(488, 1920);
                    }
                }

                &:nth-child(5) {
                    bottom: utils.to-vh(290, 1920);
                    right: 0;

                    img, video {
                        height: utils.to-vh(800, 1920);
                        min-width: utils.to-vw(300, 1080);
                        max-width: 100vw;
                    }
                }
            }
        }
    }

    &[data-orientation = 'landscape'] {
        .patch {
            img, video {
                height: utils.to-vh(420, 1080) !important;
                min-height: utils.to-vh(420, 1080) !important;
                max-height: utils.to-vh(420, 1080) !important;
                width: utils.to-vh(420, 1080) !important;
                min-width: utils.to-vh(420, 1080) !important;
                max-width: utils.to-vh(420, 1080) !important;
            }
        }

        .animation-variant_1 {
            > * {
                &:nth-child(1) {
                    top: utils.to-vh(80, 1080);
                    left: 0;

                    img, video {
                        height: utils.to-vh(857, 1080);
                        width: utils.to-vw(857, 1920);
                    }
                }

                &:nth-child(2) {
                    right: utils.to-vw(120, 1920);
                    bottom: utils.to-vh(80, 1080);

                    img, video {
                        height: utils.to-vh(488, 1080);
                        width: utils.to-vh(488, 1080);
                    }
                }

                &:nth-child(3) {
                    left: 0;
                    top: 100vh;

                    img, video {
                        min-height: utils.to-vh(300, 1080);
                        max-height: utils.to-vh(540, 1080);
                        width: utils.to-vh(300, 1080);
                    }
                }

                &:nth-child(4) {
                    left: utils.to-vw(-23, 1920);
                    bottom: utils.to-vh(160, 1080);
                    z-index: 1;

                    img, video {
                        min-height: utils.to-vh(655, 1080);
                        max-height: utils.to-vh(760, 1080);
                        width: utils.to-vw(368, 1920);
                        height: unset;
                    }
                }

                &:nth-child(5) {
                    left: utils.to-vw(311, 1920);
                    bottom: utils.to-vh(301, 1080);

                    img, video {
                        height: utils.to-vh(455, 1080);
                        max-width: utils.to-vw(792, 1920);
                        min-width: utils.to-vw(640, 1920);
                    }
                }
            }
        }

        .animation-variant_2 {
            > * {
                &:nth-child(1) {
                    top: utils.to-vh(80, 1080);
                    left: 0;

                    img, video {
                        height: utils.to-vh(770, 1080);
                        min-width: utils.to-vw(880, 1920);
                        max-width: utils.to-vw(1020, 1920);
                    }
                }

                &:nth-child(2) {
                    bottom: utils.to-vh(80, 1080);
                    right: utils.to-vw(160, 1920);

                    img, video {
                        height: utils.to-vh(448, 1080);
                        width: utils.to-vh(448, 1080);
                    }
                }

                &:nth-child(3) {
                    right: 0;
                    top: 100vh;

                    img, video {
                        min-height: utils.to-vh(300, 1080);
                        max-height: utils.to-vh(540, 1080);
                        width: utils.to-vh(300, 1080);
                    }
                }

                &:nth-child(4) {
                    left: utils.to-vw(120, 1920);
                    top: utils.to-vh(80, 1080);

                    img, video {
                        min-height: utils.to-vh(488, 1080);
                        max-height: utils.to-vh(611, 1080);
                        width: utils.to-vh(488, 1080);
                    }
                }

                &:nth-child(5) {
                    right: utils.to-vh(160, 1080);
                    bottom: 0;

                    img, video {
                        min-height: utils.to-vh(800, 1080);
                        max-height: utils.to-vh(910, 1080);
                        min-width: utils.to-vw(300, 1920);
                        max-width: 50vw;
                    }
                }
            }
        }

        .animation-variant_3 {
            > * {
                &:nth-child(1) {
                    top: utils.to-vh(146, 1080);
                    right: utils.to-vw(160, 1920);

                    img, video {
                        min-height: utils.to-vh(800, 1080);
                        max-height: utils.to-vh(934, 1080);
                        min-width: utils.to-vw(622, 1920);
                        max-width: 50vw;
                    }
                }

                &:nth-child(2) {
                    bottom: utils.to-vh(160, 1080);

                    img, video {
                        height: utils.to-vh(468, 1080);
                        width: utils.to-vw(624, 1920);
                    }
                }

                &:nth-child(3) {
                    right: 0;
                    top: 100vh;

                    img, video {
                        min-height: utils.to-vh(300, 1080);
                        max-height: utils.to-vh(540, 1080);
                        width: utils.to-vh(300, 1080);
                    }
                }

                &:nth-child(4) {
                    left: utils.to-vw(120, 1920);
                    top: utils.to-vh(80, 1080);

                    img, video {
                        min-height: utils.to-vh(488, 1080);
                        max-height: utils.to-vh(611, 1080);
                        width: utils.to-vh(488, 1080);
                    }
                }

                &:nth-child(5) {
                    bottom: 0;
                    right: utils.to-vh(160, 1920);

                    img, video {
                        height: utils.to-vh(800, 1080);
                        min-width: utils.to-vw(300, 1920);
                        max-width: 50vw;
                    }
                }
            }
        }
    }
}
