@use './common' as common;
@use '../../variables' as vars;

.intro_v3 {
    @include common.main;

    color: white;

    .media_container {
        width: 100%;
        height: 100%;
    }

    .grid_container {
        top: unset;
        right: -1px;

        &.solid {
            bottom: vars.to-vh(220, 1920);
        }
        &.dotted {
            opacity: 0.6;
            bottom: vars.to-vh(-21, 1920);
        }

        line {
            stroke: #A9AAAD;
        }
    }
}
