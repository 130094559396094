import { get } from "lodash";

export default class SessionData {
  constructor() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.screenWidth = window.screen.width;
    this.screenHeight = window.screen.height;
    this.dpi = window.devicePixelRatio;
    this.os = window.navigator.platform;
    this.browser = this.detectBrowser();
    this.cpuCores = navigator.hardwareConcurrency;
    this.performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance;
    this.url = location.href;
    this.memory_limit = this.getMemoryLimit();
  }

  detectBrowser() {
    var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
      tem=/\brv[ :]+(\d+)/g.exec(ua) || [];
      return {name:'IE',version:(tem[1]||'')};
    }
    if(M[1]==='Chrome'){
      tem=ua.match(/\bOPR|Edge\/(\d+)/)
      if(tem!=null)   {return {name:'Opera', version:tem[1]};}
    }
    M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}

    return `${M[0]} ${M[1]}`;
  }

  getMemoryLimit() {
    return get(this.performance, 'memory.jsHeapSizeLimit', null);
  }

  snapshot() {
    return this.toData();
  }

  toData() {
    return JSON.parse(JSON.stringify(this));
  }
}
