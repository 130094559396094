import { JSXElementConstructor } from 'react';

import {
    AssetType
} from '../../utils/storage';


export type WrapperProps = {
    children: JSX.Element | JSX.Element[],
}

export type AnimationData<DataType = object> = {
    Component: JSXElementConstructor<DataType>
    canLoop: boolean,
    duration: number,
    exitOffset: number,
}

export type ChapterContext = {
    width: number,
    height: number,
}

export default class Chapter<DataType = object> {
    /**
     * Use this method to transform one chapter into another type or a set of new chapters.
     *
     * @returns - "null" should be interpreted as "there is no changes/transfomrations for the input data"
     */
    static async dataMiddleware<NewDataType = object>(data: object): Promise<NewDataType | NewDataType[] | null> {
       return null;
    }

    readonly data: DataType;
    readonly channel: object;
    readonly languages: string[];
    readonly context: ChapterContext;

    component: JSXElementConstructor<DataType> = null
    wrapper: JSXElementConstructor<WrapperProps> = null

    /**
     * Defines if a chapter can loop internally. This property is used for animations with only one chapter.
     */
    canLoop: boolean = false;

    /**
     * A list of fonts needed to be loaded for this chapter.
     */
    fonts: string[] = [];

    constructor(data: DataType, channel: object = {}, languages = [], context: ChapterContext) {
        this.data = data;
        this.channel = channel;
        this.languages = languages;
        this.context = context;
    }

    getAssetsToCache(): AssetType[] {
        return [];
    }

    /**
     * Calculate the chapter duration. After this amount of time the component will be removed from DOM.
     *
     * The animation timing without transition:
     *     total animation = entry animation + main animation + exit animation
     *                                                        ^
     *                                                        |
     *                                           (moment to remove from DOM)
     *
     * With a transition it is a bit complicated. It consists from two parts:
     *     total transition = start overlap + rest of the transition animation
     *
     * The animation timing with a transition needs addition offset:
     *     exit offset = MAX(transition start overlap - exit animation, 0)
     *     total animation = entry animation + main animation + exit animation - exit offset
     *                                                        ^
     *                                                        |
     *                                           (moment to remove from DOM)
     *
     * @returns - Duration in seconds when a component should be removed from DOM.
     */
    calculateDuration(): number {
        return 0;
    }

    /**
     * Returns the amount of seconds needed to be subtracted from the total animation duration.
     *
     * @see calculateDuration
     *
     * @returns - Offset in seconds.
     */
    calculateExitOffset(): number {
        return 0;
    }

    getAnimationData(): AnimationData<DataType> {
        return {
            Component: () => this.buildComponent(),
            canLoop: this.canLoop,
            duration: this.calculateDuration(),
            exitOffset: this.calculateExitOffset(),
        };
    }

    protected buildComponent(): JSX.Element {
        const component = (
            <this.component
                {...this.data}
            />
        );

        return this.wrapper ? (
            <this.wrapper>
                {component}
            </this.wrapper>
        ) : component;
    }
}
