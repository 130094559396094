import { ThemeConfig } from "../types";
import { DefaultFullGrid, DefaultSectionGrid } from "./theme-defaults";

const riseLegacy: ThemeConfig = {
	id: "rise",
	name: "Rise",
	backgroundColor: "#000000",
	textColor: "#ffffff",
	hideGridsWhenPlayingMedia: true,
	gridColor: `rgba(255, 255, 255, .5)`,
	maxMediaWidth: 648,
	gridOffset: {
		circle: 0,
		horizontal: 0,
		vertical: 0,
	},
	gridLineThickness: {
		circle: 2,
		horizontal: 2,
		vertical: 2,
	},
	fullGrid: DefaultFullGrid,
	sectionGrid: DefaultSectionGrid,
	codification: ["heading", "ticker"],
	enableScrim: true,
	showQr: true,
	footer: {
		showIconDividers: true,
	},
	sequence: {
		headlineSwitchDuration: 6_000,
		mediaDuration: 6_000,
		mediaGap: 1_000,
		playMediaInterval: 12_000,
		// playMediaInterval: 2 * 60_000,
	},
	style: {
		headline: {
			// fontSize: 51,
			// lineHeight: 40,
			fontSize: 49,
			marginTop: -5,
			lineHeight: 47,
			fontWeight: 500,
			fontFamily: "helvetica-neue",
		},
		eyebrow: {
			fontFamily: 'helvetica-neue',
			fontSize: 14,
			lineHeight: 14.4,
			fontWeight: 500,
			letterSpacing: '-0.02em',
			textTransform: "uppercase",
		},
		qrContent: {
			fontFamily: 'helvetica-neue',
			fontSize: 13,
			lineHeight: 13,
			letterSpacing: '-0.02em',
			fontWeight: 500,
		},
		ticker: {
			fontFamily: 'futura-condensed',
			fontSize: 28,
			lineHeight: 35,
			textTransform: 'uppercase',
			letterSpacing: '-0.02em',
		}
	},
};

export default riseLegacy;