import {FastLaneSection} from "../types";
import {motion} from "framer-motion";
import React from "react";
import FastLaneSectionContent from "./fast-lane-section-content";
import {ABSOLUTE_FILL} from "../helpers";
import TickerSectionContent from "./ticker-section-content";
import {useDisplayType} from "../store/fast-lane-context";
import {
    useCalculateDims,
    useShowContent,
    useSpotlightOpacity
} from "./section-container.hooks";

interface EntryProps {
    section: FastLaneSection;
    index: number;
}

export default function SectionContainer({section, index}: EntryProps) {
    const {containerRef, showContent} = useShowContent();
    const {left, width} = useCalculateDims({section, index});
    const opacity = useSpotlightOpacity(index);

    return (
        <motion.div
            ref={containerRef}
            style={{
                ...ABSOLUTE_FILL,
                width: width,
            }}
            initial={false}
            animate={{
                opacity,
                left,
            }}
            transition={{
                duration: 1,
            }}
        >
            {showContent && <SectionContent section={section} index={index}/>}
        </motion.div>
    );
}

function SectionContent(props: EntryProps) {
    const display = useDisplayType();

    return display === "ticker" ? <TickerSectionContent {...props}/> : <FastLaneSectionContent {...props}/>
}