import { MediaType } from "../types";
import { useEffect, useState } from "react";
import { loadAsset } from "../storage";
import { LANE_HEIGHT } from '../shared';

export default function MediaPlayer({ media, style }: { media: MediaType, style?: React.CSSProperties }) {
	const [source, setSource] = useState<string | undefined>(media?.blob ? URL.createObjectURL(media?.blob) : undefined);

	useEffect(() => {
		if (source) {
			console.log('source already set');
			return;
		}
		loadAsset({media, forceHeight: LANE_HEIGHT}).then((media) => {
			setSource(media?.blob ? URL.createObjectURL(media?.blob) : media.url);
		}).catch((err) => {
			setSource(media.url);
			console.error(err);
		});
	}, [media, source]);


	if (!source) {
		return null;
	}

	if (media.resource_type === "video") {
		return (
			<video
				src={source}
				autoPlay
				muted
				loop
				style={{
					height: "100%",
					objectFit: "cover",
					maxWidth: "unset",
					width: "100%",
					...style,
				}}
			/>
		);
	}

	if (media.resource_type === "image") {
		return (
			<img
				src={source}
				style={{
					height: "100%",
					objectFit: "cover",
					maxWidth: "unset",
					width: "100%",
					...style,
				}}
			/>
		);
	}

	return null;
}