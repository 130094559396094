import NbyTemplate from '../../core/animations/nby/nby'

interface NbyProps {
    variant: 'steps' | 'pricing';
}

export default function Nby(props: NbyProps) {

    return (
        <div>
            <NbyTemplate data={props}/>
        </div>
    )
}


export function calculateDuration(data: NbyProps, config: any) {
    return 30_000;
}
