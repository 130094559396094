import {DEFAULT_BORDER, isPortrait, useRpx} from "../shared";
import {ReactNode} from "react";

export default function TopSection({children}: {children: ReactNode}) {
    const rpx = useRpx();
    const portrait = isPortrait();

    return (
        <div
            style={{
                height: isPortrait() ? 190 : rpx(180),
                borderBottom: DEFAULT_BORDER,
                display: 'flex',
                width: '100%',
                position: 'relative',
            }}
        >
            {children}
        </div>
    )
}