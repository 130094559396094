import React, {useEffect, useState} from 'react';
import Ticker from './ticker';
import _ from 'lodash';
import {getKidsTakeover, shouldPlayTakeover} from "../fastlane-kids-takeover/shared";
import FastLaneKidsTakeOver from "../fastlane-kids-takeover";
import * as Hyperlive from "../../hyperlive/utils";
import {pixelMapReady} from "../../../../utilities";


export type FastlaneTickerProps = {
	json_data: {
		category: object,
		silo: object,
	},
};

export const DEFAULT_FASTLANE_CONFIG = {
	screenWidth: 3_840,
	totalWidth: 15_360,
	height: 96,
};

const configData = Hyperlive.fetchChannelInfo()?.json_config;
const info = {
	trackLength: configData?.track_length ? Number(configData.track_length) : null,
	trackHeight: configData?.track_height ? Number(configData.track_height) : null,
	renderWidth: configData?.render_width ? Number(configData.render_width) : null,
};

const FastlaneTicker = ({ json_data }: FastlaneTickerProps) => {
	const [playTakeover, setPlayTakeover] = useState(null);
	const kidsTakeover = getKidsTakeover();
	const [key, setState] = useState(0);

	console.log('FastLaneTicker', json_data);
	const { category, silo } = json_data;

	const zones = [category, silo];

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const demo = urlParams.get('origin');

	let config = DEFAULT_FASTLANE_CONFIG;

	if (demo === 'pixel-map') {
		const height = Number(urlParams.get('height') ?? 48);
		const width = Number(urlParams.get('width') ?? 3_840);
		const isInternalWrapping = urlParams.get('wrapping-style') === 'internal';
		const screenWidth = Number(isInternalWrapping ? window.innerWidth : width);

		config = {
			height,
			screenWidth,
			totalWidth:  width,
		}
	}

	const playDefault = () => {
		setPlayTakeover(false);
		const freq = kidsTakeover?.json_data?.frequency;

		if (freq) {
			shouldPlayTakeover(freq, () => {
				setPlayTakeover(true);
			})
		}
	}

	useEffect(() => {
		playDefault();
	}, []);


	useEffect(() => {
		pixelMapReady(() => {
		  setState(key + 1);
		});
	}, []);

	if (playTakeover === null) {
		return null;
	}

	return (
		<>
			{
				playTakeover && (
					<FastLaneKidsTakeOver.FastLaneTickers
						data={kidsTakeover}
						info={info}
						onComplete={playDefault}
					/>
				)
			}
			<Ticker
			 	key={key}
				zones={zones}
				height={config.height}
				screenWidth={config.screenWidth}
				totalWidth={config.totalWidth}
				poleWidth={325}
				frameCount={5}
				// inset={25}
				top={0}
				left={0}
			/>
			{_.get(json_data, 'has_fitting_room_ticker', false) && (
				<FittingRoomTicker data={json_data} />
			)}
		</>
	);
};

const FittingRoomTicker = ({ data }) => {
	const top = _.get(data, 'fitting_room_dims.top', 288);
	const left = _.get(data, 'fitting_room_dims.left', 800);
	const width = _.get(data, 'fitting_room_dims.width', 2700);
	const height = _.get(data, 'fitting_room_dims.height', 96);
	const zone = _.get(data, 'fitting_room', []);

	return (
		<Ticker
			rotate={'180deg'}
			zIndex={2}
			zones={[zone, zone]}
			height={height}
			screenWidth={width}
			totalWidth={width}
			poleWidth={325}
			frameCount={5}
			// inset={25}
			top={top}
			left={0}
			offset={left}
		/>
	);
};

export default FastlaneTicker;
