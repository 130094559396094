import { ThemeConfig } from "../types";
import { DefaultFullGrid, DefaultSectionGrid } from "./theme-defaults";

const riseLegacy: ThemeConfig = {
	id: "rise-legacy",
	name: "Rise Legacy",
	backgroundColor: "#000",
	textColor: "#fff",
	hideGridsWhenPlayingMedia: true,
	gridColor: `rgba(255, 255, 255, .3)`,
	gridOffset: {
		circle: 0,
		horizontal: 0,
		vertical: 0,
	},
	gridLineThickness: {
		circle: 2,
		horizontal: 2,
		vertical: 2,
	},
	maxMediaWidth: undefined,
	fullGrid: DefaultFullGrid,
	sectionGrid: DefaultSectionGrid,
	sequence: {
		headlineSwitchDuration: 8_000,
		mediaDuration: 4_000,
		mediaGap: 1_000,
		playMediaInterval: 2 * 60_000,
	},
	codification: ["heading", "ticker"],
	enableScrim: false,
	showQr: true,
	style: {
		headline: {
			marginTop: 'inherit',
			fontSize: 51,
			lineHeight: 43,
			fontWeight: 700,
			textTransform: "uppercase",
			fontFamily: 'futura-condensed',
		},
		eyebrow: {
			fontSize: 14,
			lineHeight: 14.4,
			fontWeight: 500,
			letterSpacing: "-0.02em",
			// textTransform: "uppercase",
			fontFamily: 'helvetica-neue'
		},
		qrContent: {
			fontFamily: 'helvetica-neue',
			fontSize: 13,
			letterSpacing: '-0.02em',
			lineHeight: 13,
			fontWeight: 500,
		},
		ticker: {
			fontFamily: 'futura-condensed',
			fontSize: 28,
			lineHeight: 35,
			textTransform: 'uppercase',
			letterSpacing: '-0.02em',
		}
	},
};

export default riseLegacy;