import React, {useMemo} from "react";
import ScreenWrapper from "./components/ScreenWrapper";
import {MediaType} from "../../../jordan/shared";
import BookingFooter from "./components/BookingFooter";
import Background from "./components/Background";
import Title from "./components/Title";
import Header from "./components/Header";

interface NsilScheduleProps {
    supertitle?: string;
    title?: string;
    status?: string;
    showUpcoming?: string;
    currentMonth?: string;
    currentDate?: string;
    smallFont?: string;
    backgroundMedia: Array<MediaType>;
    lockup: Array<MediaType>;
    theme: string;
}

export default function NsilScheduleV2(props: NsilScheduleProps) {
    return (
        <ScreenWrapper>
            <Background media={props.backgroundMedia} status={props.status} />

            <Header status={props.status}/>

            <Title
                supertitle={props.supertitle}
                title={props.title}
                currentMonth={props.currentMonth}
                currentDate={props.currentDate}
                media={props.lockup}
                theme={props.theme}
                status={props.status}
            />

            <BookingFooter/>
        </ScreenWrapper>
    )
}