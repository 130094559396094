import {CSS, FastLaneSection, PixelMap, TickerIconType} from "../types";
import {ABSOLUTE_FILL, convertPixelPitchForTicker, useTranslatable} from "../helpers";
import Typography from "./typography";
import {useFastLane} from "../store";
import {useMemo} from "react";
import TickerIcon from "./ticker-icon";
import {usePixelMap} from "../store/fast-lane-context";
import {TICKER_HEIGHT} from "../shared";

interface TickerSectionContentProps {
    section: FastLaneSection;
    index: number;
}

function calculateHeight(pixels: any, pixelMap: PixelMap) {
    if (!pixels || pixels.length === 0) {
        return null
    }

    const pxNumber = typeof pixels === 'string' ? parseFloat(pixels) : pixels;

    return pxNumber / TICKER_HEIGHT * pixelMap.contentHeight;
}

export default function TickerSectionContent({section, index}: TickerSectionContentProps) {
    const pixelMap = usePixelMap();

    const offsetLeft = useMemo(() => {
        if (section?.position?.tickerOffsetLeft !== undefined) {
            return convertPixelPitchForTicker(section.position.tickerOffsetLeft, pixelMap);
        }

        return 0;
    }, [section, index]);

    const primary = useTranslatable(section.ticker.primary);
    const secondary = useTranslatable(section.ticker.secondary);

    return (
        <div
            style={{
                ...ABSOLUTE_FILL,
            }}
        >
            <Slide
                background={"transparent"}
                text={"var(--primary-color, white)"}
                content={!section.position?.showPrimaryTicker ? primary : ""}
                icon={section?.ticker?.primaryIcon}
            />

            <Slide
                background={"transparent"}
                text={"#000"}
                content={!section.position?.showSecondaryTicker ? secondary : ""}
                icon={section?.ticker?.secondaryIcon}
            />
        </div>
    );
}

function Slide({
                   background,
                   content,
                   text,
                   icon,
               }: {
    background: string;
    content: string;
    text: string;
    offsetLeft?: number;
    icon?: TickerIconType;
}) {
    const pixelMap = usePixelMap();
    const theme = useFastLane((f) => f.getTheme());

    const tickerStyle = useMemo(() => {
        const {
            fontSize,
            lineHeight,
            paddingTop,
            ...other
        } = theme.style.ticker;

        return {
            ...other,
            fontSize: calculateHeight(fontSize, pixelMap),
            lineHeight: calculateHeight(lineHeight, pixelMap),
            paddingTop: calculateHeight(paddingTop, pixelMap),
        }
    }, [theme.style.ticker]) as CSS;

    console.log(tickerStyle.fontSize);

    return (
        <div
            style={{
                height: "50%",
                width: "100%",
                backgroundColor: background,
                color: text,
                display: "flex",
                alignItems: "center",
                // border: '1px solid blue',
                // boxSizing: 'border-box',
                gap: 20,
                position: 'relative',
            }}
        >
            {
                icon && (
                    <TickerIcon
                        icon={icon}
                        size={tickerStyle.fontSize}
                    />
                )
            }

            <Typography
                style={tickerStyle}
                text={content}
                codification={theme.codification === "all" || theme.codification.includes("ticker")}
            />
        </div>
    );
}

