import React, { useContext } from "react";

import Typography from "./Typography";

import Context from "./Codification.context";

const CodificationSpace = () => {
	const { typography } = useContext(Context);

	return (
		<Typography className="c-codification__space" {...typography} tag="span">
			<span>&nbsp;</span>
		</Typography>
	);
};

export default CodificationSpace;
