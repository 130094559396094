import { type MediaType, Takeover } from '../types';
import { get, set } from "./index";
import { wait } from "../helpers";

export interface TakeoverState {
	takeover: Takeover;
	enableTakeover: (config: Takeover) => Promise<void>;
	setTakeover: (takeover: Takeover) => void;
	disableTakeover: () => void;
	getTakeoverEntryIndex: () => number | 'all';
}

export const takeoverState = (set: set, get: get): TakeoverState => ({
	takeover: {
		enabled: false,
		variant: "full",
		media: null,
		tiling: 'stretch',
		spotlightEntryIndex: undefined,
		takeOverDuration: 8_000,
		entryTransition: 'from-left',
		exitTransition: 'from-right',
	},

	setTakeover: (takeover: Takeover) => set({ takeover }),

	enableTakeover: async (config: Takeover) => {
		get().setTakeover({
			...get().takeover,
			...config,
		});

		const duration = config?.takeOverDuration ?? get().takeover.media?.duration ?? 8_000;

		await wait(duration);
		set({ takeover: { ...get().takeover, enabled: false } });
		// await wait(1_000);
		// set({ takeover: { ...get().takeover, spotlightEntryIndex: undefined } });
	},
	disableTakeover: () => set({ takeover: { ...get().takeover, enabled: false } }),
	getTakeoverEntryIndex: () => {
		const takeover = get().takeover;
		const defaultIndex = get().entries.length - 1;
		const spotlightIndex = takeover?.spotlightEntryIndex !== undefined ? takeover.spotlightEntryIndex : defaultIndex;

		return takeover.variant !== 'full' ? spotlightIndex : defaultIndex;
	},
});