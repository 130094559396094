enum TemplateEventsEnum {
	Init = 'template.init',
	InitEnd = 'template.init.end',
	DataLoad = 'template.data.load',
	AssetsLoad = 'template.assets.load',
	DataReady = 'template.data.ready',
	ChaptersInit = 'template.chapters.init',
	ChaptersRender = 'template.chapter.render',
	Start = 'template.start',
	Error = 'template.error',
}

export default TemplateEventsEnum;
