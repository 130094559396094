import React, { CSSProperties } from 'react';
import classNames from 'classnames';

import * as cssClassNames from '../styles/components/GridWithCell.module.scss';

import Grid from './Grid';
import GridCells from './GridCells';


type Props = {
    height?: number,
    width?: number,
    rows: number,
    cols: number,
    cellLength: number,
    toDrawBoundaries?: boolean,
    className?: string,
    style?: CSSProperties,
}

export default function GridWithCells(props: Props) {
    const {
        rows,
        cols,
        cellLength,
        toDrawBoundaries,
        className,
        style = {},
    } = props;

    const height = props.height || (rows * cellLength);
    const width = props.width || (cols * cellLength);


    return (
        <div
            className={classNames(cssClassNames.GridWithCell, className)}
            style={{
                height: `${height}px`,
                width: `${width}px`,
                ...style,
            }}
        >
            <Grid
                width={width}
                height={height}
                cols={cols}
                rows={rows}
                toDrawBoundaries={toDrawBoundaries}
                initialAnimation='visible'
                lineStyle={{
                    strokeWidth: `${100/1920}vh`,
                }}
            />
            <div className='dots'>
                <GridCells
                    rows={rows}
                    cols={cols}
                    cellHeight={cellLength}
                    cellWidth={cellLength}
                />
            </div>
        </div>
    );
}

