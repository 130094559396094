import ReactDOM from 'react-dom/client';
import { isNumber } from 'lodash';

import * as AppEventsService from '../../services/app-events.service';
import TemplateEventsEnum from '../../enums/TemplateEvents.enum';
import Theme from '../../enums/Theme.enum';
import AssetStorage from '../../utils/storage';

import { RawAnimationData } from './types';
import transformChapters from './helpers/transformChapters';
import cacheAssets from './helpers/cacheAssets';
import preloadFonts from './helpers/preloadFonts';
import prepareAnimationChapters from './helpers/prepareAnimationChapters';
import { getChapter } from './chapters/register';
import ChapterPlayerContext, { ChapterPlayerProvider } from './ChapterPlayer.context';
import ChapterPlayer from './ChapterPlayer';

import '../../utils/i18n';


type Props = {
  rawAnimationData: RawAnimationData,
  forcedChapterIndex?: number,
  playOnce?: boolean,
  channel?: object,
  width?: string,
  height?: string,
};

export default async function main(container: HTMLElement, props: Props) {
  AppEventsService.sendEvent(TemplateEventsEnum.Init);

  const {
    channel,
    forcedChapterIndex,
    playOnce,
    rawAnimationData: { chapters: data = [], languages = ['EN'] },
    width,
    height,
  } = props;

  const storage = new AssetStorage();

  AppEventsService.sendEvent(TemplateEventsEnum.DataLoad);

  const activeChapters = isNumber(forcedChapterIndex) ? [data[forcedChapterIndex]] : data;
  const transformedChaptersData = await transformChapters(activeChapters, channel);
  const chapters = transformedChaptersData.map(({ template, ...data }) => {
    const Chapter = getChapter(template);
    return new Chapter(data, channel, languages, { width, height });
  });

  await Promise.all([
    cacheAssets(chapters, storage),
    preloadFonts(chapters),
  ]);

  AppEventsService.sendEvent(TemplateEventsEnum.DataReady);

  const chapterAnimations = prepareAnimationChapters(chapters);

  AppEventsService.sendEvent(TemplateEventsEnum.InitEnd);
  AppEventsService.sendEvent(TemplateEventsEnum.ChaptersInit);

  const root = ReactDOM.createRoot(container);

  return {
    duration: chapterAnimations.reduce((total, chapter) => total + chapter.duration, 0),
    render() {
      root.render(
        <ChapterPlayerProvider
          value={{
            languages,
            channel,
            theme: Theme.light,
          }}
          style={{width: width ?? '100vw', height: height ?? '100vh'}}
        >
          <ChapterPlayer
            chapters={chapterAnimations}
            playOnce={playOnce}
          />
        </ChapterPlayerProvider>
        ,
      );
    },
    clear() {
      root.render(null);
    },
  };
}
