import React from 'react';

import { AssetType } from '../../../utils/storage';
import Chapter from '../Chapter';
import Media from '../../jordan/components/Media';
import type { MediaType } from '../../jordan/shared';


type Props = {
    media?: AssetType[],
};

export default class MediaChapter extends Chapter<Props> {
    component = () => {
        const {
            media,
        } = this.data;

        return this.renderComponent(media);
    }

    renderComponent = (media: Array<MediaType>, preventSlide: boolean = false) => (
        <Media
            preventInitialSlide={preventSlide}
            preventInitial
            media={media}
            width={'100%'}
            height={'100%'}
            useInternalTiming
            preventExit
        />
    )

    canLoop = true

    calculateDuration() {
        const {
            media,
        } =this.data;

        const duration = media?.filter(i => i).reduce((acc, curr) => acc + getMediaDuration(curr), 0);

        return duration;
    }
}

function getMediaDuration(media: AssetType) {
    const {
        resource_type,
        force_duration,
        duration,
    } = media;

    const toShowFullVideo = resource_type === 'video' && force_duration === 'full';
    const assetDuration = toShowFullVideo ? duration : Number(force_duration);

    return assetDuration || 5;
}
