import React, { forwardRef, useEffect, useState } from "react";
import { AnimationProps, motion, useIsPresent } from "framer-motion";

import '../styles/components/text-iteration.scss';

import { secToMs, setAnimationTimeout } from "../shared";

export type TextIteratorProps = {
    textToIterate: string[],
    exitTextToIterate?: string[],
    iterationDuration: number,
    animationProps?: AnimationProps,
}

function TextIterator(props: TextIteratorProps, ref) {
    const {
        textToIterate: initialTextToIterate,
        exitTextToIterate = [],
        iterationDuration,
        animationProps,
    } = props;
    const [index, setIndex] = useState(0);
    const isPresent = useIsPresent();

    const textToIterate = isPresent ? initialTextToIterate : exitTextToIterate;

    const textToShow = textToIterate[index];
    const targetText = textToIterate[textToIterate.length - 1];

    useEffect(() => {
        setIndex(0);
    }, [isPresent])

    useEffect(() => {
        if (index === textToIterate.length - 1) {
            return;
        }

        return setAnimationTimeout(() => setIndex(i => ++i), secToMs(iterationDuration));
    }, [index, textToIterate]);

    return (
        <motion.span
            ref={ref}
            className="text-iteration"
            {...animationProps}
        >
            <span className="text-iteration-target">{targetText}</span>
            <span className="text-iteration-current">{textToShow}</span>
        </motion.span>
    );
}

export default forwardRef(TextIterator);
