import {create} from "zustand";
import {NbyDataType, StepType} from "./types";

export interface NbyState {
    currentIndex: number;
    animationData: NbyDataType | null;

    initialize: (data: NbyDataType) => void;

    stepDuration: number;
    setStepIndex: (index: number) => void;
    getCurrentStep: () => StepType | null;
}

export const useNbyStore = create<NbyState>()((set, get) => ({
    animationData: null,
    currentIndex: 2,

    initialize: (data) => {
        set({animationData: data, currentIndex: 0});
    },

    setStepIndex: (index: number) => {
        set({currentIndex: index});
    },

    stepDuration: 10_000,

    getCurrentStep: () => {
        const steps = get().animationData?.steps;
        const currentIndex = get().currentIndex;

        return currentIndex === null ? null : steps?.[currentIndex];
    },
}));
