import React, {
    useEffect,
    useRef,
    useState
} from 'react';

import setAnimationTimeout from '../core/utils/setAnimationTimeout';

import {
    AnimationData,
} from './Chapter';


function secToMs(seconds: number): number {
    return seconds * 1000;
}

export type ChapterPlayerProps = {
    chaptersData: AnimationData[],
    resetTimestamp?: number,
    playOnce? : boolean,
    onNextChapter?: (data: AnimationData, index: number, chapterCounter: number) => void,
    onExitChapter?: () => void,
}

export default function useChapterPlayer(props: ChapterPlayerProps) {
    const {
        chaptersData,
        playOnce = false,
        onNextChapter = () => null,
        onExitChapter = () => null,
        resetTimestamp = 0,
    } = props;

    const [chapterCounter, setChapterCounter] = useState(0);
    const cancelCurrentAnimationTimeoutRef = useRef(() => {});
    const cancelNewChapterAnimationTimeoutRef = useRef(() => {});

    const index = chapterCounter % chaptersData.length;

    useEffect(() => {
        cancelCurrentAnimationTimeoutRef.current();
    }, [resetTimestamp]);

    useEffect(() => {
        cancelCurrentAnimationTimeoutRef.current();
        setChapterCounter(0);
    }, [chaptersData]);

    useEffect(() => {
        if (chaptersData.length === 0 || playOnce && chapterCounter === chaptersData.length) {
            return;
        }

        const {
            duration: durationInSeconds,
            exitOffset: exitOffsetInSeconds,
            canLoop,
        } = chaptersData[index];

        onNextChapter(chaptersData[index], index, chapterCounter);

        const toLoopChapter = chaptersData.length === 1 && canLoop;
        if (toLoopChapter) {
            return;
        }

        const duration = secToMs(durationInSeconds);
        const exitOffset = secToMs(exitOffsetInSeconds);

        // timer when a current chapter should be removed from the DOM
        cancelCurrentAnimationTimeoutRef.current = setAnimationTimeout(() => {
            onExitChapter();
        }, duration + exitOffset);

        // timer when a new chapter should be added to the DOM
        cancelNewChapterAnimationTimeoutRef.current = setAnimationTimeout(() => {
            setChapterCounter(i => i + 1);
        }, duration);

        return () => {
            cancelNewChapterAnimationTimeoutRef.current();
        };
    }, [chapterCounter, resetTimestamp]);

    return {
        currentIndex: index,
        chapterCounter: chapterCounter,
        nextChapter() {
            setChapterCounter(i => i + 1);
        },
        prevChapter() {
            setChapterCounter(i => {
                const nextIndex = i - 1;
                return nextIndex < 0 ? chaptersData.length - 1 : nextIndex;
            });
        },
        playChapter(index: number) {
            setChapterCounter(index % chaptersData.length);
        },
    };
}
