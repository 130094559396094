import classNames from "classnames";
import { CSSProperties } from "react";

import * as cssClassNames from '../styles/components/GridCells.module.scss';


export type GridCellsProps = {
    rows: number,
    cols: number,
    cellWidth: number,
    cellHeight: number,
    className?: string,
    style?: CSSProperties,
    children?: any,
};

export default function GridCells(props: GridCellsProps) {
    const {
        rows,
        cols,
        cellHeight,
        cellWidth,
        className,
        style,
        children = (
            <div className={cssClassNames.dot}></div>
        ),
    } = props;

    const cells = new Array(cols * rows);
    for (let y = 0; y < rows; y++)  {
        const indexOffset = y * cols;
        for (let x = 0; x < cols; x++)  {
            const index = indexOffset + x;
            cells[index] = (
                <div
                    key={index}
                    className={cssClassNames.cell}
                    style={{
                        width: `${cellWidth}px`,
                        height: `${cellHeight}px`,
                        left: `${x * cellWidth}px`,
                        top: `${y * cellHeight}px`,
                    }}
                >
                    {children}
                </div>
            );
        }
    }

    return (
        <div
            className={classNames(cssClassNames.container, className)}
            style={{
                width: `${cols * cellWidth}px`,
                height: `${rows * cellHeight}px`,
                ...style
            }}
        >
            {cells}
        </div>
    );
}
