import { toJpeg, toPng } from 'html-to-image';
import React from "react";

export enum CAPTURED_SNAPSHOT_FORMAT {
	PNG ='png',
	JPG = 'jpg'
}

const capturePngImage = async (preview) => {
	return toPng(preview, {
		width: preview.offsetWidth,
		height: preview.offsetHeight,
		pixelRatio: 1,
	});
}

const captureJpegImage = async (preview) => {
	return toJpeg(preview, {
		quality: 0.95,
		width: preview.offsetWidth,
		height: preview.offsetHeight,
		pixelRatio: 1,
	});
}

export const captureIframeSnapshot = async (previewRef: React.MutableRefObject<HTMLIFrameElement>, format: CAPTURED_SNAPSHOT_FORMAT) => {
	try {
		if (!Object.values(CAPTURED_SNAPSHOT_FORMAT).includes(format)) {
			throw new Error('Unable to capture snapshot: format is invalid');
		}

		const capturingImageAction = {
			[CAPTURED_SNAPSHOT_FORMAT.PNG]: capturePngImage,
			[CAPTURED_SNAPSHOT_FORMAT.JPG]: captureJpegImage,
		};

		const preview = previewRef.current;
		return await capturingImageAction[format](preview);
	} catch (caughtError) {
		throw caughtError;
	}
}
