import {TickerIconType} from "../types";
import {ReactNode} from "react";
import Jumpman from "./icons/jumpman";
import {useFastLane} from "../store";

interface TickerIconProps {
    icon?: TickerIconType;
    size?: number | string;
}

export default function TickerIcon({icon, size = 200}: TickerIconProps) {
    const icons: Record<TickerIconType, JSX.Element> = {
        jumpman: <Jumpman/>,
    };

    return <div style={{height: size, width: size}}>{icons?.[icon] ?? null}</div>;
}