import { PixelMapJsonData, PixelMapSectionWrap } from "../interfaces/PixelMap.interface";
import PIXEL_MAP_WRAP_TYPE from "../constants/PixelMapWrapType";
import { chain, get } from "lodash";

export const INITIAL_MIN_SIDE_VALUE = 100;
const MAX_SQUARES_TO_RENDER = 10000;

export const getMinSectionSide = (mappedSectionsWrap: PixelMapSectionWrap[]): number => {
	if (!mappedSectionsWrap.length) {
		return INITIAL_MIN_SIDE_VALUE;
	}

	let minSide = mappedSectionsWrap.reduce((minSide: number, currentWrap): number => {
		const currentMinSide = Math.min(currentWrap.width, currentWrap.height);

		if (currentWrap.type === PIXEL_MAP_WRAP_TYPE.SPACER) {
			return minSide;
		}

		return currentMinSide < minSide ? currentMinSide : minSide;
	}, INITIAL_MIN_SIDE_VALUE);

	const totalSquares = mappedSectionsWrap.reduce((total, { width, height }) => {
		const squaresInSlot = Math.floor(width / minSide) * Math.floor(height / minSide);
		return total + squaresInSlot;
	}, 0);

	if (totalSquares > MAX_SQUARES_TO_RENDER) {
		const scalingCoefficient = Math.sqrt(totalSquares / MAX_SQUARES_TO_RENDER);
		minSide = Math.ceil(minSide * scalingCoefficient);
	}

	return Math.floor(minSide);
}

export const isWrapValid = (wrapItem: PixelMapSectionWrap) => {
	if (!wrapItem) {
		return false;
	}

	const isValidSimpleWrap = [
		PIXEL_MAP_WRAP_TYPE.SPACER,
		PIXEL_MAP_WRAP_TYPE.DISPLAY,
	].includes(wrapItem.type);
	const isValidIframeWrap = wrapItem.type === PIXEL_MAP_WRAP_TYPE.IFRAME && wrapItem.channel;

	return isValidSimpleWrap || isValidIframeWrap;
}

export const mapSectionsWrap = (pixelMapJsonData: PixelMapJsonData) => {
	const sections = get(pixelMapJsonData, 'sections', []);

	if (!sections || !sections.length) {
		return [];
	}

	return chain(sections)
		.map('wrap')
		.flatten()
		.value();
}
