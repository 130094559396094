.container {
    width: 100%;
    height: 100%;
}

.media {
    width: 100%;
    height: 100%;
}

.dim {
    filter: brightness(0.8);
}
