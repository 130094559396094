import {useMemo} from "react";
import {SnkrsCalProps} from "./SnkrsCal";

export function useDerivedPlayMode(playMode: SnkrsCalProps['play_mode'], drops: SnkrsCalProps['drops']) {
    return useMemo(() => {
        if (playMode === 'carousel') {
            return 'carousel';
        }

        if (drops.length < 6) {
            return 'carousel';
        }

        return 'grid';
    }, [playMode, drops]);
}