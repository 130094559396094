import {fetchJSON} from "../../../rise/hyperlive/utils";
import {DisplayType, FastLaneInstance, FastLaneSection} from "./types";
import {get} from "lodash";
import {getThemeFromString} from "./themes/themes";
import {DOUBLE_LANE_WIDTH, LANE_HEIGHT, TICKER_HEIGHT} from "./shared";

type GlobalDisplayType = DisplayType | 'editor';

interface InitializeData {
    instance: FastLaneInstance;
    displayType: GlobalDisplayType;
}

/**
 * Pulls in both the configuration and initial data load from the
 * channel, then constructs the instance object that we then
 * use throughout for the editor and specific displays.
 */
export function initialize(): InitializeData {
    const data = fetchJSON()?.json_data as FastLaneInstance;
    const channel = getChannelData();

    const params = new URLSearchParams(window.location.search)
    const displayType = params.get('display') ?? 'fast-lane';

    const instance: FastLaneInstance = {
        isEditor: displayType === 'editor',
        entries: sortEntries(data?.entries),
        theme: getThemeFromString(channel?.theme),
        waveDirection: get(channel, 'wave_direction', 'left-to-right'),
        pixelMap: getPixelMapFromChannel(channel),
        languages: getLanguages(),

        // @TODO: implement takeover for the Fast Lane
        takeover: data?.takeover,
        sequence: data?.sequence,
        takeoverSectionWidths: channel?.takeover_section_widths,
    };

    return {
        instance,
        displayType: displayType as GlobalDisplayType,
    };
}

export function getChannelData(): any {
    return get(window, 'channel.channels.[0].json_config');
}

export function getLanguages(): Array<string> {
    return get(window, 'languages', ['en']);
}

function getPixelMapFromChannel(channel: any): FastLaneInstance['pixelMap'] {
    const fastLanePixelPitch = get(channel, 'fast_lane.pixel_pitch', 1);
    const tickerPixelPitch = get(channel, 'ticker.pixel_pitch', 1);

    return {
        "fast-lane": {
            contentWidth: get(channel, 'fast_lane.content_width', DOUBLE_LANE_WIDTH * 14),
            contentHeight: get(channel, 'fast_lane.content_height', LANE_HEIGHT),
            wrapperWidth: get(channel, 'fast_lane.wrapper_width', 3780),
            wrapperHeight: get(channel, 'fast_lane.wrapper_height', 1080),
            offsetLeft: get(channel, 'fast_lane.offset_left', 0),
            fastLanePixelPitch,
            tickerPixelPitch,
        },
        "ticker": {
            contentWidth: get(channel, 'ticker.content_width', DOUBLE_LANE_WIDTH * 14),
            contentHeight: get(channel, 'ticker.content_height', TICKER_HEIGHT),
            wrapperWidth: get(channel, 'ticker.wrapper_width', 3780),
            wrapperHeight: get(channel, 'ticker.wrapper_height', 1080),
            offsetLeft: get(channel, 'ticker.offset_left', 0),
            fastLanePixelPitch,
            tickerPixelPitch,
        },
    }
}

function sortEntries(entries: Array<FastLaneSection>) {
    // sort by position left, start with the lowest
    return entries.sort((a, b) => {
        const aLeft = get(a, 'position.left', 0);
        const bLeft = get(b, 'position.left', 0);

        return aLeft - bLeft;
    });
}