import {rpx} from "../helpers";
import {useEffect, useMemo, useState} from "react";
import {CSS} from "../types";
import {motion} from "framer-motion";
import Codification from "./codification";
import './typography.scss';

interface TypographyProps {
    text?: string;
    codification?: boolean;
    uppercase?: boolean;
    style: CSS;
    updater?: number;
}

const Code = Codification as any;

export default function Typography(props: TypographyProps) {
    const {codification, uppercase, updater} = props;
    const {fontSize, fontFamily = "helvetica-neue", lineHeight, fontWeight, ...style} = props.style;
    const [start, setStart] = useState(false);
    const [reverse, setReverse] = useState(false);
    const [text, setText] = useState(props.text);
    const [updateKey, setUpdateKey] = useState<number>(updater ?? 0);

    useEffect(() => {
        if (!props.text) {
            return;
        }

        if (props.text === text && updateKey === updater) {
            return;
        }

        setReverse(true);
    }, [props.text, updater, updateKey]);

    useEffect(() => {
        setTimeout(() => {
            setStart(true);
        }, 1000);
    }, []);

    const font = useMemo(() => {
        return {
            "helvetica-neue": "HelveticaNeue",
            "futura-condensed": "FUTURA-Condensed",
            "brut-grotesque": "Brut Grotesque",
            "nike-helvetica": "NikeHelvetica",
            'helvetica-neue-hoi': 'helvetica-neue-hoi',
        }?.[fontFamily] ?? "nope";
    }, [fontFamily]);

    const extra = useMemo(() => {
        return {
            "helvetica-neue": {
                letterSpacing: "-.04em",
            },
            "futura-condensed": {
                letterSpacing: undefined,
            },
            "brut-grotesque": {},
            "nike-helvetica": {},
        }?.[fontFamily] ?? {};
    }, [fontFamily]);

    return (
        <motion.div
            initial={false}
            style={{
                fontFamily: font,
                fontSize: typeof fontSize === "number" ? rpx(fontSize, "font") : fontSize,
                lineHeight: typeof lineHeight === "number" ? rpx(lineHeight, "font") : lineHeight,
                fontWeight: fontWeight,
                textTransform: uppercase ? "uppercase" : "none",
                whiteSpace: "pre-line",
            }}
            className={fontFamily}
            animate={{
                ...extra as any,
                ...style ?? {} as any,
            }}
        >
            {
                codification ? (
                    <Code
                        text={text}
                        typography={{}}
                        characterSwitchAmount={4}
                        characterNextTrigger={2}
                        characterTimeout={17}
                        start={start}
                        reverse={reverse}
                        onEnded={() => {
                            if (reverse) {
                                setText(props.text);
                                setReverse(false);
                            }
                        }}
                    />
                ) : props.text
            }
        </motion.div>
    );
}