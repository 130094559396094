export default class Vital {

	constructor(event = {}, fpsMeter = undefined) {
		this.startedAt = new Date();
		this.current = null;
		this.performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance;
		this.memory = this.getMemory();
		this.event = event;

		if (fpsMeter) {
			this.fps = fpsMeter;
		}
	}

	getMemory() {
		try {
			return JSON.parse(JSON.stringify(this.performance?.memory, [
				'totalJSHeapSize',
				'usedJSHeapSize'
			]));
		} catch (error) {
			console.error(`Unable to retrieve memory records`, error);
		}
	}

	snapshot() {
		this.current = new Date();
		this.secondsRunning = Math.round((this.current - this.startedAt) / 1000);
		this.memory = this.getMemory();
		return this.toData();
	}

	toData() {
		return JSON.parse(JSON.stringify(this));
	}
}
