import { get } from 'lodash';

import main from './main';
import { getScreenDims } from './helpers/get-dims';


const params = new URLSearchParams(window.location.search)
const chapter = parseInt(params.get('chapter'));
const playOnceParam = params.get('playOnce');
const dims = getScreenDims();

main(document.getElementById('root'), {
    rawAnimationData: get(window, 'animations[0].json_data', {}),
    channel: get(window, 'channel.channels[0]'),
    forcedChapterIndex: isNaN(chapter) ? undefined : chapter,
    playOnce: playOnceParam === 'true',
    width: dims?.width,
    height: dims?.height,
}).then(({
    render,
}) => {
    render();
});
