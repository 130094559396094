@use 'sass:math';
@use '../variables' as vars;

$color: #dadada;
$dot-size: vars.to-vh(4, 1920);


.container {
    position: absolute;
    top: 0;
    left: 0;
}

.cell {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.dot {
    width: $dot-size;
    height: $dot-size;
    border-radius: math.div($dot-size, 2);
    background: $color;
}
