import { AnimatePresence, motion } from 'framer-motion';
import { type CSSProperties, useCallback, useMemo, useState } from 'react';
import useFitText from '../../hooks/useFitText';

interface TextRevealProps {
  content: string;
  delay?: number;
  instant?: boolean;
  style?: CSSProperties;
  interval?: number;
}

function TextReveal({ content, delay, instant, interval = 0.02, style = {} }: TextRevealProps) {
  const [element, setElement] = useState<HTMLElement>(null);
  useFitText(element);

  type WordsWithLetterIndex = [
    [
      {
        letter: string,
        index: number,
      }
    ]
  ]

  const words = useMemo<WordsWithLetterIndex>(() => {
    const letters = content.split('');

    if (letters.length === 0) {
      return [] as unknown as WordsWithLetterIndex;
    }

    return letters.reduce((acc, letter = '', index) => {
      if (letter === ' ') {
        return [...acc, []];
      }

      const lastWord = acc[acc.length - 1];

      return [
        ...acc.slice(0, -1),
        [...lastWord, { letter, index }],
      ];
    }, [[{ letter: [] }]] as unknown as WordsWithLetterIndex) as unknown as WordsWithLetterIndex;
  }, [content]);

  const transition = useCallback((index: number) => {
    if (instant) {
      return {};
    }

    return {
      delay: (interval * index) + delay,
      duration: 0,
    };
  }, [instant, delay, words]);

  const exitTransition = useCallback((index: number) => {
    const t = transition(index);

    if (t?.delay) {
      t.delay = t?.delay - delay;
      t.duration = t.duration / 2;
    }

    return t;
  }, [transition, delay]);

  return (
    <AnimatePresence mode={'wait'}>
      <motion.div
        key={content}
        ref={node => setElement(node)}
        style={style}
      >
        {
          words.map((word) => (
            <span>
              {
                word.map(({ letter, index }) => (
                  <motion.span
                    key={index}
                    style={{
                      // respect the whitespace
                      whiteSpace: 'pre',
                    }}
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                    }}
                    exit={{
                      opacity: 0,
                      transition: exitTransition(index),
                    }}
                    transition={transition(index)}
                  >
                    {letter}
                  </motion.span>
                ))
              }
              <span>{' '}</span>
            </span>
          ))
        }
      </motion.div>
    </AnimatePresence>
  );
}

export default TextReveal;