import {
    useCallback,
    useMemo,
    useState
} from 'react';
import {
    AnimatePresence,
} from 'framer-motion';

import * as cssClassNames from '../styles/screens/Services.module.scss';

import {
    getLangText,
    secToMs
} from '../shared';
import { DEFAULT_DURATION_S } from '../constants/transition';
import { BaseChapterProps } from '../components/ChapterRenderer';
import ServiceLang, { ServiceInfo } from '../components/Services/ServiceLang';
import Grid from '../components/Grid';
import { calcTotalDuration } from '../components/Codification/Codification';


export type ServicesProps = BaseChapterProps & {
    list: ServiceInfo[],
};

const gridLineThickness = 1;
const cellSizeRef = 216 / 1080;

const gridAnimationDuration = DEFAULT_DURATION_S;
const itemAnimationDuration = DEFAULT_DURATION_S;
const nextItemDelay = 0.25;
const activeItemDisplayDuration = 4;
const codificationCharacterDuration = 0.03;

export default function Services(props: ServicesProps) {
    const {
        list,
        languages = [],
        width,
        height,
    } = props;

    const cellSize = cellSizeRef * width;
    const cols = Math.ceil(width / cellSize);
    const rows = Math.ceil(height / cellSize);
    const gridWidth = cols * cellSize;
    const gridHeight = rows * cellSize;

    const [currentLangIndexToShow, setCurrentLangIndexToShow] = useState(0);
    const [langIndexToShow, setLangIndexToShow] = useState(0);

    const serviceLangs = useMemo(() => languages.map((lang, index) => (
        <ServiceLang
            key={index}
            list={list}
            language={lang}
            width={width}
            cellHeight={cellSize}
            timingConfig={{
                activeItemDisplayDuration,
                nextItemDelay,
            }}
            itemTimingConfig={{
                animationDuration: itemAnimationDuration,
            }}
            onListDisplayFinished={() => {
                setLangIndexToShow(i => i + 1);
            }}
        />
    )), [list, languages]);

    const onExitComplete = useCallback(() => {
        setCurrentLangIndexToShow(i => i + 1);
    }, []);

    return (
        <div className={cssClassNames.services}>
            <Grid
                width={gridWidth}
                height={gridHeight}
                cols={cols}
                rows={rows}
                minDuration={gridAnimationDuration / 2}
                maxDuration={gridAnimationDuration}
                style={{
                    stroke: `${gridLineThickness}px`
                }}
                initialAnimation='visible'
                exitAnimation='out-top-left'
            />
            <AnimatePresence onExitComplete={onExitComplete}>
                {langIndexToShow === currentLangIndexToShow ? serviceLangs[langIndexToShow] : null}
            </AnimatePresence>
        </div>
    );
}


export function calculateDuration(data: ServicesProps) {
    const {
        list,
        languages = [],
    } = data;

    const entryDuration = 0;

    const allLangsDuration = languages.reduce((acc, lang) => acc + calculateSingleLangDuration(list, lang), 0);

    const result = entryDuration + allLangsDuration;

    const exitDuration = calculateExitDuration(data);

    return secToMs(result) + exitDuration;
}

export function calculateExitDuration(data: ServicesProps) {
    const duration = gridAnimationDuration;

    return secToMs(duration);
}

function calculateSingleLangDuration(list: ServiceInfo[], lang: string) {
    const lastTitleText = getLangText(list[list.length - 1].title, lang);
    const lastItemTitleLength = lastTitleText.replace('\s', '').length;
    const lastItemCodificationDuration = calcTotalDuration({
        timingConfig: {
            control: 'character',
            duration: codificationCharacterDuration,
        },
        characterNextTrigger: 2,
        characterSwitchAmount: 4,
    }, lastItemTitleLength);

    const lastItemDelay = (list.length - 1) * nextItemDelay;
    const entryDuration = lastItemDelay + lastItemCodificationDuration;

    // 1) slide in
    // 2) grow
    // 3) shrink
    // 4) slide out
    const itemSwitchingDuration = 4 * itemAnimationDuration;

    let showDetailsForItems = 3 * itemAnimationDuration + activeItemDisplayDuration;
    showDetailsForItems += (list.length - 1) * (itemSwitchingDuration + activeItemDisplayDuration);

    const exitDuration = lastItemDelay + itemAnimationDuration;

    const duration = entryDuration + showDetailsForItems + exitDuration;

    // 0.5 sec is lost somewhere in calculations or in the animation
    return duration + 0.5;
}
