import React from 'react';
import { motion } from 'framer-motion';

import * as cssClassNames from '../styles/screens/TitleCard.module.scss';

import { hasTextToDisplay } from '../shared';
import Title, {
    calculateDuration as titleCalculateDuration,
    calculateExitDuration as titleCalculateExitDuration,
} from '../components/Title';
import TitleWithSubtitle, {
    TitleWithSubtitleProps,
    calculateDuration as titleWithSubTitleCalculateDuration,
    calculateExitDuration as titleWithSubTitleCalculateExitDuration,
} from '../components/TitleWithSubtitle';


export type TitleCardProps = TitleWithSubtitleProps;

export default function TitleCard(props: TitleCardProps) {
    const component = hasSubtitle(props) ? (
        <TitleWithSubtitle {...props} />
    ) : (
        <Title {...props} />
    );

    return (
        <motion.div
            className={cssClassNames.title_chapter}
            initial={{opacity: 1}}
            exit={{
                opacity: 1,
                transition: {
                    duration: 0,
                    delay: 3
                },
            }}
        >
            {component}
        </motion.div>
    );
}

function hasSubtitle(props: Props) {
    const {
        subtitle,
        languages,
    } = props;

    return subtitle && hasTextToDisplay(subtitle, languages);
}

export function calculateDuration(data: Props) {
    return hasSubtitle(data) ? titleWithSubTitleCalculateDuration(data) : titleCalculateDuration(data);
}

export function calculateExitDuration(data: Props) {
    return hasSubtitle(data) ? titleWithSubTitleCalculateExitDuration(data) : titleCalculateExitDuration(data);
}
