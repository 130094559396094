import styles from './PixelMapChannelSlot.module.scss';
import React from 'react';
import { indexedColor } from "../utils";

const CHANNEL_RENDER_MODE = {
    LANDSCAPE: 'landscape',
    PORTRAIT: 'portrait',
};

const PixelMapChannelSlot = (props) => {
    const {
        channelIndex,
        width,
        height,
        ...otherProps
    } = props;

    const getRenderMode = (width, height) => {
        if (width === height) {
            return CHANNEL_RENDER_MODE.LANDSCAPE;
        }

        return width > height ? CHANNEL_RENDER_MODE.LANDSCAPE : CHANNEL_RENDER_MODE.PORTRAIT;
    }

    const getSlotDimensionsLabelStyles = (width, height, text) => {
        const renderMode = getRenderMode(width, height);
        const isRenderModeLandscape = renderMode === CHANNEL_RENDER_MODE.LANDSCAPE;

        const baseSideSize = isRenderModeLandscape ? width : height;
        const secondarySideSize = isRenderModeLandscape ? height : width;

        const maxPercentage = 20;

        const desiredFontSize = Math.min((baseSideSize * maxPercentage) / 100, (secondarySideSize * maxPercentage) / 100);
        const availableWidthPerCharacter = baseSideSize / text.length;
        const adjustedFontSize = Math.min(desiredFontSize, availableWidthPerCharacter);

        return {
            fontSize: `${adjustedFontSize}px`,
            transform: isRenderModeLandscape ? '' : 'rotate(-90deg)',
        };
    }

    return (
        <div
            {...otherProps}
            className={styles.pixelMapChannelSlot}
            style={{
                width,
                height,
                background: indexedColor(channelIndex),
            }}
        >
            <div
                className={styles.dimensionsLabel}
                style={getSlotDimensionsLabelStyles(width, height, `${width}x${height}`)}
            >
                {width}x{height}
            </div>
        </div>
    );
};

export default PixelMapChannelSlot;
