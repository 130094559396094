import React, {
    CSSProperties,
    useCallback,
    useEffect,
    useMemo,
    useRef
} from 'react';
import { motion } from 'framer-motion';
import ReactHlsPlayer from 'react-hls-player';
import classNames from 'classnames';

import * as cssClassNames from '../styles/components/MediaRenderer.module.scss';

import {
    CoverType,
    MediaType,
} from '../shared';


export type VideoPlayerMode = 'autoplay' | 'play' | 'pause';

export type MediaRendererProps = {
    media: MediaType;
    cover?: CoverType;
    className?: string;
    mediaStyle?: CSSProperties;
    height?: number | string;
    loopVideo?: boolean;
    videoPlayerMode?: VideoPlayerMode;
    toApplyInternalEffect?: boolean;
};

export default function MediaRenderer(props: MediaRendererProps) {
    const {
        media,
        cover,
        height,
        className,
        mediaStyle = {},
        loopVideo = true,
        videoPlayerMode = 'autoplay',
        toApplyInternalEffect = false,
    } = props;
    const {
        resource_type,
        blob,
        url,
        toDim,
    } = media;

    const playerRef = useRef<HTMLVideoElement>();
    const source = useMemo(() => {
        return blob ? URL.createObjectURL(blob) : url;
    }, [media]);

    const style = useCallback((c: string): CSSProperties => ({
        objectFit: c === 'fit' ? 'scale-down' : 'cover',
        ...mediaStyle
    }), [mediaStyle]);

    useEffect(() => {
        if (videoPlayerMode === 'autoplay' || !playerRef.current) {
            return;
        }

        switch (videoPlayerMode) {
            case 'play':
                playerRef.current.play();
                return;

            case 'pause':
                playerRef.current.pause();
                return;
        }
    }, [videoPlayerMode, playerRef.current]);

    function selectComponent () {
        switch (resource_type) {
            case 'image':
                return (
                    <motion.div
                        style={{height: '100%'}}
                        animate={toApplyInternalEffect && media.resource_type === 'image' ? {
                            scale: [1, 1.5],
                            transition: {
                                duration: 30,
                                ease: 'linear',
                                repeatType: 'mirror',
                                repeat: Infinity,
                            }
                        } : undefined}
                    >
                        <img
                            className={cssClassNames.media}
                            src={source}
                            style={style(media.fit ?? cover)} alt={''}
                        />
                    </motion.div>
                );

            case 'video':
                return (
                    <video
                        ref={playerRef}
                        className={cssClassNames.media}
                        style={style('fill')}
                        src={source}
                        muted
                        loop={loopVideo}
                        autoPlay={videoPlayerMode === 'autoplay'}
                    />
                );

            case 'm3u8-video':
                return (
                    <ReactHlsPlayer
                        playerRef={playerRef}
                        src={url}
                        autoPlay={videoPlayerMode === 'autoplay'}
                        controls={false}
                        loop={loopVideo}
                        width='100%'
                        height={height ?? 'auto'}
                        muted={true}
                        className={cssClassNames.media}
                        style={style(cover)}
                    />
                );

            default:
                return null;
        }
    }

    return (
        <div
            className={classNames(cssClassNames.container, {
                [cssClassNames.dim]: toDim
            }, className)}
        >
            {selectComponent()}
        </div>
    )
}
