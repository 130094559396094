import React, { useEffect, useState } from 'react';

import '../../styles/normalize.css';
import '../../styles/main.scss';
import '../../i18n';

import { pixelMapReady } from '../../../../utilities';
import {
    ChapterType,
    prepareShowRunner,
    TransitionConfig
} from '../../shared';
import * as AppEventsService from '../../../../services/app-events.service';
import TemplateEventsEnum from '../../../../enums/TemplateEvents.enum';
import { TickerRunnerProps } from '../../components/TickerRunner';
import Container from '../../components/Container';
import {ChapterRenderOptions} from '../../components/ChapterRenderer';
import ChapterRunner from '../../components/ChapterRunner';
import TitleCard from '../../screens/TitleCard';
import Wayfinding from '../../screens/Wayfinding';
import LayeredMedia from '../../screens/LayeredMedia';
import CampaignIntro from '../../screens/CampaignIntro';
import Services from '../../screens/Services';
import ProductIntro from '../../screens/ProductIntro';
import Carousel from '../../screens/Carousel';
import Podium from '../../screens/Podium';
import Nby from '../../screens/Nby';
import NbyAttract from '../../../core/animations/nby/screens/attract';

type AppProps = {
    data: {
        chapters?: ChapterType[],
        ticker?: TickerRunnerProps,
        transition?: TransitionConfig,
    },
    openChapter?: string | number,
    config: any;
    playOnce?: boolean;
}

const renderOptions: ChapterRenderOptions = {
    title_card: {
        render: TitleCard,
    },
    wayfinding: {
        render: Wayfinding,
    },
    media: {
        render: LayeredMedia,
    },
    services: {
        render: Services,
    },
    product_intro: {
        render: ProductIntro,
        toPreprocess: true,
    },
    campaign_intro: {
        render: CampaignIntro,
        toPreprocess: true,
    },
    carousel: {
        render: Carousel,
    },
    podium: {
        render: Podium,
        canLoop: true,
    },
    nby: {
        render: Nby,
    },
    nby_attract_video: {
        render: NbyAttract,
    },
};

export default function App(props: AppProps) {
    const {
        data = {},
        openChapter = null,
        config,
        playOnce,
    } = props;

    const [key, setKey] = useState(0);
    const [chapters, setChapters] = useState<ChapterType[]>([]);

    useEffect(() => {
        AppEventsService.sendEvent(TemplateEventsEnum.Init);

        pixelMapReady(() => {
            setKey(i => i + 1);
        });

        prepareShowRunner(data, config).then((chapters) => {
            AppEventsService.sendEvent(TemplateEventsEnum.InitEnd);
            AppEventsService.sendEvent(TemplateEventsEnum.DataReady);
            setChapters(chapters || []);
        });
    }, []);

    return (
        <Container
            framed={false}
            width={1080}
            height={1920}
        >
            {chapters.length > 0 ? (
                <ChapterRunner
                    key={key}
                    chapters={chapters}
                    config={config}
                    openChapter={openChapter}
                    renderOptions={renderOptions}
                    globalTicker={data.ticker}
                    transition={data.transition}
                    playOnce={playOnce}
                />
            ) : null}
        </Container>
    )
}
