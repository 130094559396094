import React, { useEffect, useMemo } from 'react';
import LeftGrid from '../components/left-grid';
import LandscapeView from '../components/landscape-view';
import StepsRight from '../components/steps-right';
import StepCounter from '../components/step-counter';
import Split from '../components/split';
import { DEFAULT_BORDER, isPortrait, setAnimationTimeout, themeToStyles, useRpx } from '../shared';
import { useNbyStore } from '../store';
import Typography from '../components/typography';
import MiddleGrid from '../components/middle-grid';
import StepsBottom from '../components/steps-bottom';
import { AnimatePresence, motion } from 'framer-motion';
import { DEFAULT_TRANSITION } from '../../../../rise/constants/transition';

export const VERTICAL_X_PORTRAIT_LINE = 432;


type StepsProps = {
  theme?: string,
};

export default function Steps(props: StepsProps) {
  const {
    theme,
  } = props;

  const rpx = useRpx();
  const currentStep = useNbyStore(state => state.getCurrentStep());
  const currentIndex = useNbyStore(state => state.currentIndex);
  const stepDuration = useNbyStore(state => state.stepDuration);
  const [reverseType, setReverseType] = React.useState<boolean>(false);
  const themedConfig = useMemo(() => themeToStyles(theme), [theme]);

  useEffect(() => {
    setReverseType(false);
    return setAnimationTimeout(() => {
      setReverseType(true);
    }, stepDuration - 1000);
  }, [currentIndex]);

  const stepCounter = useMemo(() => currentStep ? (
    <StepCounter
      step={currentStep.number}
      backgroundSecondary={themedConfig.stepCounterBackgroundSecondary}
    />
  ) : null, [currentStep?.number]);

  if (!currentStep) return null;

  const portrait = isPortrait();


  const themeContainer = {
    background: themedConfig?.background,
    color: themedConfig?.text,
  };

  if (portrait) {
    const content = theme === 'one' ? (
      <div style={{ width: '100%', marginLeft: 58, marginTop: 58, background: 'rgba(0, 0, 0, 0.2)' }}>
        <div
          style={{
            fontSize: 60,
            opacity: .5,
            marginBottom: 20,
          }}
        >
          <AnimatePresence mode={'popLayout'}>
            <motion.div
              key={currentStep.number}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{
                ...DEFAULT_TRANSITION
              }}
            >
              <Typography
                text={`Step ${currentStep.number}`}
                disableCodification
                instant
                primary
                style={{
                  flex: 1,
                  fontFamily: 'helvetica-neue',
                  fontWeight: '500',
                  textTransform: 'uppercase',
                }}
              />
            </motion.div>
          </AnimatePresence>

        </div>

        <div
          style={{
            fontSize: 150,
            lineHeight: '137px',
            maxWidth: '90%',
          }}
        >
          <Typography
            text={currentStep.title}
            disableCodification
            delay={1}
            primary
            style={{
              flex: 1,
              fontFamily: 'helvetica-neue',
              fontWeight: '700',
              textTransform: 'uppercase',
            }}
          />
        </div>

        <div
          style={{
            fontSize: 150,
            marginRight: 120,
            marginTop: 20,
          }}
        >
          <Typography
            text={currentStep.description}
            disableCodification
            delay={1.5}
            style={{
              fontSize: 60,
              flex: 1,
              fontFamily: 'helvetica-neue',
              fontWeight: '400',
            }}
          />
        </div>

      </div>
    ) : (
      <Split
        leftWidth={VERTICAL_X_PORTRAIT_LINE}
        left={stepCounter}
        right={(
          <div
            style={{
              fontSize: 150,
              lineHeight: '137px',
              marginLeft: 58,
              width: '100%',
              height: '100%',
              ...themedConfig.titleContainerStyle,
            }}
          >
            <Typography
              key={currentStep.title}
              text={currentStep.title}
              reverse={reverseType}
            />
          </div>
        )}
      />
    );

    return (
      <div
        style={{
          ...themeContainer,
          height: '1920px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <LeftGrid
          theme={theme}
          hideBottom
          contentHeight={theme === 'one' ? 860 : 649}
          content={content}
          withoutRoundCorners={themedConfig.gridWithoutRoundCorners}
        />

        {
          theme !== 'one' && (
            <div
              style={{
                borderTop: DEFAULT_BORDER,
                borderBottom: DEFAULT_BORDER,
                display: 'flex',
                alignItems: 'center',
                height: 218,
              }}
            >
              <div
                style={{
                  width: VERTICAL_X_PORTRAIT_LINE,
                  borderRight: DEFAULT_BORDER,
                }}
              >
                <MiddleGrid
                  withoutRoundCorners={themedConfig.gridWithoutRoundCorners}
                />
              </div>

              <div
                style={{
                  fontSize: 46,
                  lineHeight: '1em',
                  paddingLeft: rpx(50),
                  height: '100%',
                  ...themedConfig.descriptionContainerStyle,
                }}
              >
                <Typography
                  key={currentStep.description}
                  primary={false}
                  text={currentStep.description}
                  reverse={reverseType}
                  codificationSpeed={'fast'}
                  typographyStyle={themedConfig.descriptionStyle}
                />
              </div>
            </div>
          )
        }
        <div
          style={{
            flex: 1,
            position: 'relative',
            width: '100%',
            borderTop: DEFAULT_BORDER,
          }}
        >
          <StepsBottom
            theme={theme}
          />
        </div>
      </div>
    )
      ;
  }

  return (
    <LandscapeView
      left={(
        <LeftGrid
          content={(
            <Split
              left={stepCounter}
              right={(
                <div
                  style={{
                    fontSize: rpx(125),
                    paddingLeft: rpx(50),
                    height: '100%',
                    ...themedConfig.titleContainerStyle,
                  }}
                >
                  <Typography
                    key={currentStep.title}
                    text={currentStep.title}
                    reverse={reverseType}
                  />
                </div>
              )}
            />
          )}
          description={(
            <div
              style={{
                fontSize: rpx(48),
                margin: rpx(50),
                marginRight: rpx(20),
              }}
            >
              <Typography
                key={currentStep.description}
                primary={false}
                text={currentStep.description}
                reverse={reverseType}
                codificationSpeed={'fast'}
                typographyStyle={themedConfig.descriptionStyle}
              />
            </div>
          )}
          withoutRoundCorners={themedConfig.gridWithoutRoundCorners}
        />
      )}
      right={(
        <StepsRight />
      )}
    />
  );
}