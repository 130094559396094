@use './common' as common;
@use '../../variables' as vars;

.intro_v2 {
    @include common.main;

    .top_container {
        height: 33.3%;

        &.top_media_container {
            display: flex;
            align-items: center;
            padding: vars.to-vh(68, 1920);
            width: 100%;

            > * {
                height: 68%;
            }
        }
    }

    .text_container {
        font-size: vars.to-vh(130, 1920);
        line-height: 0.86em;

        > * {
            padding: vars.to-vh(68, 1920);
        }
    }
}

.primary_media_container {
    top: unset;
    left: unset;
    right: 0;
    overflow: hidden;

    > * {
        height: 100%;
        width: 100%;
    }
}

.secondary_media_container {
    top: unset;
    left: -1px;
}

.grid_container {
    left: -1px;

    .grid svg line {
        stroke: #c2c2c2;
    }
}

