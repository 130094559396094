import React, { forwardRef, useMemo } from 'react';
import { useLoader } from '@react-three/fiber';
import { SVGLoader, SVGResult } from 'three/examples/jsm/loaders/SVGLoader';
import * as THREE from 'three';
import { Size } from '../type';

const defaultMaterial = color =>
  new THREE.MeshBasicMaterial({ color });

export type SVGProps = {
  x: number;
  y: number;
  size: Size;
  scaleRatio: number;
  color: number | string;
  url: string;
  material?: THREE.ShaderMaterial;
};
const Svg = forwardRef<SVGProps & THREE.Mesh>(
  ({ x, y, size, scaleRatio, color, url, material }: SVGProps, ref) => {
    const mat = material || defaultMaterial(color);
    const svg = useLoader(SVGLoader, url) as SVGResult;
    const shapes = useMemo(
      () =>
        svg.paths.flatMap((path, index) =>
          path
            .toShapes(true)
            .map(shape => ({ index, shape })),
        ),
      [svg.paths],
    );
    return (
      <group
        key={`svg-${x}`}
        position-x={x}
        position-y={(size.height * scaleRatio) / 2 - y / 2}
        children={shapes.map(({ shape }, key) => (
          <mesh 
            userData={{height: size.height * scaleRatio}}
            key={`svg-mesh-${key}`} ref={ref} material={mat}>
            <shapeGeometry attach="geometry" args={[shape]} />
          </mesh>
        ))}
        scale={[scaleRatio, -scaleRatio, 1]}
      />
    );
  },
);

export default Svg;
