import { ReactNode, useMemo } from 'react';
import { ABSOLUTE_FILL, useFarthestPoint } from '../helpers';
import { motion } from 'framer-motion';
import { useFastLane } from '../store';
import { DEFAULT_EASE } from '../../../../jordan/shared';
import { useActiveSpotlightIndex, useTakeoverTransition } from './takeover-transition.hooks';

interface TakeoverTransitionProps {
  children: ReactNode;
}

export default function TakeoverTransition({ children }: TakeoverTransitionProps) {
  const variant = useFastLane(f => f.takeover?.variant);
  const spotlightIndex = useActiveSpotlightIndex();
  const transition = useTakeoverTransition({ spotlightIndex: spotlightIndex === 'all' ? undefined : spotlightIndex });
  const takeover = useFastLane(f => f.takeover);
  const farRight = useFarthestPoint();

  return (
    <motion.div
      id="takeover-transition"
      initial={transition.initial}
      animate={transition.animate}
      exit={transition.exit}
      style={{
        ...ABSOLUTE_FILL,
        width: farRight,
        zIndex: variant === 'background' ? 0 : 10,
      }}
      transition={{
        ease: DEFAULT_EASE,
        duration: takeover?.transitionDuration ?? 3,
      }}
    >
      {children}
    </motion.div>
  );
}