import { NbyDataType } from './types';
import { useNbyStore } from './store';
import React, { useEffect, useState } from 'react';
import Steps from './screens/steps';
import Pricing from './screens/pricing';
import './style.scss';
import '../../../jordan/styles/fonts.scss';
import { setAnimationTimeout } from './shared';

type NbyProps = {
  data: NbyDataType,
};

export default function Nby(props: NbyProps) {
  const {
    data,
  } = props;

  const initialize = useNbyStore(state => state.initialize);
  const animationData: Partial<NbyDataType> = useNbyStore(state => state?.animationData) ?? {};
  const {
    variant = 'steps',
    steps = [],
  } = animationData;
  const stepDuration = useNbyStore(state => state.stepDuration);
  const setStepIndex = useNbyStore(state => state.setStepIndex);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    initialize(data);
  }, [variant]);

  useEffect(() => {
    if (variant === 'pricing') {
      return;
    }
    if (!steps || !setStepIndex) {
      return;
    }

    setStepIndex(index);
    return setAnimationTimeout(() => {
      setIndex(i => (++i % steps.length));
    }, stepDuration);
  }, [variant, steps, setStepIndex, index]);

  return variant === 'pricing' ? <Pricing theme={data?.theme} /> : <Steps theme={data?.theme} />;
}
