import Typography from "./Typography";
import React from "react";
import {ThemeType} from "../shared";
import JumpMan from "../assets/icons/JumpMan";

interface ChapterHeaderProps {
    theme: ThemeType
}

export default function ChapterHeader({theme}: ChapterHeaderProps) {
    const color = theme === 'dark' ? 'white' : 'near-black';

    return (
        <div style={{margin: '38px 0', display: 'flex', justifyContent: 'space-between'}}>
            <div style={{height: '38px', color, display: 'flex'}}>
                {/*<JumpMan width={46} height={46}/>*/}
            </div>
            {/*<Typography variant={'jordan'} color={color} style={{fontWeight: '500'}} size={24}>*/}
            {/*    WORLD OF FLIGHT*/}
            {/*</Typography>*/}
        </div>
    )
}