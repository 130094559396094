import { useCallback, useEffect, useReducer, useRef } from 'react';

const reducer = (state, action) => {
	switch (action) {
		case 'INCREMENT':
			return state + 1;
		case 'RESET':
			return 0;

		default:
			return state;
	}

};

export const useReadyCounter = (total, callback, msg) => {
	const [counter, dispatch] = useReducer(reducer, 0);

	useEffect(() => {
		if (total > 0 && counter >= total) {
			dispatch('RESET');
			if (typeof callback === 'function') {
				callback(() => {
					dispatch('RESET')
				});
			}
		}
	}, [total, counter]);

	const updateCounter = useCallback(() => {
		dispatch('INCREMENT');
	}, [counter]);

	return { counter, updateCounter };
};
